<div class="modal" [ngClass]="{ 'is-active': isVisible }">
  <div class="modal-background"></div>
  <form
    *ngIf="form"
    [formGroup]="form"
    (ngSubmit)="onConfirmClick()"
    autocomplete="off"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <div formArrayName="formFields">
          <div *ngFor="let field of fields; index as i" class="field">
            <label [for]="field.inputLabel" class="label">{{
              field.inputLabel
            }}</label>
            <select
              *ngIf="field.inputType === 'dropdown'"
              class="select is-fullwidth"
              #modalInput
              [formControlName]="i"
              [id]="field.inputLabel"
              (change)="customErrors[field.inputLabel] = []"
              [ngClass]="{
                'is-danger':
                  formSubmitted &&
                  (formControls[i].errors ||
                    customErrors[field.inputLabel].length > 0)
              }"
              [attr.tabindex]="i + 1"
            >
              <ng-container *ngIf="!field.disableHumanize; else noHumanize">
                <option
                  *ngFor="let option of field.dropDownOptions"
                  [value]="option"
                >
                  {{ option | humanize }}
                </option>
              </ng-container>

              <ng-template #noHumanize>
                <option
                  *ngFor="let option of field.dropDownOptions"
                  [value]="option"
                >
                  {{ option }}
                </option>
              </ng-template>
            </select>
            <ng-container *ngIf="field.inputType === 'boolean'">
              <input
                [id]="field.inputLabel"
                #modalInput
                type="checkbox"
                class="switch is-primary"
                [formControlName]="i"
                (change)="customErrors[field.inputLabel] = []"
                [ngClass]="{
                  'is-danger':
                    formSubmitted &&
                    (formControls[i].errors ||
                      customErrors[field.inputLabel].length > 0)
                }"
                [attr.tabindex]="i + 1"
              />
              <label class="label" [for]="field.inputLabel"></label>
            </ng-container>
            <ng-container *ngIf="field.inputType === 'textarea'">
              <textarea
                class="textarea"
                [placeholder]="field.placeholder"
                [id]="field.inputLabel"
                #modalInput
                [formControlName]="i"
                (change)="customErrors[field.inputLabel] = []"
                [attr.tabindex]="i + 1"
              ></textarea>
            </ng-container>
            <ng-container *ngIf="field.inputType === 'file'">
              <input
                [id]="field.inputLabel"
                #modalInput
                type="file"
                class="input"
                [formControlName]="i"
                (change)="handleFileSelection($event, i)"
                [ngClass]="{
                  'is-danger':
                    formSubmitted &&
                    (formControls[i].errors ||
                      customErrors[field.inputLabel].length > 0)
                }"
                [attr.tabindex]="i + 1"
                [accept]="field.accept"
              />
              <label class="label" [for]="field.inputLabel"></label>
            </ng-container>
            <input
              *ngIf="field.inputType === 'text' || field.inputType === 'email'"
              [placeholder]="field.placeholder"
              [id]="field.inputLabel"
              #modalInput
              class="input"
              [type]="field.inputType"
              [formControlName]="i"
              [pattern]="field.pattern"
              (keydown)="customErrors[field.inputLabel] = []"
              [ngClass]="{
                'is-danger':
                  formSubmitted &&
                  (formControls[i].errors ||
                    customErrors[field.inputLabel].length > 0)
              }"
              [attr.tabindex]="i + 1"
            />
            <ng-container *ngIf="field.inputType === 'ng-select'">
              <ng-select
                #modalInput
                [formControlName]="i"
                [id]="field.inputLabel"
                [items]="field.ngSelectConfigs.options"
                [bindLabel]="field.ngSelectConfigs.bindLabel"
                [bindValue]="field.ngSelectConfigs.bindValue"
                [placeholder]="
                  field.placeholder ? field.placeholder : 'Select an option'
                "
                appendTo="body"
                [multiple]="false"
                [closeOnSelect]="true"
                [clearable]="false"
                (change)="customErrors[field.inputLabel] = []"
                [searchable]="false"
                [ngClass]="{
                  'is-danger':
                    formSubmitted &&
                    (formControls[i].errors ||
                      customErrors[field.inputLabel].length > 0)
                }"
                [attr.tabindex]="i + 1"
              >
                <!-- Customizes the look of each item in the dropdown if a template was provided -->
                <ng-container *ngIf="field.ngSelectConfigs.optionsTemplate">
                  <ng-template ng-option-tmp let-item="item">
                    <ng-container
                      *ngTemplateOutlet="
                        field.ngSelectConfigs.optionsTemplate;
                        context: { $implicit: item }
                      "
                    ></ng-container>
                  </ng-template>
                </ng-container>

                <!-- Customizes the look of the selected item if a template was provided -->
                <ng-container *ngIf="field.ngSelectConfigs.labelTemplate">
                  <ng-template ng-label-tmp let-item="item">
                    <ng-container
                      *ngTemplateOutlet="
                        field.ngSelectConfigs.labelTemplate;
                        context: { $implicit: item }
                      "
                    ></ng-container>
                  </ng-template>
                </ng-container>
              </ng-select>
            </ng-container>

            <ng-container *ngIf="field.inputType === 'infoMessage'">
              <div
                class="
                  flex
                  items-center
                  gap-2
                  rounded-lg
                  bg-steel-blue-50/30
                  text-steel-blue-500
                  p-3
                "
              >
                <svg-icon
                  src="/assets/icons/general/info.svg"
                  class="shrink-0"
                ></svg-icon>
                <span> {{ field.message }}</span>
              </div>
            </ng-container>
            <div
              *ngIf="formSubmitted && formControls[i].errors"
              class="text-red-600 text-sm"
            >
              <p *ngIf="formControls[i].errors.required">
                {{ field.inputLabel }} is required
              </p>
              <p *ngIf="formControls[i].errors.email">
                Please enter a valid email address
              </p>
              <p *ngIf="formControls[i].errors.minlength">
                {{ field.inputLabel }} cannot be shorter than
                {{ field.minLength }}
                characters
              </p>
              <p *ngIf="formControls[i].errors.maxlength">
                {{ field.inputLabel }} cannot be longer than
                {{ field.maxLength }}
                characters
              </p>
              <p *ngIf="formControls[i].errors.min">
                {{ field.inputLabel }} cannot be smaller than {{ field.min }}
              </p>
              <p *ngIf="formControls[i].errors.max">
                {{ field.inputLabel }} cannot be larger than {{ field.max }}
              </p>
              <p *ngIf="formControls[i].errors.pattern">
                {{ patternValidationMessage }}
              </p>
              <!-- Add new validation here -->
            </div>
            <!-- Custom errors-->
            <div
              *ngIf="customErrors[field.inputLabel].length > 0"
              class="text-red-600 text-sm"
            >
              <p *ngFor="let error of customErrors[field.inputLabel]">
                {{ error }}
              </p>
            </div>
            <!--End custom errors-->
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-link"
          type="submit"
          [ngClass]="[confirmBtnClass, isLoading ? 'is-loading' : '']"
          [disabled]="!(form.dirty || formDirty)"
          [attr.tabindex]="fields.length + 1"
        >
          {{ confirmBtnText }}
        </button>
        <button
          class="button"
          (click)="onCancelClick()"
          type="button"
          [ngClass]="{ 'is-disabled': isLoading }"
          [attr.tabindex]="fields.length + 2"
        >
          Cancel
        </button>
      </footer>
    </div>
  </form>
</div>
