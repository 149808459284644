import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  Json: any;
  Upload: any;
};



/** Autogenerated return type of AcceptLineItems */
export type AcceptLineItemsPayload = {
  __typename?: 'AcceptLineItemsPayload';
  orders?: Maybe<Array<Order>>;
};

export type AddendumHeader = {
  __typename?: 'AddendumHeader';
  /** Line items on the Addendum */
  addendumLineItems?: Maybe<Array<AddendumLineItem>>;
  /** Actual Addendum Progress Status */
  addendumStatus?: Maybe<Scalars['String']>;
  /** Clearing and forwarding agent code */
  cfCode?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying when the department of inspection services agent was specified */
  disAgentDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the department of inspection services agent is */
  disAgentName?: Maybe<Scalars['String']>;
  /** Five character location code */
  dischargePort?: Maybe<Scalars['String']>;
  /** eCert Agreement Code */
  eCertAgreementCode?: Maybe<Scalars['String']>;
  /** eCert progress status */
  ecertStatus?: Maybe<Scalars['String']>;
  /** PPECB Error for Addendum */
  errorReason?: Maybe<Scalars['String']>;
  /** Estimated date of departure from load port */
  estimatedDepartureDate?: Maybe<Scalars['ISO8601Date']>;
  /** Export Certificate Product Type */
  exportCertProductType?: Maybe<Scalars['String']>;
  /** Export Certificate Serial Number */
  exportCertSerialNumber?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying the Export Certificate Number */
  exportCertificateNumber?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromCapeTown?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromDurban?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromMaputo?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromOther?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromPortElizabeth?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying when the exporter agent was specified */
  exporterAgentDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the exporter agent is */
  exporterAgentName?: Maybe<Scalars['String']>;
  /** Exporter FBO code */
  exporterCode?: Maybe<Scalars['String']>;
  /** Flight number received from Air Freight shipment associated with the addendum header */
  flightNumber?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying when the forwarding agent was specified */
  forwardingAgentDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the forwarding agent is */
  forwardingAgentName?: Maybe<Scalars['String']>;
  /** Unique identifier for the Addendum */
  id: Scalars['ID'];
  /** PPECB specific field for specifying when the inland inspector was specified */
  inlandInspectorDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the inland inspector is */
  inlandInspectorName?: Maybe<Scalars['String']>;
  /** Load out instructions linked to air freight shipment line items */
  loadOutInstructions?: Maybe<Array<LoadOutInstruction>>;
  /** Five character location code */
  loadPort?: Maybe<Scalars['String']>;
  /** Activity Point code where cert will be issued */
  locationOfIssue?: Maybe<Scalars['String']>;
  /** Logistics Service Provider code */
  lspCode?: Maybe<Scalars['String']>;
  /** Pallet out linked to addendum header */
  palletOut?: Maybe<PalletOut>;
  /** PPECB specific field for specifying when the port inspector was specified */
  portInspectorDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the port inspector is */
  portInspectorName?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying the PPECB Certificate Number */
  ppecbCertificateNumber?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying some name (in print) */
  printedName?: Maybe<Scalars['String']>;
  /** Regime Code linked to load port and phc */
  regimeCode?: Maybe<Scalars['String']>;
  /** Unique id for the request */
  requestId?: Maybe<Scalars['String']>;
  /** Final country of destination */
  shippedTargetCountry?: Maybe<Scalars['String']>;
  /** Full name of the shipped_target_country */
  shippedTargetCountryName?: Maybe<Scalars['String']>;
  /** Target shipping region for addendum */
  shippedTargetRegion?: Maybe<Scalars['String']>;
  /** Vessel booking reference */
  shippingBookingReference?: Maybe<Scalars['String']>;
  /** Internal State of the Addendum */
  state?: Maybe<AddendumHeaderStateEnum>;
  /** Object containing information on the document generated for this addendum header */
  templateOutput?: Maybe<Array<TemplateOutput>>;
  /** eCert progress status */
  transactionId?: Maybe<Scalars['Int']>;
  /** Type of Transport */
  transportType?: Maybe<Scalars['String']>;
  /** Verification Status When Submitting Addendum */
  verificationStatus?: Maybe<Scalars['String']>;
  /** Name of the Vessel */
  vesselName?: Maybe<Scalars['String']>;
  /** Type of vessel */
  vesselType?: Maybe<Scalars['String']>;
  /** Voyage Number for vessels */
  voyageNumber?: Maybe<Scalars['String']>;
};

export type AddendumHeaderAttributes = {
  /** Unique identifier for the Addendum */
  id: Scalars['ID'];
  /** Unique id for the request */
  requestId?: Maybe<Scalars['String']>;
  /** Exporter FBO code */
  exporterCode?: Maybe<Scalars['String']>;
  /** Clearing and forwarding agent code */
  cfCode?: Maybe<Scalars['String']>;
  /** Logistics Service Provider code */
  lspCode?: Maybe<Scalars['String']>;
  /** Type of Transport */
  transportType?: Maybe<Scalars['String']>;
  /** Name of the Vessel */
  vesselName?: Maybe<Scalars['String']>;
  /** Type of vessel */
  vesselType?: Maybe<Scalars['String']>;
  /** Voyage Number for vessels */
  voyageNumber?: Maybe<Scalars['String']>;
  /** Regime Code linked to load port and phc */
  regimeCode?: Maybe<Scalars['String']>;
  /** Vessel booking reference */
  shippingBookingReference?: Maybe<Scalars['String']>;
  /** Five character location code */
  loadPort?: Maybe<Scalars['String']>;
  /** Five character location code */
  dischargePort?: Maybe<Scalars['String']>;
  /** Final country of destination */
  shippedTargetCountry?: Maybe<Scalars['String']>;
  /** Target shipping region for addendum */
  shippedTargetRegion?: Maybe<Scalars['String']>;
  /** Activity Point code where cert will be issued */
  locationOfIssue?: Maybe<Scalars['String']>;
  /** Estimated date of departure from load port */
  estimatedDepartureDate?: Maybe<Scalars['ISO8601Date']>;
  /** eCert Agreement Code */
  eCertAgreementCode?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying the Export Certificate Number */
  exportCertificateNumber?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromCapeTown?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromDurban?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromMaputo?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromOther?: Maybe<Scalars['String']>;
  /** PPECB specific field specifying where in South Africa the goods are being exported from */
  exportFromPortElizabeth?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying when the exporter agent was specified */
  exporterAgentDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the exporter agent is */
  exporterAgentName?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying when the forwarding agent was specified */
  forwardingAgentDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the forwarding agent is */
  forwardingAgentName?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying some name (in print) */
  printedName?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying when the department of inspection services agent was specified */
  disAgentDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the department of inspection services agent is */
  disAgentName?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying when the inland inspector was specified */
  inlandInspectorDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the inland inspector is */
  inlandInspectorName?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying when the port inspector was specified */
  portInspectorDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB specific field for specifying who the port inspector is */
  portInspectorName?: Maybe<Scalars['String']>;
  /** PPECB specific field for specifying the PPECB Certificate Number */
  ppecbCertificateNumber?: Maybe<Scalars['String']>;
  /** Export Certificate Product Type */
  exportCertProductType?: Maybe<Scalars['String']>;
  /** Export Certificate Serial Number */
  exportCertSerialNumber?: Maybe<Scalars['String']>;
};

export enum AddendumHeaderStateEnum {
  /** The state of the addendum header is GENERATING */
  Generating = 'GENERATING',
  /** The state of the addendum header is READY */
  Ready = 'READY',
  /** The state of the addendum header is SUBMITTING */
  Submitting = 'SUBMITTING',
  /** The state of the addendum header is SUBMITTED */
  Submitted = 'SUBMITTED',
  /** The state of the addendum header is ERRORED */
  Errored = 'ERRORED'
}

export type AddendumLineItem = {
  __typename?: 'AddendumLineItem';
  /** Carton Quantity */
  cartonQuantity?: Maybe<Scalars['Int']>;
  /** Client Reference for pallet */
  clientRef?: Maybe<Scalars['String']>;
  /** PPECB Commodity code */
  commodityCode?: Maybe<Scalars['String']>;
  /** Consignment number for pallet */
  consignmentNumber?: Maybe<Scalars['String']>;
  /** Container Number */
  containerNumber?: Maybe<Scalars['String']>;
  /** The color of the flesh of the fruit, specifically stone fruit */
  fleshColor?: Maybe<Scalars['String']>;
  /** Gross Weight */
  grossWeight?: Maybe<Scalars['Float']>;
  /** Unique identifier for the Addendum Line */
  id: Scalars['ID'];
  /** Inspected pallet number */
  inspectedSscc?: Maybe<Scalars['String']>;
  /** Target Region for pallet */
  inspectedTargetRegion?: Maybe<Scalars['String']>;
  /** Date of inspection as on PO */
  inspectionDate?: Maybe<Scalars['String']>;
  /** The inspection manifest number for the shipment line item */
  inspectionManifestNumber?: Maybe<Scalars['String']>;
  /** PPECB Actvitiy Point Code for inspection */
  inspectionPoint?: Maybe<Scalars['String']>;
  /** PPECB registered inspector code */
  inspectorCode?: Maybe<Scalars['String']>;
  /** PPECB Activity Point Code */
  loadPointFboCode?: Maybe<Scalars['String']>;
  /** Nett Weight */
  nettWeight?: Maybe<Scalars['Float']>;
  /** Orchard code */
  orchard?: Maybe<Scalars['String']>;
  /** Pack Code */
  packCode?: Maybe<Scalars['String']>;
  /** Pallet Quantity */
  palletQuantity?: Maybe<Scalars['Float']>;
  /** Packhouse code for pallet */
  phc?: Maybe<Scalars['String']>;
  /** Phyto Data */
  phytoData?: Maybe<Scalars['String']>;
  /** Product Class code */
  productClass?: Maybe<Scalars['String']>;
  /** Production Area */
  productionArea?: Maybe<Scalars['String']>;
  /** DALRRD PUC Code */
  puc?: Maybe<Scalars['String']>;
  /** Pallet sequence number */
  sequenceNumberOfInspectedSscc?: Maybe<Scalars['Int']>;
  /** Size Count or Berry Size */
  sizeCountBerrySize?: Maybe<Scalars['String']>;
  /** Pallet Gross Weight */
  ssccGrossWeight?: Maybe<Scalars['Float']>;
  /** Pallet Nett Weight */
  ssccNettWeight?: Maybe<Scalars['Float']>;
  /** Container loading date */
  stuffLoadDate?: Maybe<Scalars['ISO8601Date']>;
  /** Unique number issued by PPECB */
  upn?: Maybe<Scalars['String']>;
  /** Variety Code */
  varietyCode?: Maybe<Scalars['String']>;
};

export type AddendumLineItemAttributes = {
  /** Unique identifier for the Addendum Line */
  id: Scalars['ID'];
  /** Container loading date */
  stuffLoadDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB Activity Point Code */
  loadPointFboCode?: Maybe<Scalars['String']>;
  /** Consignment number for pallet */
  consignmentNumber?: Maybe<Scalars['String']>;
  /** Packhouse code for pallet */
  phc?: Maybe<Scalars['String']>;
  /** Inspected pallet number */
  inspectedSscc?: Maybe<Scalars['String']>;
  /** Client Reference for pallet */
  clientRef?: Maybe<Scalars['String']>;
  /** Unique number issued by PPECB */
  upn?: Maybe<Scalars['String']>;
  /** Target Region for pallet */
  inspectedTargetRegion?: Maybe<Scalars['String']>;
  /** PPECB Commodity code */
  commodityCode?: Maybe<Scalars['String']>;
  /** Variety Code */
  varietyCode?: Maybe<Scalars['String']>;
  /** Product Class code */
  productClass?: Maybe<Scalars['String']>;
  /** Nett Weight */
  nettWeight?: Maybe<Scalars['Float']>;
  /** Gross Weight */
  grossWeight?: Maybe<Scalars['Float']>;
  /** Pallet Nett Weight */
  ssccNettWeight?: Maybe<Scalars['Float']>;
  /** Pallet Gross Weight */
  ssccGrossWeight?: Maybe<Scalars['Float']>;
  /** Carton Quantity */
  cartonQuantity?: Maybe<Scalars['Int']>;
  /** PPECB Actvitiy Point Code for inspection */
  inspectionPoint?: Maybe<Scalars['String']>;
  /** PPECB registered inspector code */
  inspectorCode?: Maybe<Scalars['String']>;
  /** Date of inspection as on PO */
  inspectionDate?: Maybe<Scalars['String']>;
  /** Container Number */
  containerNumber?: Maybe<Scalars['String']>;
  /** Pallet sequence number */
  sequenceNumberOfInspectedSscc?: Maybe<Scalars['Int']>;
  /** DALRRD PUC Code */
  puc?: Maybe<Scalars['String']>;
  /** Orchard code */
  orchard?: Maybe<Scalars['String']>;
  /** Production Area */
  productionArea?: Maybe<Scalars['String']>;
  /** Phyto Data */
  phytoData?: Maybe<Scalars['String']>;
  /** Size Count or Berry Size */
  sizeCountBerrySize?: Maybe<Scalars['String']>;
  /** Pack Code */
  packCode?: Maybe<Scalars['String']>;
  /** Pallet Quantity */
  palletQuantity?: Maybe<Scalars['Float']>;
  /** The color of the flesh of the fruit, specifically stone fruit */
  fleshColor?: Maybe<Scalars['String']>;
  /** The inspection manifest number for the shipment line item */
  inspectionManifestNumber?: Maybe<Scalars['String']>;
};

export type AdditionalField = {
  __typename?: 'AdditionalField';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isPackingInstruction?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type AddressAttributes = {
  addressline1: Scalars['String'];
  addressline2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalcode?: Maybe<Scalars['String']>;
  country: Scalars['String'];
};

export type AirFreightShipment = {
  __typename?: 'AirFreightShipment';
  /** When the shipment actually arrived */
  actualArrivalTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** When the shipment actually departed */
  actualDepartureTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** List of line items for the air freight shipment */
  airFreightShipmentLineItems?: Maybe<Array<AirFreightShipmentLineItem>>;
  /** Who the shipment is billed to */
  billToParty?: Maybe<Scalars['String']>;
  /** Carrier name of the shipment */
  carrierName?: Maybe<Scalars['String']>;
  /** Company of the shipment */
  company?: Maybe<Scalars['String']>;
  /** Receiver of the shipment */
  consignee?: Maybe<Scalars['String']>;
  /** Sender of the shipment */
  consignor?: Maybe<Scalars['String']>;
  /** Controller of the shipment */
  controller?: Maybe<Scalars['String']>;
  /** When the shipment was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Where the shipment departs from */
  departureAirport?: Maybe<Scalars['String']>;
  /** When the shipment is estimated to arrive */
  estimatedArrivalTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** When the shipment is estimated to depart */
  estimatedDepartureTime?: Maybe<Scalars['ISO8601DateTime']>;
  /** Exporter code of the shipment */
  exporterCode?: Maybe<Scalars['String']>;
  /** External transport master ID of the shipment */
  externalTransportMasterId?: Maybe<Scalars['String']>;
  /** FBO code of the shipment */
  fboCode?: Maybe<Scalars['String']>;
  /** Flight number of the shipment */
  flightNumber?: Maybe<Scalars['String']>;
  /** Name of forwarder */
  forwarderName?: Maybe<Scalars['String']>;
  /** Unique ID for the shipment */
  id: Scalars['ID'];
  /** Master waybill number of the shipment */
  masterWaybillNumber?: Maybe<Scalars['String']>;
  /** Phone number of the shipment */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Which port the shipment is headed */
  portOfDestination?: Maybe<Scalars['String']>;
  /** Port code of where the shipment is loaded */
  portOfLoadingCode?: Maybe<Scalars['String']>;
  /** Port location name of where the shipment is loaded */
  portOfLoadingLocation?: Maybe<Scalars['String']>;
  /** Type of shipment */
  shipmentType?: Maybe<Scalars['String']>;
  /** Shipper of the shipment */
  shipper?: Maybe<Scalars['String']>;
  /** State of the shipment */
  state?: Maybe<Scalars['String']>;
  /** When the shipment was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** User name of the shipment */
  userName?: Maybe<Scalars['String']>;
};

/** The connection type for AirFreightShipment. */
export type AirFreightShipmentConnection = {
  __typename?: 'AirFreightShipmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AirFreightShipmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AirFreightShipment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AirFreightShipmentEdge = {
  __typename?: 'AirFreightShipmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AirFreightShipment>;
};

export type AirFreightShipmentLineItem = {
  __typename?: 'AirFreightShipmentLineItem';
  /** The Air Freight Shipment the SLI belongs to */
  airFreightShipment?: Maybe<AirFreightShipment>;
  /** Booking reference for the goods on the SLI */
  bookingReference?: Maybe<Scalars['String']>;
  /** Collection Date for the SLI */
  collectionDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Comments related to the SLI */
  comments?: Maybe<Scalars['String']>;
  /** Commodity code */
  commodityCode?: Maybe<Scalars['String']>;
  /** Company of the Air Freight Shipment Line Item */
  company?: Maybe<Company>;
  /** The consignee of the goods on the SLI */
  consignee?: Maybe<Scalars['String']>;
  /** The consignor of the goods on the SLI */
  consignor?: Maybe<Scalars['String']>;
  /** when the air freight shipment line item was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** Estimated Time of Arrival for the SLI */
  eta?: Maybe<Scalars['ISO8601DateTime']>;
  /** External ID for the SLI */
  externalShipmentId: Scalars['ID'];
  /** The code identifier for the final destination of the goods on the SLI */
  finalDestinationCode?: Maybe<Scalars['String']>;
  /** The final destination of the goods on the SLI */
  finalDestinationLocation?: Maybe<Scalars['String']>;
  /** The code identifier for final port of discharge of the SLI */
  finalPortOfDischargeCode?: Maybe<Scalars['String']>;
  /** The final port of discharge of the SLI */
  finalPortOfDischargeLocation?: Maybe<Scalars['String']>;
  /** Description of goods */
  goodsDescription?: Maybe<Scalars['String']>;
  /** Gross dimensions */
  grossDimensions?: Maybe<Scalars['String']>;
  /** Gross weight */
  grossWeight?: Maybe<Scalars['String']>;
  /** Housebill */
  housebill?: Maybe<Scalars['String']>;
  /** Unique ID for the shipment */
  id: Scalars['ID'];
  /** Whether the SLI is part of a transshipment */
  isTransshipment?: Maybe<Scalars['Boolean']>;
  /** Last status message received */
  lastStatusMessage?: Maybe<Scalars['String']>;
  /** Nature of goods */
  natureOfGoods?: Maybe<Scalars['String']>;
  /** Order reference for the vessel */
  orderReference?: Maybe<Scalars['String']>;
  /** Quantity of packages */
  packageQuantity?: Maybe<Scalars['String']>;
  /** Type of package */
  packageType?: Maybe<Scalars['String']>;
  /** Any special handling instructions on the SLI */
  specialHandling?: Maybe<Scalars['String']>;
  /** State of the Air Freight Shipment Line Item */
  state?: Maybe<Scalars['String']>;
  /** The name of the transshipment carrier */
  transshipmentCarrierName?: Maybe<Scalars['String']>;
  /** The flight number of the transshipment vessel */
  transshipmentFlightNumber?: Maybe<Scalars['String']>;
  /** The port for the transshipment vessel */
  transshipmentPort?: Maybe<Scalars['String']>;
  /** when the air freight shipment line item was updated */
  updatedAt: Scalars['ISO8601DateTime'];
  /** Volume */
  volume?: Maybe<Scalars['String']>;
};

/** The connection type for AirFreightShipmentLineItem. */
export type AirFreightShipmentLineItemConnection = {
  __typename?: 'AirFreightShipmentLineItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AirFreightShipmentLineItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AirFreightShipmentLineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AirFreightShipmentLineItemEdge = {
  __typename?: 'AirFreightShipmentLineItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AirFreightShipmentLineItem>;
};

export type AirFreightShipmentsFilter = {
  /** The carriers to filter on */
  carrierNames?: Maybe<Array<Scalars['String']>>;
  /** The controllers to filter on */
  controllers?: Maybe<Array<Scalars['String']>>;
  /** The flight numbers to filter on */
  flightNumbers?: Maybe<Array<Scalars['String']>>;
  /** The states to filter on */
  states?: Maybe<Array<Scalars['String']>>;
  /** The shippers to filter on */
  shippers?: Maybe<Array<Scalars['String']>>;
  /** The master waybills to filter on */
  masterWaybillNumbers?: Maybe<Array<Scalars['String']>>;
};

/** Attributes for filtering air freight shipments */
export type AirFreightShipmentsFilterAttribute = {
  __typename?: 'AirFreightShipmentsFilterAttribute';
  /** Carriers of the shipment */
  carrierNames?: Maybe<Array<Scalars['String']>>;
  /** Controllers of the shipment */
  controllers?: Maybe<Array<Scalars['String']>>;
  /** Flight numbers of the shipment */
  flightNumbers?: Maybe<Array<Scalars['String']>>;
  /** Master Waybills of the shipment */
  masterWaybillNumbers?: Maybe<Array<Scalars['String']>>;
  /** Shippers of the shipment */
  shippers?: Maybe<Array<Scalars['String']>>;
  /** States of the shipment */
  states?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of AllocateShipmentController */
export type AllocateShipmentControllerPayload = {
  __typename?: 'AllocateShipmentControllerPayload';
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AllocateSliContainer */
export type AllocateSliContainerPayload = {
  __typename?: 'AllocateSliContainerPayload';
  errors?: Maybe<Scalars['String']>;
};

export type AnalysisDownload = {
  __typename?: 'AnalysisDownload';
  commodities?: Maybe<Array<Commodity>>;
  downloadLink?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

export type Audit = {
  __typename?: 'Audit';
  action?: Maybe<Scalars['String']>;
  associatedId?: Maybe<Scalars['Int']>;
  associatedType?: Maybe<Scalars['String']>;
  auditableId?: Maybe<Scalars['Int']>;
  auditableType?: Maybe<Scalars['String']>;
  auditedChanges?: Maybe<Array<Scalars['Json']>>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  userType?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type Badge = {
  __typename?: 'Badge';
  /** The color of the badge to be used in UI */
  color?: Maybe<ColorEnum>;
  /** The date and time the badge was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Unique identifier for the badge */
  id?: Maybe<Scalars['Int']>;
  /** The text to be displayed on the badge */
  text?: Maybe<Scalars['String']>;
  /** The date and time the badge was updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type BreakBulkContainer = {
  __typename?: 'BreakBulkContainer';
  /** Agent Code on the mates file */
  agentCode?: Maybe<Scalars['String']>;
  /** Carton groupings of the container */
  cartonGroupings?: Maybe<Array<CartonGrouping>>;
  /** Internal Id of the Break Bulk Container */
  id: Scalars['ID'];
  /** Concatted Break Bulk Name */
  name?: Maybe<Scalars['String']>;
  /** vessel name that the break bulk is loaded on */
  vesselName?: Maybe<Scalars['String']>;
  /** vessel voyage number that the break bulk is loaded on */
  voyageNumber?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CalculateInvoiceIncoterm */
export type CalculateInvoiceIncotermPayload = {
  __typename?: 'CalculateInvoiceIncotermPayload';
  invoice?: Maybe<Invoice>;
};

/** Autogenerated return type of CancelAddendum */
export type CancelAddendumPayload = {
  __typename?: 'CancelAddendumPayload';
  /** The internal ID of the Addendum */
  id?: Maybe<Scalars['Int']>;
  /** Response message from PPECB */
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CancelAirAddendum */
export type CancelAirAddendumPayload = {
  __typename?: 'CancelAirAddendumPayload';
  /** The internal ID of the air addendum */
  id?: Maybe<Scalars['Int']>;
  /** Delete message */
  message?: Maybe<Scalars['String']>;
};

export type CartonCount = {
  __typename?: 'CartonCount';
  cartonTypeId?: Maybe<Scalars['Int']>;
  commodityId?: Maybe<Scalars['Int']>;
  counts?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  priceMetric?: Maybe<Scalars['String']>;
  stackSizes?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CartonGrouping = {
  __typename?: 'CartonGrouping';
  advanceDueDate?: Maybe<Scalars['ISO8601DateTime']>;
  advancePrice?: Maybe<Scalars['Float']>;
  batchNumber?: Maybe<Scalars['String']>;
  buyer?: Maybe<Company>;
  buyerId?: Maybe<Scalars['Int']>;
  cartons?: Maybe<Scalars['Int']>;
  coldStore?: Maybe<Scalars['String']>;
  commercialTerm?: Maybe<CommercialTerm>;
  commercialTermId?: Maybe<Scalars['Int']>;
  consignmentNumber?: Maybe<Scalars['String']>;
  container?: Maybe<Container>;
  containerId?: Maybe<Scalars['Int']>;
  containerNumber?: Maybe<Scalars['String']>;
  exporterCode?: Maybe<Scalars['String']>;
  /** extended (additional) fields per CG */
  extendedFields?: Maybe<ExtendedField>;
  farmCode?: Maybe<Scalars['String']>;
  /** FBO related CG Farm */
  farmFbo?: Maybe<FoodBusinessOperator>;
  finalDueDate?: Maybe<Scalars['ISO8601DateTime']>;
  finalPrice?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inspectionDate?: Maybe<Scalars['ISO8601DateTime']>;
  inventoryCode?: Maybe<Scalars['String']>;
  /** The invoice line items for this carton grouping */
  invoiceLineItems?: Maybe<Array<InvoiceLineItem>>;
  lineItem?: Maybe<LineItem>;
  lineItemId?: Maybe<Scalars['Int']>;
  loadOutInstructions?: Maybe<Array<LoadOutInstruction>>;
  mark?: Maybe<Scalars['String']>;
  netMass?: Maybe<Scalars['Float']>;
  orchard?: Maybe<Scalars['String']>;
  pack?: Maybe<Scalars['String']>;
  packhouseCode?: Maybe<Scalars['String']>;
  /** FBO related CG Packhouse */
  packhouseFbo?: Maybe<FoodBusinessOperator>;
  packingWeek?: Maybe<Scalars['String']>;
  palletAge?: Maybe<Scalars['Int']>;
  palletGrossMass?: Maybe<Scalars['Float']>;
  palletNumber?: Maybe<Scalars['String']>;
  palletRejected?: Maybe<Scalars['Boolean']>;
  palletStack?: Maybe<Scalars['Int']>;
  productCharacteristic?: Maybe<Scalars['String']>;
  seller?: Maybe<Company>;
  sellerId?: Maybe<Scalars['Int']>;
  sequenceNumber?: Maybe<Scalars['Int']>;
  sizeCount?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  targetCountry?: Maybe<Scalars['String']>;
  targetMarket?: Maybe<Scalars['String']>;
  targetRegion?: Maybe<Scalars['String']>;
  tempDeviceId?: Maybe<Scalars['String']>;
  variety?: Maybe<Variety>;
  varietyId?: Maybe<Scalars['Int']>;
  voyage?: Maybe<Voyage>;
};

/** Filter values for a provided list of carton_groupings */
export type CartonGroupingFilters = {
  __typename?: 'CartonGroupingFilters';
  /** batch numbers found on this list of carton groupings */
  batchNumber?: Maybe<Array<Scalars['String']>>;
  /** consignment numbers found on this list of carton groupings */
  consignmentNumber?: Maybe<Array<Scalars['String']>>;
  /** packing weeks found on this list of carton groupings */
  packingWeek?: Maybe<Array<Scalars['String']>>;
  /** sellers found on this list of carton groupings */
  seller?: Maybe<Array<Company>>;
  /** target markets found on this list of carton groupings */
  targetMarket?: Maybe<Array<Scalars['String']>>;
  /** varieties found on this list of carton groupings */
  variety?: Maybe<Array<Variety>>;
};

export type CartonGroupingSearch = {
  __typename?: 'CartonGroupingSearch';
  /** Carton Groupings for the provided context */
  cartonGroupings: Array<CartonGrouping>;
  /** Carton Grouping Filter Values for the provided context */
  filterValues?: Maybe<CartonGroupingFilters>;
  /** Total count of carton groupings */
  totalCount?: Maybe<Scalars['Int']>;
  /** Total count of pallets */
  totalCountPallets?: Maybe<Scalars['Int']>;
};

/** Attributes for filtering carton groupings */
export type CartonGroupingSearchAttributes = {
  /** carton groupings with these ids */
  ids?: Maybe<Array<Scalars['Int']>>;
  /** carton groupings with these pallet numbers */
  includePallets?: Maybe<Array<Scalars['String']>>;
  /** carton groupings with these batch numbers */
  batchNumbers?: Maybe<Array<Scalars['String']>>;
  /** whether to include carton groupings that are break bulk */
  breakBulk?: Maybe<Scalars['Boolean']>;
  /** whether to include carton groupings with no invoice associations */
  invoiceUnassigned?: Maybe<Scalars['Boolean']>;
  /** whether these carton groupings have a financial invoice */
  financialInvoice?: Maybe<Scalars['Boolean']>;
  /** carton groupings belonging to these companies */
  companyIds?: Maybe<Array<Scalars['Int']>>;
  /** carton groupings with these consignment numbers */
  consignmentNumbers?: Maybe<Array<Scalars['String']>>;
  /** carton groupings with these container ids */
  containerIds?: Maybe<Array<Scalars['Int']>>;
  /** carton groupings with these container numbers */
  containerNumbers?: Maybe<Array<Scalars['String']>>;
  /** currency you would like to filter on */
  currency?: Maybe<Scalars['String']>;
  /** filter up to the end due date */
  endDueDate?: Maybe<Scalars['String']>;
  /** filter events after this date */
  eventsAfter?: Maybe<Scalars['ISO8601DateTime']>;
  /** carton groupings you would like to exclude */
  excludeCartonGroupingIds?: Maybe<Array<Scalars['Int']>>;
  /** pallets you would like to exclude */
  excludePallets?: Maybe<Array<Scalars['String']>>;
  /** states you would like to exclude */
  excludeStates?: Maybe<Array<CartonGroupingStateEnum>>;
  /** limit the number of items returned */
  limit?: Maybe<Scalars['Int']>;
  /** limit the number of pallets returned */
  limitPallets?: Maybe<Scalars['Int']>;
  /** cgs associated with a specific load out instruction */
  loadOutInstructionId?: Maybe<Scalars['Int']>;
  /** whether to include carton groupings with no load out instruction associations */
  loadOutInstructionsUnallocated?: Maybe<Scalars['Boolean']>;
  /** whether to order by pallet number */
  orderByPalletNumber?: Maybe<Scalars['Boolean']>;
  /** order id you would like to filter on */
  orderId?: Maybe<Scalars['Int']>;
  /** range of packing weeks you would like to filter on */
  packingWeeks?: Maybe<Array<Scalars['String']>>;
  /** filter from the start packing week */
  packingWeekFrom?: Maybe<Scalars['String']>;
  /** filter up to the end packing week */
  packingWeekTo?: Maybe<Scalars['String']>;
  /** search field you would like to match the search term to */
  searchField?: Maybe<CartonGroupingSearchableFieldEnum>;
  /** search term you would like to match on */
  searchTerm?: Maybe<Scalars['String']>;
  /** season ids you would like to filter on */
  seasonIds?: Maybe<Array<Scalars['Int']>>;
  /** seller ids you would like to filter on */
  sellerIds?: Maybe<Array<Scalars['Int']>>;
  /** filter from the start due date */
  startDueDate?: Maybe<Scalars['String']>;
  /** states you would like to filter on */
  states?: Maybe<Array<CartonGroupingStateEnum>>;
  /** target markets you would like to filter on */
  targetMarkets?: Maybe<Array<Scalars['String']>>;
  /** whether to include carton groupings with tracking disabled */
  trackingDisabled?: Maybe<Scalars['Boolean']>;
  /** varieties you would like to filter on */
  varietyIds?: Maybe<Array<Scalars['Int']>>;
  /** field you would like to order by */
  orderByField?: Maybe<Scalars['String']>;
  /** direction you would like to order by */
  orderByDirection?: Maybe<Scalars['String']>;
};

export enum CartonGroupingSearchableFieldEnum {
  /** Search on Everything */
  All = 'all',
  /** The batch number of the carton grouping */
  BatchNumber = 'batch_number',
  /** The name of the company buying the carton grouping */
  BuyerName = 'buyer_name',
  /** the container number of the carton grouping */
  ContainerNumber = 'container_number',
  /** The commodity name of the carton grouping */
  CommodityName = 'commodity_name',
  /** The consignment number of the carton grouping */
  ConsignmentNumber = 'consignment_number',
  /** The exporter code of the carton grouping */
  ExporterCode = 'exporter_code',
  /** The farm code of the carton grouping */
  FarmCode = 'farm_code',
  /** The grade of the carton grouping */
  Grade = 'grade',
  /** The inventory code of the carton grouping */
  InventoryCode = 'inventory_code',
  /** The mark of the carton grouping */
  Mark = 'mark',
  /** The orchard of the carton grouping */
  Orchard = 'orchard',
  /** The pack of the carton grouping */
  Pack = 'pack',
  /** The packhouse code of the carton grouping */
  PackhouseCode = 'packhouse_code',
  /** The packing week of the carton grouping */
  PackingWeek = 'packing_week',
  /** Start packing week of the carton grouping */
  PackingWeekFrom = 'packing_week_from',
  /** End packing week of the carton grouping */
  PackingWeekTo = 'packing_week_to',
  /** The pallet number of the carton grouping */
  PalletNumber = 'pallet_number',
  /** The name of the company selling the carton grouping */
  SellerName = 'seller_name',
  /** The size count of the carton grouping */
  SizeCount = 'size_count',
  /** The state of the carton grouping */
  State = 'state',
  /** The sub commodity of the carton grouping */
  SubCommodityName = 'sub_commodity_name',
  /** The target country of the carton grouping */
  TargetCountry = 'target_country',
  /** The target market of the carton grouping */
  TargetMarket = 'target_market',
  /** The target region of the carton grouping */
  TargetRegion = 'target_region',
  /** The variety name of the carton grouping */
  VarietyName = 'variety_name'
}

export enum CartonGroupingStateEnum {
  /** The carton grouping state is created */
  Created = 'created',
  /** The carton grouping state is packed */
  Packed = 'packed',
  /** The carton grouping state is departed_packhouse */
  DepartedPackhouse = 'departed_packhouse',
  /** The carton grouping state is at_cold_store */
  AtColdStore = 'at_cold_store',
  /** The carton grouping state is at_rail_depot */
  AtRailDepot = 'at_rail_depot',
  /** The carton grouping state is loaded_into_container */
  LoadedIntoContainer = 'loaded_into_container',
  /** The carton grouping state is departed_cold_store */
  DepartedColdStore = 'departed_cold_store',
  /** The carton grouping state is departed_for_cold_store */
  DepartedForColdStore = 'departed_for_cold_store',
  /** The carton grouping state is departed_rail_depot */
  DepartedRailDepot = 'departed_rail_depot',
  /** The carton grouping state is at_origin_port */
  AtOriginPort = 'at_origin_port',
  /** The carton grouping is in transit */
  InTransit = 'in_transit',
  /** The carton grouping is delivered */
  Delivered = 'delivered'
}

export type CartonGroupingTransaction = {
  __typename?: 'CartonGroupingTransaction';
  amount?: Maybe<Scalars['Float']>;
  cartonGrouping?: Maybe<CartonGrouping>;
  costCode: CostCode;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<CurrencyEnum>;
  exchangeRate?: Maybe<Scalars['Float']>;
  externalReferenceNum?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  transactionDate?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for CartonGroupingTransaction. */
export type CartonGroupingTransactionConnection = {
  __typename?: 'CartonGroupingTransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CartonGroupingTransactionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CartonGroupingTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CartonGroupingTransactionEdge = {
  __typename?: 'CartonGroupingTransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CartonGroupingTransaction>;
};

/** Returns the name of the location and CG states at that location. */
export type CartonGroupingWithLocation = {
  __typename?: 'CartonGroupingWithLocation';
  /** The actual arrival time */
  actualArrivalTime?: Maybe<Scalars['String']>;
  /** The actual departure time */
  actualDepartureTime?: Maybe<Scalars['String']>;
  /** The batch number */
  batchNumber?: Maybe<Scalars['String']>;
  /** The buyer id number */
  buyerId?: Maybe<Scalars['String']>;
  /** The buyer number */
  buyerName?: Maybe<Scalars['String']>;
  /** The number of cartons */
  cartons?: Maybe<Scalars['Int']>;
  /** The commodity id */
  commodityId?: Maybe<Scalars['String']>;
  /** The commodity name */
  commodityName?: Maybe<Scalars['String']>;
  /** The Consignment number */
  consignmentNumber?: Maybe<Scalars['String']>;
  /** The id of the current location */
  currentLocationId?: Maybe<Scalars['String']>;
  /** The name of the current location */
  currentLocationName?: Maybe<Scalars['String']>;
  /** The type of the current location */
  currentLocationType?: Maybe<Scalars['String']>;
  /** The document status */
  documentStatus?: Maybe<Scalars['String']>;
  /** The estimated arrival time */
  estimatedArrivalTime?: Maybe<Scalars['String']>;
  /** The estimated departure time */
  estimatedDepartureTime?: Maybe<Scalars['String']>;
  /** The exporter code */
  exporterCode?: Maybe<Scalars['String']>;
  /** The farm code */
  farmCode?: Maybe<Scalars['String']>;
  /** The carton grouping grade */
  grade?: Maybe<Scalars['String']>;
  /** The ID */
  id?: Maybe<Scalars['Int']>;
  /** The inspection date */
  inspectionDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** The inventory code */
  inventoryCode?: Maybe<Scalars['String']>;
  /** The mark */
  mark?: Maybe<Scalars['String']>;
  /** The net mass */
  netMass?: Maybe<Scalars['Float']>;
  /** The id of the next location */
  nextLocationId?: Maybe<Scalars['String']>;
  /** The name of the next location */
  nextLocationName?: Maybe<Scalars['String']>;
  /** The type of the next location */
  nextLocationType?: Maybe<Scalars['String']>;
  /** The orchard */
  orchard?: Maybe<Scalars['String']>;
  /** The pack type */
  pack?: Maybe<Scalars['String']>;
  /** The packhouse code */
  packhouseCode?: Maybe<Scalars['String']>;
  /** The packing week */
  packingWeek?: Maybe<Scalars['String']>;
  /** The pallet age measured in days */
  palletAge?: Maybe<Scalars['Int']>;
  /** The pallet gross mass */
  palletGrossMass?: Maybe<Scalars['Float']>;
  /** The pallet number */
  palletNumber?: Maybe<Scalars['String']>;
  /** The id of the previous location */
  previousLocationId?: Maybe<Scalars['String']>;
  /** The name of the previous location */
  previousLocationName?: Maybe<Scalars['String']>;
  /** The type of the previous location */
  previousLocationType?: Maybe<Scalars['String']>;
  /** The product characteristic */
  productCharacteristic?: Maybe<Scalars['String']>;
  /** The seller id */
  sellerId?: Maybe<Scalars['String']>;
  /** The seller name */
  sellerName?: Maybe<Scalars['String']>;
  /** The sequence number */
  sequenceNumber?: Maybe<Scalars['Int']>;
  /** The size count */
  sizeCount?: Maybe<Scalars['String']>;
  /** The state of the carton grouping */
  state?: Maybe<Scalars['String']>;
  /** The status of the carton grouping at the location */
  status?: Maybe<Scalars['String']>;
  /** The commodity id */
  subCommodityId?: Maybe<Scalars['String']>;
  /** The commodity name */
  subCommodityName?: Maybe<Scalars['String']>;
  /** The target country */
  targetCountry?: Maybe<Scalars['String']>;
  /** The target market */
  targetMarket?: Maybe<Scalars['String']>;
  /** The target region */
  targetRegion?: Maybe<Scalars['String']>;
  /** The variety id */
  varietyId?: Maybe<Scalars['String']>;
  /** The variety name */
  varietyName?: Maybe<Scalars['String']>;
  /** The variety code */
  varietyVarietyCode?: Maybe<Scalars['String']>;
};

export type CartonType = {
  __typename?: 'CartonType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Certificate Transport Instruction */
export type CertificateTransportInstruction = {
  __typename?: 'CertificateTransportInstruction';
  /** Booking reference */
  bookingRef?: Maybe<Scalars['String']>;
  /** Company associated with this certificate transport instruction */
  company?: Maybe<Company>;
  /** Container number */
  containerNumber?: Maybe<Scalars['String']>;
  /** Delivery address */
  deliverTo?: Maybe<Scalars['String']>;
  /** Description information */
  description?: Maybe<Scalars['String']>;
  /** Empty depot information */
  emptyDepot?: Maybe<Scalars['String']>;
  /** Exporter information */
  exporter?: Maybe<Scalars['String']>;
  /** Gross weight */
  grossWeight?: Maybe<Scalars['String']>;
  /** Unique identifier for the certificate transport instruction */
  id: Scalars['ID'];
  /** Loadout reference */
  loadoutRef?: Maybe<Scalars['String']>;
  /** Packstore name */
  packstore?: Maybe<Scalars['String']>;
  /** Port of discharge details */
  portOfDischarge?: Maybe<Scalars['String']>;
  /** Port of loading details */
  portOfLoading?: Maybe<Scalars['String']>;
  /** Seal number */
  sealNumber?: Maybe<Scalars['String']>;
  /** Second Packstore name */
  secondPackstore?: Maybe<Scalars['String']>;
  /** Shipment Line Item Id reference */
  shipmentLineItemId?: Maybe<Scalars['ID']>;
  /** Tare weight */
  tareWeight?: Maybe<Scalars['String']>;
  /** Temperature code settings */
  tempCode?: Maybe<Scalars['String']>;
  /** Temperature settings */
  tempSettings?: Maybe<Scalars['String']>;
  /**
   * Object containing information on the document
   *            generated for this certificate transport instruction
   */
  templateOutput?: Maybe<TemplateOutput>;
  /** Transporter details */
  transporter?: Maybe<Scalars['String']>;
  /** Vent settings */
  vents?: Maybe<Scalars['String']>;
  /** Vessel information */
  vessel?: Maybe<Scalars['String']>;
  /** Voyage details */
  voyage?: Maybe<Scalars['String']>;
};

export type CertificateType = {
  __typename?: 'CertificateType';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CgError = {
  __typename?: 'CgError';
  errorContext?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

export type ChargeApplied = {
  __typename?: 'ChargeApplied';
  moneyAmount: Money;
  name: Scalars['String'];
};

/** The color of the badge to be used in UI */
export enum ColorEnum {
  Red = 'red',
  Orange = 'orange',
  Amber = 'amber',
  Yellow = 'yellow',
  Lime = 'lime',
  Green = 'green',
  Emerald = 'emerald',
  Teal = 'teal',
  Cyan = 'cyan',
  Sky = 'sky',
  Blue = 'blue',
  Indigo = 'indigo',
  Violet = 'violet',
  Purple = 'purple',
  Fuchsia = 'fuchsia',
  Pink = 'pink',
  Rose = 'rose',
  Slate = 'slate'
}

/** Attributes to order on */
export type ColumnOrderAttributes = {
  /** field you want to order on */
  orderField: Scalars['String'];
  /** direction you want to order on */
  orderDirection: ColumnOrderDirectionEnum;
};

export enum ColumnOrderDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type Command = {
  __typename?: 'Command';
  batchId?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  commandType?: Maybe<CommandTypeEnum>;
  connectorName?: Maybe<Scalars['String']>;
};

/** Attributes for an on premises batch result */
export type CommandResultAttributes = {
  batchid: Scalars['String'];
  state: CommandStateEnum;
  jsonresult?: Maybe<Scalars['Json']>;
  /** base64 encoded, zlib compressed, json string */
  zippedjson?: Maybe<Scalars['String']>;
};

export enum CommandStateEnum {
  OnPremisesSuccess = 'on_premises_success',
  OnPremisesError = 'on_premises_error',
  OnPremisesUnknown = 'on_premises_unknown'
}

export enum CommandTypeEnum {
  MssqlQuery = 'MSSQL_QUERY',
  CheckForUpdate = 'CHECK_FOR_UPDATE',
  Restart = 'RESTART',
  ReconnectDb = 'RECONNECT_DB',
  SystemReport = 'SYSTEM_REPORT',
  SetConnector = 'SET_CONNECTOR'
}

export type Comment = {
  __typename?: 'Comment';
  commentText?: Maybe<Scalars['String']>;
  commentable?: Maybe<CommentableUnion>;
  commentableId?: Maybe<Scalars['Int']>;
  commentableType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export type CommentableUnion = LineItem | Order;

export type CommercialTerm = {
  __typename?: 'CommercialTerm';
  advanceAmount?: Maybe<Scalars['Float']>;
  advanceCreditTerm?: Maybe<Scalars['Int']>;
  advanceCurrency?: Maybe<Scalars['String']>;
  advanceEvent?: Maybe<Scalars['String']>;
  advanceType?: Maybe<Scalars['String']>;
  advanceUnit?: Maybe<Scalars['String']>;
  buyer?: Maybe<Company>;
  buyerId?: Maybe<Scalars['Int']>;
  commodity?: Maybe<Commodity>;
  deleted: Scalars['Boolean'];
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  finalCreditTerm?: Maybe<Scalars['Int']>;
  finalCurrency?: Maybe<Scalars['String']>;
  finalEvent?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  pack?: Maybe<Scalars['String']>;
  paymentTerm?: Maybe<Scalars['String']>;
  rankOneAdvanceEvent?: Maybe<Scalars['String']>;
  rankOneFinalEvent?: Maybe<Scalars['String']>;
  seller?: Maybe<Company>;
  sellerId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  targetRegion?: Maybe<Scalars['String']>;
};

export type CommercialTermChangeRequest = {
  __typename?: 'CommercialTermChangeRequest';
  commercialTerm?: Maybe<CommercialTerm>;
  flag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type Commodity = {
  __typename?: 'Commodity';
  commodityGroup?: Maybe<CommodityGroupEnum>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  icon?: Maybe<Image>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  varieties?: Maybe<Array<Variety>>;
};

export type CommodityDefaultSeason = {
  __typename?: 'CommodityDefaultSeason';
  commodityId: Scalars['Int'];
  id: Scalars['Int'];
  maxWeek: Scalars['String'];
  minWeek: Scalars['String'];
};


export type CommodityDefaultSeasonMaxWeekArgs = {
  year?: Scalars['Int'];
};


export type CommodityDefaultSeasonMinWeekArgs = {
  year?: Scalars['Int'];
};

export enum CommodityGroupEnum {
  TableGrapes = 'TableGrapes',
  Citrus = 'Citrus',
  Subtropical = 'Subtropical',
  Exotic = 'Exotic',
  Stone = 'Stone',
  Pome = 'Pome',
  Tropical = 'Tropical',
  Vegetable = 'Vegetable'
}

export type CommoditySeason = {
  __typename?: 'CommoditySeason';
  commodity?: Maybe<Commodity>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Company = {
  __typename?: 'Company';
  about?: Maybe<Scalars['String']>;
  activeUsers?: Maybe<Array<User>>;
  additionalFields?: Maybe<Array<AdditionalField>>;
  address?: Maybe<Address>;
  averageRating?: Maybe<Scalars['Float']>;
  buyers?: Maybe<Array<Company>>;
  certificates?: Maybe<Array<CompanyCertificate>>;
  commoditySeasons?: Maybe<Array<CommoditySeason>>;
  /** @deprecated Companies has been replaced with Buyers and Sellers */
  companies?: Maybe<Array<Company>>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  invites?: Maybe<Array<Invite>>;
  isBuyer?: Maybe<Scalars['Boolean']>;
  isSeller?: Maybe<Scalars['Boolean']>;
  /** @deprecated Doesn't provide a full URL. Use logoImage instead */
  logo?: Maybe<Scalars['String']>;
  /** @deprecated Replaced by logoImage */
  logoFile?: Maybe<Document>;
  logoImage?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  numberOfRatings?: Maybe<Scalars['Int']>;
  registrationNumber?: Maybe<Scalars['String']>;
  sellerVarieties?: Maybe<Array<Variety>>;
  sellers?: Maybe<Array<Company>>;
  tradeRegions?: Maybe<Array<TradeRegion>>;
  users?: Maybe<Array<User>>;
  varieties?: Maybe<Array<FarmVariety>>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type CompanyCertificate = {
  __typename?: 'CompanyCertificate';
  certificate?: Maybe<Document>;
  certificateType?: Maybe<CertificateType>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  expiryDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of CompleteAirFreightShipment */
export type CompleteAirFreightShipmentPayload = {
  __typename?: 'CompleteAirFreightShipmentPayload';
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CompleteShipment */
export type CompleteShipmentPayload = {
  __typename?: 'CompleteShipmentPayload';
  errors?: Maybe<Scalars['String']>;
};

export type Config = {
  __typename?: 'Config';
  /** name of the config */
  name?: Maybe<Scalars['String']>;
  /** value of the config */
  value?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ConfirmExportNotificationLineItem */
export type ConfirmExportNotificationLineItemPayload = {
  __typename?: 'ConfirmExportNotificationLineItemPayload';
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
  /** The current shipment */
  shipment?: Maybe<Shipment>;
};

export type Contact = {
  __typename?: 'Contact';
  /** The company associated with the contact */
  company?: Maybe<Company>;
  /** The type of contact */
  contactType?: Maybe<ContactTypeEnum>;
  /** The date and time the contact was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Unique identifier for the contact */
  id?: Maybe<Scalars['Int']>;
  /** Additional information about the contact */
  info?: Maybe<Scalars['String']>;
  /** The name of the contact */
  name?: Maybe<Scalars['String']>;
  /** The date and time the contact was updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ContactAttributes = {
  contactType?: Maybe<ContactTypeEnum>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** The type of contact to be used in templates */
export enum ContactTypeEnum {
  Consignee = 'consignee',
  Consignor = 'consignor',
  InvoiceParty = 'invoice_party',
  NotifyParty = 'notify_party'
}

export type Container = {
  __typename?: 'Container';
  /** Container carrier name */
  carrierName?: Maybe<Scalars['String']>;
  /** Carton groupings of the container */
  cartonGroupings?: Maybe<Array<CartonGrouping>>;
  /** Container number */
  containerNumber?: Maybe<Scalars['String']>;
  /** Container size */
  containerSize?: Maybe<Scalars['String']>;
  /** Container ID */
  id?: Maybe<Scalars['Int']>;
  /** Container seal number */
  sealNumber?: Maybe<Scalars['String']>;
  /** Container stuff date */
  stuffDate?: Maybe<Scalars['ISO8601DateTime']>;
};

export type CostCode = {
  __typename?: 'CostCode';
  costType: Scalars['String'];
  fieldName: Scalars['String'];
  generalLedgerName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  supplyChainLeg?: Maybe<SupplyChainLegEnum>;
};

/** Basic attributes for adding additional fields to a company's new order template */
export type CreateAdditionalFieldAttributes = {
  companyid: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  fieldtype: Scalars['String'];
  ispackinginstruction?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parameters?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateAdditionalField */
export type CreateAdditionalFieldPayload = {
  __typename?: 'CreateAdditionalFieldPayload';
  additionalField?: Maybe<AdditionalField>;
};

export type CreateCommentAttributes = {
  commentabletype: Scalars['String'];
  commentableid: Scalars['Int'];
  userid: Scalars['Int'];
  commenttext: Scalars['String'];
};

/** Autogenerated return type of CreateComment */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  comment?: Maybe<Comment>;
};

export type CreateCommercialTermChangeRequestAttributes = {
  commercialtermsid: Scalars['Int'];
  message: Scalars['String'];
  flag: Scalars['String'];
};

/** Autogenerated return type of CreateCommercialTermChangeRequest */
export type CreateCommercialTermChangeRequestPayload = {
  __typename?: 'CreateCommercialTermChangeRequestPayload';
  changeRequest?: Maybe<CommercialTermChangeRequest>;
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateContact */
export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  contact?: Maybe<Contact>;
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCustomField */
export type CreateCustomFieldPayload = {
  __typename?: 'CreateCustomFieldPayload';
  /** The custom field */
  customField?: Maybe<CustomField>;
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateExportNotificationBooking */
export type CreateExportNotificationBookingPayload = {
  __typename?: 'CreateExportNotificationBookingPayload';
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateExportNotificationLineItem */
export type CreateExportNotificationLineItemPayload = {
  __typename?: 'CreateExportNotificationLineItemPayload';
  errors?: Maybe<Scalars['String']>;
};

/** Basic attributes for adding Varieties to a Company */
export type CreateFarmVarietyAttributes = {
  companyId: Scalars['Int'];
  hectares?: Maybe<Scalars['Float']>;
  packingend: Scalars['String'];
  packingstart: Scalars['String'];
  peakcount?: Maybe<Scalars['String']>;
  varietyid: Scalars['Int'];
};

/** Autogenerated return type of CreateFarmVariety */
export type CreateFarmVarietyPayload = {
  __typename?: 'CreateFarmVarietyPayload';
  farmVariety?: Maybe<FarmVariety>;
};

/** Autogenerated return type of CreateFarmVarietyRequest */
export type CreateFarmVarietyRequestPayload = {
  __typename?: 'CreateFarmVarietyRequestPayload';
  action?: Maybe<Scalars['String']>;
  clientChangeRequest?: Maybe<FarmVarietyRequest>;
  commodityId?: Maybe<Scalars['Int']>;
  varietyId?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateFoodBusinessOperatorRequest */
export type CreateFoodBusinessOperatorRequestPayload = {
  __typename?: 'CreateFoodBusinessOperatorRequestPayload';
  action?: Maybe<Scalars['String']>;
  clientChangeRequest?: Maybe<FoodBusinessOperatorRequest>;
};

/** Autogenerated return type of CreateInvoice */
export type CreateInvoicePayload = {
  __typename?: 'CreateInvoicePayload';
  errors?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

export type CreateLineItemAttributes = {
  varietyid: Scalars['Int'];
  commodityid: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
  quantityunit?: Maybe<Scalars['String']>;
  priceminorunit?: Maybe<Scalars['Int']>;
  priceunit?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  sizecounts: Array<Scalars['String']>;
  pack: Scalars['String'];
  priceterm: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  additionalfields: Scalars['String'];
  palletstack: Scalars['Int'];
  unlimited: Scalars['Boolean'];
  targetmarket?: Maybe<Scalars['String']>;
  targetregion: Scalars['String'];
  targetcountry?: Maybe<Scalars['String']>;
  packingweek?: Maybe<Scalars['String']>;
  startweek?: Maybe<Scalars['String']>;
  endweek?: Maybe<Scalars['String']>;
  incoterm: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  mark?: Maybe<Scalars['String']>;
  referencenumber?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  batchnumber?: Maybe<Scalars['String']>;
  inventorycode?: Maybe<Scalars['String']>;
};

export type CreateLineItemGroupingAttributes = {
  cumulativequantity?: Maybe<Scalars['Int']>;
  quantitytype?: Maybe<Scalars['String']>;
  commonfields: Array<Scalars['String']>;
  lineitems: Array<CreateLineItemAttributes>;
  rank?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateLoadOutInstruction */
export type CreateLoadOutInstructionPayload = {
  __typename?: 'CreateLoadOutInstructionPayload';
  errors?: Maybe<Scalars['String']>;
  loadOutInstruction?: Maybe<LoadOutInstruction>;
};

export type CreateOrderAttributes = {
  buyerid?: Maybe<Scalars['Int']>;
  commonfields?: Maybe<Array<Scalars['String']>>;
  ispackhouserun: Scalars['Boolean'];
  lineitemgroupings?: Maybe<Array<CreateLineItemGroupingAttributes>>;
  sellerid?: Maybe<Scalars['Int']>;
  userid: Scalars['Int'];
};

/** Autogenerated return type of CreateOrder */
export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload';
  errors?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

/** Autogenerated return type of CreateReportGroup */
export type CreateReportGroupPayload = {
  __typename?: 'CreateReportGroupPayload';
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
  /** The report group */
  reportGroup?: Maybe<ReportGroup>;
};

/** Autogenerated return type of CreateShipmentLineItem */
export type CreateShipmentLineItemPayload = {
  __typename?: 'CreateShipmentLineItemPayload';
  errors?: Maybe<Scalars['String']>;
  shipmentLineItem?: Maybe<ShipmentLineItem>;
};

/** Autogenerated return type of CreateShipment */
export type CreateShipmentPayload = {
  __typename?: 'CreateShipmentPayload';
  errors?: Maybe<Scalars['String']>;
  shipment?: Maybe<Shipment>;
};

export type CreateTicketAttributes = {
  subject: Scalars['String'];
  description: Scalars['String'];
};

/** Autogenerated return type of CreateTicket */
export type CreateTicketPayload = {
  __typename?: 'CreateTicketPayload';
  ticket?: Maybe<Ticket>;
};

/** Autogenerated return type of CreateUiConfig */
export type CreateUiConfigPayload = {
  __typename?: 'CreateUiConfigPayload';
  config?: Maybe<UiConfig>;
};

export type CreateUpdateUiConfigAttributes = {
  configType: Scalars['String'];
  data: Scalars['Json'];
  /** Required when updating a config item */
  id?: Maybe<Scalars['Int']>;
  visibility: UiConfigVisibilityEnum;
};

/** A summary of the results of a tabular mutation */
export type CudSummary = {
  __typename?: 'CudSummary';
  /** Unique list of errors relating to failed changes. */
  errors: Array<Scalars['String']>;
  /** Count of failed changes. */
  failed: Scalars['Int'];
  /** Count of successful changes. */
  succeeded: Scalars['Int'];
};

/** A currency as defined by the ISO 4217 standard. */
export type Currency = {
  __typename?: 'Currency';
  /** The currency format as defined by IS0 4217. */
  isoCode: Iso4217;
  /** The smallest denomination of the currency. */
  smallestDenomination: Scalars['Int'];
  /** Factor used to convert a subunit to a unit. */
  subunitToUnit: Scalars['Int'];
  /** The symbol for the currency (i.e. "€"). */
  symbol: Scalars['String'];
};

/** Collection of Currency Conversions rates for a particular day */
export type CurrencyConversion = {
  __typename?: 'CurrencyConversion';
  /** Unique indentifier for each currency conversion entry */
  id?: Maybe<Scalars['ID']>;
  /** Currency conversion exchange rates */
  rates?: Maybe<Scalars['Json']>;
  /** When exchange rates were stored */
  timestamp?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum CurrencyEnum {
  /** The currency with code USD */
  Usd = 'USD',
  /** The currency with code EUR */
  Eur = 'EUR',
  /** The currency with code GBP */
  Gbp = 'GBP',
  /** The currency with code CAD */
  Cad = 'CAD',
  /** The currency with code ZAR */
  Zar = 'ZAR',
  /** The currency with code JPY */
  Jpy = 'JPY',
  /** The currency with code CNY */
  Cny = 'CNY',
  /** The currency with code NAD */
  Nad = 'NAD'
}

export type CustomField = {
  __typename?: 'CustomField';
  defaultValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type CustomFieldAttributes = {
  /** The id of the customField */
  id?: Maybe<Scalars['Int']>;
  /** The name of the custom field */
  name?: Maybe<Scalars['String']>;
  /** Whether the custom field is required */
  required?: Maybe<Scalars['Boolean']>;
  /** Whether the custom field should appear by default */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** The default value of the custom field */
  defaultValue?: Maybe<Scalars['String']>;
};

export type DashboardCartonGrouping = {
  __typename?: 'DashboardCartonGrouping';
  batchNumber?: Maybe<Scalars['String']>;
  cartons?: Maybe<Scalars['Int']>;
  commodityName?: Maybe<Scalars['String']>;
  consignmentNumber?: Maybe<Scalars['String']>;
  containerId?: Maybe<Scalars['Int']>;
  /**
   * A unique sequence made up of 4 letters and 7 numbers, the container \
   *           number is displayed on the top right part of the container door
   */
  containerNumber?: Maybe<Scalars['String']>;
  exporterCode?: Maybe<Scalars['String']>;
  farmCode?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inspectionDate?: Maybe<Scalars['ISO8601DateTime']>;
  inventoryCode?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  mark?: Maybe<Scalars['String']>;
  netMass?: Maybe<Scalars['Float']>;
  orchard?: Maybe<Scalars['String']>;
  packhouseCode?: Maybe<Scalars['String']>;
  packingWeek?: Maybe<Scalars['String']>;
  palletGrossMass?: Maybe<Scalars['Float']>;
  palletNumber?: Maybe<Scalars['String']>;
  productCharacteristic?: Maybe<Scalars['String']>;
  sequenceNumber?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  targetCountry?: Maybe<Scalars['String']>;
  targetMarket?: Maybe<Scalars['String']>;
  targetRegion?: Maybe<Scalars['String']>;
  varietyName?: Maybe<Scalars['String']>;
};

export type DashboardContainer = {
  __typename?: 'DashboardContainer';
  /** Actual arrival date of goods at current location, blank for goods on a vessel */
  arrivalDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Delivery event classifier. States if the arrival date is an estimate or an actual */
  arrivalEventClassifier?: Maybe<Scalars['String']>;
  /** Number of cartons inside container or bulk grouping */
  cartons?: Maybe<Scalars['Int']>;
  /** Number of cartons inside container or bulk grouping that are in transit */
  cartonsInTransit?: Maybe<Scalars['Int']>;
  /** Collection date of the goods at the current location */
  collectionDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  /** Original unique identifier of an actual container, used for debugging purposes */
  containerId?: Maybe<Scalars['Int']>;
  /** A unique sequence made up of 4 letters and 7 numbers, the container number is displayed on the top right part of the container door */
  containerNumber?: Maybe<Scalars['String']>;
  /** Whether the container is a bulk grouping or an actual container */
  containerType?: Maybe<Scalars['String']>;
  /** Actual or planned departure date of goods on a vessel, or actual or planned from current location */
  departureDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Collection event classifier. States if the departure Date is an estimate or an actual */
  departureEventClassifier?: Maybe<Scalars['String']>;
  /** Actual or planned delivery date of the goods at the final location */
  finalDeliveryDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  /** Identifier that combined with final_destination_type uniquely identifies a final destination */
  finalDestinationId?: Maybe<Scalars['Int']>;
  /** Latitude of the final destination */
  finalDestinationLatitude?: Maybe<Scalars['Float']>;
  /** Longitude of the final destination */
  finalDestinationLongitude?: Maybe<Scalars['Float']>;
  /** Name of the final destination */
  finalDestinationName?: Maybe<Scalars['String']>;
  /** Describes the type of the final destination, e.g. SeaPort */
  finalDestinationType?: Maybe<Scalars['String']>;
  /** Uniquely identifies this container */
  id?: Maybe<Scalars['Int']>;
  /** Unique identifier of the location this container was last seen or is currently situated at */
  locationId?: Maybe<Scalars['Int']>;
  /** actual or planned delivery date of the goods at the next location */
  nextDeliveryDatetime?: Maybe<Scalars['ISO8601DateTime']>;
  /** Identifier that combined with next_destination_type uniquely identifies a final destination */
  nextDestinationId?: Maybe<Scalars['Int']>;
  /** Latitude of the next destination */
  nextDestinationLatitude?: Maybe<Scalars['Float']>;
  /** Longitude of the next destination */
  nextDestinationLongitude?: Maybe<Scalars['Float']>;
  /** Name of the next destination */
  nextDestinationName?: Maybe<Scalars['String']>;
  /** Describes the type of the next destination, e.g. SeaPort */
  nextDestinationType?: Maybe<Scalars['String']>;
  /** Number of pallets inside container or bulk grouping */
  pallets?: Maybe<Scalars['Int']>;
  /** Number of pallets inside container or bulk grouping that are in transit */
  palletsInTransit?: Maybe<Scalars['Int']>;
  /** Representative of the container's current state as a sequence of states representing the overall supply chain */
  state?: Maybe<Scalars['String']>;
  /** Representative of the container's estimated state based on unallocated cartons in transit */
  stateInTransit?: Maybe<Scalars['String']>;
};

export type DashboardFilters = {
  __typename?: 'DashboardFilters';
  commodities?: Maybe<Array<Commodity>>;
};

export type DashboardLocation = {
  __typename?: 'DashboardLocation';
  /** Number of cartons at location */
  cartons?: Maybe<Scalars['Int']>;
  /** Number of cartons at location that are in transit */
  cartonsInTransit?: Maybe<Scalars['Int']>;
  /** Number of containers at location */
  containers?: Maybe<Scalars['Int']>;
  /** Number of containers at location that are in transit */
  containersInTransit?: Maybe<Scalars['Int']>;
  /** Coordinates of the location */
  geoLocation?: Maybe<GeoLocation>;
  /** Location identifier */
  id?: Maybe<Scalars['Int']>;
  /**
   * Location latitude
   * @deprecated Replaced by geo_location
   */
  latitude?: Maybe<Scalars['Float']>;
  /** Location identifier */
  locationId?: Maybe<Scalars['Int']>;
  /** Location type */
  locationType?: Maybe<Scalars['String']>;
  /**
   * Location longitude
   * @deprecated Replaced by geo_location
   */
  longitude?: Maybe<Scalars['Float']>;
  /** Location name */
  name?: Maybe<Scalars['String']>;
  /** Number of pallets at location */
  pallets?: Maybe<Scalars['Int']>;
  /** Number of pallets at location that are in transit */
  palletsInTransit?: Maybe<Scalars['Int']>;
  /** Number of unallocated containers or carton groupings at location */
  unallocated?: Maybe<Scalars['Int']>;
  /** Number of unallocated containers or carton groupings at location that are in transit */
  unallocatedInTransit?: Maybe<Scalars['Int']>;
};

export enum DashboardSearchFieldsEnum {
  BatchNumber = 'batch_number',
  ArrivalEventClassifier = 'arrival_event_classifier',
  CommodityName = 'commodity_name',
  ConsignmentNumber = 'consignment_number',
  ContainerNumber = 'container_number',
  ContainerType = 'container_type',
  DepartureDate = 'departure_date',
  DepartureEventClassifier = 'departure_event_classifier',
  ExporterCode = 'exporter_code',
  FarmCode = 'farm_code',
  Grade = 'grade',
  FinalDeliveryDatetime = 'final_delivery_datetime',
  FinalDestinationName = 'final_destination_name',
  FinalDestinationType = 'final_destination_type',
  InspectionDate = 'inspection_date',
  InventoryCode = 'inventory_code',
  LocationType = 'location_type',
  Mark = 'mark',
  Name = 'name',
  NextDeliveryDatetime = 'next_delivery_datetime',
  NextDestinationName = 'next_destination_name',
  NextDestinationType = 'next_destination_type',
  Orchard = 'orchard',
  PackhouseCode = 'packhouse_code',
  PackingWeek = 'packing_week',
  PalletNumber = 'pallet_number',
  ProductCharacteristic = 'product_characteristic',
  ArrivalDate = 'arrival_date',
  State = 'state',
  StateInTransit = 'state_in_transit',
  TargetCountry = 'target_country',
  TargetMarket = 'target_market',
  TargetRegion = 'target_region',
  VarietyName = 'variety_name'
}

export type DashboardTrackingData = {
  __typename?: 'DashboardTrackingData';
  /** current active order carton_groupings */
  cartonGroupings?: Maybe<Array<DashboardCartonGrouping>>;
  /** current active order containers */
  containers?: Maybe<Array<DashboardContainer>>;
  /** current active order locations */
  locations?: Maybe<Array<DashboardLocation>>;
};

/** Contains information on data generation output */
export type DataOutput = {
  __typename?: 'DataOutput';
  /** Timestamp representing the date this document was last updated */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Timestamp representing the date this document was last downloaded */
  downloadedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Data source type associated with this data output */
  fileType?: Maybe<Scalars['String']>;
  /** Unique identifier of this object */
  id?: Maybe<Scalars['ID']>;
  /** The documents produced from template generation */
  outputFile?: Maybe<Document>;
};

/** Attributes for creating or updating a Data Output */
export type DataOutputAttributes = {
  /** Date and time the Data Output was downloaded */
  downloadedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** ID of the Data Output */
  id?: Maybe<Scalars['Int']>;
};

/** Contains information on data preparation */
export type DataPreparation = {
  __typename?: 'DataPreparation';
  /** The documents produced from template generation */
  dataOutputs?: Maybe<Array<DataOutput>>;
  /** Description of the output */
  description?: Maybe<Scalars['String']>;
  /** Provides further detail if state is errored */
  error?: Maybe<Scalars['String']>;
  /** Unique identifier of this object */
  id?: Maybe<Scalars['ID']>;
  /** Name of the output */
  name?: Maybe<Scalars['String']>;
  /** State of the template generation (Created, Processing, Errored, Completed) */
  status?: Maybe<Scalars['String']>;
  /** Timestamp representing the date this document was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of DeleteAddendumLineItem */
export type DeleteAddendumLineItemPayload = {
  __typename?: 'DeleteAddendumLineItemPayload';
  addendumLineItems?: Maybe<Array<AddendumLineItem>>;
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteAdditionalField */
export type DeleteAdditionalFieldPayload = {
  __typename?: 'DeleteAdditionalFieldPayload';
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteComment */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  comment?: Maybe<Comment>;
};

/** Autogenerated return type of DeleteCompanyCertificate */
export type DeleteCompanyCertificatePayload = {
  __typename?: 'DeleteCompanyCertificatePayload';
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteContact */
export type DeleteContactPayload = {
  __typename?: 'DeleteContactPayload';
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
  /** The contact was deleted */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteCustomField */
export type DeleteCustomFieldPayload = {
  __typename?: 'DeleteCustomFieldPayload';
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
  /** The custom field was deleted */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteEmployee */
export type DeleteEmployeePayload = {
  __typename?: 'DeleteEmployeePayload';
  employeeId?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteEphytoDocument */
export type DeleteEphytoDocumentPayload = {
  __typename?: 'DeleteEphytoDocumentPayload';
  /** The ID of the Ephyto Document */
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteEphyto */
export type DeleteEphytoPayload = {
  __typename?: 'DeleteEphytoPayload';
  /** Response message when attempting to delete */
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteExportDocument */
export type DeleteExportDocumentPayload = {
  __typename?: 'DeleteExportDocumentPayload';
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteFarmVarietyImage */
export type DeleteFarmVarietyImagePayload = {
  __typename?: 'DeleteFarmVarietyImagePayload';
  farmVariety?: Maybe<FarmVariety>;
};

/** Autogenerated return type of DeleteFarmVariety */
export type DeleteFarmVarietyPayload = {
  __typename?: 'DeleteFarmVarietyPayload';
  farmVariety?: Maybe<FarmVariety>;
};

/** Autogenerated return type of DeleteInvoice */
export type DeleteInvoicePayload = {
  __typename?: 'DeleteInvoicePayload';
  /** Whether the deletion was successful */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteLoadOutInstruction */
export type DeleteLoadOutInstructionPayload = {
  __typename?: 'DeleteLoadOutInstructionPayload';
  /** Whether the deletion was successful */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteReportGroup */
export type DeleteReportGroupPayload = {
  __typename?: 'DeleteReportGroupPayload';
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
  /** The report group was deleted */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteShipmentDocument */
export type DeleteShipmentDocumentPayload = {
  __typename?: 'DeleteShipmentDocumentPayload';
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteShipmentLineItem */
export type DeleteShipmentLineItemPayload = {
  __typename?: 'DeleteShipmentLineItemPayload';
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteTaskDocument */
export type DeleteTaskDocumentPayload = {
  __typename?: 'DeleteTaskDocumentPayload';
  /** ID of the Task::Task that the document belongs to */
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteUiConfig */
export type DeleteUiConfigPayload = {
  __typename?: 'DeleteUiConfigPayload';
  id?: Maybe<Scalars['Int']>;
};

export type Document = {
  __typename?: 'Document';
  contentType?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  v2Url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DraftEphyto */
export type DraftEphytoPayload = {
  __typename?: 'DraftEphytoPayload';
  /** The Ephyto */
  ephyto?: Maybe<Ephyto>;
  /** Any errors when attempting to submit draft */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DuplicateAddendumLineItem */
export type DuplicateAddendumLineItemPayload = {
  __typename?: 'DuplicateAddendumLineItemPayload';
  addendumLineItems?: Maybe<Array<AddendumLineItem>>;
};

/** Autogenerated return type of DuplicateEphyto */
export type DuplicateEphytoPayload = {
  __typename?: 'DuplicateEphytoPayload';
  /** The ID of the Ephyto to duplicate */
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of DuplicateInvoice */
export type DuplicateInvoicePayload = {
  __typename?: 'DuplicateInvoicePayload';
  /** Any errors from duplicating */
  errors?: Maybe<Scalars['String']>;
  /** id of the duplicated invoice */
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of DuplicateShipmentLineItem */
export type DuplicateShipmentLineItemPayload = {
  __typename?: 'DuplicateShipmentLineItemPayload';
  /** All SLIs on the shipment */
  shipmentLineItems?: Maybe<Array<ShipmentLineItem>>;
};

export type ElasticHit = {
  __typename?: 'ElasticHit';
  highlight?: Maybe<Scalars['Json']>;
  score?: Maybe<Scalars['Float']>;
  source?: Maybe<ElasticHitSourceUnion>;
};

export type ElasticHitSourceUnion = DashboardCartonGrouping | DashboardContainer | DashboardLocation;

export type ElasticSearchResult = {
  __typename?: 'ElasticSearchResult';
  /** Array of results from elasticsearch */
  hits?: Maybe<Array<ElasticHit>>;
  /** The raw response from Elasticsearch. Use only for debugging. */
  raw?: Maybe<Scalars['Json']>;
  /** Info on how many matching items were found in total. hits may only be a subset */
  total?: Maybe<ElasticTotal>;
};

export type ElasticTotal = {
  __typename?: 'ElasticTotal';
  relation?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

/** Returns all attributes needed to embed a PowerBI report. */
export type EmbeddedChart = {
  __typename?: 'EmbeddedChart';
  /** Report embed access token */
  embedToken?: Maybe<Scalars['String']>;
  /** Report embed URL */
  embedUrl?: Maybe<Scalars['String']>;
  /** Report Id in powerbi */
  reportId?: Maybe<Scalars['String']>;
  /** Report embed config variables */
  settings?: Maybe<Scalars['String']>;
};

export type Employee = {
  __typename?: 'Employee';
  /** The ID of the Employee */
  id?: Maybe<Scalars['Int']>;
  /** The first name of the User the Employee belongs to */
  userFirstName?: Maybe<Scalars['String']>;
  /** The last name of the User the Employee belongs to */
  userLastName?: Maybe<Scalars['String']>;
};

export type Ephyto = {
  __typename?: 'Ephyto';
  /** Any additional treatment information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** Is additional inspection required */
  additionalInspection?: Maybe<Scalars['Boolean']>;
  /** Agreement Code related the commodity and target country */
  agreementCode?: Maybe<Scalars['String']>;
  /** Reference returned by Ecert when Phyto has been submitted */
  applicationReferenceNo?: Maybe<Scalars['String']>;
  /** The status for the Phyto returned from Ecert */
  applicationStatus?: Maybe<Scalars['String']>;
  /** Type of Ephyto being applied for */
  applicationType?: Maybe<Scalars['Int']>;
  /** Is Phyto based on the original */
  basedOnOriginalPhyto?: Maybe<Scalars['Boolean']>;
  /** Booking Reference */
  bookingRef?: Maybe<Scalars['String']>;
  /** The scientific name of the plant */
  botanicalName?: Maybe<Scalars['String']>;
  /** Ecert Business Registered ID to be billed for the Phyto */
  cbrBillingId?: Maybe<Scalars['Int']>;
  /** Ecert Business Registered ID applying for the Phyto */
  cbrId?: Maybe<Scalars['Int']>;
  /** Flexi Field for CBS Free Area on the ephyto */
  cbsFreeArea?: Maybe<Scalars['String']>;
  /** Type of chemical applied */
  chemical?: Maybe<Scalars['String']>;
  /** Company that this ephyto belongs to */
  companyId?: Maybe<Scalars['Int']>;
  /** The concentration of chemical application */
  concentration?: Maybe<Scalars['String']>;
  /** Consignees Address */
  consigneeAddressLine1?: Maybe<Scalars['String']>;
  /** Consignees Address */
  consigneeAddressLine2?: Maybe<Scalars['String']>;
  /** Consignees Address */
  consigneeAddressLine3?: Maybe<Scalars['String']>;
  /** Consignees Country Code */
  consigneeCountryId?: Maybe<Scalars['String']>;
  /** Consignees registered ID on Ecert */
  consigneeId?: Maybe<Scalars['String']>;
  /** Consignees name */
  consigneeName?: Maybe<Scalars['String']>;
  /** Consignors address */
  consignorAddressLine1?: Maybe<Scalars['String']>;
  /** Consignors address */
  consignorAddressLine2?: Maybe<Scalars['String']>;
  /** Consignors address */
  consignorAddressLine3?: Maybe<Scalars['String']>;
  /** Consignors registered Id on Ecert */
  consignorId?: Maybe<Scalars['String']>;
  /** Consignors name */
  consignorName?: Maybe<Scalars['String']>;
  /** Reference No */
  customReferenceNo?: Maybe<Scalars['String']>;
  /** DALRRD registered Account number to handle payments */
  dalrrdAccNum?: Maybe<Scalars['String']>;
  /** Means of conveyance */
  declaredMeansOfConveyance?: Maybe<Scalars['String']>;
  /** First Point of Entry */
  declaredPointOfEntry?: Maybe<Scalars['String']>;
  /** Date of Departure for Vessel */
  departureDate?: Maybe<Scalars['String']>;
  /** The location where the EPhyto is issued */
  desiredIssueLocation?: Maybe<Scalars['String']>;
  /** The scientific name of the plant */
  distinguishingMarks?: Maybe<Scalars['String']>;
  /** Length of treatment and temperature applied */
  durationAndTemperature?: Maybe<Scalars['String']>;
  /** Consignment items declared for the ephyto */
  ephytoConsignmentItems?: Maybe<Array<EphytoConsignmentItem>>;
  /** Supporting documents uploaded for the ephyto */
  ephytoDocuments?: Maybe<Array<EphytoDocument>>;
  /** Trade Units declared for the Ephyto */
  ephytoTradeUnits?: Maybe<Array<EphytoTradeUnit>>;
  /** Error tracking for the Ephyto */
  errorReason?: Maybe<Scalars['String']>;
  /** Ephyto ID */
  id: Scalars['ID'];
  /** Import Country Code */
  importCountryId?: Maybe<Scalars['String']>;
  /** Flexi Field for import permit date on the ephyto */
  importPermitDate?: Maybe<Scalars['String']>;
  /** Flexi Field for import permit no on the ephyto */
  importPermitNo?: Maybe<Scalars['String']>;
  /** PPECB Activity Point Code that represents the load point */
  loadSeaPoint?: Maybe<Scalars['String']>;
  /** Name of the product and declared quantity */
  nameOfProductAndDeclaredQuantity?: Maybe<Scalars['String']>;
  /** Email where updates will be sent via Ecert */
  notificationEmail?: Maybe<Scalars['String']>;
  /** Number and description of Package */
  numberAndDescriptionOfPackage?: Maybe<Scalars['String']>;
  /** Is the consignment in the same or packed in a new container */
  originalContainer?: Maybe<Scalars['Boolean']>;
  /** The original phyto or a certified copy if false */
  originalPhyto?: Maybe<Scalars['Boolean']>;
  /** Date of issuance for original phyto */
  originalPhytoDate?: Maybe<Scalars['String']>;
  /** The original phyto no being reexported */
  originalPhytoNo?: Maybe<Scalars['String']>;
  /** Type of Payment such as Cash or Account */
  paymentType?: Maybe<Scalars['String']>;
  /** Place of origin */
  placeOfOrigin?: Maybe<Scalars['String']>;
  /** Location Code for port of entry */
  portOfEntry?: Maybe<Scalars['String']>;
  /** Is the original Phyto repacked */
  rePacked?: Maybe<Scalars['Boolean']>;
  /** The reexport phyto place of origin */
  reexportPlaceOfOrigin?: Maybe<Scalars['String']>;
  /** Flexi Field for regime code on the ephyto */
  regimeCode?: Maybe<Scalars['String']>;
  /** Internal State of the Ephyto */
  state?: Maybe<Scalars['String']>;
  /** Stuff Date related to the Ephyto */
  stuffDate?: Maybe<Scalars['String']>;
  /** Target Country Code */
  targetCountry?: Maybe<Scalars['String']>;
  /** Target Country Code */
  targetCountryId?: Maybe<Scalars['String']>;
  /** Target Region Code */
  targetRegion?: Maybe<Scalars['String']>;
  /** Temperature Regime */
  temperatureRegime?: Maybe<Scalars['String']>;
  /** Further describing transport eg. Container Vessel if Marine */
  transportMeans?: Maybe<Scalars['String']>;
  /** Transport Type code to represent Air or Marine */
  transportModeCode?: Maybe<Scalars['Int']>;
  /** Type of treatment */
  treatment?: Maybe<Scalars['String']>;
  /** Date of treatment */
  treatmentDate?: Maybe<Scalars['String']>;
  /** Location Code */
  unloadingBaseportLocation?: Maybe<Scalars['String']>;
  /** When the ephyto was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Flexi Field for VAT no on the ephyto */
  vatNo?: Maybe<Scalars['String']>;
  /** Vessel or Carrier Name */
  vessel?: Maybe<Scalars['String']>;
  /** Voyage Number or Flight Number */
  voyageNumber?: Maybe<Scalars['String']>;
};

/** Agreement Attributes related to exporting */
export type EphytoAgreementCode = {
  __typename?: 'EphytoAgreementCode';
  /** Agreement Code for exporting goods to a country */
  agreementCode?: Maybe<Scalars['String']>;
  /** Agreement Name for exporting goods to a country */
  agreementName?: Maybe<Scalars['String']>;
};

export type EphytoAttributes = {
  /** Ephyto ID */
  id: Scalars['ID'];
  /** Reference returned by Ecert when Phyto has been submitted */
  applicationReferenceNo?: Maybe<Scalars['String']>;
  /** Type of Ephyto being applied for */
  applicationType?: Maybe<Scalars['Int']>;
  /** The status for the Phyto returned from Ecert */
  applicationStatus?: Maybe<Scalars['String']>;
  /** Ecert Business Registered ID applying for the Phyto */
  cbrId?: Maybe<Scalars['Int']>;
  /** Ecert Business Registered ID to be billed for the Phyto */
  cbrBillingId?: Maybe<Scalars['Int']>;
  /** Reference No */
  customReferenceNo?: Maybe<Scalars['String']>;
  /** Agreement Code related the commodity and target country */
  agreementCode?: Maybe<Scalars['String']>;
  /** The location where the EPhyto is issued */
  desiredIssueLocation?: Maybe<Scalars['String']>;
  /** Email where updates will be sent via Ecert */
  notificationEmail?: Maybe<Scalars['String']>;
  /** Consignors registered Id on Ecert */
  consignorId?: Maybe<Scalars['String']>;
  /** Consignors name */
  consignorName?: Maybe<Scalars['String']>;
  /** Consignors address */
  consignorAddressLine1?: Maybe<Scalars['String']>;
  /** Consignors address */
  consignorAddressLine2?: Maybe<Scalars['String']>;
  /** Consignors address */
  consignorAddressLine3?: Maybe<Scalars['String']>;
  /** Consignees registered ID on Ecert */
  consigneeId?: Maybe<Scalars['String']>;
  /** Consignees name */
  consigneeName?: Maybe<Scalars['String']>;
  /** Consignees Address */
  consigneeAddressLine1?: Maybe<Scalars['String']>;
  /** Consignees Address */
  consigneeAddressLine2?: Maybe<Scalars['String']>;
  /** Consignees Address */
  consigneeAddressLine3?: Maybe<Scalars['String']>;
  /** Consignees Country Code */
  consigneeCountryId?: Maybe<Scalars['String']>;
  /** Import Country Code */
  importCountryId?: Maybe<Scalars['String']>;
  /** Target Country Code */
  targetCountryId?: Maybe<Scalars['String']>;
  /** Location Code */
  unloadingBaseportLocation?: Maybe<Scalars['String']>;
  /** Transport Type code to represent Air or Marine */
  transportModeCode?: Maybe<Scalars['Int']>;
  /** Further describing transport eg. Container Vessel if Marine */
  transportMeans?: Maybe<Scalars['String']>;
  /** Type of Payment such as Cash or Account */
  paymentType?: Maybe<Scalars['String']>;
  /** DALRRD registered Account number to handle payments */
  dalrrdAccNum?: Maybe<Scalars['String']>;
  /** PPECB Activity Point Code that represents the load point */
  loadSeaPoint?: Maybe<Scalars['String']>;
  /** Target Country Code */
  targetCountry?: Maybe<Scalars['String']>;
  /** Target Region Code */
  targetRegion?: Maybe<Scalars['String']>;
  /** Location Code for port of entry */
  portOfEntry?: Maybe<Scalars['String']>;
  /** Booking Reference */
  bookingRef?: Maybe<Scalars['String']>;
  /** Vessel or Carrier Name */
  vessel?: Maybe<Scalars['String']>;
  /** Voyage Number or Flight Number */
  voyageNumber?: Maybe<Scalars['String']>;
  /** Date of Departure for Vessel */
  departureDate?: Maybe<Scalars['String']>;
  /** Stuff Date related to the Ephyto */
  stuffDate?: Maybe<Scalars['String']>;
  /** Temperature Regime */
  temperatureRegime?: Maybe<Scalars['String']>;
  /** Number and description of Package */
  numberAndDescriptionOfPackage?: Maybe<Scalars['String']>;
  /** The scientific name of the plant */
  botanicalName?: Maybe<Scalars['String']>;
  /** The scientific name of the plant */
  distinguishingMarks?: Maybe<Scalars['String']>;
  /** Place of origin */
  placeOfOrigin?: Maybe<Scalars['String']>;
  /** Means of conveyance */
  declaredMeansOfConveyance?: Maybe<Scalars['String']>;
  /** First Point of Entry */
  declaredPointOfEntry?: Maybe<Scalars['String']>;
  /** Name of the product and declared quantity */
  nameOfProductAndDeclaredQuantity?: Maybe<Scalars['String']>;
  /** Date of treatment */
  treatmentDate?: Maybe<Scalars['String']>;
  /** Type of treatment */
  treatment?: Maybe<Scalars['String']>;
  /** Type of chemical applied */
  chemical?: Maybe<Scalars['String']>;
  /** Length of treatment and temperature applied */
  durationAndTemperature?: Maybe<Scalars['String']>;
  /** The concentration of chemical application */
  concentration?: Maybe<Scalars['String']>;
  /** Any additional treatment information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** Company that this ephyto belongs to */
  companyId?: Maybe<Scalars['Int']>;
  /** Internal State of the Ephyto */
  state?: Maybe<Scalars['String']>;
  /** Date of issuance for original phyto */
  originalPhytoDate?: Maybe<Scalars['String']>;
  /** The original phyto no being reexported */
  originalPhytoNo?: Maybe<Scalars['String']>;
  /** The reexport phyto place of origin */
  reexportPlaceOfOrigin?: Maybe<Scalars['String']>;
  /** The original phyto or a certified copy if false */
  originalPhyto?: Maybe<Scalars['Boolean']>;
  /** Is the original Phyto repacked */
  rePacked?: Maybe<Scalars['Boolean']>;
  /** Is the consignment in the same or packed in a new container */
  originalContainer?: Maybe<Scalars['Boolean']>;
  /** Is Phyto based on the original */
  basedOnOriginalPhyto?: Maybe<Scalars['Boolean']>;
  /** Is additional inspection required */
  additionalInspection?: Maybe<Scalars['Boolean']>;
  /** Flexi Field for CBS Free Area on the ephyto */
  cbsFreeArea?: Maybe<Scalars['String']>;
  /** Flexi Field for VAT no on the ephyto */
  vatNo?: Maybe<Scalars['String']>;
  /** Flexi Field for regime code on the ephyto */
  regimeCode?: Maybe<Scalars['String']>;
  /** Flexi Field for import permit no on the ephyto */
  importPermitNo?: Maybe<Scalars['String']>;
  /** Flexi Field for import permit date on the ephyto */
  importPermitDate?: Maybe<Scalars['String']>;
};

/** The connection type for Ephyto. */
export type EphytoConnection = {
  __typename?: 'EphytoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EphytoEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Ephyto>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type EphytoConsignmentItem = {
  __typename?: 'EphytoConsignmentItem';
  /** Additional notes or declarations for consignment */
  additionalConsignmentNotes?: Maybe<Scalars['String']>;
  /** Additional notes for treatments */
  appliedProcessAdditionalNote?: Maybe<Scalars['String']>;
  /** Chemical Ingredient */
  appliedProcessChemicalCode?: Maybe<Scalars['String']>;
  /** Concentration Level */
  appliedProcessConcentration?: Maybe<Scalars['String']>;
  /** Unit of measure for concentration */
  appliedProcessConcentrationUnitCode?: Maybe<Scalars['String']>;
  /** Treatment End Date */
  appliedProcessEndDate?: Maybe<Scalars['String']>;
  /** Treatment Start Date */
  appliedProcessStartDate?: Maybe<Scalars['String']>;
  /** Temperature applied */
  appliedProcessTemperature?: Maybe<Scalars['String']>;
  /** Unit of measure for temperature */
  appliedProcessTemperatureUnitCode?: Maybe<Scalars['String']>;
  /** Treatment Type Level 1 */
  appliedProcessTreatmentTypeLevel1?: Maybe<Scalars['String']>;
  /** Treatment Type Level 2 */
  appliedProcessTreatmentTypeLevel2?: Maybe<Scalars['String']>;
  /** Condition of commodity */
  commodityConditionClass?: Maybe<Scalars['String']>;
  /** Commoditys intent of use */
  commodityIntentOfUseClass?: Maybe<Scalars['String']>;
  /** Type of commodity class */
  commodityVegetablePart?: Maybe<Scalars['String']>;
  /** General product name */
  commonName?: Maybe<Scalars['String']>;
  /** Harmonized system code */
  customsHarmonizedSystemClass?: Maybe<Scalars['String']>;
  /** Product Description */
  description?: Maybe<Scalars['String']>;
  /** Duration of treatment */
  durationMeasure?: Maybe<Scalars['String']>;
  /** Unit code for the duration of treatment */
  durationMeasureCode?: Maybe<Scalars['String']>;
  /** Ephyto ID this item belongs to */
  ephytoId?: Maybe<Scalars['Int']>;
  /** Summary of the full treatment as would appear on the Phyto. */
  fullTreatmentInformation?: Maybe<Scalars['String']>;
  /** Gross weight */
  grossWeightMeasure?: Maybe<Scalars['String']>;
  /** Unit of measure for the gross weight */
  grossWeightMeasureCode?: Maybe<Scalars['String']>;
  /** Internal Unique Consignment Item ID */
  id: Scalars['ID'];
  /** Net weight */
  netWeightMeasure?: Maybe<Scalars['String']>;
  /** Unit of measure for net weight */
  netWeightMeasureCode?: Maybe<Scalars['String']>;
  /** Quantity of packaged items */
  packageItemQuantity?: Maybe<Scalars['String']>;
  /** Unit of code to quantify the packaged item */
  packageItemUnitCode?: Maybe<Scalars['String']>;
  /** Package Level */
  packageLevelCode?: Maybe<Scalars['String']>;
  /** Visible Shipping Marks */
  packageShippingMarks?: Maybe<Scalars['String']>;
  /** Package Type */
  packageTypeCode?: Maybe<Scalars['String']>;
  /** The scientific name of the product */
  scientificName?: Maybe<Scalars['String']>;
  /** A number used to uniquely identify a consignment for updates on eCert */
  sequenceNumeric?: Maybe<Scalars['String']>;
};

export type EphytoConsignmentItemAttributes = {
  /** Consignment Item ID in our DB */
  id?: Maybe<Scalars['ID']>;
  /** Product Description */
  description?: Maybe<Scalars['String']>;
  /** General product name */
  commonName?: Maybe<Scalars['String']>;
  /** The scientific name of the product */
  scientificName?: Maybe<Scalars['String']>;
  /** Unit of measure for net weight */
  netWeightMeasureCode?: Maybe<Scalars['String']>;
  /** Net weight */
  netWeightMeasure?: Maybe<Scalars['String']>;
  /** Unit of measure for the gross weight */
  grossWeightMeasureCode?: Maybe<Scalars['String']>;
  /** Gross weight */
  grossWeightMeasure?: Maybe<Scalars['String']>;
  /** Harmonized system code */
  customsHarmonizedSystemClass?: Maybe<Scalars['String']>;
  /** Type of commodity class */
  commodityVegetablePart?: Maybe<Scalars['String']>;
  /** Condition of commodity */
  commodityConditionClass?: Maybe<Scalars['String']>;
  /** Commoditys intent of use */
  commodityIntentOfUseClass?: Maybe<Scalars['String']>;
  /** Treatment Start Date */
  appliedProcessStartDate?: Maybe<Scalars['String']>;
  /** Treatment End Date */
  appliedProcessEndDate?: Maybe<Scalars['String']>;
  /** Unit code for the duration of treatment */
  durationMeasureCode?: Maybe<Scalars['String']>;
  /** Duration of treatment */
  durationMeasure?: Maybe<Scalars['String']>;
  /** Treatment Type Level 1 */
  appliedProcessTreatmentTypeLevel1?: Maybe<Scalars['String']>;
  /** Treatment Type Level 2 */
  appliedProcessTreatmentTypeLevel2?: Maybe<Scalars['String']>;
  /** Chemical Ingredient */
  appliedProcessChemicalCode?: Maybe<Scalars['String']>;
  /** Unit of measure for temperature */
  appliedProcessTemperatureUnitCode?: Maybe<Scalars['String']>;
  /** Temperature applied */
  appliedProcessTemperature?: Maybe<Scalars['String']>;
  /** Unit of measure for concentration */
  appliedProcessConcentrationUnitCode?: Maybe<Scalars['String']>;
  /** Concentration Level */
  appliedProcessConcentration?: Maybe<Scalars['String']>;
  /** Additional notes for treatments */
  appliedProcessAdditionalNote?: Maybe<Scalars['String']>;
  /** Summary of the full treatment as would appear on the Phyto. */
  fullTreatmentInformation?: Maybe<Scalars['String']>;
  /** Package Level */
  packageLevelCode?: Maybe<Scalars['String']>;
  /** Package Type */
  packageTypeCode?: Maybe<Scalars['String']>;
  /** Unit of code to quantify the packaged item */
  packageItemUnitCode?: Maybe<Scalars['String']>;
  /** Quantity of packaged items */
  packageItemQuantity?: Maybe<Scalars['String']>;
  /** Visible Shipping Marks */
  packageShippingMarks?: Maybe<Scalars['String']>;
  /** Additional notes or declarations for consignment */
  additionalConsignmentNotes?: Maybe<Scalars['String']>;
  /** A number used to uniquely identify a consignment for updates on eCert */
  sequenceNumeric?: Maybe<Scalars['String']>;
  /** Ephyto ID this item belongs to */
  ephytoId?: Maybe<Scalars['Int']>;
};

export type EphytoCountry = {
  __typename?: 'EphytoCountry';
  /** Country ISO alpha code */
  isoCode?: Maybe<Scalars['String']>;
  /** Name of country */
  name?: Maybe<Scalars['String']>;
};

export type EphytoDocument = {
  __typename?: 'EphytoDocument';
  /** The Ecert Ephyto specified document type */
  documentType?: Maybe<Scalars['String']>;
  /** The document associated with the EphytoDocument */
  file?: Maybe<Document>;
  /** Unique identifier of this object */
  id?: Maybe<Scalars['ID']>;
};

/** An edge in a connection. */
export type EphytoEdge = {
  __typename?: 'EphytoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Ephyto>;
};

export type EphytoFilterAttribute = {
  __typename?: 'EphytoFilterAttribute';
  /** ephyto consignees */
  consignees?: Maybe<Array<Scalars['String']>>;
  /** ephyto consignors */
  consignors?: Maybe<Array<Scalars['String']>>;
  /** ephyto customer references */
  customerReferences?: Maybe<Array<Scalars['String']>>;
  /** ephyto ports of entry */
  entryPorts?: Maybe<Array<Scalars['String']>>;
  /** ephyto statuses */
  statuses?: Maybe<Array<Scalars['String']>>;
};

export type EphytoFilterAttributes = {
  /** ephyto statuses */
  applicationStatuses?: Maybe<Array<Scalars['String']>>;
  /** ephyto customer references */
  customerReferences?: Maybe<Array<Scalars['String']>>;
  /** ephyto ports of entry */
  entryPorts?: Maybe<Array<Scalars['String']>>;
  /** ephyto consignees */
  consignees?: Maybe<Array<Scalars['String']>>;
  /** ephyto consignors */
  consignors?: Maybe<Array<Scalars['String']>>;
};

export type EphytoLocation = {
  __typename?: 'EphytoLocation';
  /** Location code value */
  code?: Maybe<Scalars['String']>;
  /** Display name of location */
  name?: Maybe<Scalars['String']>;
};

export type EphytoPackage = {
  __typename?: 'EphytoPackage';
  /** Code of the package type */
  code?: Maybe<Scalars['String']>;
  /** Display name of the package type */
  name?: Maybe<Scalars['String']>;
};

export type EphytoTradeUnit = {
  __typename?: 'EphytoTradeUnit';
  /** Clients Reference for this trade unit */
  clientRef?: Maybe<Scalars['String']>;
  /** Commodity Code */
  commodity?: Maybe<Scalars['String']>;
  /** Container Number if applicable for trade unit */
  containerNumber?: Maybe<Scalars['String']>;
  /** Ephyto ID this trade unit belongs to */
  ephytoId?: Maybe<Scalars['Int']>;
  /** Flesh color for trade unit if applicable */
  fleshColour?: Maybe<Scalars['String']>;
  /** Trade Unit Id in our DB */
  id: Scalars['ID'];
  /** PPECB manifest no for the trade unit */
  inspectionManifestNo?: Maybe<Scalars['String']>;
  /** PPECB Actvitiy Point Code for inspection */
  inspectionPointLocationCode?: Maybe<Scalars['String']>;
  /** Marketing Indication for trade unit */
  marketingIndication?: Maybe<Scalars['String']>;
  /** Nett Weight */
  netTradeUnitWeight?: Maybe<Scalars['String']>;
  /** Pallet Nett Weight */
  nettPalletWeight?: Maybe<Scalars['String']>;
  /** Packaged Quantity for trade unit */
  numberOfPackagedItems?: Maybe<Scalars['String']>;
  /** Orchard Code */
  orchard?: Maybe<Scalars['String']>;
  /** Phyto Stats for Orchard */
  orchardPhytoStatus?: Maybe<Scalars['String']>;
  /** Packhouse code for trade unit */
  phc?: Maybe<Scalars['String']>;
  /** Date of inspection as on PO */
  ppecbInspectionDate?: Maybe<Scalars['String']>;
  /** Production Area */
  productionArea?: Maybe<Scalars['String']>;
  /** DALRRD PUC Code */
  puc?: Maybe<Scalars['String']>;
  /** Loading Date for trade unit */
  stuffDate?: Maybe<Scalars['String']>;
  /** Trade Units ID such as pallet or container number */
  tradeUnitId?: Maybe<Scalars['String']>;
  /** product class code for the trade unit */
  tuClass?: Maybe<Scalars['String']>;
};

export type EphytoTreatment = {
  __typename?: 'EphytoTreatment';
  /** First level of chemical treatment */
  firstLevel?: Maybe<Scalars['String']>;
  /** Unique code for first level */
  firstLevelCode?: Maybe<Scalars['String']>;
  /** Second level of chemical treatment */
  secondLevel?: Maybe<Scalars['String']>;
  /** Unique code for second level */
  secondLevelCode?: Maybe<Scalars['String']>;
};

export type Estimate = {
  __typename?: 'Estimate';
  cartonType: CartonType;
  commodity: Commodity;
  company: Company;
  estimateValues?: Maybe<Array<EstimateValue>>;
  fboOrchard?: Maybe<FboOrchard>;
  id: Scalars['Int'];
  packHouse?: Maybe<FoodBusinessOperator>;
  productionUnit?: Maybe<FoodBusinessOperator>;
  provider?: Maybe<Company>;
  season: Scalars['Int'];
  variety?: Maybe<Variety>;
  week?: Maybe<Scalars['String']>;
};

/** Basic attributes for creating & updating an estimate */
export type EstimateAttributes = {
  cartonTypeId: Scalars['Int'];
  commodityId: Scalars['Int'];
  fboOrchardId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  packHouseId?: Maybe<Scalars['Int']>;
  productionUnitId?: Maybe<Scalars['Int']>;
  providerId: Scalars['Int'];
  season: Scalars['Int'];
  values: Array<EstimateValueAttributes>;
  varietyId?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['String']>;
};

/** Determines how estimates and line items are matched. */
export enum EstimateMatchModeEnum {
  Provider = 'provider',
  Puc = 'puc',
  Variety = 'variety',
  SubCommodity = 'sub_commodity',
  Commodity = 'commodity'
}

export type EstimateValue = {
  __typename?: 'EstimateValue';
  acceptedValue: Scalars['Float'];
  conversionWeight?: Maybe<Scalars['Float']>;
  estType: EstimateValueTypeEnum;
  grade?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inSeasonValue?: Maybe<Scalars['Float']>;
  sizeCounts?: Maybe<Array<Scalars['String']>>;
  value: Scalars['Float'];
};


export type EstimateValueAcceptedValueArgs = {
  matchCols?: Maybe<Array<EstimateMatchModeEnum>>;
};

/** Basic attributes for creating & updating an estimate value */
export type EstimateValueAttributes = {
  conversionWeight?: Maybe<Scalars['Float']>;
  deleted?: Maybe<Scalars['Boolean']>;
  estType: EstimateValueTypeEnum;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inSeasonValue?: Maybe<Scalars['Float']>;
  sizeCounts?: Maybe<Array<Scalars['String']>>;
  value: Scalars['Float'];
};

/** The type of estimate value. Either picked (kg) or packed (cartons) */
export enum EstimateValueTypeEnum {
  Picked = 'picked',
  Packed = 'packed'
}

export type EtlDocument = {
  __typename?: 'EtlDocument';
  createdAt: Scalars['ISO8601DateTime'];
  errorReason?: Maybe<Scalars['String']>;
  erroredRows?: Maybe<Scalars['Int']>;
  fileUrl?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['Int'];
  processedRows?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
  state: EtlDocumentStateEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for EtlDocument. */
export type EtlDocumentConnection = {
  __typename?: 'EtlDocumentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EtlDocumentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EtlDocument>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EtlDocumentEdge = {
  __typename?: 'EtlDocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EtlDocument>;
};

export type EtlDocumentRow = {
  __typename?: 'EtlDocumentRow';
  createdAt: Scalars['ISO8601DateTime'];
  errorReason?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  processOutput?: Maybe<Scalars['String']>;
  state: EtlDocumentRowStateEnum;
  value: Scalars['Json'];
};

/** The connection type for EtlDocumentRow. */
export type EtlDocumentRowConnection = {
  __typename?: 'EtlDocumentRowConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EtlDocumentRowEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EtlDocumentRow>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EtlDocumentRowEdge = {
  __typename?: 'EtlDocumentRowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EtlDocumentRow>;
};

export enum EtlDocumentRowStateEnum {
  Unprocessed = 'unprocessed',
  Mapped = 'mapped',
  Processed = 'processed',
  Skipped = 'skipped',
  Errored = 'errored'
}

export enum EtlDocumentStateEnum {
  Created = 'created',
  Unmapped = 'unmapped',
  Processed = 'processed',
  Ignored = 'ignored',
  Duplicate = 'duplicate',
  Errored = 'errored'
}

export type ExpectedReturnSummary = {
  __typename?: 'ExpectedReturnSummary';
  equivalentPrice?: Maybe<IncotermPrice>;
  error?: Maybe<Scalars['String']>;
  pricePoints?: Maybe<Array<IncotermPrice>>;
  unit?: Maybe<Scalars['String']>;
};

export type ExportDocument = {
  __typename?: 'ExportDocument';
  documentType?: Maybe<Scalars['String']>;
  file?: Maybe<Document>;
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type ExtendedField = {
  __typename?: 'ExtendedField';
  /** company the extended fields are for */
  companyId?: Maybe<Scalars['ID']>;
  /** polymorphic parent ID */
  resourceId: Scalars['ID'];
  /** polymorphic parent e.g. CartonGrouping */
  resourceType: Scalars['String'];
  /** extended fields key-value pairs */
  value?: Maybe<Scalars['Json']>;
};

export type FarmVariety = {
  __typename?: 'FarmVariety';
  commodity?: Maybe<Commodity>;
  commodityName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  hectares?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Image>>;
  name?: Maybe<Scalars['String']>;
  packingEnd?: Maybe<Scalars['String']>;
  packingStart?: Maybe<Scalars['String']>;
  peakCount?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  varietyCodes?: Maybe<Scalars['String']>;
  varietyId?: Maybe<Scalars['Int']>;
};

export type FarmVarietyFilterAttribute = {
  __typename?: 'FarmVarietyFilterAttribute';
  code?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
};

export type FarmVarietyRequest = {
  __typename?: 'FarmVarietyRequest';
  commodityName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  varietyCodes?: Maybe<Scalars['String']>;
};

export type FboOrchard = {
  __typename?: 'FboOrchard';
  block?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  foodBusinessOperator: FoodBusinessOperator;
  hectares?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  orchard: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  variety: Variety;
};


export type FboOrchardHectaresArgs = {
  season?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of FetchEphytoStatus */
export type FetchEphytoStatusPayload = {
  __typename?: 'FetchEphytoStatusPayload';
  /** The Ephyto */
  ephyto?: Maybe<Ephyto>;
  /** Any errors when attempting to fetch ephyto status */
  errors?: Maybe<Scalars['String']>;
};

/** Fields defining a generic filter model */
export type Filter = {
  /** Field to filter on */
  field?: Maybe<Scalars['String']>;
  /** can be a value of 'text' or 'number' */
  fieldType?: Maybe<Scalars['String']>;
  /**
   * Filter type in report group:
   * - 'input': Custom text entry.
   * - 'idSelection': Dropdown 'id', for backend identifiers.
   * - 'labelSelection': Dropdown 'label', for display text.
   * Select based on backend requirements.
   */
  filterType?: Maybe<Scalars['String']>;
  /** Filtering condition: 'equals', 'notEqual', 'contains','notContains', 'startsWith', 'endsWith','lessThan', 'lessThanOrEqual', 'greaterThan','greaterThanOrEqual', 'inRange' */
  condition?: Maybe<Scalars['String']>;
  /** The value you want to filter on. */
  filter?: Maybe<Scalars['String']>;
  /** The value you want to filter on. */
  filterTo?: Maybe<Scalars['String']>;
  /** The values you want to filter on. */
  values?: Maybe<Array<Scalars['String']>>;
};

export type FinanceReport = {
  __typename?: 'FinanceReport';
  items?: Maybe<Array<FinanceReportItem>>;
};

export type FinanceReportFilterAttributes = {
  companyIds?: Maybe<Array<Scalars['Int']>>;
  containerNumbers?: Maybe<Array<Scalars['String']>>;
  currencies?: Maybe<Array<Scalars['String']>>;
  endWeek?: Maybe<Scalars['String']>;
  orderIds?: Maybe<Array<Scalars['Int']>>;
  startWeek?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Scalars['String']>>;
};

export type FinanceReportItem = {
  __typename?: 'FinanceReportItem';
  amount?: Maybe<Scalars['Float']>;
  amountPerCarton?: Maybe<Scalars['Float']>;
  batchNumber?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  cartons?: Maybe<Scalars['Int']>;
  commodity?: Maybe<Scalars['String']>;
  containerNumber?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  exporterCode?: Maybe<Scalars['String']>;
  farmCode?: Maybe<Scalars['String']>;
  generalLedgerName?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  incoterm?: Maybe<Scalars['String']>;
  isActual?: Maybe<Scalars['Boolean']>;
  liReferenceNumber?: Maybe<Scalars['String']>;
  packType?: Maybe<Scalars['String']>;
  packingWeek?: Maybe<Scalars['String']>;
  palletNumber?: Maybe<Scalars['String']>;
  primaryCategory?: Maybe<Scalars['String']>;
  secondaryCategory?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  shippingWeek?: Maybe<Scalars['String']>;
  sizeCount?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stdCartons?: Maybe<Scalars['Int']>;
  targetMarket?: Maybe<Scalars['String']>;
  targetRegion?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['ISO8601DateTime']>;
  transactionType?: Maybe<Scalars['String']>;
  transactionWeek?: Maybe<Scalars['String']>;
  txReferenceNumber?: Maybe<Scalars['String']>;
  variety?: Maybe<Scalars['String']>;
  zarAmount?: Maybe<Scalars['Float']>;
  zarPerCarton?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of FindOrCreateVessel */
export type FindOrCreateVesselPayload = {
  __typename?: 'FindOrCreateVesselPayload';
  /** Any errors that occurred */
  errors?: Maybe<Scalars['String']>;
  /** The vessel */
  vessel?: Maybe<Vessel>;
};

export type FoodBusinessOperator = {
  __typename?: 'FoodBusinessOperator';
  company?: Maybe<Company>;
  createdAt: Scalars['ISO8601DateTime'];
  fboCode?: Maybe<Scalars['String']>;
  globalGapNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  producerName?: Maybe<Scalars['String']>;
  typeOfBusiness?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type FoodBusinessOperatorRequest = {
  __typename?: 'FoodBusinessOperatorRequest';
  fboCode?: Maybe<Scalars['String']>;
  fboName?: Maybe<Scalars['String']>;
  globalGapNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['Int']>;
  producerName?: Maybe<Scalars['String']>;
  productionRegion?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  typeOfBusiness?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateAddendumHeaderOutputFiles */
export type GenerateAddendumHeaderOutputFilesPayload = {
  __typename?: 'GenerateAddendumHeaderOutputFilesPayload';
  /** The template output object containing state of the file generation */
  templateOutput?: Maybe<Array<TemplateOutput>>;
};

/** Autogenerated return type of GenerateAddendum */
export type GenerateAddendumPayload = {
  __typename?: 'GenerateAddendumPayload';
  /** The Addendum Header */
  addendumHeader?: Maybe<AddendumHeader>;
  /** Errors */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateAirAddendum */
export type GenerateAirAddendumPayload = {
  __typename?: 'GenerateAirAddendumPayload';
  /** The generated addendum header */
  addendumHeader?: Maybe<AddendumHeader>;
  /** Errors */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateCommoditySeasons */
export type GenerateCommoditySeasonsPayload = {
  __typename?: 'GenerateCommoditySeasonsPayload';
  error?: Maybe<Scalars['String']>;
  failures?: Maybe<Scalars['Int']>;
  successes?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of GenerateDataOutputFiles */
export type GenerateDataOutputFilesPayload = {
  __typename?: 'GenerateDataOutputFilesPayload';
  /** The template output object containing state of the file generation */
  dataPreparation?: Maybe<DataPreparation>;
};

/** Autogenerated return type of GenerateEphyto */
export type GenerateEphytoPayload = {
  __typename?: 'GenerateEphytoPayload';
  /** The Resulting Ephyto */
  ephyto?: Maybe<Ephyto>;
  /** Errors */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateEphytoTradeUnit */
export type GenerateEphytoTradeUnitPayload = {
  __typename?: 'GenerateEphytoTradeUnitPayload';
  /** The Resulting EphytoTradeUnits */
  ephytoTradeUnits?: Maybe<Array<EphytoTradeUnit>>;
  /** Errors */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateInvoiceOutputFiles */
export type GenerateInvoiceOutputFilesPayload = {
  __typename?: 'GenerateInvoiceOutputFilesPayload';
  /** The template output object containing state of the file generation */
  templateOutput?: Maybe<Array<TemplateOutput>>;
};

/** Autogenerated return type of GenerateLoadOutInstructionOutputFiles */
export type GenerateLoadOutInstructionOutputFilesPayload = {
  __typename?: 'GenerateLoadOutInstructionOutputFilesPayload';
  /** The template output object containing state of the file generation */
  templateOutput?: Maybe<Array<TemplateOutput>>;
};

/** Autogenerated return type of GeneratePalletInOutputFiles */
export type GeneratePalletInOutputFilesPayload = {
  __typename?: 'GeneratePalletInOutputFilesPayload';
  /** Unique identifier of the created or updated pallet in */
  palletInId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of GeneratePalletOutOutputFiles */
export type GeneratePalletOutOutputFilesPayload = {
  __typename?: 'GeneratePalletOutOutputFilesPayload';
  /** Unique identifier of the created or updated pallet out */
  palletOutId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of GenerateShipmentExportNotificationOutputFiles */
export type GenerateShipmentExportNotificationOutputFilesPayload = {
  __typename?: 'GenerateShipmentExportNotificationOutputFilesPayload';
  /** The shipment containing state of the file generation */
  shipment?: Maybe<Shipment>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  /** Latitude */
  lat?: Maybe<Scalars['Float']>;
  /** Longitude */
  lon?: Maybe<Scalars['Float']>;
};

export type GridColumn = {
  aggFunc?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  field?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Fields defining the filter model for AG Grid */
export type GridFilterModel = {
  /** Filter type, should be text or number. */
  filterType?: Maybe<Scalars['String']>;
  /**
   * The specific type of filter, should be one of 'equals', 'notEqual', 'contains',
   * 'notContains', 'startsWith', 'endsWith', for Strings and 'lessThan', 'lessThanOrEqual', 'greaterThan',
   * 'greaterThanOrEqual', 'inRange', 'lessThan', 'lessThanOrEqual', 'greaterThan',
   * 'greaterThanOrEqual', 'inRange', 'set', 'notSet' for Numbers.
   */
  type?: Maybe<Scalars['String']>;
  /** The value you want to filter on. */
  filter?: Maybe<Scalars['String']>;
};

/** This defines a request for a SSR AG Grid */
export type GridRequest = {
  /** First row requested or undefined for all rows. */
  startRow: Scalars['Int'];
  /** Last row requested or undefined for all rows. */
  endRow: Scalars['Int'];
  /** Columns that are currently row grouped. */
  rowGroupCols?: Maybe<Array<GridColumn>>;
  /** Columns that have aggregations on them. */
  valueCols?: Maybe<Array<GridColumn>>;
  /** Columns that have pivot on them. */
  pivotCols?: Maybe<Array<GridColumn>>;
  /** Defines if pivot mode is on or off. */
  pivotMode?: Maybe<Scalars['Boolean']>;
  /** What groups the user is viewing. */
  groupKeys?: Maybe<Array<Scalars['String']>>;
  /** If filtering, what the filter model is. */
  filterModel?: Maybe<Array<GridFilterModel>>;
  /** If sorting, what the sort model is. */
  sortModel?: Maybe<Array<GridSortModel>>;
};

/** Fields defining the sorting model for AG Grid */
export type GridSortModel = {
  /** Column Id to apply the sort to. */
  colId: Scalars['String'];
  /** Sort direction. 'asc' or 'desc' */
  sort: Scalars['String'];
};

/** Autogenerated return type of HandleInvoiceRemittance */
export type HandleInvoiceRemittancePayload = {
  __typename?: 'HandleInvoiceRemittancePayload';
  /** Any errors when updating a invoice */
  errors?: Maybe<Scalars['String']>;
  /** Completed invoice return  */
  invoices?: Maybe<Array<Invoice>>;
};

/** A valid ISO 4217 currency code string. */
export enum Iso4217 {
  /** United Arab Emirates Dirham */
  Aed = 'AED',
  /** Afghan Afghani */
  Afn = 'AFN',
  /** Albanian Lek */
  All = 'ALL',
  /** Armenian Dram */
  Amd = 'AMD',
  /** Netherlands Antillean Gulden */
  Ang = 'ANG',
  /** Angolan Kwanza */
  Aoa = 'AOA',
  /** Argentine Peso */
  Ars = 'ARS',
  /** Australian Dollar */
  Aud = 'AUD',
  /** Aruban Florin */
  Awg = 'AWG',
  /** Azerbaijani Manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark */
  Bam = 'BAM',
  /** Barbadian Dollar */
  Bbd = 'BBD',
  /** Bitcoin Cash */
  Bch = 'BCH',
  /** Bangladeshi Taka */
  Bdt = 'BDT',
  /** Bulgarian Lev */
  Bgn = 'BGN',
  /** Bahraini Dinar */
  Bhd = 'BHD',
  /** Burundian Franc */
  Bif = 'BIF',
  /** Bermudian Dollar */
  Bmd = 'BMD',
  /** Brunei Dollar */
  Bnd = 'BND',
  /** Bolivian Boliviano */
  Bob = 'BOB',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Bahamian Dollar */
  Bsd = 'BSD',
  /** Bitcoin */
  Btc = 'BTC',
  /** Bhutanese Ngultrum */
  Btn = 'BTN',
  /** Botswana Pula */
  Bwp = 'BWP',
  /** Belarusian Ruble */
  Byr = 'BYR',
  /** Belize Dollar */
  Bzd = 'BZD',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Congolese Franc */
  Cdf = 'CDF',
  /** Swiss Franc */
  Chf = 'CHF',
  /** Unidad de Fomento */
  Clf = 'CLF',
  /** Chilean Peso */
  Clp = 'CLP',
  /** Chinese Renminbi Yuan Offshore */
  Cnh = 'CNH',
  /** Chinese Renminbi Yuan */
  Cny = 'CNY',
  /** Colombian Peso */
  Cop = 'COP',
  /** Costa Rican Colón */
  Crc = 'CRC',
  /** Cuban Convertible Peso */
  Cuc = 'CUC',
  /** Cuban Peso */
  Cup = 'CUP',
  /** Cape Verdean Escudo */
  Cve = 'CVE',
  /** Czech Koruna */
  Czk = 'CZK',
  /** Djiboutian Franc */
  Djf = 'DJF',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Dominican Peso */
  Dop = 'DOP',
  /** Algerian Dinar */
  Dzd = 'DZD',
  /** Estonian Kroon */
  Eek = 'EEK',
  /** Egyptian Pound */
  Egp = 'EGP',
  /** Eritrean Nakfa */
  Ern = 'ERN',
  /** Ethiopian Birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fijian Dollar */
  Fjd = 'FJD',
  /** Falkland Pound */
  Fkp = 'FKP',
  /** British Pound */
  Gbp = 'GBP',
  /** British Penny */
  Gbx = 'GBX',
  /** Georgian Lari */
  Gel = 'GEL',
  /** Guernsey Pound */
  Ggp = 'GGP',
  /** Ghanaian Cedi */
  Ghs = 'GHS',
  /** Gibraltar Pound */
  Gip = 'GIP',
  /** Gambian Dalasi */
  Gmd = 'GMD',
  /** Guinean Franc */
  Gnf = 'GNF',
  /** Guatemalan Quetzal */
  Gtq = 'GTQ',
  /** Guyanese Dollar */
  Gyd = 'GYD',
  /** Hong Kong Dollar */
  Hkd = 'HKD',
  /** Honduran Lempira */
  Hnl = 'HNL',
  /** Croatian Kuna */
  Hrk = 'HRK',
  /** Haitian Gourde */
  Htg = 'HTG',
  /** Hungarian Forint */
  Huf = 'HUF',
  /** Indonesian Rupiah */
  Idr = 'IDR',
  /** Israeli New Sheqel */
  Ils = 'ILS',
  /** Isle of Man Pound */
  Imp = 'IMP',
  /** Indian Rupee */
  Inr = 'INR',
  /** Iraqi Dinar */
  Iqd = 'IQD',
  /** Iranian Rial */
  Irr = 'IRR',
  /** Icelandic Króna */
  Isk = 'ISK',
  /** Jersey Pound */
  Jep = 'JEP',
  /** Jamaican Dollar */
  Jmd = 'JMD',
  /** Jordanian Dinar */
  Jod = 'JOD',
  /** Japanese Yen */
  Jpy = 'JPY',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Kyrgyzstani Som */
  Kgs = 'KGS',
  /** Cambodian Riel */
  Khr = 'KHR',
  /** Comorian Franc */
  Kmf = 'KMF',
  /** North Korean Won */
  Kpw = 'KPW',
  /** South Korean Won */
  Krw = 'KRW',
  /** Kuwaiti Dinar */
  Kwd = 'KWD',
  /** Cayman Islands Dollar */
  Kyd = 'KYD',
  /** Kazakhstani Tenge */
  Kzt = 'KZT',
  /** Lao Kip */
  Lak = 'LAK',
  /** Lebanese Pound */
  Lbp = 'LBP',
  /** Sri Lankan Rupee */
  Lkr = 'LKR',
  /** Liberian Dollar */
  Lrd = 'LRD',
  /** Lesotho Loti */
  Lsl = 'LSL',
  /** Lithuanian Litas */
  Ltl = 'LTL',
  /** Latvian Lats */
  Lvl = 'LVL',
  /** Libyan Dinar */
  Lyd = 'LYD',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu */
  Mdl = 'MDL',
  /** Malagasy Ariary */
  Mga = 'MGA',
  /** Macedonian Denar */
  Mkd = 'MKD',
  /** Myanmar Kyat */
  Mmk = 'MMK',
  /** Mongolian Tögrög */
  Mnt = 'MNT',
  /** Macanese Pataca */
  Mop = 'MOP',
  /** Mauritanian Ouguiya */
  Mru = 'MRU',
  /** Maltese Lira */
  Mtl = 'MTL',
  /** Mauritian Rupee */
  Mur = 'MUR',
  /** Maldivian Rufiyaa */
  Mvr = 'MVR',
  /** Malawian Kwacha */
  Mwk = 'MWK',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Malaysian Ringgit */
  Myr = 'MYR',
  /** Mozambican Metical */
  Mzn = 'MZN',
  /** Namibian Dollar */
  Nad = 'NAD',
  /** Nigerian Naira */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese Rupee */
  Npr = 'NPR',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Omani Rial */
  Omr = 'OMR',
  /** Panamanian Balboa */
  Pab = 'PAB',
  /** Peruvian Sol */
  Pen = 'PEN',
  /** Papua New Guinean Kina */
  Pgk = 'PGK',
  /** Philippine Peso */
  Php = 'PHP',
  /** Pakistani Rupee */
  Pkr = 'PKR',
  /** Polish Złoty */
  Pln = 'PLN',
  /** Paraguayan Guaraní */
  Pyg = 'PYG',
  /** Qatari Riyal */
  Qar = 'QAR',
  /** Romanian Leu */
  Ron = 'RON',
  /** Serbian Dinar */
  Rsd = 'RSD',
  /** Russian Ruble */
  Rub = 'RUB',
  /** Rwandan Franc */
  Rwf = 'RWF',
  /** Saudi Riyal */
  Sar = 'SAR',
  /** Solomon Islands Dollar */
  Sbd = 'SBD',
  /** Seychellois Rupee */
  Scr = 'SCR',
  /** Sudanese Pound */
  Sdg = 'SDG',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Saint Helenian Pound */
  Shp = 'SHP',
  /** Slovak Koruna */
  Skk = 'SKK',
  /** Sierra Leonean Leone */
  Sll = 'SLL',
  /** Somali Shilling */
  Sos = 'SOS',
  /** Surinamese Dollar */
  Srd = 'SRD',
  /** South Sudanese Pound */
  Ssp = 'SSP',
  /** São Tomé and Príncipe Dobra */
  Std = 'STD',
  /** Salvadoran Colón */
  Svc = 'SVC',
  /** Syrian Pound */
  Syp = 'SYP',
  /** Swazi Lilangeni */
  Szl = 'SZL',
  /** Thai Baht */
  Thb = 'THB',
  /** Tajikistani Somoni */
  Tjs = 'TJS',
  /** Turkmenistani Manat */
  Tmt = 'TMT',
  /** Tunisian Dinar */
  Tnd = 'TND',
  /** Tongan Paʻanga */
  Top = 'TOP',
  /** Turkish Lira */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar */
  Ttd = 'TTD',
  /** New Taiwan Dollar */
  Twd = 'TWD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia */
  Uah = 'UAH',
  /** Ugandan Shilling */
  Ugx = 'UGX',
  /** United States Dollar */
  Usd = 'USD',
  /** Uruguayan Peso */
  Uyu = 'UYU',
  /** Uzbekistan Som */
  Uzs = 'UZS',
  /** Venezuelan Bolívar */
  Vef = 'VEF',
  /** Venezuelan Bolívar Soberano */
  Ves = 'VES',
  /** Vietnamese Đồng */
  Vnd = 'VND',
  /** Vanuatu Vatu */
  Vuv = 'VUV',
  /** Samoan Tala */
  Wst = 'WST',
  /** Central African Cfa Franc */
  Xaf = 'XAF',
  /** Silver (Troy Ounce) */
  Xag = 'XAG',
  /** Gold (Troy Ounce) */
  Xau = 'XAU',
  /** European Composite Unit */
  Xba = 'XBA',
  /** European Monetary Unit */
  Xbb = 'XBB',
  /** European Unit of Account 9 */
  Xbc = 'XBC',
  /** European Unit of Account 17 */
  Xbd = 'XBD',
  /** East Caribbean Dollar */
  Xcd = 'XCD',
  /** Special Drawing Rights */
  Xdr = 'XDR',
  /** UIC Franc */
  Xfu = 'XFU',
  /** West African Cfa Franc */
  Xof = 'XOF',
  /** Palladium */
  Xpd = 'XPD',
  /** Cfp Franc */
  Xpf = 'XPF',
  /** Platinum */
  Xpt = 'XPT',
  /** Codes specifically reserved for testing purposes */
  Xts = 'XTS',
  /** Yemeni Rial */
  Yer = 'YER',
  /** South African Rand */
  Zar = 'ZAR',
  /** Zambian Kwacha */
  Zmk = 'ZMK',
  /** Zimbabwean Dollar */
  Zwd = 'ZWD'
}



export type Image = {
  __typename?: 'Image';
  /** The image as a base 64 encoded string formatted for usage in an img html tag */
  base64ImgSrc?: Maybe<Scalars['String']>;
  /** The original name of the file */
  filename?: Maybe<Scalars['String']>;
  /** The ID of the image */
  id?: Maybe<Scalars['Int']>;
  /** A smaller version of the image */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** The direct URL to the image */
  url?: Maybe<Scalars['String']>;
  /** The upgraded version of the thumbnail URL */
  v2ThumbnailUrl?: Maybe<Scalars['String']>;
  /** The direct URL to the image that includes the host */
  v2Url?: Maybe<Scalars['String']>;
};

export enum IncotermEnum {
  /** The incoterm is EXW */
  Exw = 'EXW',
  /** The incoterm is DIP */
  Dip = 'DIP',
  /** The incoterm is FCA */
  Fca = 'FCA',
  /** The incoterm is FAS */
  Fas = 'FAS',
  /** The incoterm is FOB */
  Fob = 'FOB',
  /** The incoterm is CFR */
  Cfr = 'CFR',
  /** The incoterm is CIF */
  Cif = 'CIF',
  /** The incoterm is FOT */
  Fot = 'FOT',
  /** The incoterm is DAP */
  Dap = 'DAP',
  /** The incoterm is DDP */
  Ddp = 'DDP'
}

export type IncotermPrice = {
  __typename?: 'IncotermPrice';
  chargesApplied?: Maybe<Array<ChargeApplied>>;
  incoterm: IncotermWithNscEnum;
  moneyAmount: Money;
};

export enum IncotermWithNscEnum {
  /** The incoterm is NSC */
  Nsc = 'NSC',
  /** The incoterm is EXW */
  Exw = 'EXW',
  /** The incoterm is DIP */
  Dip = 'DIP',
  /** The incoterm is FCA */
  Fca = 'FCA',
  /** The incoterm is FAS */
  Fas = 'FAS',
  /** The incoterm is FOB */
  Fob = 'FOB',
  /** The incoterm is CFR */
  Cfr = 'CFR',
  /** The incoterm is CIF */
  Cif = 'CIF',
  /** The incoterm is FOT */
  Fot = 'FOT',
  /** The incoterm is DAP */
  Dap = 'DAP',
  /** The incoterm is DDP */
  Ddp = 'DDP'
}

export type Invite = {
  __typename?: 'Invite';
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  initiator?: Maybe<User>;
  message?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of InviteUser */
export type InviteUserPayload = {
  __typename?: 'InviteUserPayload';
  invite?: Maybe<Invite>;
};

export type Invoice = {
  __typename?: 'Invoice';
  /** The number of days within which advance payment is needed from agreed upon event */
  advanceCreditTerm?: Maybe<Scalars['Int']>;
  /** The total advance payment for this invoice */
  advancePayment?: Maybe<Scalars['Float']>;
  /** The air waybill no associated with this Invoice */
  airWaybillNo?: Maybe<Scalars['String']>;
  /** The allocated weight of the goods for the Invoice */
  allocatedWeight?: Maybe<Scalars['Float']>;
  /** Amount remitted against this invoice */
  amountRemitted?: Maybe<Scalars['Float']>;
  /** The number of cartons in the invoice */
  cartonCount?: Maybe<Scalars['Int']>;
  /** The carton groupings that are associated with this invoice */
  cartonGroupings?: Maybe<Array<CartonGrouping>>;
  /** The company that owns this invoice */
  company?: Maybe<Company>;
  /** The connecting flight date if applicable */
  connectingFlightDate?: Maybe<Scalars['ISO8601Date']>;
  /** The contacts for the invoice */
  contacts?: Maybe<Array<Contact>>;
  /** The containers on the invoice */
  containers?: Maybe<Array<Container>>;
  /** Whether or not to convert tons to kilograms */
  convertTonToKg?: Maybe<Scalars['Boolean']>;
  /** The country of origin for the invoice */
  countryOfOrigin?: Maybe<TradeCountry>;
  /** Currency of the invoice */
  currency?: Maybe<CurrencyEnum>;
  /** The destination associated with the Invoice */
  destination?: Maybe<Scalars['String']>;
  /** The kind of document e.g. invoice, tax invoice, ... */
  documentType?: Maybe<Scalars['String']>;
  /** The number of days within which final payment is needed from agreed upon event */
  finalCreditTerm?: Maybe<Scalars['Int']>;
  /** The total final payment for this invoice */
  finalPayment?: Maybe<Scalars['Float']>;
  /** The flights date */
  flightDate?: Maybe<Scalars['ISO8601Date']>;
  /** Freight cost of the CI */
  freightCost?: Maybe<Scalars['Float']>;
  /** Unique CI identifier */
  id?: Maybe<Scalars['ID']>;
  /** Incoterm applicable */
  incoterm?: Maybe<IncotermEnum>;
  /** Insurance cost of the CI */
  insuranceCost?: Maybe<Scalars['Float']>;
  /** Custom fields associated with this invoice */
  invoiceCustomFields?: Maybe<Array<InvoiceCustomField>>;
  /** The user-selected date that the invoice was issued */
  invoiceDate?: Maybe<Scalars['ISO8601Date']>;
  /** The pallets that are associated with this invoice's carton groupings */
  invoiceLineItems?: Maybe<Array<InvoiceLineItem>>;
  /** A reference assigned to the CI */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** The FBO where the goods are loaded */
  loadingPoint?: Maybe<FoodBusinessOperator>;
  /** Local costs of the CI */
  localCosts?: Maybe<Scalars['Float']>;
  /** The FBO where the goods are unloaded */
  offloadingPoint?: Maybe<FoodBusinessOperator>;
  /** The number of pallets in the invoice */
  palletCount?: Maybe<Scalars['Int']>;
  /** Purchase order number */
  poNumber?: Maybe<Scalars['String']>;
  /** Where vessel is delivering the shipment */
  portOfDestination?: Maybe<SeaPort>;
  /** Where shipment was loaded onto a vessel */
  portOfLoading?: Maybe<SeaPort>;
  /** The flights routing */
  routing?: Maybe<Scalars['String']>;
  /** The sale type of this CI */
  saleType?: Maybe<Scalars['String']>;
  /** Emails in which this document was included */
  sentEmailList?: Maybe<Array<SentEmail>>;
  /** The Shippers name */
  shipper?: Maybe<Scalars['String']>;
  /** The state of the invoice */
  state?: Maybe<InvoiceStateEnum>;
  /** FBO Associated with this CI */
  stuffLocation?: Maybe<FoodBusinessOperator>;
  /** Object containing information on the document generated for this invoice */
  templateOutput?: Maybe<Array<TemplateOutput>>;
  /** The total FOB value of the invoice */
  totalFob?: Maybe<Scalars['Float']>;
  /** Total incoterm value of the CI */
  totalIncoterm?: Maybe<Scalars['Float']>;
  /** Transport Type seleccted for this document */
  transportType?: Maybe<Scalars['String']>;
  /** UCR number */
  ucrNumber?: Maybe<Scalars['String']>;
  /** The time at which the invoice was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The vehicle number that is associated with this CI */
  vehicleNumber?: Maybe<Scalars['String']>;
  /** Vessel associated with CI */
  vessel?: Maybe<Vessel>;
  /** Voyage that the associated vessel is taking */
  voyage?: Maybe<Scalars['String']>;
};


export type InvoiceSentEmailListArgs = {
  direction?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
};

export type InvoiceAttributes = {
  /** The air waybill number associated with this Invoice */
  airWaybillNo?: Maybe<Scalars['String']>;
  /** The allocated weight of the goods for the Invoice */
  allocatedWeight?: Maybe<Scalars['Float']>;
  /** The IDs of the carton groupings associated with the invoice */
  cartongroupingids?: Maybe<Array<Scalars['Int']>>;
  /** The connecting flight date if applicable */
  connectingFlightDate?: Maybe<Scalars['ISO8601Date']>;
  /** The IDs of the contacts associated with the invoice */
  contactIds?: Maybe<Array<Scalars['Int']>>;
  /** The IDs of the containers associated with the invoice */
  containerids?: Maybe<Array<Scalars['Int']>>;
  /** Indicates whether the weight should be converted from tons to kilograms */
  convertTonToKg?: Maybe<Scalars['Boolean']>;
  /** The ID of the country of origin for the shipment */
  countryOfOriginId?: Maybe<Scalars['Int']>;
  /** The destination associated with the Invoice */
  destination?: Maybe<Scalars['String']>;
  /** The type of document (e.g., invoice, credit note) */
  documentType: Scalars['String'];
  /** The flight's date */
  flightDate?: Maybe<Scalars['ISO8601Date']>;
  /** The ID of the invoice */
  id?: Maybe<Scalars['ID']>;
  /** The Incoterm associated with the shipment */
  incoterm?: Maybe<IncotermEnum>;
  /** The custom fields associated with the invoice */
  invoiceCustomFields?: Maybe<Array<InvoiceCustomFieldAttributes>>;
  /** The date associated to the invoice */
  invoiceDate?: Maybe<Scalars['ISO8601Date']>;
  /** The invoice number */
  invoicenumber?: Maybe<Scalars['String']>;
  /** The pallets associated with the invoice */
  invoicelineitems?: Maybe<Array<InvoiceLineItemAttributes>>;
  /** The loading point */
  loadingPointId?: Maybe<Scalars['Int']>;
  /** The offloading point */
  offloadingPointId?: Maybe<Scalars['Int']>;
  /** The purchase order number associated with the shipment */
  ponumber?: Maybe<Scalars['String']>;
  /** The ID of the port of destination for the shipment */
  portofdestinationid?: Maybe<Scalars['Int']>;
  /** The ID of the port of loading for the shipment */
  portofloadingid?: Maybe<Scalars['Int']>;
  /** The flights routing */
  routing?: Maybe<Scalars['String']>;
  /** The sale type */
  saleType?: Maybe<Scalars['String']>;
  /** The Shipper's name */
  shipper?: Maybe<Scalars['String']>;
  /** The state of the invoice */
  state?: Maybe<InvoiceStateEnum>;
  /** Where the container was loaded */
  stuffLocationId?: Maybe<Scalars['Int']>;
  /** The type of transportation for the shipment (e.g., container, break bulk) */
  transportType?: Maybe<Scalars['String']>;
  /** The Unique Consignment Reference (UCR) number associated with the shipment */
  ucrnumber?: Maybe<Scalars['String']>;
  /** The vehicle number */
  vehicleNumber?: Maybe<Scalars['String']>;
  /** The vessel details associated with the shipment */
  vessel?: Maybe<VesselAttributes>;
  /** The voyage or trip number associated with the shipment */
  voyage?: Maybe<Scalars['String']>;
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type InvoiceCustomField = {
  __typename?: 'InvoiceCustomField';
  /** Unique custom field identifier */
  customFieldId?: Maybe<Scalars['Int']>;
  /** The default value of the custom field */
  defaultValue?: Maybe<Scalars['String']>;
  /** Unique invoice identifier */
  invoiceId?: Maybe<Scalars['Int']>;
  /** Whether the custom field should appear by default */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** The name of the custom field */
  name?: Maybe<Scalars['String']>;
  /** Whether the custom field is required */
  required?: Maybe<Scalars['Boolean']>;
  /** The value of the custom field */
  value?: Maybe<Scalars['String']>;
};

export type InvoiceCustomFieldAttributes = {
  /** The id of the invoice */
  invoiceId?: Maybe<Scalars['Int']>;
  /** The id of the custom field */
  customFieldId?: Maybe<Scalars['Int']>;
  /** The custom field value */
  value?: Maybe<Scalars['String']>;
};

export type InvoiceDocument = {
  __typename?: 'InvoiceDocument';
  name: Scalars['String'];
  type: Scalars['String'];
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export type InvoiceFilterAttribute = {
  __typename?: 'InvoiceFilterAttribute';
  /** commodities found on this user's CIs */
  commodities?: Maybe<Array<Commodity>>;
  /** Contacts for this invoice */
  contacts?: Maybe<Array<Contact>>;
  /** Types of invoices - commercial / tax / credit_note / debit_note */
  documentTypes: Array<InvoiceDocument>;
  /** Incoterms associated with this user's CIs */
  incoterms?: Maybe<Array<IncotermEnum>>;
  /** Invoice number for the invoice */
  invoiceNumbers?: Maybe<Array<Scalars['String']>>;
  /** The states of a document e.g. draft, finalised, ... */
  states?: Maybe<Array<Scalars['String']>>;
  /** Target countries associated with this user's CIs */
  targetCountries: Array<Scalars['String']>;
  /** Transport Types of this user's CIs */
  transportTypes?: Maybe<Array<Scalars['String']>>;
  /** varieties found on this user's CIs */
  varieties?: Maybe<Array<Variety>>;
  /** Vessels associated with this user's CIs */
  vessels?: Maybe<Array<Vessel>>;
};

export type InvoiceIncotermAttributes = {
  /** The id of the invoice */
  invoiceId: Scalars['Int'];
  /** The currency to calculate the incoterm for */
  currency: CurrencyEnum;
  /** The incoterm to calculate for */
  incoterm: IncotermEnum;
  /** The freight cost to use */
  freightCost?: Maybe<Scalars['Float']>;
  /** The insurance cost to use */
  insuranceCost?: Maybe<Scalars['Float']>;
  /** The local costs to use */
  localCosts?: Maybe<Scalars['Float']>;
  /** The total advance payment for this invoice */
  advancePayment?: Maybe<Scalars['Float']>;
  /** The total final payment for this invoice */
  finalPayment?: Maybe<Scalars['Float']>;
  /** The number of days within which advance payment is needed from agreed upon event */
  advanceCreditTerm?: Maybe<Scalars['Int']>;
  /** The number of days within which final payment is needed from agreed upon event */
  finalCreditTerm?: Maybe<Scalars['Int']>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  /** The carton grouping associated with this invoice line item */
  cartonGrouping?: Maybe<CartonGrouping>;
  /** The updated number of cartons in the carton grouping */
  cartons?: Maybe<Scalars['Int']>;
  /** The updated currency of the carton grouping */
  currency?: Maybe<CurrencyEnum>;
  /** The kind of document e.g. invoice, tax invoice, ... */
  documentType?: Maybe<Scalars['String']>;
  /** Unique CI pallet identifier */
  id?: Maybe<Scalars['ID']>;
  /** Parent CI identifier */
  invoiceId?: Maybe<Scalars['ID']>;
  /** The updated net mass of the carton grouping */
  netMass?: Maybe<Scalars['Float']>;
  /** The number of pallets in the carton grouping */
  palletCount?: Maybe<Scalars['Float']>;
  /** The updated gross mass of the carton grouping */
  palletGrossMass?: Maybe<Scalars['Float']>;
  /** The updated price of the carton grouping */
  price?: Maybe<Scalars['String']>;
  /** The updated price unit of the carton grouping (carton/ton) */
  priceUnit?: Maybe<PriceUnitEnum>;
  /** The target market of the invoice line item */
  targetMarket?: Maybe<Scalars['String']>;
  /** The total price minor unit of the invoice line item. */
  totalPriceMinorUnit?: Maybe<Scalars['String']>;
};

export type InvoiceLineItemAttributes = {
  /** The ID of the associated carton grouping. */
  cartongroupingid: Scalars['Int'];
  /** The number of cartons in the invoice line item. */
  cartons?: Maybe<Scalars['Int']>;
  /** The currency of the invoice line item. */
  currency?: Maybe<CurrencyEnum>;
  /** The document type of the invoice line item. */
  documenttype?: Maybe<Scalars['String']>;
  /** The net mass of the invoice line item. */
  netmass?: Maybe<Scalars['Float']>;
  /** The gross mass of the pallet in the invoice line item. */
  palletgrossmass?: Maybe<Scalars['Float']>;
  /** The price of the invoice line item in minor units. */
  price?: Maybe<Scalars['String']>;
  /** The unit of the price (e.g., carton or ton). */
  priceunit?: Maybe<PriceUnitEnum>;
  /** The target market of the invoice line item. */
  targetmarket?: Maybe<Scalars['String']>;
};

/** The different states available for invoices. */
export enum InvoiceStateEnum {
  /** The invoice state type draft */
  Draft = 'draft',
  /** The invoice state type finalised */
  Finalised = 'finalised',
  /** The invoice state type awaiting_payment */
  AwaitingPayment = 'awaiting_payment',
  /** The invoice state type partially_paid */
  PartiallyPaid = 'partially_paid',
  /** The invoice state type paid */
  Paid = 'paid',
  /** The invoice state type overdue */
  Overdue = 'overdue'
}

export type InvoicesFilters = {
  /** List of commodity IDs to filter by. */
  commodityids?: Maybe<Array<Scalars['Int']>>;
  /** List of contact IDs to filter by. */
  contactIds?: Maybe<Array<Scalars['Int']>>;
  /** List of document types to filter by. */
  documenttypes?: Maybe<Array<Scalars['String']>>;
  /** List of Incoterms to filter by. */
  incoterms?: Maybe<Array<IncotermEnum>>;
  /** List of invoice numbers to filter by. */
  invoicenumbers?: Maybe<Array<Scalars['String']>>;
  /** List of states to filter by. */
  states?: Maybe<Array<Scalars['String']>>;
  /** List of target countries to filter by. */
  targetcountries?: Maybe<Array<Scalars['String']>>;
  /** List of transport types to filter by. */
  transporttypes?: Maybe<Array<Scalars['String']>>;
  /** List of variety IDs to filter by. */
  varietyids?: Maybe<Array<Scalars['Int']>>;
  /** List of vessel IDs to filter by. */
  vesselids?: Maybe<Array<Scalars['Int']>>;
};



export type LineItem = {
  __typename?: 'LineItem';
  additionalFields?: Maybe<Scalars['Json']>;
  audits?: Maybe<Array<Audit>>;
  batchNumber?: Maybe<Scalars['String']>;
  cartonGroupings?: Maybe<Array<CartonGrouping>>;
  comments?: Maybe<Array<Comment>>;
  commodity?: Maybe<Commodity>;
  commodityId?: Maybe<Scalars['Int']>;
  containerId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated use MoneyType `price` instead */
  currency?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  departurePort?: Maybe<Array<SeaPort>>;
  destinationPort?: Maybe<Array<SeaPort>>;
  endWeek?: Maybe<Scalars['String']>;
  /** Net return summary using input args OR defaults 'ZAR', 'RAW CARTON', 'EXW' */
  expectedReturnSummary?: Maybe<ExpectedReturnSummary>;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  incoterm?: Maybe<Scalars['String']>;
  inventoryCode?: Maybe<Scalars['String']>;
  lineItemGroupingId?: Maybe<Scalars['Int']>;
  mark?: Maybe<Scalars['String']>;
  newSellerId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  pack?: Maybe<Scalars['String']>;
  packedCartons?: Maybe<Scalars['Int']>;
  packedPallets?: Maybe<Scalars['Float']>;
  packingWeek?: Maybe<Scalars['String']>;
  palletStack?: Maybe<Scalars['Int']>;
  plannedQuantity?: Maybe<Scalars['Int']>;
  plannedQuantityUnit?: Maybe<Scalars['String']>;
  price?: Maybe<Money>;
  /** @deprecated use MoneyType `price` instead */
  priceMinorUnit?: Maybe<Scalars['Int']>;
  priceTerm?: Maybe<Scalars['String']>;
  priceUnit?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  quantityUnit?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  referenceNumber?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  sellerId?: Maybe<Scalars['Int']>;
  sizeCounts?: Maybe<Array<Scalars['String']>>;
  startWeek?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateUpdate?: Maybe<Array<Scalars['String']>>;
  statesDistribution?: Maybe<Array<LineItemState>>;
  targetCountry?: Maybe<Scalars['String']>;
  targetMarket?: Maybe<Scalars['String']>;
  targetRegion?: Maybe<Scalars['String']>;
  unlimited?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  variety?: Maybe<Variety>;
  varietyId?: Maybe<Scalars['Int']>;
};


export type LineItemExpectedReturnSummaryArgs = {
  currency?: Maybe<CurrencyEnum>;
  incoterm?: Maybe<IncotermWithNscEnum>;
  unit?: Maybe<Scalars['String']>;
};

export type LineItemGrouping = {
  __typename?: 'LineItemGrouping';
  /** List of fields that line items in this group have the same value for. */
  commonFields?: Maybe<Array<Scalars['String']>>;
  /** The date and time when the line item grouping was created. */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The total cumulative number of units requested on this line item group (optional). */
  cumulativeQuantity?: Maybe<Scalars['Int']>;
  /** Descriptive information about the cumulative quantity for this line item. */
  cumulativeTooltip?: Maybe<Scalars['String']>;
  /** Indicates whether the line item grouping is marked as deleted. */
  deleted?: Maybe<Scalars['Boolean']>;
  /** The unique identifier of the line item grouping. */
  id?: Maybe<Scalars['Int']>;
  /** List of line items belonging to this grouping. */
  lineItems?: Maybe<Array<LineItem>>;
  /** Total number of line items within the same grouping. */
  numberCumulativeSiblings?: Maybe<Scalars['Int']>;
  /** The unique identifier of the order associated with this line item grouping. */
  orderId?: Maybe<Scalars['Int']>;
  /** The unit of measurement for the cumulative quantity, such as cartons, pallets, or containers. */
  quantityType?: Maybe<Scalars['String']>;
  /** The rank or order of this line item grouping among others. */
  rank?: Maybe<Scalars['Int']>;
  /** The date and time when the line item grouping was last updated. */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};


export type LineItemGroupingLineItemsArgs = {
  commodityIds?: Maybe<Array<Scalars['Int']>>;
  lineItemStates?: Maybe<Array<Scalars['String']>>;
  packingWeekEnd?: Maybe<Scalars['String']>;
  packingWeekStart?: Maybe<Scalars['String']>;
  subCommodityIds?: Maybe<Array<Scalars['Int']>>;
  varietyIds?: Maybe<Array<Scalars['Int']>>;
  states?: Maybe<Array<StateFilter>>;
};

export type LineItemState = {
  __typename?: 'LineItemState';
  /** The number of items in this state */
  palletQuantity?: Maybe<Scalars['Int']>;
  /** Total number of items expected */
  palletQuantityTotal?: Maybe<Scalars['Int']>;
  /** The number of items in this state */
  quanity?: Maybe<Scalars['Int']>;
  /** Total number of items expected */
  quanityTotal?: Maybe<Scalars['Int']>;
  quanityType?: Maybe<Quantity>;
  /** The number of items in this state */
  quantity?: Maybe<Scalars['Int']>;
  /** Total number of items expected */
  quantityTotal?: Maybe<Scalars['Int']>;
  /** The name of this state type */
  state?: Maybe<Scalars['String']>;
  /** The index of its order in the supply chain, 0 = first */
  supplyChainOrder?: Maybe<Scalars['Int']>;
};

export type LoadOutAttributes = {
  /** Reference of booked container */
  bookingReference?: Maybe<Scalars['String']>;
  /** CGs associated with the LOI */
  cartonGroupingIds?: Maybe<Array<Scalars['Int']>>;
  /** Contacts associated with the LOI */
  contactIds?: Maybe<Array<Scalars['Int']>>;
  /** Container unique identification number */
  containerNumber?: Maybe<Scalars['String']>;
  /** When the shipment is expected to be delivered */
  estimatedTimeOfDelivery?: Maybe<Scalars['ISO8601DateTime']>;
  /** Company exporting the goods */
  exporterId?: Maybe<Scalars['Int']>;
  /** Unique reference for exporting company */
  exportersReference?: Maybe<Scalars['String']>;
  /** Unique idetification number associated with the LOI */
  id?: Maybe<Scalars['Int']>;
  /** Unique LOI reference number */
  loadOutReference?: Maybe<Scalars['String']>;
  /** Additional information or special instructions for the LOI */
  notes?: Maybe<Scalars['String']>;
  /** Where the shipment is going */
  portOfDestinationId?: Maybe<Scalars['Int']>;
  /** Where the ship was loaded */
  portOfLoadingId?: Maybe<Scalars['Int']>;
  /** When the LOI was created */
  printedDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Storage configuration requirements */
  q67Reference?: Maybe<Scalars['String']>;
  /** Shipping company transporting the goods */
  shippingLine?: Maybe<Scalars['String']>;
  /** When the container was loaded */
  stuffDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Where the container was loaded */
  stuffLocationId?: Maybe<Scalars['Int']>;
  /** Temperature configuration */
  temperatureSetting?: Maybe<Scalars['String']>;
  /** The id of the vessel shipping the goods */
  vesselId?: Maybe<Scalars['Int']>;
  /** The name of the vessel shipping the goods */
  vesselName?: Maybe<Scalars['String']>;
  /** The vessel voyage */
  voyage?: Maybe<Scalars['String']>;
  /** The type of transport */
  transportType?: Maybe<TransportTypeEnum>;
  /** The trucking number */
  truckNumber?: Maybe<Scalars['String']>;
  /** The loading point */
  loadingPointId?: Maybe<Scalars['Int']>;
  /** The offloading point */
  offloadingPointId?: Maybe<Scalars['Int']>;
  /** The file number associated with the LOI */
  fileNumber?: Maybe<Scalars['String']>;
  /** The air waybill no associated with this LOI */
  airWaybillNo?: Maybe<Scalars['String']>;
  /** The Shippers name */
  shipper?: Maybe<Scalars['String']>;
  /** The destination associated with the LOI */
  destination?: Maybe<Scalars['String']>;
  /** The allocated weight of the goods for the LOI */
  allocatedWeight?: Maybe<Scalars['Float']>;
  /** The flights routing */
  routing?: Maybe<Scalars['String']>;
  /** The flights date */
  flightDate?: Maybe<Scalars['ISO8601Date']>;
  /** The connecting flight date if applicable */
  connectingFlightDate?: Maybe<Scalars['ISO8601Date']>;
  /** Defined equipment used */
  equipment?: Maybe<Scalars['String']>;
  /** The Phyto Certificate identifier */
  phytoCertificate?: Maybe<Scalars['String']>;
  /** Name of who prepared the order */
  orderPreparedBy?: Maybe<Scalars['String']>;
  /** Name of who picked up the order */
  orderPickedBy?: Maybe<Scalars['String']>;
  /** Checked at warehouse information */
  checked?: Maybe<Scalars['String']>;
  /** Air Freight SLI associated with the LOI */
  airFreightShipmentLineItemId?: Maybe<Scalars['ID']>;
};

export type LoadOutInstruction = {
  __typename?: 'LoadOutInstruction';
  /** The Air Freight SLI that is associated with this load out information */
  airFreightShipmentLineItem?: Maybe<AirFreightShipmentLineItem>;
  /** The air waybill no associated with this LOI */
  airWaybillNo?: Maybe<Scalars['String']>;
  /** The allocated weight of the goods for the LOI */
  allocatedWeight?: Maybe<Scalars['Float']>;
  /** Numerical value assigned to the reservation of a ship */
  bookingReference?: Maybe<Scalars['String']>;
  /** Whether the LOI is for break bulk (left in for backwards compatibility) */
  breakBulk?: Maybe<Scalars['Boolean']>;
  /** The carton groupings that are associated with this load out information */
  cartonGroupings?: Maybe<Array<CartonGrouping>>;
  /** Checked at warehouse information */
  checked?: Maybe<Scalars['String']>;
  /** Company associated with LOI */
  company?: Maybe<Company>;
  /** The connecting flight date if applicable */
  connectingFlightDate?: Maybe<Scalars['ISO8601Date']>;
  /** Contacts associated with LOI */
  contacts?: Maybe<Array<Contact>>;
  /** Unique identifier of a container */
  containerNumber?: Maybe<Scalars['String']>;
  /** The destination associated with the LOI */
  destination?: Maybe<Scalars['String']>;
  /** Defined equipment used */
  equipment?: Maybe<Scalars['String']>;
  /** Estimate of when goods are to be delivered */
  estimatedTimeOfDelivery?: Maybe<Scalars['ISO8601DateTime']>;
  /** Exporter associated with LOI */
  exporter?: Maybe<Company>;
  /** Unique identifier for exporters */
  exportersReference?: Maybe<Scalars['String']>;
  /** The file number associated with the LOI */
  fileNumber?: Maybe<Scalars['String']>;
  /** The flights date */
  flightDate?: Maybe<Scalars['ISO8601Date']>;
  /** Unique LOI identifier */
  id?: Maybe<Scalars['ID']>;
  /** A reference assigned to the LOI */
  loadOutReference?: Maybe<Scalars['String']>;
  /** The FBO where the goods are loaded */
  loadingPoint?: Maybe<FoodBusinessOperator>;
  /** The flattend carton groupings that are associated with this load out information */
  loiCartonGroupings?: Maybe<Array<LoiCartonGrouping>>;
  /** Additional information about a LOI */
  notes?: Maybe<Scalars['String']>;
  /** The FBO where the goods are unloaded */
  offloadingPoint?: Maybe<FoodBusinessOperator>;
  /** Name of who picked up the order */
  orderPickedBy?: Maybe<Scalars['String']>;
  /** Name of who prepared the order */
  orderPreparedBy?: Maybe<Scalars['String']>;
  /** Pallet in linked to load out instruction */
  palletIn?: Maybe<PalletIn>;
  /** The Phyto Certificate identifier */
  phytoCertificate?: Maybe<Scalars['String']>;
  /** Where shipment is going */
  portOfDestination?: Maybe<SeaPort>;
  /** Where shipment was loaded onto a vessel */
  portOfLoading?: Maybe<SeaPort>;
  /** Date of LOI sticker */
  printedDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Q67 stipulates the temperature settings and other protocols that must be followed */
  q67Reference?: Maybe<Scalars['String']>;
  /** The flights routing */
  routing?: Maybe<Scalars['String']>;
  /** Emails in which this document was included */
  sentEmailList?: Maybe<Array<SentEmail>>;
  /** The Shippers name */
  shipper?: Maybe<Scalars['String']>;
  /** Company that operates the ships that transport the goods */
  shippingLine?: Maybe<Scalars['String']>;
  /** Date when shipment was loaded */
  stuffDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** FBO Associated with this LOI */
  stuffLocation?: Maybe<FoodBusinessOperator>;
  /** Temperature instructions */
  temperatureSetting?: Maybe<Scalars['String']>;
  /** Object containing information on the document generated for this load out information */
  templateOutput?: Maybe<TemplateOutput>;
  /** The type of transport used to transport the goods */
  transportType?: Maybe<TransportTypeEnum>;
  /** The truck number that is associated with this LOI */
  truckNumber?: Maybe<Scalars['String']>;
  /** The time at which the LOI was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Vessel associated with LOI */
  vessel?: Maybe<Vessel>;
  /** Vessel name associated with LOI */
  vesselName?: Maybe<Scalars['String']>;
  /** Voyage that the associated vessel is taking */
  voyage?: Maybe<Scalars['String']>;
};


export type LoadOutInstructionSentEmailListArgs = {
  direction?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
};

/** The connection type for LoadOutInstruction. */
export type LoadOutInstructionConnection = {
  __typename?: 'LoadOutInstructionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoadOutInstructionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LoadOutInstruction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LoadOutInstructionEdge = {
  __typename?: 'LoadOutInstructionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LoadOutInstruction>;
};

export type LoadOutInstructionFilterAttribute = {
  __typename?: 'LoadOutInstructionFilterAttribute';
  /** commodities found on this user's LOIs */
  commodities?: Maybe<Array<Commodity>>;
  /** Contacts for this user's LOI */
  contacts?: Maybe<Array<Contact>>;
  /** exporters found on this user's LOIs */
  exporters?: Maybe<Array<Company>>;
  /** List of unique FBOs used as loading points */
  loadingPoints?: Maybe<Array<FoodBusinessOperator>>;
  /** List of unique FBOs used as offloading points */
  offloadingPoints?: Maybe<Array<FoodBusinessOperator>>;
  /** varieties found on this user's LOIs */
  varieties?: Maybe<Array<Variety>>;
  /** Vessels associated with this this user's LOIs */
  vessels?: Maybe<Array<Vessel>>;
};

/** Filtering options for load out instructions */
export type LoadOutInstructionsFilters = {
  /** filter by the commodity ids */
  commodityIds?: Maybe<Array<Scalars['ID']>>;
  /** filter by the contact ids */
  contactIds?: Maybe<Array<Scalars['ID']>>;
  /** filter by the exporter ids */
  exporterIds?: Maybe<Array<Scalars['ID']>>;
  /** filter by the date the container started being stuffed */
  stuffDateStart?: Maybe<Scalars['ISO8601DateTime']>;
  /** filter by the date the container finished being stuffed */
  stuffDateEnd?: Maybe<Scalars['ISO8601DateTime']>;
  /** filter by the variety ids */
  varietyIds?: Maybe<Array<Scalars['ID']>>;
  /** filter by the vessel ids */
  vesselIds?: Maybe<Array<Scalars['ID']>>;
  /** only show load out instructions that were loaded at one of the FBOs in this list */
  loadingPointIds?: Maybe<Array<Scalars['ID']>>;
  /** only show load out instructions that were offloaded at one of the FBOs in this list */
  offloadingPointIds?: Maybe<Array<Scalars['ID']>>;
  /** only show load out instructions printed after this date (inclusive) */
  printedDateStart?: Maybe<Scalars['ISO8601DateTime']>;
  /** only show load out instructions printed before this date (inclusive) */
  printedDateEnd?: Maybe<Scalars['ISO8601DateTime']>;
  /** only show load out instructions of a certain transport type */
  transportTypes?: Maybe<Array<Scalars['String']>>;
};

export type LoadingPoint = {
  __typename?: 'LoadingPoint';
  activityPointCode?: Maybe<Scalars['String']>;
  activityPointName?: Maybe<Scalars['String']>;
  containerType?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  isLoaded?: Maybe<Scalars['Boolean']>;
  loadedQuantity?: Maybe<Scalars['Int']>;
  loadingDate?: Maybe<Scalars['ISO8601Date']>;
  ppecbActivityPointId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  shipmentLineItemId?: Maybe<Scalars['Int']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type LoadingPointAttributes = {
  id?: Maybe<Scalars['Int']>;
  activityPointCode?: Maybe<Scalars['String']>;
  loadingDate?: Maybe<Scalars['ISO8601Date']>;
  quantity?: Maybe<Scalars['Int']>;
  containerType?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  isLoaded?: Maybe<Scalars['Boolean']>;
  loadedQuantity?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  shipmentLineItemId?: Maybe<Scalars['Int']>;
  ppecbActivityPointId?: Maybe<Scalars['Int']>;
};

export type Location = {
  __typename?: 'Location';
  createdAt: Scalars['ISO8601DateTime'];
  id?: Maybe<Scalars['ID']>;
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  voyage?: Maybe<Voyage>;
};

/** Returns the location information of pallets at that location. */
export type LocationMillerFilter = {
  __typename?: 'LocationMillerFilter';
  /** ID of the Location */
  id?: Maybe<Scalars['ID']>;
  /** The location name class as a string */
  locationType?: Maybe<Scalars['String']>;
  /** Location name */
  name?: Maybe<Scalars['String']>;
  /** The total number of pallets at that location */
  totalPallets?: Maybe<Scalars['Int']>;
};

export type LoiCartonGrouping = {
  __typename?: 'LoiCartonGrouping';
  /** The batch number of the carton grouping */
  batchNumber?: Maybe<Scalars['String']>;
  /** Who the carton grouping was sold to */
  buyerName?: Maybe<Scalars['String']>;
  /** The number of cartons in the carton grouping */
  cartons?: Maybe<Scalars['Int']>;
  /** The name of the commodity */
  commodityName?: Maybe<Scalars['String']>;
  /** The consignment number of the carton grouping */
  consignmentNumber?: Maybe<Scalars['String']>;
  /** Whether the carton grouping has been loaded out previously */
  existingLoadOut?: Maybe<Scalars['String']>;
  /** The exporter code of the carton grouping */
  exporterCode?: Maybe<Scalars['String']>;
  /** The farm code of the carton grouping */
  farmCode?: Maybe<Scalars['String']>;
  /** The grade of the carton grouping */
  grade?: Maybe<Scalars['String']>;
  /** The ID of the carton grouping */
  id: Scalars['ID'];
  /** The inventory code of the carton grouping */
  inventoryCode?: Maybe<Scalars['String']>;
  /** Whether the carton grouping can be reassigned */
  isReassignable?: Maybe<Scalars['Boolean']>;
  /** The mark of the carton grouping */
  mark?: Maybe<Scalars['String']>;
  /** The net mass of the carton grouping */
  netMass?: Maybe<Scalars['Float']>;
  /** The orchard of the carton grouping */
  orchard?: Maybe<Scalars['String']>;
  /** The pack of the carton grouping */
  pack?: Maybe<Scalars['String']>;
  /** The packing week of the carton grouping */
  packingWeek?: Maybe<Scalars['String']>;
  /** How old the pallet is */
  palletAge?: Maybe<Scalars['Int']>;
  /** The pallet gross mass of the carton grouping */
  palletGrossMass?: Maybe<Scalars['Float']>;
  /** The pallet number of the carton grouping */
  palletNumber?: Maybe<Scalars['String']>;
  /** Who the carton grouping was sold by */
  sellerName?: Maybe<Scalars['String']>;
  /** Indicator of whether the carton grouping is part of a sequence appearing on the same pallet */
  sequenceNumber?: Maybe<Scalars['String']>;
  /** The size count of the carton grouping */
  sizeCount?: Maybe<Scalars['String']>;
  /** The state of the carton grouping */
  state?: Maybe<Scalars['String']>;
  /** The name of the sub commodity */
  subCommodityName?: Maybe<Scalars['String']>;
  /** The target market of the carton grouping */
  targetMarket?: Maybe<Scalars['String']>;
  /** The target region of the carton grouping */
  targetRegion?: Maybe<Scalars['String']>;
  /** The name of the variety */
  varietyName?: Maybe<Scalars['String']>;
};

export type MapPoint = {
  __typename?: 'MapPoint';
  containers?: Maybe<Array<MapPointContainer>>;
  location?: Maybe<MapPointLocatableUnion>;
  orders?: Maybe<Array<MapPointOrder>>;
  totalCartons?: Maybe<Scalars['Int']>;
};

export type MapPointContainer = {
  __typename?: 'MapPointContainer';
  containerNumber?: Maybe<Scalars['String']>;
  eta?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  otherPartyName?: Maybe<Scalars['String']>;
  portOfDestination?: Maybe<Scalars['String']>;
  vesselName?: Maybe<Scalars['String']>;
};

export type MapPointLocatableUnion = FoodBusinessOperator | SeaPort | Vessel;

export type MapPointOrder = {
  __typename?: 'MapPointOrder';
  orderId?: Maybe<Scalars['Int']>;
  otherPartyName?: Maybe<Scalars['String']>;
  totalCartons?: Maybe<Scalars['Int']>;
};

/** The domain of the report in the value chain (e.g. production, logistics etc.) */
export enum MetabaseDomainEnum {
  GlobalMarket = 'global_market',
  Production = 'production',
  MarketingAndSales = 'marketing_and_sales',
  Logistics = 'logistics',
  Claims = 'claims'
}

export type MetabaseReport = {
  __typename?: 'MetabaseReport';
  /** The badges associated with the report */
  badges?: Maybe<Array<Badge>>;
  /** The chart source of the report */
  chartSource?: Maybe<Scalars['String']>;
  /** The chart type of the report */
  chartType?: Maybe<Scalars['String']>;
  /** The description of the report */
  description?: Maybe<Scalars['String']>;
  /** The name of the report to display in the UI */
  displayName?: Maybe<Scalars['String']>;
  /** The domain of the report in the value chain (e.g. production, logistics etc.) */
  domain?: Maybe<MetabaseDomainEnum>;
  /** The unique identifier of the report */
  id?: Maybe<Scalars['Int']>;
  /** The name of the report */
  name?: Maybe<Scalars['String']>;
  /** Fetches the PowerBI report and returns embedding attributes. */
  powerbiEmbedAttributes?: Maybe<EmbeddedChart>;
  /** The production identifier of the report */
  productionId?: Maybe<Scalars['Int']>;
  /** The perspective of a report on a time dimension (e.g. predictive, operational etc.) */
  scope?: Maybe<MetabaseScopeEnum>;
  /** The metabase URL of the report */
  url?: Maybe<Scalars['String']>;
};

export type MetabaseReportFilters = {
  /** The badges to filter on */
  badgeIds?: Maybe<Array<Scalars['Int']>>;
  /** The domains to filter on */
  domains?: Maybe<Array<MetabaseDomainEnum>>;
  /** The scopes to filter on */
  scopes?: Maybe<Array<MetabaseScopeEnum>>;
};

export type MetabaseReportsFilterAttribute = {
  __typename?: 'MetabaseReportsFilterAttribute';
  /** The badges associated with the reports */
  badges?: Maybe<Array<Badge>>;
};

/** The perspective of a report on a time dimension (e.g. predictive, operational etc.) */
export enum MetabaseScopeEnum {
  Predictive = 'predictive',
  Operational = 'operational',
  Retrospective = 'retrospective'
}

/** Returns the miller column second level field values. */
export type MillerCollapsibleInLocation = {
  __typename?: 'MillerCollapsibleInLocation';
  /** The custom field to aggregate pallets in location by. */
  field?: Maybe<Scalars['String']>;
  /** Total pallets with value of the custom field. */
  totalPallets?: Maybe<Scalars['Int']>;
  /** The value of the custom field. */
  value?: Maybe<Scalars['String']>;
};

/** Attributes for the miller column to filter data */
export type MillerCollapsibleInLocationInput = {
  /** The ID of the location */
  locationId: Scalars['ID'];
  /** The class of the location object */
  locationType: Scalars['String'];
  /** The column field name to filter by */
  filterField: Scalars['String'];
  /** The column field value used to filter */
  filterValue?: Maybe<Scalars['String']>;
};

/** An object representing money, with an amount and currency. */
export type Money = {
  __typename?: 'Money';
  /** Numerical amount of the money (i.e. 3.50). */
  amount: Scalars['Float'];
  /** Currency of the money */
  currency: Currency;
  /** Displayable string (i.e. "$3.50"). */
  displayString: Scalars['String'];
  /** Fractional unit value of a given currency (i.e. 350). */
  fractional: Scalars['Int'];
};

/** root mutation */
export type Mutation = {
  __typename?: 'Mutation';
  acceptLineItems?: Maybe<AcceptLineItemsPayload>;
  allocateShipmentController?: Maybe<AllocateShipmentControllerPayload>;
  allocateSliContainer?: Maybe<AllocateSliContainerPayload>;
  calculateInvoiceIncoterm?: Maybe<CalculateInvoiceIncotermPayload>;
  cancelAddendum?: Maybe<CancelAddendumPayload>;
  cancelAirAddendum?: Maybe<CancelAirAddendumPayload>;
  completeAirFreightShipment?: Maybe<CompleteAirFreightShipmentPayload>;
  completeShipment?: Maybe<CompleteShipmentPayload>;
  /** Send confirmation to PPECB for an already submitted ShipmentLineItem */
  confirmExportNotificationLineItem?: Maybe<ConfirmExportNotificationLineItemPayload>;
  createAdditionalField?: Maybe<CreateAdditionalFieldPayload>;
  createComment?: Maybe<CreateCommentPayload>;
  createCommercialTermChangeRequest?: Maybe<CreateCommercialTermChangeRequestPayload>;
  createContact?: Maybe<CreateContactPayload>;
  createCustomField?: Maybe<CreateCustomFieldPayload>;
  createExportNotificationBooking?: Maybe<CreateExportNotificationBookingPayload>;
  createExportNotificationLineItem?: Maybe<CreateExportNotificationLineItemPayload>;
  createFarmVariety?: Maybe<CreateFarmVarietyPayload>;
  createFarmVarietyClientChangeRequest?: Maybe<CreateFarmVarietyRequestPayload>;
  createFoodBusinessOperatorClientChangeRequest?: Maybe<CreateFoodBusinessOperatorRequestPayload>;
  createInvoice?: Maybe<CreateInvoicePayload>;
  createLoadOutInstruction?: Maybe<CreateLoadOutInstructionPayload>;
  createOrder?: Maybe<CreateOrderPayload>;
  createReportGroup?: Maybe<CreateReportGroupPayload>;
  createShipment?: Maybe<CreateShipmentPayload>;
  createShipmentLineItem?: Maybe<CreateShipmentLineItemPayload>;
  createTicket?: Maybe<CreateTicketPayload>;
  createUiConfig?: Maybe<CreateUiConfigPayload>;
  deleteAddendumLineItem?: Maybe<DeleteAddendumLineItemPayload>;
  deleteAdditionalField?: Maybe<DeleteAdditionalFieldPayload>;
  deleteComment?: Maybe<DeleteCommentPayload>;
  deleteCompanyCertificate?: Maybe<DeleteCompanyCertificatePayload>;
  deleteContact?: Maybe<DeleteContactPayload>;
  deleteCustomField?: Maybe<DeleteCustomFieldPayload>;
  deleteEmployee?: Maybe<DeleteEmployeePayload>;
  /** Delete an existing Ephyto and send a delete request to ecert */
  deleteEphyto?: Maybe<DeleteEphytoPayload>;
  deleteEphytoDocument?: Maybe<DeleteEphytoDocumentPayload>;
  deleteExportDocument?: Maybe<DeleteExportDocumentPayload>;
  deleteFarmVariety?: Maybe<DeleteFarmVarietyPayload>;
  deleteFarmVarietyImage?: Maybe<DeleteFarmVarietyImagePayload>;
  deleteInvoice?: Maybe<DeleteInvoicePayload>;
  deleteLoadOutInstruction?: Maybe<DeleteLoadOutInstructionPayload>;
  deleteReportGroup?: Maybe<DeleteReportGroupPayload>;
  deleteShipmentDocument?: Maybe<DeleteShipmentDocumentPayload>;
  deleteShipmentLineItem?: Maybe<DeleteShipmentLineItemPayload>;
  /** Delete the document related to a task */
  deleteTaskDocument?: Maybe<DeleteTaskDocumentPayload>;
  deleteUiConfig?: Maybe<DeleteUiConfigPayload>;
  /** Send an ephyto to ecert in a DRAFT state */
  draftEphyto?: Maybe<DraftEphytoPayload>;
  duplicateAddendumLineItem?: Maybe<DuplicateAddendumLineItemPayload>;
  /** Duplicate an existing Ephyto */
  duplicateEphyto?: Maybe<DuplicateEphytoPayload>;
  duplicateInvoice?: Maybe<DuplicateInvoicePayload>;
  /** Duplicate an existing ShipmentLineItem */
  duplicateShipmentLineItem?: Maybe<DuplicateShipmentLineItemPayload>;
  /** Fetch the status of a submitted Ephyto on eCert */
  fetchEphytoStatus?: Maybe<FetchEphytoStatusPayload>;
  /** Find or create a vessel */
  findOrCreateVessel?: Maybe<FindOrCreateVesselPayload>;
  generateAddendum?: Maybe<GenerateAddendumPayload>;
  /** Requests the generation of PDF & XLS for a specific addendum header.Generation is done asyncronously */
  generateAddendumHeaderOutputFiles?: Maybe<GenerateAddendumHeaderOutputFilesPayload>;
  /** Generate an addendum for the selected load out instructions */
  generateAirAddendum?: Maybe<GenerateAirAddendumPayload>;
  generateCommoditySeasons?: Maybe<GenerateCommoditySeasonsPayload>;
  /** Generates Output files for specified Data Preparation */
  generateDataOutputFiles?: Maybe<GenerateDataOutputFilesPayload>;
  /** Generate an Ephyto from existing AFSLI or SLIs */
  generateEphyto?: Maybe<GenerateEphytoPayload>;
  /** Generate Ephyto Trade Units for the Ephyto */
  generateEphytoTradeUnit?: Maybe<GenerateEphytoTradeUnitPayload>;
  /** Requests the generation of PDF & XLS for a specific invoice. Generation is done asyncronously */
  generateInvoiceOutputFiles?: Maybe<GenerateInvoiceOutputFilesPayload>;
  /** Requests the generation of PDF & XLS for a specific load out instruction. Generation is done asyncronously */
  generateLoadOutInstructionOutputFiles?: Maybe<GenerateLoadOutInstructionOutputFilesPayload>;
  /** Requests the generation of a pallet in EDI for a specific load out instruction. Generation is done asyncronously */
  generatePalletInOutputFiles?: Maybe<GeneratePalletInOutputFilesPayload>;
  /** Requests the generation of a pallet out EDI for a specific addendum header. Generation is done asyncronously */
  generatePalletOutOutputFiles?: Maybe<GeneratePalletOutOutputFilesPayload>;
  /** Requests the generation of PDF & XLS for a specific shipment export notification.Generation is done asyncronously */
  generateShipmentExportNotificationOutputFiles?: Maybe<GenerateShipmentExportNotificationOutputFilesPayload>;
  handleInvoiceRemittance?: Maybe<HandleInvoiceRemittancePayload>;
  inviteUser?: Maybe<InviteUserPayload>;
  processEtlDocument?: Maybe<ProcessEtlDocumentPayload>;
  queryAddendum?: Maybe<QueryAddendumPayload>;
  /** Reassign an SLIs Shipment Line Grouping. */
  reassignShipmentLineItemGrouping?: Maybe<ReassignShipmentLineItemGroupingPayload>;
  resendInvite?: Maybe<ResendInvitePayload>;
  resolveCgError?: Maybe<ResolveCgErrorPayload>;
  revertLineItemsToCreated?: Maybe<RevertLineItemsToCreatedPayload>;
  revertOrderChange?: Maybe<RevertOrderChangePayload>;
  revokeInvite?: Maybe<RevokeInvitePayload>;
  /** Requests the emailing of (templatable, for now) documents, for a list of document IDs, a list of recipients, and a specific document type (Invoice/LOI/etc). Generation is done asyncronously */
  sendEmail?: Maybe<SendEmailPayload>;
  /** Submit an Addendum to PPECB */
  submitAddendum?: Maybe<SubmitAddendumPayload>;
  /** Mark an existing Ephyto on ECERT as Submit for processing */
  submitEphyto?: Maybe<SubmitEphytoPayload>;
  /** Starts the job of submitting the shipment header and each ShipmentLineItem to PPEB */
  submitShipmentExportNotification?: Maybe<SubmitShipmentExportNotificationPayload>;
  /** Update an Addendum Header and its Line Items in our system */
  updateAddendum?: Maybe<UpdateAddendumPayload>;
  updateAdditionalField?: Maybe<UpdateAdditionalFieldPayload>;
  /** Update an Air Addendum Header and its Line Items in our system */
  updateAirAddendum?: Maybe<UpdateAirAddendumPayload>;
  /** Update an Air Freight Shipment controller in our system */
  updateAirFreightShipmentController?: Maybe<UpdateAirFreightShipmentControllerPayload>;
  updateCartonGroupingTransactions?: Maybe<UpdateCartonGroupingTransactionsPayload>;
  /** Updates an on premises command with a result */
  updateCommandResult?: Maybe<UpdateCommandResultPayload>;
  updateCompanyDetails?: Maybe<UpdateCompanyDetailsPayload>;
  updateCompanyTradeRegions?: Maybe<UpdateCompanyTradeRegionsPayload>;
  updateContact?: Maybe<UpdateContactPayload>;
  updateCustomField?: Maybe<UpdateCustomFieldPayload>;
  /** Updates a Data Output */
  updateDataOutput?: Maybe<UpdateDataOutputPayload>;
  /** Update an Ephyto on eCert */
  updateDraftEphyto?: Maybe<UpdateDraftEphytoPayload>;
  /** Update an Ephyto and its related models in our system */
  updateEphyto?: Maybe<UpdateEphytoPayload>;
  updateEphytoDocument?: Maybe<UpdateEphytoDocumentPayload>;
  updateEstimates?: Maybe<UpdateEstimatesPayload>;
  updateExportDocument?: Maybe<UpdateExportDocumentPayload>;
  updateFarmVariety?: Maybe<UpdateFarmVarietyPayload>;
  updateInvoice?: Maybe<UpdateInvoicePayload>;
  updateLineItems?: Maybe<UpdateLineItemsPayload>;
  updateLoadOutInstruction?: Maybe<UpdateLoadOutInstructionPayload>;
  updateOrchardSeasons?: Maybe<UpdateOrchardSeasonsPayload>;
  updateOrchardYields?: Maybe<UpdateOrchardYieldsPayload>;
  updateOrder?: Maybe<UpdateOrderPayload>;
  updateReportGroup?: Maybe<UpdateReportGroupPayload>;
  updateShipment?: Maybe<UpdateShipmentPayload>;
  updateShipmentDocument?: Maybe<UpdateShipmentDocumentPayload>;
  updateShipmentLineItem?: Maybe<UpdateShipmentLineItemPayload>;
  /** Update shipment line item tasks */
  updateShipmentLineItemTasks?: Maybe<UpdateShipmentLineItemTasksPayload>;
  /** Mutation that updates tasks dates */
  updateTasksDates?: Maybe<UpdateTasksDatesPayload>;
  updateUiConfig?: Maybe<UpdateUiConfigPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserFeedItem?: Maybe<UpdateUserFeedItemPayload>;
  updateUserFeedItems?: Maybe<UpdateUserFeedItemsPayload>;
  uploadCompanyCertificate?: Maybe<UploadCompanyCertificatePayload>;
  uploadCompanyLogo?: Maybe<UploadCompanyLogoPayload>;
  /** Upload of support ephyto documents on the UI, Ephyto tab specifically */
  uploadEphytoDocument?: Maybe<UploadEphytoDocumentPayload>;
  uploadEtlDocument?: Maybe<UploadEtlDocumentPayload>;
  uploadExportDocument?: Maybe<UploadExportDocumentPayload>;
  uploadFarmVarietyImage?: Maybe<UploadFarmVarietyImagePayload>;
  uploadOrderShipmentDocument?: Maybe<UploadOrderShipmentDocumentPayload>;
  /** Mutation that uploads a document to a task */
  uploadTaskDocument?: Maybe<UploadTaskDocumentPayload>;
  uploadUserAvatar?: Maybe<UploadUserAvatarPayload>;
  /** Creates or updates a CTI from the SLI and generates a PDF and XL documentGeneration is done asynchronously */
  upsertCertificateTransportInstruction?: Maybe<UpsertCertificateTransportInstructionPayload>;
  wontPackLineItems?: Maybe<WontPackLineItemsPayload>;
};


/** root mutation */
export type MutationAcceptLineItemsArgs = {
  ids: Array<Scalars['Int']>;
};


/** root mutation */
export type MutationAllocateShipmentControllerArgs = {
  shipmentId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
};


/** root mutation */
export type MutationAllocateSliContainerArgs = {
  sliId: Scalars['Int'];
  sliContainerId?: Maybe<Scalars['Int']>;
  isBreakBulk: Scalars['Boolean'];
};


/** root mutation */
export type MutationCalculateInvoiceIncotermArgs = {
  attributes: InvoiceIncotermAttributes;
};


/** root mutation */
export type MutationCancelAddendumArgs = {
  deleteAddendum: Scalars['Boolean'];
  id: Scalars['Int'];
};


/** root mutation */
export type MutationCancelAirAddendumArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationCompleteAirFreightShipmentArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationCompleteShipmentArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationConfirmExportNotificationLineItemArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationCreateAdditionalFieldArgs = {
  attributes: CreateAdditionalFieldAttributes;
};


/** root mutation */
export type MutationCreateCommentArgs = {
  attributes: CreateCommentAttributes;
};


/** root mutation */
export type MutationCreateCommercialTermChangeRequestArgs = {
  attributes: CreateCommercialTermChangeRequestAttributes;
};


/** root mutation */
export type MutationCreateContactArgs = {
  attributes: ContactAttributes;
};


/** root mutation */
export type MutationCreateCustomFieldArgs = {
  attributes: CustomFieldAttributes;
};


/** root mutation */
export type MutationCreateExportNotificationBookingArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationCreateExportNotificationLineItemArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationCreateFarmVarietyArgs = {
  attributes: CreateFarmVarietyAttributes;
};


/** root mutation */
export type MutationCreateFarmVarietyClientChangeRequestArgs = {
  action?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['Int']>;
  commodityName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  varietyCodes?: Maybe<Scalars['String']>;
  varietyId?: Maybe<Scalars['Int']>;
};


/** root mutation */
export type MutationCreateFoodBusinessOperatorClientChangeRequestArgs = {
  fboCode?: Maybe<Scalars['String']>;
  fboName?: Maybe<Scalars['String']>;
  globalGapNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['Int']>;
  producerName?: Maybe<Scalars['String']>;
  productionRegion?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  typeOfBusiness?: Maybe<Scalars['String']>;
};


/** root mutation */
export type MutationCreateInvoiceArgs = {
  attributes: InvoiceAttributes;
};


/** root mutation */
export type MutationCreateLoadOutInstructionArgs = {
  attributes: LoadOutAttributes;
};


/** root mutation */
export type MutationCreateOrderArgs = {
  attributes: CreateOrderAttributes;
};


/** root mutation */
export type MutationCreateReportGroupArgs = {
  attributes: ReportGroupAttributes;
};


/** root mutation */
export type MutationCreateShipmentArgs = {
  attributes: ShipmentAttributes;
};


/** root mutation */
export type MutationCreateShipmentLineItemArgs = {
  attributes: ShipmentLineItemAttributes;
};


/** root mutation */
export type MutationCreateTicketArgs = {
  attributes: CreateTicketAttributes;
};


/** root mutation */
export type MutationCreateUiConfigArgs = {
  attributes: CreateUpdateUiConfigAttributes;
};


/** root mutation */
export type MutationDeleteAddendumLineItemArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteAdditionalFieldArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteCommentArgs = {
  commentId: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteCompanyCertificateArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteContactArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteCustomFieldArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteEmployeeArgs = {
  employeeId: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteEphytoArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteEphytoDocumentArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteExportDocumentArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteFarmVarietyArgs = {
  companyId: Scalars['Int'];
  varietyId: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteFarmVarietyImageArgs = {
  varietyId: Scalars['Int'];
  imageId: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteInvoiceArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteLoadOutInstructionArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteReportGroupArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteShipmentDocumentArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteShipmentLineItemArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteTaskDocumentArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDeleteUiConfigArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDraftEphytoArgs = {
  ephytoId: Scalars['Int'];
};


/** root mutation */
export type MutationDuplicateAddendumLineItemArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationDuplicateEphytoArgs = {
  id: Scalars['Int'];
  applicationType: Scalars['Int'];
};


/** root mutation */
export type MutationDuplicateInvoiceArgs = {
  documentType: Scalars['String'];
  id: Scalars['ID'];
};


/** root mutation */
export type MutationDuplicateShipmentLineItemArgs = {
  id: Scalars['ID'];
};


/** root mutation */
export type MutationFetchEphytoStatusArgs = {
  ephytoId: Scalars['Int'];
};


/** root mutation */
export type MutationFindOrCreateVesselArgs = {
  vesselName: Scalars['String'];
};


/** root mutation */
export type MutationGenerateAddendumArgs = {
  bookingReference?: Maybe<Scalars['String']>;
  sliIds?: Maybe<Array<Scalars['Int']>>;
  shipmentId?: Maybe<Scalars['ID']>;
};


/** root mutation */
export type MutationGenerateAddendumHeaderOutputFilesArgs = {
  addendumHeaderId: Scalars['ID'];
};


/** root mutation */
export type MutationGenerateAirAddendumArgs = {
  loiIds: Array<Scalars['ID']>;
};


/** root mutation */
export type MutationGenerateCommoditySeasonsArgs = {
  seasonYear: Scalars['Int'];
};


/** root mutation */
export type MutationGenerateDataOutputFilesArgs = {
  id: Scalars['ID'];
};


/** root mutation */
export type MutationGenerateEphytoArgs = {
  bookingReference?: Maybe<Scalars['String']>;
  ephytoId?: Maybe<Scalars['Int']>;
  isEntireBooking: Scalars['Boolean'];
  shipmentType: Scalars['String'];
  sliIds?: Maybe<Array<Scalars['Int']>>;
};


/** root mutation */
export type MutationGenerateEphytoTradeUnitArgs = {
  shipmentType: Scalars['String'];
  ephytoId?: Maybe<Scalars['Int']>;
};


/** root mutation */
export type MutationGenerateInvoiceOutputFilesArgs = {
  invoiceId: Scalars['ID'];
};


/** root mutation */
export type MutationGenerateLoadOutInstructionOutputFilesArgs = {
  loadoutinstructionid: Scalars['ID'];
};


/** root mutation */
export type MutationGeneratePalletInOutputFilesArgs = {
  loadOutInstructionId: Scalars['ID'];
};


/** root mutation */
export type MutationGeneratePalletOutOutputFilesArgs = {
  addendumHeaderId: Scalars['ID'];
};


/** root mutation */
export type MutationGenerateShipmentExportNotificationOutputFilesArgs = {
  shipmentId: Scalars['ID'];
};


/** root mutation */
export type MutationHandleInvoiceRemittanceArgs = {
  amountRemitted?: Maybe<Scalars['Float']>;
  batchUpdate?: Maybe<Scalars['Boolean']>;
  ids: Array<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
};


/** root mutation */
export type MutationInviteUserArgs = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


/** root mutation */
export type MutationProcessEtlDocumentArgs = {
  documentId: Scalars['Int'];
};


/** root mutation */
export type MutationQueryAddendumArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationReassignShipmentLineItemGroupingArgs = {
  id: Scalars['ID'];
  sligId: Scalars['ID'];
};


/** root mutation */
export type MutationResendInviteArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationResolveCgErrorArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationRevertLineItemsToCreatedArgs = {
  ids: Array<Scalars['Int']>;
};


/** root mutation */
export type MutationRevertOrderChangeArgs = {
  startId: Scalars['Int'];
  endId: Scalars['Int'];
  orderId: Scalars['Int'];
};


/** root mutation */
export type MutationRevokeInviteArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationSendEmailArgs = {
  attributes: SendEmailAttributes;
};


/** root mutation */
export type MutationSubmitAddendumArgs = {
  id: Scalars['Int'];
};


/** root mutation */
export type MutationSubmitEphytoArgs = {
  ephytoId: Scalars['Int'];
};


/** root mutation */
export type MutationSubmitShipmentExportNotificationArgs = {
  id: Scalars['ID'];
};


/** root mutation */
export type MutationUpdateAddendumArgs = {
  addendumHeader: AddendumHeaderAttributes;
  addendumLineItems: Array<AddendumLineItemAttributes>;
};


/** root mutation */
export type MutationUpdateAdditionalFieldArgs = {
  id: Scalars['Int'];
  attributes: UpdateAdditionalFieldAttributes;
};


/** root mutation */
export type MutationUpdateAirAddendumArgs = {
  addendumHeader: AddendumHeaderAttributes;
  addendumLineItems: Array<AddendumLineItemAttributes>;
};


/** root mutation */
export type MutationUpdateAirFreightShipmentControllerArgs = {
  airFreightShipmentId: Scalars['Int'];
  controllerId?: Maybe<Scalars['Int']>;
  controllerName?: Maybe<Scalars['String']>;
};


/** root mutation */
export type MutationUpdateCartonGroupingTransactionsArgs = {
  deleteData?: Maybe<Array<Scalars['Int']>>;
};


/** root mutation */
export type MutationUpdateCommandResultArgs = {
  attributes: CommandResultAttributes;
};


/** root mutation */
export type MutationUpdateCompanyDetailsArgs = {
  attributes: UpdateCompanyAttributes;
};


/** root mutation */
export type MutationUpdateCompanyTradeRegionsArgs = {
  addTradeRegions: Array<TradeRegionAttributes>;
  removeTradeRegions: Array<TradeRegionAttributes>;
};


/** root mutation */
export type MutationUpdateContactArgs = {
  attributes: ContactAttributes;
};


/** root mutation */
export type MutationUpdateCustomFieldArgs = {
  attributes: CustomFieldAttributes;
};


/** root mutation */
export type MutationUpdateDataOutputArgs = {
  attributes: DataOutputAttributes;
};


/** root mutation */
export type MutationUpdateDraftEphytoArgs = {
  ephytoId: Scalars['Int'];
};


/** root mutation */
export type MutationUpdateEphytoArgs = {
  ephyto: EphytoAttributes;
  ephytoConsignmentItems?: Maybe<Array<EphytoConsignmentItemAttributes>>;
};


/** root mutation */
export type MutationUpdateEphytoDocumentArgs = {
  id: Scalars['Int'];
  type: Scalars['String'];
};


/** root mutation */
export type MutationUpdateEstimatesArgs = {
  createData?: Maybe<Array<EstimateAttributes>>;
  deleteData?: Maybe<Array<Scalars['Int']>>;
  updateData?: Maybe<Array<EstimateAttributes>>;
};


/** root mutation */
export type MutationUpdateExportDocumentArgs = {
  id: Scalars['Int'];
  type: Scalars['String'];
};


/** root mutation */
export type MutationUpdateFarmVarietyArgs = {
  attributes: UpdateFarmVarietyAttributes;
};


/** root mutation */
export type MutationUpdateInvoiceArgs = {
  attributes: InvoiceAttributes;
};


/** root mutation */
export type MutationUpdateLineItemsArgs = {
  attributes: Array<UpdateLineItemAttributes>;
};


/** root mutation */
export type MutationUpdateLoadOutInstructionArgs = {
  attributes: LoadOutAttributes;
};


/** root mutation */
export type MutationUpdateOrchardSeasonsArgs = {
  deleteData?: Maybe<Array<Scalars['Int']>>;
  updateData?: Maybe<Array<OrchardSeasonAttributes>>;
};


/** root mutation */
export type MutationUpdateOrchardYieldsArgs = {
  createData?: Maybe<Array<OrchardYieldAttributes>>;
  deleteData?: Maybe<Array<Scalars['Int']>>;
  updateData?: Maybe<Array<OrchardYieldAttributes>>;
};


/** root mutation */
export type MutationUpdateOrderArgs = {
  attributes: UpdateOrderAttributes;
};


/** root mutation */
export type MutationUpdateReportGroupArgs = {
  attributes: ReportGroupAttributes;
};


/** root mutation */
export type MutationUpdateShipmentArgs = {
  attributes: ShipmentAttributes;
};


/** root mutation */
export type MutationUpdateShipmentDocumentArgs = {
  id: Scalars['Int'];
  type: Scalars['String'];
};


/** root mutation */
export type MutationUpdateShipmentLineItemArgs = {
  attributes: ShipmentLineItemAttributes;
};


/** root mutation */
export type MutationUpdateShipmentLineItemTasksArgs = {
  attributes: ShipmentLineItemTaskAttributes;
};


/** root mutation */
export type MutationUpdateTasksDatesArgs = {
  updateValues: Scalars['Json'];
};


/** root mutation */
export type MutationUpdateUiConfigArgs = {
  attributes: CreateUpdateUiConfigAttributes;
};


/** root mutation */
export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  notifyEmail: Scalars['Boolean'];
  notifyBrowserPush: Scalars['Boolean'];
  useV2: Scalars['Boolean'];
};


/** root mutation */
export type MutationUpdateUserFeedItemArgs = {
  attributes: UpdateUserFeedItemAttributes;
};


/** root mutation */
export type MutationUpdateUserFeedItemsArgs = {
  attributes: Array<UpdateUserFeedItemAttributes>;
};


/** root mutation */
export type MutationUploadCompanyCertificateArgs = {
  companyId: Scalars['Int'];
  file: Scalars['Upload'];
  expiryDate: Scalars['String'];
  certificateTypeId: Scalars['Int'];
};


/** root mutation */
export type MutationUploadCompanyLogoArgs = {
  companyId: Scalars['Int'];
  file: Scalars['Upload'];
};


/** root mutation */
export type MutationUploadEphytoDocumentArgs = {
  ephytoId: Scalars['Int'];
  file: Scalars['Upload'];
};


/** root mutation */
export type MutationUploadEtlDocumentArgs = {
  documentId: Scalars['Int'];
  file: Scalars['Upload'];
};


/** root mutation */
export type MutationUploadExportDocumentArgs = {
  attributes: ShipmentLineItemAttributes;
  file: Scalars['Upload'];
};


/** root mutation */
export type MutationUploadFarmVarietyImageArgs = {
  id: Scalars['Int'];
  image: Scalars['Upload'];
};


/** root mutation */
export type MutationUploadOrderShipmentDocumentArgs = {
  orderid: Scalars['Int'];
  type: Scalars['String'];
  file: Scalars['Upload'];
};


/** root mutation */
export type MutationUploadTaskDocumentArgs = {
  customDate?: Maybe<Scalars['ISO8601DateTime']>;
  file: Scalars['Upload'];
  taskId: Scalars['Int'];
  uploadDate?: Maybe<Scalars['ISO8601DateTime']>;
};


/** root mutation */
export type MutationUploadUserAvatarArgs = {
  file: Scalars['Upload'];
};


/** root mutation */
export type MutationUpsertCertificateTransportInstructionArgs = {
  shipmentLineItemId: Scalars['ID'];
};


/** root mutation */
export type MutationWontPackLineItemsArgs = {
  ids: Array<Scalars['Int']>;
};

export type NonEphytoShipmentLineItem = {
  __typename?: 'NonEphytoShipmentLineItem';
  /** Unique identifier for the shipment line item */
  id: Scalars['Int'];
  /** Label displayed to easily identify the SLI */
  labelDisplay?: Maybe<Scalars['String']>;
  /** This could be Shipment or AirFreightShipment related */
  type?: Maybe<Scalars['String']>;
};

export type NotifiableUnion = Comment;

export type OrchardSeason = {
  __typename?: 'OrchardSeason';
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  fboOrchard: FboOrchard;
  foodBusinessOperator: FoodBusinessOperator;
  hectares: Scalars['Float'];
  id: Scalars['Int'];
  plantedDate?: Maybe<Scalars['ISO8601DateTime']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Basic attributes for updating an Orchard Season */
export type OrchardSeasonAttributes = {
  endDate: Scalars['ISO8601DateTime'];
  hectares: Scalars['Float'];
  id: Scalars['Int'];
  plantedDate: Scalars['ISO8601DateTime'];
  startDate: Scalars['ISO8601DateTime'];
};

export type OrchardYield = {
  __typename?: 'OrchardYield';
  captureDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['Int'];
  orchardSeasonId: Scalars['Int'];
  weight: Scalars['Float'];
  weightPerBin?: Maybe<Scalars['Float']>;
};

/** Basic attributes for creating & updating an orchard yield */
export type OrchardYieldAttributes = {
  captureDate: Scalars['ISO8601DateTime'];
  id?: Maybe<Scalars['Int']>;
  orchardSeasonId?: Maybe<Scalars['Int']>;
  weight: Scalars['Float'];
  weightPerBin?: Maybe<Scalars['Float']>;
};

/** returns details about the order model */
export type Order = {
  __typename?: 'Order';
  /** the audit trail associated with the order */
  audits?: Maybe<Array<Audit>>;
  /** the buyer associated with the order */
  buyer?: Maybe<Company>;
  /** the comments associated with the order */
  comments?: Maybe<Array<Comment>>;
  /** when the order was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** the unique identifier for the order */
  id?: Maybe<Scalars['Int']>;
  /** whether the order is a packhouse run, packhouse runs are ordersrequesting all the fruit from a specific packhouse */
  isPackhouseRun?: Maybe<Scalars['Boolean']>;
  /** the line item groupings associated with the order */
  lineItemGroupings?: Maybe<Array<LineItemGrouping>>;
  /** the line items associated with the order */
  lineItems?: Maybe<Array<LineItem>>;
  /** the seller associated with the order */
  seller?: Maybe<Company>;
  /** the shipment documents associated with the order */
  shipmentDocuments?: Maybe<Array<ShipmentDocument>>;
  /** the state of the order */
  state?: Maybe<Scalars['String']>;
  /** when the order was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** the user associated with the order */
  user?: Maybe<User>;
};

export type OrderBy = {
  column?: Maybe<OrderByColumn>;
  direction?: Maybe<OrderByDirection>;
};

export enum OrderByColumn {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  ReferenceNumber = 'reference_number',
  SellerName = 'seller_name',
  BuyerName = 'buyer_name',
  Id = 'id',
  State = 'state'
}

export enum OrderByDirection {
  Asc = 'asc',
  Desc = 'desc'
}

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export type OrderFilter = {
  buyercontains?: Maybe<Scalars['String']>;
  buyerids?: Maybe<Array<Scalars['Int']>>;
  commoditycontains?: Maybe<Scalars['String']>;
  commodityseasonids?: Maybe<Array<Scalars['Int']>>;
  ordering?: Maybe<Array<OrderBy>>;
  packingweekcontains?: Maybe<Scalars['String']>;
  packingweekends?: Maybe<Scalars['String']>;
  packingweekstarts?: Maybe<Scalars['String']>;
  referencestartswith?: Maybe<Scalars['String']>;
  sellercontains?: Maybe<Scalars['String']>;
  sellerids?: Maybe<Array<Scalars['Int']>>;
  /** only return orders with this state */
  states?: Maybe<Array<StateFilter>>;
  /** only include orders that one of these companies participate in */
  companyIds?: Maybe<Array<Scalars['Int']>>;
};

/** Attributes to filter orders by */
export type OrderFilterAttribute = {
  __typename?: 'OrderFilterAttribute';
  /** buyers you want to filter by */
  buyers?: Maybe<Array<Company>>;
  /** commodities you want to filter by */
  commodities?: Maybe<Array<Commodity>>;
  /** sellers you want to filter by */
  sellers?: Maybe<Array<Company>>;
  /** varieties you want to filter by */
  varieties?: Maybe<Array<Variety>>;
};

/** Attributes to filter orders by */
export type OrderFilterAttributes = {
  /** buyers you want to filter by */
  buyerIds?: Maybe<Array<Scalars['ID']>>;
  /** sellers you want to filter by */
  sellerIds?: Maybe<Array<Scalars['ID']>>;
  /** states you want to filter by */
  states?: Maybe<Array<OrderStateEnum>>;
  /** commodities you want to filter by */
  commodityIds?: Maybe<Array<Scalars['ID']>>;
  /** varieties you want to filter by */
  varietyIds?: Maybe<Array<Scalars['ID']>>;
  /** start of packing week you want to filter by */
  packingWeekStart?: Maybe<Scalars['String']>;
  /** end of packing week you want to filter by */
  packingWeekEnd?: Maybe<Scalars['String']>;
};

export type OrderSearch = {
  __typename?: 'OrderSearch';
  orders?: Maybe<Array<Order>>;
  totalFiltered?: Maybe<Scalars['Int']>;
  totalOrders?: Maybe<Scalars['Int']>;
};


export type OrderSearchOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export enum OrderStateEnum {
  Accepted = 'ACCEPTED',
  AtColdStore = 'AT_COLD_STORE',
  AtOriginPort = 'AT_ORIGIN_PORT',
  Created = 'CREATED',
  Delivered = 'DELIVERED',
  Expired = 'EXPIRED',
  InTransit = 'IN_TRANSIT',
  Packed = 'PACKED',
  PartiallyAccepted = 'PARTIALLY_ACCEPTED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  PartiallyPacked = 'PARTIALLY_PACKED',
  WontPack = 'WONT_PACK'
}

export type OrdersSearchFilterAttributes = {
  __typename?: 'OrdersSearchFilterAttributes';
  /** unique set of commodities used on line items */
  commodities?: Maybe<Array<Commodity>>;
  /** unique set of trade partners that appear on line items */
  companies?: Maybe<Array<Company>>;
  /** unique set of sub-commodities used on line items */
  subCommodities?: Maybe<Array<SubCommodity>>;
  /** unique set of varieties used on line items */
  varieties?: Maybe<Array<Variety>>;
};

export type PackInstructionLineItem = {
  __typename?: 'PackInstructionLineItem';
  /** arbitrary fields saved onto a line item */
  additionalFields?: Maybe<Scalars['Json']>;
  /** arbitrary grouping of line items */
  batchNumber?: Maybe<Scalars['String']>;
  /** purchasing company name */
  buyer?: Maybe<Scalars['String']>;
  /** line item comments */
  comments?: Maybe<Array<Comment>>;
  /** commodity name */
  commodity?: Maybe<Scalars['String']>;
  /** quality indicator */
  grade?: Maybe<Scalars['String']>;
  /** unique identifier showing cumulative grouping */
  groupId?: Maybe<Scalars['Int']>;
  /** unique identifier */
  id?: Maybe<Scalars['Int']>;
  /** inventory code */
  inventoryCode?: Maybe<Scalars['String']>;
  /** identifying word or number on freight */
  mark?: Maybe<Scalars['String']>;
  /** order identifier */
  orderId?: Maybe<Scalars['Int']>;
  /** code indicating which box to pack fruit into */
  pack?: Maybe<Scalars['String']>;
  /** isoweek when fruit is going to be or was packed */
  packingWeek?: Maybe<Scalars['String']>;
  /** number of cartons on a pallet */
  palletStack?: Maybe<Scalars['Int']>;
  /** priority of order */
  priority?: Maybe<Scalars['Int']>;
  /** quantities packed per size count */
  quantitesPacked?: Maybe<Array<Scalars['Int']>>;
  /** quantities requested */
  quantityRequested?: Maybe<Scalars['Int']>;
  /** quantity unit */
  quantityUnit?: Maybe<Scalars['String']>;
  /** reference number */
  referenceNumber?: Maybe<Scalars['String']>;
  /** selling company name */
  seller?: Maybe<Scalars['String']>;
  /** indication of commodity size */
  sizeCounts?: Maybe<Array<Scalars['String']>>;
  /** state */
  state?: Maybe<Scalars['String']>;
  /** sub commodity name */
  subCommodity?: Maybe<Scalars['String']>;
  /** target country */
  targetCountry?: Maybe<Scalars['String']>;
  /** target market */
  targetMarket?: Maybe<Scalars['String']>;
  /** target region */
  targetRegion?: Maybe<Scalars['String']>;
  /** variety name */
  variety?: Maybe<Scalars['String']>;
  /** main code associated with the seller farm variety */
  varietyCode?: Maybe<Scalars['String']>;
};

export enum PackInstructionStateEnum {
  Overdue = 'OVERDUE',
  Packed = 'PACKED',
  PackhouseRun = 'PACKHOUSE_RUN',
  PartiallyPacked = 'PARTIALLY_PACKED',
  ToPack = 'TO_PACK',
  OverPacked = 'OVER_PACKED'
}

export type PackInstructionsAttributes = {
  /** Packing Week to filter on */
  endWeek: Scalars['String'];
  /** Packing Week to filter on */
  startWeek: Scalars['String'];
  /** States to filter on */
  states?: Maybe<Array<PackInstructionStateEnum>>;
  /** Varieties to filter on */
  varietyIds?: Maybe<Array<Scalars['Int']>>;
  /** Commmodities to filter on */
  commodityIds?: Maybe<Array<Scalars['Int']>>;
  /** Buyers to filter on */
  buyerIds?: Maybe<Array<Scalars['Int']>>;
  /** Sellers to filter on */
  sellerIds?: Maybe<Array<Scalars['Int']>>;
  /** Sub Commodities to filter on */
  subCommodityIds?: Maybe<Array<Scalars['Int']>>;
  /** Only return entries that contain these reference numbers (wild card, case insensitive) */
  referenceNumbers?: Maybe<Array<Scalars['String']>>;
};

export type PackInstructionsFilterAttribute = {
  __typename?: 'PackInstructionsFilterAttribute';
  /** this company's buyers */
  buyers?: Maybe<Array<Company>>;
  /** this company's commodityes */
  commodities?: Maybe<Array<Commodity>>;
  /** this company's sellers */
  sellers?: Maybe<Array<Company>>;
  /** this company's commodityes */
  subCommodities?: Maybe<Array<SubCommodity>>;
  /** this company's varieties */
  varieties?: Maybe<Array<Variety>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Contains information on pallet ins */
export type PalletIn = {
  __typename?: 'PalletIn';
  /** Unique identifier of the Pallet In */
  id?: Maybe<Scalars['ID']>;
  /** Unique identifier of the load out instruction associated with this pallet in */
  loadOutInstructionId?: Maybe<Scalars['ID']>;
  /** The document produced for this pallet in */
  outputFile?: Maybe<Document>;
  /** State of the template generation (created, processing, completed) */
  state?: Maybe<Scalars['String']>;
  /** Timestamp representing the date this document was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Contains information on pallet outs */
export type PalletOut = {
  __typename?: 'PalletOut';
  /** Unique identifier of the addendum header associated with this pallet out */
  addendumHeaderId?: Maybe<Scalars['ID']>;
  /** Unique identifier of this object */
  id?: Maybe<Scalars['ID']>;
  /** The document produced for this pallet out */
  outputFile?: Maybe<Document>;
  /** State of the template generation (created, processing, completed) */
  state?: Maybe<Scalars['String']>;
  /** Timestamp representing the date this document was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Permission = {
  __typename?: 'Permission';
  key?: Maybe<PermissionEnum>;
  value?: Maybe<Scalars['Boolean']>;
};

export enum PermissionEnum {
  /** Whether the Demo page is available */
  PagesDemo = 'pages_demo',
  /** Whether the Dashboard page is available */
  PagesDashboard = 'pages_dashboard',
  /** Whether the Settings page is available */
  PagesSettings = 'pages_settings',
  /** Whether the Company page is available */
  PagesCompany = 'pages_company',
  /** Whether the Orders page is available */
  PagesOrders = 'pages_orders',
  /** Whether the Vieworder page is available */
  PagesViewOrder = 'pages_viewOrder',
  /** Whether the Neworder page is available */
  PagesNewOrder = 'pages_newOrder',
  /** Whether the Partners page is available */
  PagesPartners = 'pages_partners',
  /** Whether the Support page is available */
  PagesSupport = 'pages_support',
  /** Whether the Planning page is available */
  PagesPlanning = 'pages_planning',
  /** Whether the Packinstructions page is available */
  PagesPackInstructions = 'pages_packInstructions',
  /** Whether the Pallets page is available */
  PagesPallets = 'pages_pallets',
  /** Whether the Cashflow page is available */
  PagesCashFlow = 'pages_cashFlow',
  /** Whether the Commercialterms page is available */
  PagesCommercialTerms = 'pages_commercialTerms',
  /** Whether the Analysisdownloads page is available */
  PagesAnalysisDownloads = 'pages_analysisDownloads',
  /** Whether the Insights page is available */
  PagesInsights = 'pages_insights',
  /** Whether the Loadoutinstructions page is available */
  PagesLoadOutInstructions = 'pages_loadOutInstructions',
  /** Whether the Commercialinvoices page is available */
  PagesCommercialInvoices = 'pages_commercialInvoices',
  /** Whether the Estimations page is available */
  PagesEstimations = 'pages_estimations',
  /** Whether the Shipments page is available */
  PagesShipments = 'pages_shipments',
  /** Whether the Airfreightshipments page is available */
  PagesAirFreightShipments = 'pages_airFreightShipments',
  /** Whether the Dataexports page is available */
  PagesDataExports = 'pages_dataExports',
  /** Whether the Financepipeline page is available */
  PagesFinancePipeline = 'pages_financePipeline',
  /** Whether the Financialinvoices page is available */
  PagesFinancialInvoices = 'pages_financialInvoices',
  /** Whether the Payments page is available */
  PagesPayments = 'pages_payments',
  /** Whether the Stock page is available */
  PagesStock = 'pages_stock',
  /** Whether the Financeindex page is available */
  PagesFinanceIndex = 'pages_financeIndex',
  /** Whether the Masterfiles page is available */
  PagesMasterfiles = 'pages_masterfiles',
  /**
   * Whether the Orders
   * page should allow the Viewprice
   * action for this user
   */
  OrdersViewPrice = 'orders_viewPrice',
  /**
   * Whether the Orders
   * page should allow the Duplicateorder
   * action for this user
   */
  OrdersDuplicateOrder = 'orders_duplicateOrder',
  /**
   * Whether the Vieworder
   * page should allow the Acceptandwontpacktoggle
   * action for this user
   */
  ViewOrderAcceptAndWontPackToggle = 'viewOrder_acceptAndWontPackToggle',
  /**
   * Whether the Vieworder
   * page should allow the Addorremovelineitem
   * action for this user
   */
  ViewOrderAddOrRemoveLineItem = 'viewOrder_addOrRemoveLineItem',
  /**
   * Whether the Vieworder
   * page should allow the Addorremovelineitemgroup
   * action for this user
   */
  ViewOrderAddOrRemoveLineItemGroup = 'viewOrder_addOrRemoveLineItemGroup',
  /**
   * Whether the Vieworder
   * page should allow the Duplicateorder
   * action for this user
   */
  ViewOrderDuplicateOrder = 'viewOrder_duplicateOrder',
  /**
   * Whether the Vieworder
   * page should allow the Editbatchnumber
   * action for this user
   */
  ViewOrderEditBatchNumber = 'viewOrder_editBatchNumber',
  /**
   * Whether the Vieworder
   * page should allow the Editcommodity
   * action for this user
   */
  ViewOrderEditCommodity = 'viewOrder_editCommodity',
  /**
   * Whether the Vieworder
   * page should allow the Editgrade
   * action for this user
   */
  ViewOrderEditGrade = 'viewOrder_editGrade',
  /**
   * Whether the Vieworder
   * page should allow the Editincoterm
   * action for this user
   */
  ViewOrderEditIncoterm = 'viewOrder_editIncoterm',
  /**
   * Whether the Vieworder
   * page should allow the Editinventorycode
   * action for this user
   */
  ViewOrderEditInventoryCode = 'viewOrder_editInventoryCode',
  /**
   * Whether the Vieworder
   * page should allow the Editmark
   * action for this user
   */
  ViewOrderEditMark = 'viewOrder_editMark',
  /**
   * Whether the Vieworder
   * page should allow the Editorder
   * action for this user
   */
  ViewOrderEditOrder = 'viewOrder_editOrder',
  /**
   * Whether the Vieworder
   * page should allow the Editpackingweek
   * action for this user
   */
  ViewOrderEditPackingWeek = 'viewOrder_editPackingWeek',
  /**
   * Whether the Vieworder
   * page should allow the Editpacktype
   * action for this user
   */
  ViewOrderEditPackType = 'viewOrder_editPackType',
  /**
   * Whether the Vieworder
   * page should allow the Editplannedquantity
   * action for this user
   */
  ViewOrderEditPlannedQuantity = 'viewOrder_editPlannedQuantity',
  /**
   * Whether the Vieworder
   * page should allow the Editpriority
   * action for this user
   */
  ViewOrderEditPriority = 'viewOrder_editPriority',
  /**
   * Whether the Vieworder
   * page should allow the Editreferencenumber
   * action for this user
   */
  ViewOrderEditReferenceNumber = 'viewOrder_editReferenceNumber',
  /**
   * Whether the Vieworder
   * page should allow the Editrequestedquantity
   * action for this user
   */
  ViewOrderEditRequestedQuantity = 'viewOrder_editRequestedQuantity',
  /**
   * Whether the Vieworder
   * page should allow the Editsizecounts
   * action for this user
   */
  ViewOrderEditSizeCounts = 'viewOrder_editSizeCounts',
  /**
   * Whether the Vieworder
   * page should allow the Editstacksize
   * action for this user
   */
  ViewOrderEditStackSize = 'viewOrder_editStackSize',
  /**
   * Whether the Vieworder
   * page should allow the Edittargetcountry
   * action for this user
   */
  ViewOrderEditTargetCountry = 'viewOrder_editTargetCountry',
  /**
   * Whether the Vieworder
   * page should allow the Edittargetmarket
   * action for this user
   */
  ViewOrderEditTargetMarket = 'viewOrder_editTargetMarket',
  /**
   * Whether the Vieworder
   * page should allow the Edittargetregion
   * action for this user
   */
  ViewOrderEditTargetRegion = 'viewOrder_editTargetRegion',
  /**
   * Whether the Vieworder
   * page should allow the Editvariety
   * action for this user
   */
  ViewOrderEditVariety = 'viewOrder_editVariety',
  /**
   * Whether the Vieworder
   * page should allow the Grouplineitemtoggle
   * action for this user
   */
  ViewOrderGroupLineItemToggle = 'viewOrder_groupLineItemToggle',
  /**
   * Whether the Vieworder
   * page should allow the Viewcomments
   * action for this user
   */
  ViewOrderViewComments = 'viewOrder_viewComments',
  /**
   * Whether the Vieworder
   * page should allow the Viewpallets
   * action for this user
   */
  ViewOrderViewPallets = 'viewOrder_viewPallets',
  /**
   * Whether the Vieworder
   * page should allow the Viewprice
   * action for this user
   */
  ViewOrderViewPrice = 'viewOrder_viewPrice',
  /**
   * Whether the Vieworder
   * page should allow the Viewtimeline
   * action for this user
   */
  ViewOrderViewTimeline = 'viewOrder_viewTimeline',
  /**
   * Whether the Vieworder
   * page should allow the Staticorderlineitemtable additionalfield
   * action for this user
   */
  ViewOrderStaticOrderLineItemTableAdditionalField = 'viewOrder_staticOrderLineItemTable_additionalField',
  /**
   * Whether the Planning
   * page should allow the Allocatable
   * action for this user
   */
  PlanningAllocatable = 'planning_allocatable',
  /**
   * Whether the General
   * page should allow the Netreturnscalc
   * action for this user
   */
  GeneralNetReturnsCalc = 'general_netReturnsCalc',
  /**
   * Whether the General
   * page should allow the Exportactions
   * action for this user
   */
  GeneralExportActions = 'general_exportActions'
}

export type Permissions = {
  __typename?: 'Permissions';
  landingPage?: Maybe<PermissionEnum>;
  permissions?: Maybe<Array<Permission>>;
};

export type PortArrival = {
  __typename?: 'PortArrival';
  /** The date that the vessel will be arriving */
  arrivalDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** The date that this information was received */
  observedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The port that the vessel will be arriving at */
  seaPort?: Maybe<SeaPort>;
  /** The vessel that is going to be arriving */
  vessel?: Maybe<Vessel>;
  /** The outgoing voyage number for the incoming vessel */
  voyage?: Maybe<Scalars['String']>;
};

export type PpecbActivityPoint = {
  __typename?: 'PpecbActivityPoint';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PpecbContainerType = {
  __typename?: 'PpecbContainerType';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PpecbCountry = {
  __typename?: 'PpecbCountry';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PpecbExporter = {
  __typename?: 'PpecbExporter';
  company?: Maybe<Company>;
  exporterFboCode?: Maybe<Scalars['String']>;
  ppecbRegisteredExporterCode?: Maybe<Scalars['String']>;
};

export type PpecbPort = {
  __typename?: 'PpecbPort';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  portCode?: Maybe<Scalars['String']>;
  portName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PpecbProduct = {
  __typename?: 'PpecbProduct';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PpecbRegimeCode = {
  __typename?: 'PpecbRegimeCode';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  loadSpecification?: Maybe<Scalars['String']>;
  maxProductTemp?: Maybe<Scalars['Float']>;
  minProductTemp?: Maybe<Scalars['Float']>;
  recipeDescription?: Maybe<Scalars['String']>;
  regimeCode?: Maybe<Scalars['String']>;
  regimeDescription?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PpecbUser = {
  __typename?: 'PpecbUser';
  agentFboCode?: Maybe<Scalars['String']>;
  ppecbExporters?: Maybe<Array<PpecbExporter>>;
  ppecbRegisteredAgentCode?: Maybe<Scalars['String']>;
};

export type PpecbVent = {
  __typename?: 'PpecbVent';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PpecbVoyageNumber = {
  __typename?: 'PpecbVoyageNumber';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  ppecbVoyageNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vesselName?: Maybe<Scalars['String']>;
  voyageNumber?: Maybe<Scalars['String']>;
};

export type PpecbWorldPort = {
  __typename?: 'PpecbWorldPort';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  portCode?: Maybe<Scalars['String']>;
  portName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum PriceUnitEnum {
  /** The line item is priced per ton */
  Ton = 'ton',
  /** The line item is priced per carton */
  Carton = 'carton'
}

/** Autogenerated return type of ProcessEtlDocument */
export type ProcessEtlDocumentPayload = {
  __typename?: 'ProcessEtlDocumentPayload';
  document?: Maybe<EtlDocument>;
};

export enum Quantity {
  Carton = 'CARTON',
  Pallet = 'PALLET',
  Container = 'CONTAINER'
}

/** root query */
export type Query = {
  __typename?: 'Query';
  /** Fetches Addendum */
  addendumHeaderById?: Maybe<AddendumHeader>;
  /** Fetches Addendum Line Items for specific addendum */
  addendumLineItemsByHeaderId?: Maybe<Array<AddendumLineItem>>;
  /** Fetches Addendums on a air freight shipment */
  addendumsOnAirFreightShipment?: Maybe<Array<AddendumHeader>>;
  /** Fetches Addendums on a shipment */
  addendumsOnShipment?: Maybe<Array<AddendumHeader>>;
  /** Gets additional fields configured for companies */
  additionalFields?: Maybe<Array<AdditionalField>>;
  /** Fetches Air Freight Shipment by Id */
  airFreightShipmentById?: Maybe<AirFreightShipment>;
  /** Fetches attributes for air freight shipments filters */
  airFreightShipmentLineItemsByShipment?: Maybe<Array<AirFreightShipmentLineItem>>;
  /** Fetches all air freight SLIs with no LOI and `In progress` state */
  airFreightShipmentLineItemsWithNoLoi?: Maybe<Array<AirFreightShipmentLineItem>>;
  /** Gets all load out instructions associated with a shipment */
  airFreightShipmentLois?: Maybe<Array<LoadOutInstruction>>;
  /** fetches air freight shipments based on filters or search term */
  airFreightShipments?: Maybe<AirFreightShipmentConnection>;
  /** Fetches attributes for air freight shipments filters */
  airFreightShipmentsFilterAttributes?: Maybe<AirFreightShipmentsFilterAttribute>;
  /** Fetches all analysis downloads or fetches downloads based on the companies commodity seasons */
  analysisDownloads?: Maybe<Array<AnalysisDownload>>;
  /** get all carton counts */
  cartonCounts?: Maybe<Array<CartonCount>>;
  cartonGroupingsForLineItem?: Maybe<Array<CartonGrouping>>;
  /** get cartons groupings for a specific week */
  cartonGroupingsForWeek?: Maybe<Array<CartonGrouping>>;
  /**
   * fetchs a list of carton groupings based on the defined search requirements,
   * also returns the total amount of cartons found and
   * the distinct values of certain columns to be used for filters
   */
  cartonGroupingsSearch?: Maybe<CartonGroupingSearch>;
  /** get the iframe url for the cartons per buyer chart for the current user */
  cartonsPerBuyerChartUrl?: Maybe<Scalars['String']>;
  /** get the iframe url for the cartons per seller chart for the current user */
  cartonsPerSellerChartUrl?: Maybe<Scalars['String']>;
  /** Fetches the specific certificate transport instruction */
  certificateTransportInstruction?: Maybe<CertificateTransportInstruction>;
  /** get a list of all known certificate types */
  certificateTypes?: Maybe<Array<CertificateType>>;
  /** Provides Errors to client */
  cgErrors: Array<CgError>;
  /** Gets all carton grouping transactions with given filters */
  cgTransactions: CartonGroupingTransactionConnection;
  /** get all commercial terms */
  commercialTerms?: Maybe<Array<CommercialTerm>>;
  /** Get all commodities */
  commodities?: Maybe<Array<Commodity>>;
  /** Get all commodity default seasons */
  commodityDefaultSeasons?: Maybe<Array<CommodityDefaultSeason>>;
  /** get the iframe url for the commodity split chart for the current user */
  commoditySplitChartUrl?: Maybe<Scalars['String']>;
  /** Gets a single company */
  company?: Maybe<Company>;
  /** Company contacts */
  contacts?: Maybe<Array<Contact>>;
  /** Container */
  containers?: Maybe<Array<Container>>;
  /** Gets the latest dates currency conversions */
  currencyConversion?: Maybe<CurrencyConversion>;
  /** Get all active map locations for a specific Order */
  currentMapPointsForOrder?: Maybe<Array<MapPoint>>;
  /** Fetches all custom fields for the selected company */
  customFields?: Maybe<Array<CustomField>>;
  /** get filtering data for the dashboard */
  dashboardFilters?: Maybe<DashboardFilters>;
  /** Search all active carton groupings */
  dashboardSearch?: Maybe<ElasticSearchResult>;
  /** Get all active carton groupings */
  dashboardTrackingData?: Maybe<DashboardTrackingData>;
  /** Fetches the data preparation for the user */
  dataPreparation?: Maybe<DataPreparation>;
  /** Fetches the data preparations for the user */
  dataPreparations?: Maybe<Array<DataPreparation>>;
  /** Gets a specific document */
  document: EtlDocument;
  /** Gets document rows for a specific document */
  documentRows: EtlDocumentRowConnection;
  /** Gets documents for a company */
  documents: EtlDocumentConnection;
  /** Gets all company employees information */
  employees?: Maybe<Array<Employee>>;
  /** Fetches the Agreemet Codes from ecert */
  ephytoAgreementCodes?: Maybe<Array<EphytoAgreementCode>>;
  /** Fetches the applied chemicals from ecert */
  ephytoAppliedChemicals?: Maybe<Array<Scalars['String']>>;
  /** Fetches Ephyto by ID */
  ephytoById?: Maybe<Ephyto>;
  /** Fetches the ephyto certificate */
  ephytoCertificateUrl?: Maybe<Scalars['String']>;
  /** Fetches the country ISOs from ecert */
  ephytoCountries?: Maybe<Array<EphytoCountry>>;
  /** Fetches the Ephyto Document Types */
  ephytoDocumentTypes?: Maybe<Array<Scalars['String']>>;
  /** Fetches attributes for Ephyto Filters */
  ephytoFilterAttributes?: Maybe<EphytoFilterAttribute>;
  /** Fetches the Ephyto Locations from eCert */
  ephytoLocations?: Maybe<Array<EphytoLocation>>;
  /** Fetches the package types from eCert */
  ephytoPackageTypes?: Maybe<Array<EphytoPackage>>;
  /** Fetches the transport means from ecert */
  ephytoTransportMeans?: Maybe<Array<Scalars['String']>>;
  /** Fetches the treatment levels from ecert */
  ephytoTreatments?: Maybe<Array<EphytoTreatment>>;
  /** Fetches the unit of measure codes from ecert */
  ephytoUnitOfMeasureCodes?: Maybe<Array<Scalars['String']>>;
  /** Fetches all Ephytos for the user */
  ephytos?: Maybe<EphytoConnection>;
  /** Gets all estimates for a company for a given season */
  estimates?: Maybe<Array<Estimate>>;
  /** Fetches the Export Document Types */
  exportDocumentTypes?: Maybe<Array<Scalars['String']>>;
  /** Fetches exporters */
  exporters?: Maybe<Array<Company>>;
  /** All Farm Varieties */
  farmVarieties: Array<FarmVariety>;
  /** Farm Variety Change requests from clients */
  farmVarietyClientChangeRequests: Array<FarmVarietyRequest>;
  /** Fetches attributes for farm variety and farm variety client change request filters */
  farmVarietyFilterAttributes?: Maybe<FarmVarietyFilterAttribute>;
  /** Returns the active orchards for the current user's company */
  fboOrchards?: Maybe<Array<FboOrchard>>;
  fetchReportResult?: Maybe<Scalars['Json']>;
  /** Fetches cg based financial items for report purposes */
  financeReport?: Maybe<FinanceReport>;
  /** Food Business Operator Change requests from clients */
  foodBusinessOperatorClientChangeRequests: Array<FoodBusinessOperatorRequest>;
  /** Returns food business operators for the current user's company */
  foodBusinessOperators?: Maybe<Array<FoodBusinessOperator>>;
  /** Fetches all business specific FBOs for the user */
  foodBusinessOperatorsByType?: Maybe<Array<FoodBusinessOperator>>;
  /** Fetches the specific invoice */
  invoice?: Maybe<Invoice>;
  /** A default reference to be used */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Fetches all invoices for the selected company */
  invoices?: Maybe<InvoiceConnection>;
  /** Fetches attributes for invoice filters */
  invoicesFilterAttributes?: Maybe<InvoiceFilterAttribute>;
  /** Get all varieties */
  listVarieties?: Maybe<Array<Variety>>;
  /** Fetches the specific load out instruction */
  loadOutInstruction?: Maybe<LoadOutInstruction>;
  /** Load Out Instructions Carton Groupings with reassignable flag */
  loadOutInstructionCgs: Array<LoiCartonGrouping>;
  /** A default reference to be used */
  loadOutInstructionReference?: Maybe<Scalars['String']>;
  /** Gets load out instructions based on search and filter criteria */
  loadOutInstructions?: Maybe<LoadOutInstructionConnection>;
  /** Fetches attributes for load out instruction filters */
  loadOutInstructionsFilterAttributes?: Maybe<LoadOutInstructionFilterAttribute>;
  /** get metabase report */
  metabaseReport?: Maybe<MetabaseReport>;
  /** Fetches attributes for metabase report filters */
  metabaseReportFilterAttributes?: Maybe<MetabaseReportsFilterAttribute>;
  /** get metabase reports */
  metabaseReports?: Maybe<Array<MetabaseReport>>;
  /** get the signed in users company */
  myCompany?: Maybe<Company>;
  /** get the signed in user */
  myUser?: Maybe<User>;
  /** Fetches air freight shipment line items for parent */
  nestedAirFreightShipmentLineItems?: Maybe<Array<AirFreightShipmentLineItem>>;
  /** Fetches SLIs without an addendum on a shipment */
  nonAddendumShipmentLineItems?: Maybe<Array<ShipmentLineItem>>;
  /** Fetches SLIs without an addendum on a shipment */
  nonEphytoShipmentLineItems?: Maybe<Array<NonEphytoShipmentLineItem>>;
  /** Gets all orchard seasons for given company and season */
  orchardSeasons?: Maybe<Array<OrchardSeason>>;
  /** Gets all orchard yields in a given range */
  orchardYields?: Maybe<Array<OrchardYield>>;
  /** Find an order by its ID */
  order?: Maybe<Order>;
  /** Gets all orders */
  orders?: Maybe<Array<Order>>;
  /** Fetches attributes for order filters */
  ordersFilterAttributes?: Maybe<OrderFilterAttribute>;
  /** Gets orders based on search criteria */
  ordersSearch?: Maybe<OrderSearch>;
  /** Fetches attributes that orders can be filtered on */
  ordersSearchFilterAttributes?: Maybe<OrdersSearchFilterAttributes>;
  /** Gets orders based on search and filter criteria */
  ordersV3?: Maybe<OrderConnection>;
  /** creates pack instructions based on applied filters */
  packInstructions?: Maybe<Array<PackInstructionLineItem>>;
  /** Fetches attributes for pack instruction filters */
  packInstructionsFilterAttributes?: Maybe<PackInstructionsFilterAttribute>;
  /** Fetches port arrivals */
  portArrivals?: Maybe<PortArrival>;
  /** Fetches all ppecb_activity_points for the user */
  ppecbActivityPoints?: Maybe<Array<PpecbActivityPoint>>;
  /** Fetches all ppecb_container_types for the user */
  ppecbContainerTypes?: Maybe<Array<PpecbContainerType>>;
  /** Fetches all ppecb_countries for the user */
  ppecbCountries?: Maybe<Array<PpecbCountry>>;
  /** Fetches all ppecb_voyage_numbers for the user */
  ppecbPorts?: Maybe<Array<PpecbPort>>;
  /** Fetches all ppecb_products for the user */
  ppecbProducts?: Maybe<Array<PpecbProduct>>;
  /** Fetches all ppecb_regime_codes for the user */
  ppecbRegimeCodes?: Maybe<Array<PpecbRegimeCode>>;
  /** Fetches ppecb_user data for a specific User */
  ppecbUserByUserId?: Maybe<PpecbUser>;
  /** Fetches all ppecb_vents for the user */
  ppecbVents?: Maybe<Array<PpecbVent>>;
  /** Fetches all ppecb_voyage_numbers for the user */
  ppecbVoyageNumbers?: Maybe<Array<PpecbVoyageNumber>>;
  /** Fetches all ppecb_world_ports for the user */
  ppecbWorldPorts?: Maybe<Array<PpecbWorldPort>>;
  /** Quantity types for line items */
  quantityType?: Maybe<Quantity>;
  /** Fetches the report configuration */
  report?: Maybe<Report>;
  /** Fetches the report group for the user */
  reportGroup?: Maybe<ReportGroup>;
  /** Fetches report_groups for the user */
  reportGroups?: Maybe<Array<ReportGroup>>;
  /** Runs the report for the user for a given report */
  runReport?: Maybe<Scalars['Json']>;
  /** Runs the report for the user for a given report */
  runReportAsync?: Maybe<Scalars['Json']>;
  /** Fetches sea ports */
  seaPorts?: Maybe<Array<SeaPort>>;
  /** Fetches all company users that have ppecb user credentials */
  seaShipmentControllers?: Maybe<Array<User>>;
  /** Fetches the specific shipment */
  shipment?: Maybe<Shipment>;
  /** Fetches Addendums on a shipment */
  shipmentBookingReferences?: Maybe<Array<Scalars['String']>>;
  /** Fetches the specific shipment */
  shipmentLineItemById?: Maybe<ShipmentLineItem>;
  /** Fetches attributes for Shipment Line Item Filters */
  shipmentLineItemFilterAttributes?: Maybe<ShipmentLineItemFilterAttribute>;
  /** Fetches shipment_line_item_groupings for a specific shipment */
  shipmentLineItemGroupingsByShipment?: Maybe<Array<ShipmentLineItemGrouping>>;
  /** Fetches shipment_line_items based on applied filters */
  shipmentLineItems?: Maybe<ShipmentLineItemConnection>;
  /** Fetches shipment_line_items for a specific shipment */
  shipmentLineItemsByShipment?: Maybe<Array<ShipmentLineItem>>;
  /** Fetches shipment_line_items for a specific shipment_line_item_grouping */
  shipmentLineItemsBySlig?: Maybe<Array<ShipmentLineItem>>;
  /** Fetches all Shipment Line Item Break Bulks */
  sliBreakBulks?: Maybe<Array<SliBreakBulk>>;
  /** Fetches all Shipment Line Item Containers */
  sliContainers?: Maybe<Array<SliContainer>>;
  /** Fetches SLI Container based on booking ref */
  sliContainersByBookingReference?: Maybe<Array<SliContainer>>;
  /** Fetches the carton groupings for the company. */
  stockAtLocation?: Maybe<StockModuleData>;
  /** Fetches attributes that stock can be filtered on */
  stockFilterAttributes?: Maybe<StockFilterAttributes>;
  /** Fetches stuff locations */
  stuffLocations?: Maybe<Array<FoodBusinessOperator>>;
  /** get the iframe url for the target market split chart for the current user */
  targetMarketSplitChartUrl?: Maybe<Scalars['String']>;
  /** Fetches template output files by template_output id */
  templateOutputFiles?: Maybe<TemplateOutput>;
  /** Fetch template output files based on the data source type and data source id */
  templateOutputFilesBySource?: Maybe<Array<TemplateOutput>>;
  /** Fetches all Terminals */
  terminals?: Maybe<Array<Terminal>>;
  /** gets all tickets by email */
  tickets?: Maybe<Array<Ticket>>;
  /** gets all trade countries */
  tradeCountries?: Maybe<Array<TradeCountry>>;
  /** Gets all company associations */
  tradePartners?: Maybe<Array<Company>>;
  /** gets all trade regions */
  tradeRegions?: Maybe<Array<TradeRegion>>;
  /** Fetches trucking onload points */
  truckingLoadingPoints?: Maybe<Array<FoodBusinessOperator>>;
  /** Fetches trucking offload points */
  truckingOffloadingPoints?: Maybe<Array<FoodBusinessOperator>>;
  /** Fetches a UI config data available to the user */
  uiConfig?: Maybe<UiConfig>;
  /** Fetches all remote UI config data available to the user */
  uiConfigs?: Maybe<Array<UiConfig>>;
  /** Fetches all unallocated air freight shipment line items */
  unallocatedAirFreightShipmentLineItems?: Maybe<AirFreightShipmentLineItemConnection>;
  /** get a list of all signed in user UserFeedItem paging */
  userFeedConnection: UserFeedItemConnection;
  /** get the amount of unseen user feed items */
  userFeedUnseenCount: Scalars['Int'];
  /** Get all varieties */
  varieties?: Maybe<Array<Variety>>;
  /** Fetches vessels */
  vessels?: Maybe<Array<Vessel>>;
  /** Returns voyages for a certain vessel and potentially a port of departure */
  voyages?: Maybe<Array<Scalars['String']>>;
};


/** root query */
export type QueryAddendumHeaderByIdArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryAddendumLineItemsByHeaderIdArgs = {
  addendumHeaderId: Scalars['Int'];
};


/** root query */
export type QueryAddendumsOnAirFreightShipmentArgs = {
  airFreightShipmentId: Scalars['Int'];
};


/** root query */
export type QueryAddendumsOnShipmentArgs = {
  shipmentId: Scalars['Int'];
};


/** root query */
export type QueryAdditionalFieldsArgs = {
  companyIds: Array<Scalars['Int']>;
};


/** root query */
export type QueryAirFreightShipmentByIdArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryAirFreightShipmentLineItemsByShipmentArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryAirFreightShipmentLoisArgs = {
  airFreightShipmentId: Scalars['ID'];
};


/** root query */
export type QueryAirFreightShipmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterAttributes: AirFreightShipmentsFilter;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes>>;
};


/** root query */
export type QueryCartonGroupingsForLineItemArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryCartonGroupingsForWeekArgs = {
  week: Scalars['String'];
};


/** root query */
export type QueryCartonGroupingsSearchArgs = {
  attributes: CartonGroupingSearchAttributes;
};


/** root query */
export type QueryCertificateTransportInstructionArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryCgTransactionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  docRowIds?: Maybe<Array<Scalars['Int']>>;
};


/** root query */
export type QueryCommercialTermsArgs = {
  startweek?: Maybe<Scalars['String']>;
  endweek?: Maybe<Scalars['String']>;
  companyid?: Maybe<Scalars['Int']>;
  targetregion?: Maybe<Scalars['String']>;
  advancetype?: Maybe<Scalars['String']>;
  advanceunit?: Maybe<Scalars['String']>;
  commodity?: Maybe<Scalars['String']>;
  rankoneevent?: Maybe<Scalars['String']>;
};


/** root query */
export type QueryCommoditiesArgs = {
  onlyOperational?: Maybe<Scalars['Boolean']>;
};


/** root query */
export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


/** root query */
export type QueryContainersArgs = {
  containernumber?: Maybe<Scalars['String']>;
  eventsafter?: Maybe<Scalars['ISO8601DateTime']>;
  excludesassignedinvlis?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  onlytrackingenabled?: Maybe<Scalars['Boolean']>;
  originportid?: Maybe<Scalars['Int']>;
};


/** root query */
export type QueryCurrentMapPointsForOrderArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryCustomFieldsArgs = {
  excludedCustomFieldIds?: Maybe<Array<Scalars['Int']>>;
};


/** root query */
export type QueryDashboardSearchArgs = {
  commodityGroups?: Maybe<Array<CommodityGroupEnum>>;
  fields?: Maybe<Array<DashboardSearchFieldsEnum>>;
  searchTerm?: Maybe<Scalars['String']>;
};


/** root query */
export type QueryDashboardTrackingDataArgs = {
  commoditygroups?: Maybe<Array<CommodityGroupEnum>>;
  containerId?: Maybe<Scalars['Int']>;
  forExport?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['Int']>;
};


/** root query */
export type QueryDataPreparationArgs = {
  id: Scalars['ID'];
};


/** root query */
export type QueryDocumentArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryDocumentRowsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  documentId: Scalars['Int'];
  states?: Maybe<Array<EtlDocumentRowStateEnum>>;
};


/** root query */
export type QueryDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  dateAfter?: Maybe<Scalars['ISO8601DateTime']>;
  dateBefore?: Maybe<Scalars['ISO8601DateTime']>;
  states?: Maybe<Array<EtlDocumentStateEnum>>;
};


/** root query */
export type QueryEphytoByIdArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryEphytoCertificateUrlArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryEphytoLocationsArgs = {
  countryCodes: Array<Scalars['String']>;
};


/** root query */
export type QueryEphytosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterAttributes: EphytoFilterAttributes;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes>>;
};


/** root query */
export type QueryEstimatesArgs = {
  endWeek?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['Int']>;
  startWeek?: Maybe<Scalars['String']>;
  weeks?: Maybe<Scalars['Boolean']>;
  commodityIds?: Maybe<Array<Scalars['Int']>>;
  subCommodityIds?: Maybe<Array<Scalars['Int']>>;
  varietyIds?: Maybe<Array<Scalars['Int']>>;
};


/** root query */
export type QueryExportersArgs = {
  companyname?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  orderbyloi?: Maybe<Scalars['Boolean']>;
};


/** root query */
export type QueryFboOrchardsArgs = {
  includeSellers?: Maybe<Scalars['Boolean']>;
};


/** root query */
export type QueryFetchReportResultArgs = {
  requestUuid: Scalars['String'];
};


/** root query */
export type QueryFinanceReportArgs = {
  attributes: FinanceReportFilterAttributes;
};


/** root query */
export type QueryFoodBusinessOperatorsArgs = {
  businessTypes?: Maybe<Array<Scalars['Int']>>;
  includeSellers?: Maybe<Scalars['Boolean']>;
};


/** root query */
export type QueryFoodBusinessOperatorsByTypeArgs = {
  typeOfBusiness: Scalars['Int'];
};


/** root query */
export type QueryInvoiceArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryInvoiceNumberArgs = {
  documenttype?: Maybe<Scalars['String']>;
};


/** root query */
export type QueryInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterAttributes: InvoicesFilters;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes>>;
  page: Scalars['String'];
};


/** root query */
export type QueryInvoicesFilterAttributesArgs = {
  page: Scalars['String'];
};


/** root query */
export type QueryLoadOutInstructionArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryLoadOutInstructionCgsArgs = {
  attributes: CartonGroupingSearchAttributes;
  hideReassignable?: Maybe<Scalars['Boolean']>;
  loadingPointId?: Maybe<Scalars['Int']>;
  portOfLoadingId?: Maybe<Scalars['Int']>;
  transportType?: Maybe<Scalars['String']>;
};


/** root query */
export type QueryLoadOutInstructionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterAttributes?: Maybe<LoadOutInstructionsFilters>;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes>>;
};


/** root query */
export type QueryMetabaseReportArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryMetabaseReportsArgs = {
  filters: MetabaseReportFilters;
  searchField: Scalars['String'];
  searchTerm: Scalars['String'];
};


/** root query */
export type QueryNestedAirFreightShipmentLineItemsArgs = {
  airFreightShipmentId: Scalars['ID'];
};


/** root query */
export type QueryNonAddendumShipmentLineItemsArgs = {
  bookingRef?: Maybe<Scalars['String']>;
  shipmentId: Scalars['Int'];
};


/** root query */
export type QueryNonEphytoShipmentLineItemsArgs = {
  shipmentId: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
};


/** root query */
export type QueryOrchardSeasonsArgs = {
  season: Scalars['Int'];
};


/** root query */
export type QueryOrchardYieldsArgs = {
  capturedAfter: Scalars['ISO8601DateTime'];
  capturedBefore: Scalars['ISO8601DateTime'];
  orchardSeasonId?: Maybe<Scalars['ID']>;
};


/** root query */
export type QueryOrderArgs = {
  id: Scalars['ID'];
};


/** root query */
export type QueryOrdersSearchArgs = {
  filter?: Maybe<OrderFilter>;
};


/** root query */
export type QueryOrdersV3Args = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterAttributes: OrderFilterAttributes;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes>>;
};


/** root query */
export type QueryPackInstructionsArgs = {
  attributes: PackInstructionsAttributes;
};


/** root query */
export type QueryPpecbUserByUserIdArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryReportArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryReportGroupArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryReportGroupsArgs = {
  filters: ReportGroupFilters;
  searchField: Scalars['String'];
  searchTerm: Scalars['String'];
};


/** root query */
export type QueryRunReportArgs = {
  gridRequest?: Maybe<GridRequest>;
  id: Scalars['Int'];
  outputType?: Maybe<RunReportOutputTypeEnum>;
  reportFilters?: Maybe<Array<Filter>>;
  reportTab?: Maybe<Tab>;
};


/** root query */
export type QueryRunReportAsyncArgs = {
  id: Scalars['Int'];
  requestUuid: Scalars['String'];
  gridRequest?: Maybe<GridRequest>;
  reportFilters?: Maybe<Array<Filter>>;
};


/** root query */
export type QuerySeaPortsArgs = {
  isportofloading?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  orderbyinv?: Maybe<Scalars['Boolean']>;
  orderbyloi?: Maybe<Scalars['Boolean']>;
  seaportname?: Maybe<Scalars['String']>;
  stufflocationid?: Maybe<Scalars['Int']>;
};


/** root query */
export type QueryShipmentArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryShipmentBookingReferencesArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryShipmentLineItemByIdArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryShipmentLineItemGroupingsByShipmentArgs = {
  id: Scalars['ID'];
};


/** root query */
export type QueryShipmentLineItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filterAttributes: ShipmentLineItemFilterAttributes;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes>>;
};


/** root query */
export type QueryShipmentLineItemsByShipmentArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryShipmentLineItemsBySligArgs = {
  id: Scalars['ID'];
};


/** root query */
export type QuerySliContainersByBookingReferenceArgs = {
  bookingReference: Scalars['String'];
};


/** root query */
export type QueryStockAtLocationArgs = {
  filterAttributes?: Maybe<StockFilterInputAttributes>;
  millerAttributes?: Maybe<MillerCollapsibleInLocationInput>;
  orderAttributes?: Maybe<Array<ColumnOrderAttributes>>;
  searchAttributes?: Maybe<SearchAttributes>;
};


/** root query */
export type QueryStuffLocationsArgs = {
  fboname?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  orderbyloi?: Maybe<Scalars['Boolean']>;
};


/** root query */
export type QueryTemplateOutputFilesArgs = {
  id: Scalars['Int'];
};


/** root query */
export type QueryTemplateOutputFilesBySourceArgs = {
  dataSourceId: Scalars['Int'];
  dataSourceType: Scalars['String'];
};


/** root query */
export type QueryTicketsArgs = {
  email: Scalars['String'];
};


/** root query */
export type QueryTradeCountriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  orderByInv?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
};


/** root query */
export type QueryTradePartnersArgs = {
  id: Scalars['ID'];
};


/** root query */
export type QueryTruckingLoadingPointsArgs = {
  fboName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  orderByLoi?: Maybe<Scalars['Boolean']>;
};


/** root query */
export type QueryTruckingOffloadingPointsArgs = {
  fboName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  orderByLoi?: Maybe<Scalars['Boolean']>;
};


/** root query */
export type QueryUiConfigArgs = {
  id: Scalars['ID'];
};


/** root query */
export type QueryUiConfigsArgs = {
  configType?: Maybe<Scalars['String']>;
  visibility?: Maybe<UiConfigVisibilityEnum>;
};


/** root query */
export type QueryUnallocatedAirFreightShipmentLineItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderByFields?: Maybe<Array<ColumnOrderAttributes>>;
};


/** root query */
export type QueryUserFeedConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** root query */
export type QueryVarietiesArgs = {
  onlyOperational?: Maybe<Scalars['Boolean']>;
  skipMixedVarieties?: Maybe<Scalars['Boolean']>;
};


/** root query */
export type QueryVesselsArgs = {
  limit?: Maybe<Scalars['Int']>;
  seaportid?: Maybe<Scalars['Int']>;
  vesselname?: Maybe<Scalars['String']>;
};


/** root query */
export type QueryVoyagesArgs = {
  seaportid?: Maybe<Scalars['Int']>;
  vesselid: Scalars['Int'];
};

/** Autogenerated return type of QueryAddendum */
export type QueryAddendumPayload = {
  __typename?: 'QueryAddendumPayload';
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ReassignShipmentLineItemGrouping */
export type ReassignShipmentLineItemGroupingPayload = {
  __typename?: 'ReassignShipmentLineItemGroupingPayload';
  errors?: Maybe<Scalars['String']>;
};

/** A report */
export type Report = {
  __typename?: 'Report';
  /** configs associated with the report */
  configs?: Maybe<Array<Config>>;
  /** The date and time the report was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The ID of the data preparation used to generate the report */
  dataPreparationId?: Maybe<Scalars['Int']>;
  /** A longer description of the report */
  description?: Maybe<Scalars['String']>;
  /** The ID of the report */
  id?: Maybe<Scalars['ID']>;
  /** The name of the report */
  name?: Maybe<Scalars['String']>;
  /** How the report should be displayed: 'grid' or 'chart' */
  reportType?: Maybe<Scalars['String']>;
  /** The date and time the report was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ReportGroup = {
  __typename?: 'ReportGroup';
  /** The badges associated with the report */
  badges?: Maybe<Array<Badge>>;
  /** configs associated with the report group */
  configs?: Maybe<Array<Config>>;
  /** report group created at */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** report group description */
  description?: Maybe<Scalars['String']>;
  /** The domain of the report in the value chain (e.g. production, logistics etc.) */
  domain?: Maybe<ReportGroupDomainEnum>;
  /** report group unique identifier */
  id?: Maybe<Scalars['Int']>;
  /** layout represents the structure of the reports appearing on the report group. If there is only one report in your report group, you would most likely just want the layout to be [[your_report_id]] */
  layout?: Maybe<Scalars['String']>;
  /** report group name */
  name?: Maybe<Scalars['String']>;
  /** reports associated with the report group */
  reports?: Maybe<Array<Report>>;
  /** The perspective of a report on a time dimension (e.g. predictive, operational etc.) */
  scope?: Maybe<ReportGroupScopeEnum>;
  /** report group updated at */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ReportGroupAttributes = {
  /** The default filters of the new report group */
  defaultFilters?: Maybe<Scalars['String']>;
  /** The description of the new report group */
  description?: Maybe<Scalars['String']>;
  /** The id of the reportGroup */
  id?: Maybe<Scalars['Int']>;
  /** The name of the new report group */
  name?: Maybe<Scalars['String']>;
};

/** The domain of the report in the value chain (e.g. production, logistics etc.) */
export enum ReportGroupDomainEnum {
  GlobalMarket = 'global_market',
  Production = 'production',
  MarketingAndSales = 'marketing_and_sales',
  Logistics = 'logistics',
  Claims = 'claims'
}

export type ReportGroupFilters = {
  /** The badges to filter on */
  badgeIds?: Maybe<Array<Scalars['Int']>>;
  /** The domains to filter on */
  domains?: Maybe<Array<ReportGroupDomainEnum>>;
  /** The scopes to filter on */
  scopes?: Maybe<Array<ReportGroupScopeEnum>>;
};

/** The perspective of a report on a time dimension (e.g. predictive, operational etc.) */
export enum ReportGroupScopeEnum {
  Predictive = 'predictive',
  Operational = 'operational',
  Retrospective = 'retrospective'
}

/** Autogenerated return type of ResendInvite */
export type ResendInvitePayload = {
  __typename?: 'ResendInvitePayload';
  id?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of ResolveCgError */
export type ResolveCgErrorPayload = {
  __typename?: 'ResolveCgErrorPayload';
  cgError: CgError;
};

/** Autogenerated return type of RevertLineItemsToCreated */
export type RevertLineItemsToCreatedPayload = {
  __typename?: 'RevertLineItemsToCreatedPayload';
  orders?: Maybe<Array<Order>>;
};

/** Autogenerated return type of RevertOrderChange */
export type RevertOrderChangePayload = {
  __typename?: 'RevertOrderChangePayload';
  errors?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

/** Autogenerated return type of RevokeInvite */
export type RevokeInvitePayload = {
  __typename?: 'RevokeInvitePayload';
  id?: Maybe<Scalars['Int']>;
};

export type Role = {
  __typename?: 'Role';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['Int']>;
  resourceType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum RunReportOutputTypeEnum {
  /** report output will be JSON */
  Json = 'json',
  /** report output will be a string to a URL for a PDF */
  Pdf = 'pdf',
  /** report output will be a string to a URL for a xls */
  Xls = 'xls'
}

export type SeaPort = {
  __typename?: 'SeaPort';
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unLocode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes to search on */
export type SearchAttributes = {
  /** term you want to search for */
  searchTerm: Scalars['String'];
  /** field you want to search on */
  searchField: Scalars['String'];
};

export type SendEmailAttributes = {
  /** Body of the email */
  body: Scalars['String'];
  /** IDs of the records within abovementioned model */
  docIds: Array<Scalars['Int']>;
  /** Comma-seperated list of types of documents to send e.g. "PDF","Excel" */
  docTypes: Array<Scalars['String']>;
  /** The templatable model from which documents should be retrieved and sent */
  model: Scalars['String'];
  /** Comma-seperated list of recipient email address */
  recipientEmailAddresses: Array<Scalars['String']>;
  /** Subject of the email */
  subject: Scalars['String'];
};

/** Autogenerated return type of SendEmail */
export type SendEmailPayload = {
  __typename?: 'SendEmailPayload';
  /** Any errors that occured during processing */
  errors?: Maybe<Scalars['String']>;
  /** Return Message */
  message: Scalars['String'];
};

export type SentEmail = {
  __typename?: 'SentEmail';
  attachments?: Maybe<Scalars['JSON']>;
  body?: Maybe<Scalars['String']>;
  /** DateTime when the email was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** Record ID */
  id: Scalars['ID'];
  /** Email address of the receiver */
  recipient?: Maybe<Scalars['String']>;
  /** Email address of the sender */
  sender?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Subject of the email */
  subject?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Shipment = {
  __typename?: 'Shipment';
  /** The actual time of departure for the shipment */
  actualTimeOfDeparture?: Maybe<Scalars['ISO8601Date']>;
  /** The party responsible for paying the bill */
  billToParty?: Maybe<Scalars['String']>;
  /** The CF code for the shipment */
  cfCode?: Maybe<Scalars['String']>;
  /** The client for the shipment */
  client?: Maybe<Scalars['String']>;
  /** Any additional comments related to the shipment */
  comments?: Maybe<Scalars['String']>;
  /** Company associated with this Shipment */
  company?: Maybe<Company>;
  /** Controller (PPECB Registered User/Company User) of the shipment */
  controller?: Maybe<Scalars['String']>;
  /** The date and time when the shipment was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The date when the shipment was created */
  dateCreated?: Maybe<Scalars['ISO8601Date']>;
  /** The estimated time of departure for the shipment */
  estimatedTimeOfDeparture?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB Error for Export Notification Submissions */
  exportNotificationError?: Maybe<Scalars['String']>;
  /** The code for the exporter */
  exporterCode?: Maybe<Scalars['String']>;
  /** The unique identifier for the shipment */
  id: Scalars['ID'];
  /** The port where the shipment is loaded */
  loadPort?: Maybe<Scalars['String']>;
  /** The phone number associated with the shipment */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The port where the shipment is loaded */
  portOfLoading?: Maybe<PpecbPort>;
  /** The PPECB booking reference number */
  ppecbBookingReference?: Maybe<Scalars['Int']>;
  /** The PPECB registered code for the agent */
  ppecbRegisteredAgentCode?: Maybe<Scalars['String']>;
  /** The PPECB registered code for the exporter */
  ppecbRegisteredExporterCode?: Maybe<Scalars['String']>;
  /** The voyage number provided by PPECB */
  ppecbVoyageNumber?: Maybe<Scalars['String']>;
  /** List of line items for the shipment */
  shipmentLineItems?: Maybe<Array<ShipmentLineItem>>;
  /** The party who is shipping the goods */
  shipper?: Maybe<Scalars['String']>;
  /** The shipping line for the shipment */
  shippingLine?: Maybe<Scalars['String']>;
  /** The voyage number provided by the shipping line */
  shippingLineVoyageNumber?: Maybe<Scalars['String']>;
  /** The dates when the shipments stacks open */
  stackDates?: Maybe<Scalars['ISO8601Date']>;
  /** The dates when the shipments stacks close */
  stackDatesClose?: Maybe<Scalars['ISO8601Date']>;
  /** The current state of the shipment */
  state?: Maybe<Scalars['String']>;
  /** The current status of the shipment */
  status?: Maybe<Scalars['String']>;
  /** Object containing information on the document generated for this shipment */
  templateOutput?: Maybe<TemplateOutput>;
  /** The terminal associated with the shipment */
  terminal?: Maybe<Terminal>;
  /** The date and time when the shipment was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The username of the person who created the shipment */
  userName?: Maybe<Scalars['String']>;
  /** The code of the vessel carrying the shipment */
  vesselCode?: Maybe<Scalars['String']>;
  /** The name of the vessel carrying the shipment */
  vesselName?: Maybe<Scalars['String']>;
  /** The original estimated date of departure for the vessel */
  vesselOriginalEstimatedDateOfDeparture?: Maybe<Scalars['ISO8601Date']>;
  /** The voyage number for the shipment */
  voyageNumber?: Maybe<Scalars['String']>;
};

export type ShipmentAttributes = {
  /** Unique ID associated with Shipment */
  id?: Maybe<Scalars['Int']>;
  /** Booking Reference provided by PPECB */
  ppecbBookingReference?: Maybe<Scalars['String']>;
  /** Shipping line transporting goods */
  shippingLine?: Maybe<Scalars['String']>;
  /** Indentifier for the voyage */
  voyageNumber?: Maybe<Scalars['String']>;
  /** Combination of Shipping line and voyage nr */
  shippingLineVoyageNumber?: Maybe<Scalars['String']>;
  /** Port where goods are loaded */
  loadPort?: Maybe<Scalars['String']>;
  /** Registered code of the party that will be billed */
  billToParty?: Maybe<Scalars['String']>;
  /** Registered Exporter Code */
  exporterCode?: Maybe<Scalars['String']>;
  /** Agent FBO Code */
  cfCode?: Maybe<Scalars['String']>;
  /** PPECB registered Exporter Code */
  ppecbRegisteredExporterCode?: Maybe<Scalars['String']>;
  /** PPECB registered Agent Code */
  ppecbRegisteredAgentCode?: Maybe<Scalars['String']>;
  /** Contact details for agent */
  phoneNumber?: Maybe<Scalars['String']>;
  /** PPECB registered user name */
  userName?: Maybe<Scalars['String']>;
  /** Date of booking creation */
  dateCreated?: Maybe<Scalars['ISO8601DateTime']>;
  /** Vessel planned departure date */
  vesselOriginalEstimatedDateOfDeparture?: Maybe<Scalars['ISO8601DateTime']>;
  /** Planned opening stack dates */
  stackDates?: Maybe<Scalars['ISO8601DateTime']>;
  /** Planned closing stack dates */
  stackDatesClose?: Maybe<Scalars['ISO8601DateTime']>;
  /** Name of the shipper */
  shipper?: Maybe<Scalars['String']>;
  /** Status of the booking */
  status?: Maybe<Scalars['String']>;
  /** Name of the vessel */
  vesselName?: Maybe<Scalars['String']>;
  /** Code to identify the vessel */
  vesselCode?: Maybe<Scalars['String']>;
  /** PPECB registered voyage number */
  ppecbVoyageNumber?: Maybe<Scalars['String']>;
  /** Controller (PPECB Registered User/Company User) of the shipment */
  controller?: Maybe<Scalars['String']>;
  /** Client of the shipment */
  client?: Maybe<Scalars['String']>;
  /** Port of loading ID (PPECB registered Port) of the shipment */
  portOfLoadingId?: Maybe<Scalars['Int']>;
  /** Comments made on the shipment */
  comments?: Maybe<Scalars['String']>;
  /** Terminal ID of the shipment */
  terminalId?: Maybe<Scalars['Int']>;
  /** Vessel Estimated Time of Departure */
  estimatedTimeOfDeparture?: Maybe<Scalars['ISO8601Date']>;
  /** Vessel Actual Time of Departure */
  actualTimeOfDeparture?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB Error for Export Notification Submissions */
  exportNotificationError?: Maybe<Scalars['String']>;
};

export type ShipmentDocument = {
  __typename?: 'ShipmentDocument';
  documentType?: Maybe<Scalars['String']>;
  file?: Maybe<Document>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ShipmentLineItem = {
  __typename?: 'ShipmentLineItem';
  /** The date when an addendum was added */
  addendumDate?: Maybe<Scalars['ISO8601Date']>;
  /** The date the bill was released */
  billReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** The date the bill was submitted */
  billSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** The date on which the shipment was booked */
  bookingDate?: Maybe<Scalars['ISO8601Date']>;
  /** The date when the cargo dues are due */
  cargoDuesDate?: Maybe<Scalars['ISO8601Date']>;
  /** The Verified Gross Mass (VGM) of the cargo */
  cargoVgm?: Maybe<Scalars['String']>;
  /** The certificate transport instruction generated for this shipment line item */
  certificateTransportInstruction?: Maybe<CertificateTransportInstruction>;
  /** The reference number provided by the client */
  clientReference?: Maybe<Scalars['String']>;
  /** The date when the shipment was collected */
  collectionDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Any additional comments or details about the shipment */
  comments?: Maybe<Scalars['String']>;
  /** The party to whom the shipment is consigned */
  consignee?: Maybe<Scalars['String']>;
  /** The combined number of the container and its corresponding seal */
  containerAndSealNumber?: Maybe<Scalars['String']>;
  /** The container number specific to the shipment line item */
  containerNumber?: Maybe<Scalars['String']>;
  /** The date when the Certificate of Origin was released */
  cooReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** The date when the Certificate of Origin was submitted */
  cooSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** When the shipment line item was first created in the system */
  createdAt: Scalars['ISO8601DateTime'];
  /** The date of the CTI (??? unclear acronym ???) */
  ctiDate?: Maybe<Scalars['ISO8601Date']>;
  /** Customer reference number or code */
  customerReference?: Maybe<Scalars['String']>;
  /** When the shipment line item was created */
  dateCreated?: Maybe<Scalars['ISO8601DateTime']>;
  /** The depot where the shipment is stored or prepared */
  depot?: Maybe<Scalars['String']>;
  /** ??? */
  elgDate?: Maybe<Scalars['ISO8601Date']>;
  /** The depot where the empty containers are located */
  emptyDepot?: Maybe<FoodBusinessOperator>;
  /** Estimated time of arrival for the shipment */
  eta?: Maybe<Scalars['ISO8601DateTime']>;
  /** The date when the export certificate was released */
  exportCertReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** The date when the export certificate was submitted */
  exportCertSubmitDate?: Maybe<Scalars['ISO8601Date']>;
  /** The documents required for export */
  exportDocuments?: Maybe<Array<ExportDocument>>;
  /** The date on which the export notification was sent */
  exportNotificationDate?: Maybe<Scalars['ISO8601Date']>;
  /** PPECB Error for Export Notification Submissions */
  exportNotificationError?: Maybe<Scalars['String']>;
  /** Exporter as named on the certificate */
  exporterOnCertificate?: Maybe<Scalars['String']>;
  /** Final country of destination for the shipment */
  finalCountryOfDestination?: Maybe<Scalars['String']>;
  /** Final port where the shipment will be discharged */
  finalPortOfDischarge?: Maybe<Scalars['String']>;
  /** The gross weight of the shipment line item */
  grossWeight?: Maybe<Scalars['Float']>;
  /** The type of haulage used for the shipment */
  haulageType?: Maybe<Scalars['String']>;
  /** Humidity conditions within the shipping environment */
  humidity?: Maybe<Scalars['String']>;
  /** Unique identifier for the shipment line item */
  id: Scalars['ID'];
  /** Import permit number for the shipment */
  importPermitNumber?: Maybe<Scalars['String']>;
  /** The date when the shipment was invoiced */
  invoicedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Boolean value indicating if the shipment requires cold treatment */
  isColdTreatment?: Maybe<Scalars['Boolean']>;
  /** Boolean value indicating if the shipment uses conventional shipping methods */
  isConventionalShipping?: Maybe<Scalars['Boolean']>;
  /** Boolean value indicating if a generator set is required */
  isGensetRequired?: Maybe<Scalars['Boolean']>;
  /** Boolean value indicating if the shipment is a transhipment */
  isTranshipment?: Maybe<Scalars['Boolean']>;
  /** The loading instruction number associated with the shipment */
  loadingInstructionNumber?: Maybe<Scalars['String']>;
  /** The points at which the shipment will be loaded */
  loadingPoints?: Maybe<Array<LoadingPoint>>;
  /** The net weight of the shipment line item */
  nettWeight?: Maybe<Scalars['Float']>;
  /** The date when the original documents were issued */
  originalsDate?: Maybe<Scalars['ISO8601Date']>;
  /** The terms of payment agreed upon for the shipment */
  paymentTerms?: Maybe<Scalars['String']>;
  /** The date when the phyto was approved */
  phytoApprovedDate?: Maybe<Scalars['ISO8601Date']>;
  /** The date when the phyto was submitted */
  phytoSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** The final destination port of the shipment */
  portOfDestination?: Maybe<PpecbWorldPort>;
  /** PPECB booking item reference number */
  ppecbBookingItemReference?: Maybe<Scalars['Int']>;
  /** PPECB booking reference number */
  ppecbBookingReference?: Maybe<Scalars['Int']>;
  /** The product codes associated with the shipment line item */
  productCodes?: Maybe<Scalars['String']>;
  /** The agreed number of days as per the protocol for the shipment */
  protocolDays?: Maybe<Scalars['Int']>;
  /** The agreed temperature as per the protocol for the shipment */
  protocolTemperature?: Maybe<Scalars['Float']>;
  /** The individual or company designated to receive the shipment */
  receiver?: Maybe<Scalars['String']>;
  /** Regime code for the shipment */
  regimeCode?: Maybe<Scalars['String']>;
  /** The seal number specific to the shipment line item */
  sealNumber?: Maybe<Scalars['String']>;
  /** The number of the service contract for the shipment */
  serviceContractNumber?: Maybe<Scalars['String']>;
  /** The overall shipment to which this line item belongs */
  shipment?: Maybe<Shipment>;
  /** The unique identifier of the overall shipment */
  shipmentId?: Maybe<Scalars['Int']>;
  /** ID of the Shipment Line Item Grouping it belongs to */
  shipmentLineItemGroupingId?: Maybe<Scalars['ID']>;
  /** Contact number for the shipping line */
  shippingLineContactNumber?: Maybe<Scalars['String']>;
  /** Shipping line reference number or code */
  shippingLineReference?: Maybe<Scalars['String']>;
  /** The Break Bulk linked to the SLI */
  sliBreakBulk?: Maybe<SliBreakBulk>;
  /** The Container linked to the SLI */
  sliContainer?: Maybe<SliContainer>;
  /** Compliance status with the Safety of Life at Sea (SOLAS) convention */
  solas?: Maybe<Scalars['String']>;
  /** Special instructions given for the shipment */
  specialInstruction?: Maybe<Scalars['String']>;
  /** The current state of the shipment line item */
  state?: Maybe<Scalars['String']>;
  /** The current status of the shipment line item */
  status?: Maybe<Scalars['String']>;
  /** Tare weight of the goods */
  tareWeight?: Maybe<Scalars['Float']>;
  /** The tasks related to this SLI */
  tasks?: Maybe<Array<TaskableTask>>;
  /** The device used to measure and control the temperature during the shipment */
  tempDevice?: Maybe<Scalars['String']>;
  /** Temperature sensor used in the shipment */
  tempSensor?: Maybe<Scalars['String']>;
  /** Total Cartons on this SLI */
  totalCartons?: Maybe<Scalars['Int']>;
  /** Port used for transhipment */
  transhipmentPort?: Maybe<Scalars['String']>;
  /** Name of the vessel used for transhipment */
  transhipmentVesselName?: Maybe<Scalars['String']>;
  /** Voyage number for the transhipment */
  transhipmentVoyageNumber?: Maybe<Scalars['String']>;
  /** The company or individual responsible for transporting the shipment */
  transporter?: Maybe<Scalars['String']>;
  /** When the shipment line item was last updated in the system */
  updatedAt: Scalars['ISO8601DateTime'];
  /** Name of the user who created or last modified the shipment */
  userName?: Maybe<Scalars['String']>;
  /** The settings of the ventilation system within the shipment */
  ventSetting?: Maybe<Scalars['String']>;
  /** Information about the void plugs used in the shipment */
  voidPlugs?: Maybe<Scalars['String']>;
};

export type ShipmentLineItemAttributes = {
  /** Unique identifier for the data entry */
  id?: Maybe<Scalars['Int']>;
  /** Reference number for the PPECB booking */
  ppecbBookingReference?: Maybe<Scalars['Int']>;
  /** Reference number for the PPECB booking item */
  ppecbBookingItemReference?: Maybe<Scalars['Int']>;
  /** Reference string for the shipping line */
  shippingLineReference?: Maybe<Scalars['String']>;
  /** Reference string for the customer */
  customerReference?: Maybe<Scalars['String']>;
  /** Code that represents the trade regime */
  regimeCode?: Maybe<Scalars['String']>;
  /** Country where the shipment is destined */
  finalCountryOfDestination?: Maybe<Scalars['String']>;
  /** Number for the import permit */
  importPermitNumber?: Maybe<Scalars['String']>;
  /** Exporter's name on the certificate */
  exporterOnCertificate?: Maybe<Scalars['String']>;
  /** Indicates if a generator set is required */
  isGensetRequired?: Maybe<Scalars['Boolean']>;
  /** Indicates if shipment is a transhipment */
  isTranshipment?: Maybe<Scalars['Boolean']>;
  /** Indicates if cold treatment is needed for shipment */
  isColdTreatment?: Maybe<Scalars['Boolean']>;
  /** Name of the vessel used for transhipment */
  transhipmentVesselName?: Maybe<Scalars['String']>;
  /** Number of the voyage used for transhipment */
  transhipmentVoyageNumber?: Maybe<Scalars['String']>;
  /** Port used for transhipment */
  transhipmentPort?: Maybe<Scalars['String']>;
  /** Final port where goods will be discharged */
  finalPortOfDischarge?: Maybe<Scalars['String']>;
  /** Type of temperature sensor used */
  tempSensor?: Maybe<Scalars['String']>;
  /** Name of the user creating the shipment record */
  userName?: Maybe<Scalars['String']>;
  /** When the shipment was created */
  dateCreated?: Maybe<Scalars['ISO8601DateTime']>;
  /** Indicates if the shipping is conventional */
  isConventionalShipping?: Maybe<Scalars['Boolean']>;
  /** Depot where shipment is stored */
  depot?: Maybe<Scalars['String']>;
  /** Date when the shipment was collected */
  collectionDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** Loading instruction number for shipment */
  loadingInstructionNumber?: Maybe<Scalars['String']>;
  /** Type of haulage used for transportation */
  haulageType?: Maybe<Scalars['String']>;
  /** Transporter involved in shipment */
  transporter?: Maybe<Scalars['String']>;
  /** Receiver of the shipment */
  receiver?: Maybe<Scalars['String']>;
  /** Verified Gross Mass (VGM) of the cargo */
  cargoVgm?: Maybe<Scalars['String']>;
  /** Safety of Life at Sea (SOLAS) requirement for shipment */
  solas?: Maybe<Scalars['String']>;
  /** Temperature device used in shipment */
  tempDevice?: Maybe<Scalars['String']>;
  /** Estimated Time of Arrival (ETA) of the shipment */
  eta?: Maybe<Scalars['ISO8601DateTime']>;
  /** Container and seal number for the shipment */
  containerAndSealNumber?: Maybe<Scalars['String']>;
  /** Payment terms for the shipment */
  paymentTerms?: Maybe<Scalars['String']>;
  /** Contact number of the shipping line */
  shippingLineContactNumber?: Maybe<Scalars['String']>;
  /** Humidity condition for shipment */
  humidity?: Maybe<Scalars['String']>;
  /** Information about void plugs used in shipment */
  voidPlugs?: Maybe<Scalars['String']>;
  /** Ventilation setting for the shipment */
  ventSetting?: Maybe<Scalars['String']>;
  /** Protocol temperature for the shipment */
  protocolTemperature?: Maybe<Scalars['Float']>;
  /** Protocol days for the shipment */
  protocolDays?: Maybe<Scalars['Int']>;
  /** Special instructions for the shipment */
  specialInstruction?: Maybe<Scalars['String']>;
  /** Current status of the shipment */
  status?: Maybe<Scalars['String']>;
  /** Product codes included in the shipment */
  productCodes?: Maybe<Scalars['String']>;
  /** Timestamp when the shipment record was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Timestamp when the shipment record was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Unique identifier for the shipment */
  shipmentId?: Maybe<Scalars['Int']>;
  /** Loading points for the shipment */
  loadingPoints?: Maybe<Array<LoadingPointAttributes>>;
  /** Number of the container used for shipment */
  containerNumber?: Maybe<Scalars['String']>;
  /** Number of the seal used for shipment */
  sealNumber?: Maybe<Scalars['String']>;
  /** Gross weight of the shipment */
  grossWeight?: Maybe<Scalars['Float']>;
  /** Nett weight of the shipment */
  nettWeight?: Maybe<Scalars['Float']>;
  /** Current state of the shipment */
  state?: Maybe<Scalars['String']>;
  /** Comments regarding the shipment */
  comments?: Maybe<Scalars['String']>;
  /** Date the shipment was booked */
  bookingDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date of export notification */
  exportNotificationDate?: Maybe<Scalars['ISO8601Date']>;
  /** CTI (Container Terminal Interest) date */
  ctiDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the bill was submitted */
  billSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the bill was released */
  billReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the phytosanitary certificate was submitted */
  phytoSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the phytosanitary certificate was approved */
  phytoApprovedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the Certificate of Origin (COO) was submitted */
  cooSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the Certificate of Origin (COO) was released */
  cooReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the export certificate was submitted */
  exportCertSubmitDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the export certificate was released */
  exportCertReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** ELG (Entry of Goods) date */
  elgDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when cargo dues were paid */
  cargoDuesDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the shipment was invoiced */
  invoicedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the original documents were issued */
  originalsDate?: Maybe<Scalars['ISO8601Date']>;
  /** Party to whom the shipment will be delivered */
  consignee?: Maybe<Scalars['String']>;
  /** Service contract number for the shipment */
  serviceContractNumber?: Maybe<Scalars['String']>;
  /** Identifier for the port of destination */
  portOfDestinationId?: Maybe<Scalars['Int']>;
  /** Reference number or string from the client */
  clientReference?: Maybe<Scalars['String']>;
  /** Date of any addendum related to the shipment */
  addendumDate?: Maybe<Scalars['ISO8601Date']>;
  /** Identifier for the depot where the container is stored when empty */
  emptyDepotId?: Maybe<Scalars['Int']>;
  /** Total Cartons on this SLI */
  totalCartons?: Maybe<Scalars['Int']>;
  /** PPECB Error for Export Notification Submissions */
  exportNotificationError?: Maybe<Scalars['String']>;
  /** Tare weight of the goods */
  tareWeight?: Maybe<Scalars['Float']>;
  /** ID of the Shipment Line Item Grouping it belongs to */
  shipmentLineItemGroupingId?: Maybe<Scalars['ID']>;
};

/** The connection type for ShipmentLineItem. */
export type ShipmentLineItemConnection = {
  __typename?: 'ShipmentLineItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShipmentLineItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShipmentLineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ShipmentLineItemEdge = {
  __typename?: 'ShipmentLineItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShipmentLineItem>;
};

export type ShipmentLineItemFilterAttribute = {
  __typename?: 'ShipmentLineItemFilterAttribute';
  /** this shipments clients */
  clients?: Maybe<Array<Scalars['String']>>;
  /** this shipments controllers */
  controllers?: Maybe<Array<Scalars['String']>>;
  /** this shipments booking_references */
  shippingLineReferences?: Maybe<Array<Scalars['String']>>;
  /** this shipments state */
  states?: Maybe<Array<Scalars['String']>>;
  /** this shipments vessels */
  vessels?: Maybe<Array<Scalars['String']>>;
};

export type ShipmentLineItemFilterAttributes = {
  /** this shipments clients */
  clients?: Maybe<Array<Scalars['String']>>;
  /** this shipments controllers */
  controllers?: Maybe<Array<Scalars['String']>>;
  /** this shipments booking references */
  shippingLineReferences?: Maybe<Array<Scalars['String']>>;
  /** this shipments state */
  states?: Maybe<Array<Scalars['String']>>;
  /** this shipments vessels */
  vessels?: Maybe<Array<Scalars['String']>>;
};

export type ShipmentLineItemGrouping = {
  __typename?: 'ShipmentLineItemGrouping';
  /** Unique identifier for the Company associated with this SLIG */
  companyId: Scalars['ID'];
  /** When the shipment line item grouping was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Unique identifier for the shipment line item grouping */
  id: Scalars['ID'];
  /** Unique identifier for the Shipment associated with this SLIG */
  shipmentId: Scalars['ID'];
  /** The fields the SLIG is grouped on */
  sligFields?: Maybe<Scalars['JSON']>;
  /** Summation of all the SLIs cartons belonging to this SLIG */
  totalCartons?: Maybe<Scalars['Int']>;
  /** Summation of all the SLIs gross weight belonging to this SLIG */
  totalGrossWeight?: Maybe<Scalars['Float']>;
  /** Summation of all the SLIs nett weight belonging to this SLIG */
  totalNettWeight?: Maybe<Scalars['Float']>;
  /** When the shipment line item grouping was last updated in the system */
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Attributes for updating a shipment line item task */
export type ShipmentLineItemTaskAttributes = {
  /** Unique identifier for the data entry */
  id?: Maybe<Scalars['Int']>;
  /** Date the shipment was booked */
  bookingDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date of any addendum related to the shipment */
  addendumDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date of export notification */
  exportNotificationDate?: Maybe<Scalars['ISO8601Date']>;
  /** CTI (Container Terminal Interest) date */
  ctiDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the bill was submitted */
  billSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the bill was released */
  billReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the phytosanitary certificate was submitted */
  phytoSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the phytosanitary certificate was approved */
  phytoApprovedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the Certificate of Origin (COO) was submitted */
  cooSubmittedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the Certificate of Origin (COO) was released */
  cooReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the export certificate was submitted */
  exportCertSubmitDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the export certificate was released */
  exportCertReleasedDate?: Maybe<Scalars['ISO8601Date']>;
  /** ELG (Entry of Goods) date */
  elgDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when cargo dues were paid */
  cargoDuesDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the shipment was invoiced */
  invoicedDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when the original documents were issued */
  originalsDate?: Maybe<Scalars['ISO8601Date']>;
};

export type SliBreakBulk = {
  __typename?: 'SliBreakBulk';
  /** Break Bulk Container joined on the SliBreakBulk record */
  breakBulkContainer?: Maybe<BreakBulkContainer>;
  /** Internal Id of the SliBreakBulk join */
  id: Scalars['ID'];
};

export type SliContainer = {
  __typename?: 'SliContainer';
  container?: Maybe<Container>;
  id: Scalars['ID'];
};

export enum StateFilter {
  Expired = 'expired',
  WontPack = 'wont_pack',
  Created = 'created',
  Accepted = 'accepted',
  PartiallyPacked = 'partially_packed',
  Packed = 'packed',
  AtColdStore = 'at_cold_store',
  AtRailDepot = 'at_rail_depot',
  AtOriginPort = 'at_origin_port',
  InTransit = 'in_transit',
  AtDestinationPort = 'at_destination_port',
  Delivered = 'delivered',
  PartiallyDelivered = 'partially_delivered',
  PartiallyAccepted = 'partially_accepted',
  Allocated = 'allocated',
  ToPack = 'to_pack'
}

export type Status = {
  __typename?: 'Status';
  requestUuid: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

/** Attributes for filtering stock */
export type StockFilterAttributes = {
  __typename?: 'StockFilterAttributes';
  /** batch numbers to filter on */
  batchNumbers?: Maybe<Array<Scalars['String']>>;
  /** buyers to filter on */
  buyers?: Maybe<Array<Company>>;
  /** commodities to filter on */
  commodities?: Maybe<Array<Commodity>>;
  /** exporter codes to filter on */
  exporterCodes?: Maybe<Array<Scalars['String']>>;
  /** grades to filter on */
  grades?: Maybe<Array<Scalars['String']>>;
  /** inventory codes to filter on */
  inventoryCodes?: Maybe<Array<Scalars['String']>>;
  /** marks to filter on */
  marks?: Maybe<Array<Scalars['String']>>;
  /** pack types to filter on */
  packTypes?: Maybe<Array<Scalars['String']>>;
  /** sellers to filter on */
  sellers?: Maybe<Array<Company>>;
  /** size counts to filter on */
  sizeCounts?: Maybe<Array<Scalars['String']>>;
  /** states to filter on */
  states?: Maybe<Array<Scalars['String']>>;
  /** sub-commodities to filter on */
  subCommodities?: Maybe<Array<SubCommodity>>;
  /** target markets to filter on */
  targetMarkets?: Maybe<Array<Scalars['String']>>;
  /** varieties to filter on */
  varieties?: Maybe<Array<Variety>>;
};

/** Attributes for filtering stock */
export type StockFilterInputAttributes = {
  /** Batch numbers to filter on */
  batchNumbers?: Maybe<Array<Scalars['String']>>;
  /** Buyer IDs to filter on */
  buyerId?: Maybe<Array<Scalars['ID']>>;
  /** Commodity IDs to filter on */
  commodityId?: Maybe<Array<Scalars['ID']>>;
  /** Exporter codes to filter on */
  exporterCodes?: Maybe<Array<Scalars['String']>>;
  /** Grades to filter on */
  grades?: Maybe<Array<Scalars['String']>>;
  /** Inspection date end */
  inspectionDateEnd?: Maybe<Scalars['ISO8601DateTime']>;
  /** Inspection date start */
  inspectionDateStart?: Maybe<Scalars['ISO8601DateTime']>;
  /** Inventory codes to filter on */
  inventoryCodes?: Maybe<Array<Scalars['String']>>;
  /** Marks to filter on */
  marks?: Maybe<Array<Scalars['String']>>;
  /** Pack types to filter on */
  packs?: Maybe<Array<Scalars['String']>>;
  /** Sellers to filter on */
  sellerId?: Maybe<Array<Scalars['ID']>>;
  /** Size counts to filter on */
  sizeCounts?: Maybe<Array<Scalars['String']>>;
  /** States to filter on */
  states?: Maybe<Array<Scalars['String']>>;
  /** Sub-commodities to filter on */
  subCommodityId?: Maybe<Array<Scalars['ID']>>;
  /** Target markets to filter on */
  targetMarkets?: Maybe<Array<Scalars['String']>>;
  /** Variety IDs to filter on */
  varietyId?: Maybe<Array<Scalars['ID']>>;
};

/** Returns the name of the location and CG states at that location. */
export type StockModuleData = {
  __typename?: 'StockModuleData';
  /** Locations miller column. */
  allLocations?: Maybe<Array<LocationMillerFilter>>;
  /** What fields are used to group the data. */
  groupFields?: Maybe<Array<Scalars['String']>>;
  /** What values is the data grouped by. */
  groupKeys?: Maybe<Array<Scalars['String']>>;
  /** The Miller column second level which further aggregates the carton groupings/stock. */
  millerCollapsibleForLocation?: Maybe<Array<MillerCollapsibleInLocation>>;
  /** Cartons groupings with location */
  results?: Maybe<Array<CartonGroupingWithLocation>>;
};


/** Returns the name of the location and CG states at that location. */
export type StockModuleDataMillerCollapsibleForLocationArgs = {
  millerColumnAttributes?: Maybe<MillerCollapsibleInLocationInput>;
};

export type SubCommodity = {
  __typename?: 'SubCommodity';
  commodity?: Maybe<Commodity>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of SubmitAddendum */
export type SubmitAddendumPayload = {
  __typename?: 'SubmitAddendumPayload';
  /** The Addendum Header */
  addendumHeader?: Maybe<AddendumHeader>;
  /**
   * Errors
   * @deprecated no longer used
   */
  errors?: Maybe<Scalars['String']>;
  /** Validation information */
  validation?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SubmitEphyto */
export type SubmitEphytoPayload = {
  __typename?: 'SubmitEphytoPayload';
  /** The Ephyto */
  ephyto?: Maybe<Ephyto>;
  /** Any errors when attempting to submit */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SubmitShipmentExportNotification */
export type SubmitShipmentExportNotificationPayload = {
  __typename?: 'SubmitShipmentExportNotificationPayload';
  /** The errors that occured */
  errors?: Maybe<Scalars['String']>;
  /** The current shipment */
  shipment?: Maybe<Shipment>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newOrder: Order;
  newUserFeedItem: UserFeedItem;
  onAcceptLineItems: Order;
  /** Triggered on state change for Addendum generation */
  onAddendumHeaderStateChange: AddendumHeader;
  /** Triggered on state change for Pallet In file generation */
  onPalletInOutputStateChange: PalletIn;
  /** Triggered on state change for Pallet Out file generation */
  onPalletOutOutputStateChange: PalletOut;
  onPremCommands: Array<Command>;
  /** Triggered when data for a given report is ready */
  onReportComplete: Status;
  onRevertLineItemsToCreated: Order;
  /** Triggered on state change for Export Notification */
  onShipmentStateChange: Shipment;
  /** Triggered on state change for template output */
  onTemplateOutputStateChange: TemplateOutput;
  onWontPackLineItems: Order;
};

export enum SupplyChainLegEnum {
  Exw = 'EXW',
  Dip = 'DIP',
  Fca = 'FCA',
  Fas = 'FAS',
  Fob = 'FOB',
  Cfr = 'CFR',
  Cif = 'CIF',
  Fot = 'FOT',
  Dap = 'DAP',
  Ddp = 'DDP',
  AccountSale = 'ACCOUNT_SALE',
  Invoice = 'INVOICE'
}

/** Fields defining a generic tab model */
export type Tab = {
  /** tab label - as displayed on front end */
  label?: Maybe<Scalars['String']>;
  /** filters that are applied by this tab */
  tabFilters?: Maybe<Scalars['Json']>;
  /** filters that are required to be set for this tab to be displayed on the front end */
  requiredFilters?: Maybe<Scalars['Json']>;
  /** layout to set/override the report group to */
  layout?: Maybe<Scalars['String']>;
};

export type TaskableTask = {
  __typename?: 'TaskableTask';
  /** DateTime when this document was uploaded. Null if never */
  customDate?: Maybe<Scalars['ISO8601DateTime']>;
  /** The document that was uploaded against this task */
  file?: Maybe<Document>;
  /** Whether this task requires a document */
  hasDocument?: Maybe<Scalars['Boolean']>;
  /** Unique identifier for the task */
  id: Scalars['ID'];
  /** Index indicating order of tasks */
  index: Scalars['Int'];
  /** The id to which this task belongs */
  taskableId: Scalars['ID'];
  /** The class to which this task belongs */
  taskableType: Scalars['String'];
  /** Title of the task */
  title: Scalars['String'];
  /** DateTime when this document was uploaded. Null if never */
  uploadedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Contains information on template generation output */
export type TemplateOutput = {
  __typename?: 'TemplateOutput';
  /** Provides further detail if state is errored */
  errorReason?: Maybe<Scalars['String']>;
  /** Unique identifier of this object */
  id?: Maybe<Scalars['ID']>;
  /** The documents produced from template generation */
  outputFiles?: Maybe<Array<Document>>;
  /** State of the template generation (created, processing, completed) */
  state?: Maybe<Scalars['String']>;
  /** Unique identifier of the data source associated with this template output */
  templateDataSourceId?: Maybe<Scalars['String']>;
  /** Data source type associated with this template output */
  templateDataSourceType?: Maybe<Scalars['String']>;
  /** Document variant generated for this template output */
  templateVariant?: Maybe<Scalars['String']>;
  /** Timestamp representing the date this document was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Terminal = {
  __typename?: 'Terminal';
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type TradeCountry = {
  __typename?: 'TradeCountry';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TradeRegion = {
  __typename?: 'TradeRegion';
  code?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<TradeCountry>>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TradeRegionAttributes = {
  id: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export enum TransportTypeEnum {
  /** The transport type trucking */
  Trucking = 'trucking',
  /** The transport type break_bulk */
  BreakBulk = 'break_bulk',
  /** The transport type container */
  Container = 'container',
  /** The transport type air */
  Air = 'air'
}

export type UiConfig = {
  __typename?: 'UiConfig';
  configType: Scalars['String'];
  data: Scalars['Json'];
  id: Scalars['Int'];
  readonly: Scalars['Boolean'];
  visibility: UiConfigVisibilityEnum;
};

export enum UiConfigVisibilityEnum {
  Private = 'Private',
  Company = 'Company',
  Public = 'Public'
}

/** Autogenerated return type of UpdateAddendum */
export type UpdateAddendumPayload = {
  __typename?: 'UpdateAddendumPayload';
  /** Errors that occurred while updating the Addendum */
  errors?: Maybe<Scalars['String']>;
};

/** Basic attributes for updating an additional field */
export type UpdateAdditionalFieldAttributes = {
  description?: Maybe<Scalars['String']>;
  fieldtype: Scalars['String'];
  ispackinginstruction?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parameters?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateAdditionalField */
export type UpdateAdditionalFieldPayload = {
  __typename?: 'UpdateAdditionalFieldPayload';
  additionalField?: Maybe<AdditionalField>;
};

/** Autogenerated return type of UpdateAirAddendum */
export type UpdateAirAddendumPayload = {
  __typename?: 'UpdateAirAddendumPayload';
  /** Errors that occurred while updating the Addendum */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAirFreightShipmentController */
export type UpdateAirFreightShipmentControllerPayload = {
  __typename?: 'UpdateAirFreightShipmentControllerPayload';
  /** Errors that occurred while updating the Air Freight Shipment controller */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCartonGroupingTransactions */
export type UpdateCartonGroupingTransactionsPayload = {
  __typename?: 'UpdateCartonGroupingTransactionsPayload';
  /** Summarized result of create changes. */
  createSummary?: Maybe<CudSummary>;
  /** Summarized result of delete changes. */
  deleteSummary?: Maybe<CudSummary>;
  /** Summarized result of update changes. */
  updateSummary?: Maybe<CudSummary>;
};

/** Autogenerated return type of UpdateCommandResult */
export type UpdateCommandResultPayload = {
  __typename?: 'UpdateCommandResultPayload';
  result?: Maybe<Scalars['String']>;
};

/** Basic attributes for a updating company */
export type UpdateCompanyAttributes = {
  id: Scalars['Int'];
  name: Scalars['String'];
  about?: Maybe<Scalars['String']>;
  registrationnumber: Scalars['String'];
  vatnumber: Scalars['String'];
  address?: Maybe<AddressAttributes>;
};

/** Autogenerated return type of UpdateCompanyDetails */
export type UpdateCompanyDetailsPayload = {
  __typename?: 'UpdateCompanyDetailsPayload';
  company?: Maybe<Company>;
};

/** Autogenerated return type of UpdateCompanyTradeRegions */
export type UpdateCompanyTradeRegionsPayload = {
  __typename?: 'UpdateCompanyTradeRegionsPayload';
  company?: Maybe<Company>;
};

/** Autogenerated return type of UpdateContact */
export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  contact?: Maybe<Contact>;
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCustomField */
export type UpdateCustomFieldPayload = {
  __typename?: 'UpdateCustomFieldPayload';
  /** The custom field */
  customField?: Maybe<CustomField>;
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateDataOutput */
export type UpdateDataOutputPayload = {
  __typename?: 'UpdateDataOutputPayload';
  /** The updated Data Output */
  dataOutput?: Maybe<DataOutput>;
  /** Errors that occurred during the update */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateDraftEphyto */
export type UpdateDraftEphytoPayload = {
  __typename?: 'UpdateDraftEphytoPayload';
  /** The Ephyto */
  ephyto?: Maybe<Ephyto>;
  /** Any errors when attempting to update the draft */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateEphytoDocument */
export type UpdateEphytoDocumentPayload = {
  __typename?: 'UpdateEphytoDocumentPayload';
  /** Any errors when updating the Ephyto Document */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateEphyto */
export type UpdateEphytoPayload = {
  __typename?: 'UpdateEphytoPayload';
  /** Errors that occurred while updating the Ephyto */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateEstimates */
export type UpdateEstimatesPayload = {
  __typename?: 'UpdateEstimatesPayload';
  /** Summarized result of create changes. */
  createSummary?: Maybe<CudSummary>;
  /** Summarized result of delete changes. */
  deleteSummary?: Maybe<CudSummary>;
  /** Summarized result of update changes. */
  updateSummary?: Maybe<CudSummary>;
};

/** Autogenerated return type of UpdateExportDocument */
export type UpdateExportDocumentPayload = {
  __typename?: 'UpdateExportDocumentPayload';
  exportDocument?: Maybe<ExportDocument>;
};

/** Basic attributes for updating a FarmVariety */
export type UpdateFarmVarietyAttributes = {
  companyId: Scalars['Int'];
  hectares?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  packingend: Scalars['String'];
  packingstart: Scalars['String'];
  peakcount?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateFarmVariety */
export type UpdateFarmVarietyPayload = {
  __typename?: 'UpdateFarmVarietyPayload';
  farmVariety?: Maybe<FarmVariety>;
};

/** Autogenerated return type of UpdateInvoice */
export type UpdateInvoicePayload = {
  __typename?: 'UpdateInvoicePayload';
  errors?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

export type UpdateLineItemAttributes = {
  id?: Maybe<Scalars['Int']>;
  varietyid: Scalars['Int'];
  commodityid: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
  plannedquantity?: Maybe<Scalars['Int']>;
  plannedquantityunit?: Maybe<Scalars['String']>;
  quantityunit?: Maybe<Scalars['String']>;
  priceminorunit?: Maybe<Scalars['Int']>;
  priceunit?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  sizecounts: Array<Scalars['String']>;
  pack: Scalars['String'];
  priceterm: Scalars['String'];
  additionalfields: Scalars['String'];
  palletstack: Scalars['Int'];
  unlimited: Scalars['Boolean'];
  targetmarket?: Maybe<Scalars['String']>;
  targetregion: Scalars['String'];
  targetcountry?: Maybe<Scalars['String']>;
  packingweek?: Maybe<Scalars['String']>;
  startweek?: Maybe<Scalars['String']>;
  endweek?: Maybe<Scalars['String']>;
  incoterm: Scalars['String'];
  grade?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  mark?: Maybe<Scalars['String']>;
  referencenumber?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  batchnumber?: Maybe<Scalars['String']>;
  inventorycode?: Maybe<Scalars['String']>;
  orderid?: Maybe<Scalars['Int']>;
  lineitemgroupingid?: Maybe<Scalars['Int']>;
  selected?: Maybe<Scalars['Boolean']>;
  stateupdate?: Maybe<Array<Scalars['String']>>;
  newsellerid?: Maybe<Scalars['Int']>;
};

export type UpdateLineItemGroupingAttributes = {
  id?: Maybe<Scalars['Int']>;
  cumulativequantity?: Maybe<Scalars['Int']>;
  quantitytype?: Maybe<Scalars['String']>;
  commonfields: Array<Scalars['String']>;
  lineitems: Array<UpdateLineItemAttributes>;
  deleted?: Maybe<Scalars['Boolean']>;
  rank?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateLineItems */
export type UpdateLineItemsPayload = {
  __typename?: 'UpdateLineItemsPayload';
  errors?: Maybe<Scalars['String']>;
  lineItems?: Maybe<Array<LineItem>>;
};

/** Autogenerated return type of UpdateLoadOutInstruction */
export type UpdateLoadOutInstructionPayload = {
  __typename?: 'UpdateLoadOutInstructionPayload';
  errors?: Maybe<Scalars['String']>;
  loadOutInstruction?: Maybe<LoadOutInstruction>;
};

/** Autogenerated return type of UpdateOrchardSeasons */
export type UpdateOrchardSeasonsPayload = {
  __typename?: 'UpdateOrchardSeasonsPayload';
  /** Summarized result of create changes. */
  createSummary?: Maybe<CudSummary>;
  /** Summarized result of delete changes. */
  deleteSummary?: Maybe<CudSummary>;
  /** Summarized result of update changes. */
  updateSummary?: Maybe<CudSummary>;
};

/** Autogenerated return type of UpdateOrchardYields */
export type UpdateOrchardYieldsPayload = {
  __typename?: 'UpdateOrchardYieldsPayload';
  /** Summarized result of create changes. */
  createSummary?: Maybe<CudSummary>;
  /** Summarized result of delete changes. */
  deleteSummary?: Maybe<CudSummary>;
  /** Summarized result of update changes. */
  updateSummary?: Maybe<CudSummary>;
};

export type UpdateOrderAttributes = {
  id: Scalars['Int'];
  ispackhouserun: Scalars['Boolean'];
  commonfields?: Maybe<Array<Scalars['String']>>;
  lineitemgroupings?: Maybe<Array<UpdateLineItemGroupingAttributes>>;
};

/** Autogenerated return type of UpdateOrder */
export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload';
  errors?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

/** Autogenerated return type of UpdateReportGroup */
export type UpdateReportGroupPayload = {
  __typename?: 'UpdateReportGroupPayload';
  /** The errors that occurred */
  errors?: Maybe<Scalars['String']>;
  /** The report group */
  reportGroup?: Maybe<ReportGroup>;
};

/** Autogenerated return type of UpdateShipmentDocument */
export type UpdateShipmentDocumentPayload = {
  __typename?: 'UpdateShipmentDocumentPayload';
  shipmentDocument?: Maybe<ShipmentDocument>;
};

/** Autogenerated return type of UpdateShipmentLineItem */
export type UpdateShipmentLineItemPayload = {
  __typename?: 'UpdateShipmentLineItemPayload';
  errors?: Maybe<Scalars['String']>;
  shipmentLineItem?: Maybe<ShipmentLineItem>;
};

/** Autogenerated return type of UpdateShipmentLineItemTasks */
export type UpdateShipmentLineItemTasksPayload = {
  __typename?: 'UpdateShipmentLineItemTasksPayload';
  /** Errors encountered */
  errors?: Maybe<Scalars['String']>;
  /** The updated shipment line item */
  shipmentLineItems?: Maybe<Array<ShipmentLineItem>>;
};

/** Autogenerated return type of UpdateShipment */
export type UpdateShipmentPayload = {
  __typename?: 'UpdateShipmentPayload';
  errors?: Maybe<Scalars['String']>;
  shipment?: Maybe<Shipment>;
};

/** Autogenerated return type of UpdateTasksDates */
export type UpdateTasksDatesPayload = {
  __typename?: 'UpdateTasksDatesPayload';
  /** Error information when attempting to update task dates */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUiConfig */
export type UpdateUiConfigPayload = {
  __typename?: 'UpdateUiConfigPayload';
  config?: Maybe<UiConfig>;
};

/** Basic attributes for a updating UserFeedItem */
export type UpdateUserFeedItemAttributes = {
  id: Scalars['Int'];
  read?: Maybe<Scalars['Boolean']>;
  seen?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateUserFeedItem */
export type UpdateUserFeedItemPayload = {
  __typename?: 'UpdateUserFeedItemPayload';
  userFeedItem?: Maybe<UserFeedItem>;
};

/** Autogenerated return type of UpdateUserFeedItems */
export type UpdateUserFeedItemsPayload = {
  __typename?: 'UpdateUserFeedItemsPayload';
  userFeedItems?: Maybe<Array<UserFeedItem>>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<User>;
};


/** Autogenerated return type of UploadCompanyCertificate */
export type UploadCompanyCertificatePayload = {
  __typename?: 'UploadCompanyCertificatePayload';
  certificate?: Maybe<CompanyCertificate>;
};

/** Autogenerated return type of UploadCompanyLogo */
export type UploadCompanyLogoPayload = {
  __typename?: 'UploadCompanyLogoPayload';
  company?: Maybe<Company>;
};

/** Autogenerated return type of UploadEphytoDocument */
export type UploadEphytoDocumentPayload = {
  __typename?: 'UploadEphytoDocumentPayload';
  /** Ephyto docs on ephyto returned to update UI */
  ephytoDocuments?: Maybe<Array<EphytoDocument>>;
  /** Error information when attempting to upload documents */
  errors?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UploadEtlDocument */
export type UploadEtlDocumentPayload = {
  __typename?: 'UploadEtlDocumentPayload';
  document?: Maybe<EtlDocument>;
};

/** Autogenerated return type of UploadExportDocument */
export type UploadExportDocumentPayload = {
  __typename?: 'UploadExportDocumentPayload';
  errors?: Maybe<Scalars['String']>;
  shipmentLineItem?: Maybe<ShipmentLineItem>;
};

/** Autogenerated return type of UploadFarmVarietyImage */
export type UploadFarmVarietyImagePayload = {
  __typename?: 'UploadFarmVarietyImagePayload';
  farmVariety?: Maybe<FarmVariety>;
};

/** Autogenerated return type of UploadOrderShipmentDocument */
export type UploadOrderShipmentDocumentPayload = {
  __typename?: 'UploadOrderShipmentDocumentPayload';
  order?: Maybe<Order>;
};

/** Autogenerated return type of UploadTaskDocument */
export type UploadTaskDocumentPayload = {
  __typename?: 'UploadTaskDocumentPayload';
  /** Error information when attempting to upload documents */
  errors?: Maybe<Scalars['String']>;
  /** Task returned to update UI */
  task?: Maybe<TaskableTask>;
};

/** Autogenerated return type of UploadUserAvatar */
export type UploadUserAvatarPayload = {
  __typename?: 'UploadUserAvatarPayload';
  user?: Maybe<User>;
};

/** Autogenerated return type of UpsertCertificateTransportInstruction */
export type UpsertCertificateTransportInstructionPayload = {
  __typename?: 'UpsertCertificateTransportInstructionPayload';
  /** The CTI together with the state of the file generation */
  certificateTransportInstruction?: Maybe<CertificateTransportInstruction>;
  /** Errors received if upsert fails */
  errors?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  avatarFile?: Maybe<Document>;
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  employee?: Maybe<Employee>;
  employeeId?: Maybe<Scalars['Int']>;
  externalIdentifier?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  notifyBrowserPush?: Maybe<Scalars['Boolean']>;
  notifyEmail?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Permissions>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  useV2?: Maybe<Scalars['Boolean']>;
};

export type UserFeedItem = {
  __typename?: 'UserFeedItem';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  notifiable?: Maybe<NotifiableUnion>;
  read?: Maybe<Scalars['Boolean']>;
  seen?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** The connection type for UserFeedItem. */
export type UserFeedItemConnection = {
  __typename?: 'UserFeedItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserFeedItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserFeedItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserFeedItemEdge = {
  __typename?: 'UserFeedItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserFeedItem>;
};

export type Variety = {
  __typename?: 'Variety';
  commodity?: Maybe<Commodity>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['Int']>;
  /** The full name of the variety, including sub-commodity and variety code */
  name?: Maybe<Scalars['String']>;
  subCommodity?: Maybe<SubCommodity>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** variety code stored on the variety */
  varietyCode?: Maybe<Scalars['String']>;
  /** The full name of the variety, including sub-commodity and variety code */
  varietyName?: Maybe<Scalars['String']>;
};

export type Vessel = {
  __typename?: 'Vessel';
  destination?: Maybe<Scalars['String']>;
  eta?: Maybe<Scalars['String']>;
  finalDestination?: Maybe<Scalars['String']>;
  finalEta?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  locationType?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  origin?: Maybe<Scalars['String']>;
  vesselName?: Maybe<Scalars['String']>;
  voyageNumber?: Maybe<Scalars['String']>;
};

export type VesselAttributes = {
  id?: Maybe<Scalars['Int']>;
  vesselname?: Maybe<Scalars['String']>;
};

export type Voyage = {
  __typename?: 'Voyage';
  arrivalTime?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  departureTime?: Maybe<Scalars['ISO8601DateTime']>;
  estimatedTimeOfArrival?: Maybe<Scalars['ISO8601DateTime']>;
  estimatedTimeOfDeparture?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['ID']>;
  locations?: Maybe<Array<Location>>;
  portOfDestination?: Maybe<SeaPort>;
  portOfOrigin?: Maybe<SeaPort>;
  updatedAt: Scalars['ISO8601DateTime'];
  vesselCallSign?: Maybe<Scalars['String']>;
  vesselImo?: Maybe<Scalars['String']>;
  vesselMmsi?: Maybe<Scalars['String']>;
  vesselName?: Maybe<Scalars['String']>;
  voyageNumber?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of WontPackLineItems */
export type WontPackLineItemsPayload = {
  __typename?: 'WontPackLineItemsPayload';
  orders?: Maybe<Array<Order>>;
};

export type CreateAdditionalFieldMutationVariables = Exact<{
  attributes: CreateAdditionalFieldAttributes;
}>;


export type CreateAdditionalFieldMutation = (
  { __typename?: 'Mutation' }
  & { createAdditionalField?: Maybe<(
    { __typename?: 'CreateAdditionalFieldPayload' }
    & { additionalField?: Maybe<(
      { __typename?: 'AdditionalField' }
      & Pick<AdditionalField, 'id' | 'companyId' | 'name' | 'description' | 'fieldType' | 'parameters' | 'isPackingInstruction' | 'private'>
    )> }
  )> }
);

export type DeleteAdditionalFieldMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAdditionalFieldMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdditionalField?: Maybe<(
    { __typename?: 'DeleteAdditionalFieldPayload' }
    & Pick<DeleteAdditionalFieldPayload, 'id'>
  )> }
);

export type UpdateAdditionalFieldMutationVariables = Exact<{
  id: Scalars['Int'];
  attributes: UpdateAdditionalFieldAttributes;
}>;


export type UpdateAdditionalFieldMutation = (
  { __typename?: 'Mutation' }
  & { updateAdditionalField?: Maybe<(
    { __typename?: 'UpdateAdditionalFieldPayload' }
    & { additionalField?: Maybe<(
      { __typename?: 'AdditionalField' }
      & Pick<AdditionalField, 'id' | 'name' | 'description' | 'fieldType' | 'parameters' | 'isPackingInstruction' | 'private'>
    )> }
  )> }
);

export type AdditionalFieldsQueryVariables = Exact<{
  companyids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AdditionalFieldsQuery = (
  { __typename?: 'Query' }
  & { additionalFields?: Maybe<Array<(
    { __typename?: 'AdditionalField' }
    & Pick<AdditionalField, 'id' | 'name' | 'description' | 'fieldType' | 'parameters' | 'isPackingInstruction' | 'private'>
  )>> }
);

export type AirAddendumHeaderByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AirAddendumHeaderByIdQuery = (
  { __typename?: 'Query' }
  & { addendumHeaderById?: Maybe<(
    { __typename?: 'AddendumHeader' }
    & { addendumLineItems?: Maybe<Array<(
      { __typename?: 'AddendumLineItem' }
      & AirAddendumLineItemFieldsFragment
    )>> }
    & AirAddendumHeaderFieldsFragment
  )> }
);

export type AddendumsOnAirFreightShipmentQueryVariables = Exact<{
  airFreightShipmentId: Scalars['Int'];
}>;


export type AddendumsOnAirFreightShipmentQuery = (
  { __typename?: 'Query' }
  & { addendumsOnAirFreightShipment?: Maybe<Array<(
    { __typename?: 'AddendumHeader' }
    & Pick<AddendumHeader, 'id'>
    & { loadOutInstructions?: Maybe<Array<(
      { __typename?: 'LoadOutInstruction' }
      & Pick<LoadOutInstruction, 'id' | 'loadOutReference'>
      & { airFreightShipmentLineItem?: Maybe<(
        { __typename?: 'AirFreightShipmentLineItem' }
        & Pick<AirFreightShipmentLineItem, 'externalShipmentId'>
        & { airFreightShipment?: Maybe<(
          { __typename?: 'AirFreightShipment' }
          & Pick<AirFreightShipment, 'masterWaybillNumber'>
        )> }
      )> }
    )>> }
  )>> }
);

export type AirFreightShipmentLoisQueryVariables = Exact<{
  airFreightShipmentId: Scalars['ID'];
}>;


export type AirFreightShipmentLoisQuery = (
  { __typename?: 'Query' }
  & { airFreightShipmentLois?: Maybe<Array<(
    { __typename?: 'LoadOutInstruction' }
    & Pick<LoadOutInstruction, 'id' | 'loadOutReference' | 'airWaybillNo'>
  )>> }
);

export type GenerateAirAddendumMutationVariables = Exact<{
  loiIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GenerateAirAddendumMutation = (
  { __typename?: 'Mutation' }
  & { generateAirAddendum?: Maybe<(
    { __typename?: 'GenerateAirAddendumPayload' }
    & Pick<GenerateAirAddendumPayload, 'errors'>
    & { addendumHeader?: Maybe<(
      { __typename?: 'AddendumHeader' }
      & { addendumLineItems?: Maybe<Array<(
        { __typename?: 'AddendumLineItem' }
        & AirAddendumLineItemFieldsFragment
      )>> }
      & AirAddendumHeaderFieldsFragment
    )> }
  )> }
);

export type UpdateAirAddendumMutationVariables = Exact<{
  addendumLineItems: Array<AddendumLineItemAttributes> | AddendumLineItemAttributes;
  addendumHeader: AddendumHeaderAttributes;
}>;


export type UpdateAirAddendumMutation = (
  { __typename?: 'Mutation' }
  & { updateAirAddendum?: Maybe<(
    { __typename?: 'UpdateAirAddendumPayload' }
    & Pick<UpdateAirAddendumPayload, 'errors'>
  )> }
);

export type CancelAirAddendumMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CancelAirAddendumMutation = (
  { __typename?: 'Mutation' }
  & { cancelAirAddendum?: Maybe<(
    { __typename?: 'CancelAirAddendumPayload' }
    & Pick<CancelAirAddendumPayload, 'id' | 'message'>
  )> }
);

export type GenerateAddendumHeaderOutputFilesMutationVariables = Exact<{
  addendumHeaderId: Scalars['ID'];
}>;


export type GenerateAddendumHeaderOutputFilesMutation = (
  { __typename?: 'Mutation' }
  & { generateAddendumHeaderOutputFiles?: Maybe<(
    { __typename?: 'GenerateAddendumHeaderOutputFilesPayload' }
    & { templateOutput?: Maybe<Array<(
      { __typename?: 'TemplateOutput' }
      & Pick<TemplateOutput, 'errorReason' | 'id' | 'state'>
    )>> }
  )> }
);

export type GeneratePalletOutOutputFilesMutationVariables = Exact<{
  addendumHeaderId: Scalars['ID'];
}>;


export type GeneratePalletOutOutputFilesMutation = (
  { __typename?: 'Mutation' }
  & { generatePalletOutOutputFiles?: Maybe<(
    { __typename?: 'GeneratePalletOutOutputFilesPayload' }
    & Pick<GeneratePalletOutOutputFilesPayload, 'palletOutId'>
  )> }
);

export type OnPalletOutOutputStateChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnPalletOutOutputStateChangeSubscription = (
  { __typename?: 'Subscription' }
  & { onPalletOutOutputStateChange: (
    { __typename?: 'PalletOut' }
    & PalletOutFieldsFragment
  ) }
);

export type PalletOutFieldsFragment = (
  { __typename?: 'PalletOut' }
  & Pick<PalletOut, 'id' | 'addendumHeaderId' | 'state' | 'updatedAt'>
  & { outputFile?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'contentType' | 'filename' | 'v2Url' | 'id' | 'url'>
  )> }
);

export type AirAddendumHeaderFieldsFragment = (
  { __typename?: 'AddendumHeader' }
  & Pick<AddendumHeader, 'id' | 'requestId' | 'exporterCode' | 'cfCode' | 'lspCode' | 'transportType' | 'vesselName' | 'vesselType' | 'voyageNumber' | 'exportCertificateNumber' | 'exportFromCapeTown' | 'exportFromDurban' | 'exportFromMaputo' | 'exportFromOther' | 'exportFromPortElizabeth' | 'exporterAgentDate' | 'exporterAgentName' | 'forwardingAgentDate' | 'forwardingAgentName' | 'disAgentDate' | 'disAgentName' | 'inlandInspectorDate' | 'inlandInspectorName' | 'portInspectorDate' | 'portInspectorName' | 'shippingBookingReference' | 'loadPort' | 'dischargePort' | 'shippedTargetCountry' | 'shippedTargetRegion' | 'locationOfIssue' | 'estimatedDepartureDate' | 'eCertAgreementCode' | 'verificationStatus' | 'addendumStatus' | 'ecertStatus' | 'transactionId' | 'errorReason' | 'state' | 'printedName' | 'ppecbCertificateNumber'>
  & { templateOutput?: Maybe<Array<(
    { __typename?: 'TemplateOutput' }
    & TemplateOutputFieldsFragment
  )>>, palletOut?: Maybe<(
    { __typename?: 'PalletOut' }
    & PalletOutFieldsFragment
  )> }
);

export type AirAddendumLineItemFieldsFragment = (
  { __typename?: 'AddendumLineItem' }
  & Pick<AddendumLineItem, 'id' | 'stuffLoadDate' | 'loadPointFboCode' | 'consignmentNumber' | 'phc' | 'inspectedSscc' | 'clientRef' | 'upn' | 'inspectedTargetRegion' | 'commodityCode' | 'varietyCode' | 'productClass' | 'nettWeight' | 'grossWeight' | 'ssccNettWeight' | 'ssccGrossWeight' | 'cartonQuantity' | 'inspectionPoint' | 'inspectorCode' | 'inspectionDate' | 'containerNumber' | 'sequenceNumberOfInspectedSscc' | 'puc' | 'orchard' | 'productionArea' | 'phytoData' | 'sizeCountBerrySize' | 'packCode' | 'palletQuantity' | 'fleshColor' | 'inspectionManifestNumber'>
);

export type AirFreightShipmentsQueryVariables = Exact<{
  filterAttributes: AirFreightShipmentsFilter;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes> | ColumnOrderAttributes>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type AirFreightShipmentsQuery = (
  { __typename?: 'Query' }
  & { airFreightShipments?: Maybe<(
    { __typename?: 'AirFreightShipmentConnection' }
    & Pick<AirFreightShipmentConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'AirFreightShipment' }
      & AirFreightShipmentFieldsFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type AirFreightShipmentsFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type AirFreightShipmentsFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { airFreightShipmentsFilterAttributes?: Maybe<(
    { __typename?: 'AirFreightShipmentsFilterAttribute' }
    & Pick<AirFreightShipmentsFilterAttribute, 'carrierNames' | 'controllers' | 'flightNumbers' | 'states' | 'shippers' | 'masterWaybillNumbers'>
  )> }
);

export type AirFreightShipmentLineItemsByShipmentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AirFreightShipmentLineItemsByShipmentQuery = (
  { __typename?: 'Query' }
  & { airFreightShipmentLineItemsByShipment?: Maybe<Array<(
    { __typename?: 'AirFreightShipmentLineItem' }
    & { airFreightShipment?: Maybe<(
      { __typename?: 'AirFreightShipment' }
      & Pick<AirFreightShipment, 'id'>
    )> }
    & AirFreightShipmentLineItemFieldsFragment
  )>> }
);

export type AirFreightShipmentByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AirFreightShipmentByIdQuery = (
  { __typename?: 'Query' }
  & { airFreightShipmentById?: Maybe<(
    { __typename?: 'AirFreightShipment' }
    & { airFreightShipmentLineItems?: Maybe<Array<(
      { __typename?: 'AirFreightShipmentLineItem' }
      & AirFreightShipmentLineItemFieldsFragment
    )>> }
    & AirFreightShipmentFieldsFragment
  )> }
);

export type NestedAirFreightShipmentLineItemsQueryVariables = Exact<{
  airFreightShipmentId: Scalars['ID'];
}>;


export type NestedAirFreightShipmentLineItemsQuery = (
  { __typename?: 'Query' }
  & { nestedAirFreightShipmentLineItems?: Maybe<Array<(
    { __typename?: 'AirFreightShipmentLineItem' }
    & Pick<AirFreightShipmentLineItem, 'bookingReference' | 'collectionDate' | 'comments' | 'commodityCode' | 'consignee' | 'consignor' | 'eta' | 'externalShipmentId' | 'finalDestinationCode' | 'finalDestinationLocation' | 'finalPortOfDischargeCode' | 'finalPortOfDischargeLocation' | 'goodsDescription' | 'grossDimensions' | 'grossWeight' | 'housebill' | 'id' | 'isTransshipment' | 'natureOfGoods' | 'packageQuantity' | 'packageType' | 'orderReference' | 'specialHandling' | 'transshipmentPort' | 'transshipmentCarrierName' | 'transshipmentFlightNumber' | 'volume' | 'lastStatusMessage' | 'state'>
  )>> }
);

export type UnallocatedAirFreightShipmentLineItemsQueryVariables = Exact<{
  orderByFields?: Maybe<Array<ColumnOrderAttributes> | ColumnOrderAttributes>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type UnallocatedAirFreightShipmentLineItemsQuery = (
  { __typename?: 'Query' }
  & { unallocatedAirFreightShipmentLineItems?: Maybe<(
    { __typename?: 'AirFreightShipmentLineItemConnection' }
    & Pick<AirFreightShipmentLineItemConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'AirFreightShipmentLineItem' }
      & Pick<AirFreightShipmentLineItem, 'bookingReference' | 'collectionDate' | 'comments' | 'commodityCode' | 'consignee' | 'consignor' | 'createdAt' | 'eta' | 'externalShipmentId' | 'finalDestinationCode' | 'finalDestinationLocation' | 'finalPortOfDischargeCode' | 'finalPortOfDischargeLocation' | 'goodsDescription' | 'grossDimensions' | 'grossWeight' | 'housebill' | 'id' | 'isTransshipment' | 'natureOfGoods' | 'packageQuantity' | 'packageType' | 'orderReference' | 'specialHandling' | 'lastStatusMessage' | 'state' | 'transshipmentPort' | 'transshipmentCarrierName' | 'transshipmentFlightNumber' | 'updatedAt' | 'volume'>
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type EmployeesQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeesQuery = (
  { __typename?: 'Query' }
  & { employees?: Maybe<Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'userFirstName' | 'userLastName'>
  )>> }
);

export type AirFreightShipmentFieldsFragment = (
  { __typename?: 'AirFreightShipment' }
  & Pick<AirFreightShipment, 'id' | 'externalTransportMasterId' | 'createdAt' | 'updatedAt' | 'shipmentType' | 'carrierName' | 'flightNumber' | 'masterWaybillNumber' | 'shipper' | 'consignor' | 'consignee' | 'portOfLoadingCode' | 'portOfLoadingLocation' | 'forwarderName' | 'departureAirport' | 'estimatedDepartureTime' | 'actualDepartureTime' | 'portOfDestination' | 'estimatedArrivalTime' | 'actualArrivalTime' | 'controller' | 'userName' | 'phoneNumber' | 'state' | 'billToParty' | 'exporterCode' | 'fboCode'>
);

export type AirFreightShipmentLineItemFieldsFragment = (
  { __typename?: 'AirFreightShipmentLineItem' }
  & Pick<AirFreightShipmentLineItem, 'id' | 'externalShipmentId' | 'orderReference' | 'bookingReference' | 'consignee' | 'consignor' | 'finalPortOfDischargeCode' | 'finalPortOfDischargeLocation' | 'finalDestinationCode' | 'finalDestinationLocation' | 'eta' | 'collectionDate' | 'isTransshipment' | 'transshipmentCarrierName' | 'transshipmentFlightNumber' | 'transshipmentPort' | 'specialHandling' | 'comments' | 'packageType' | 'packageQuantity' | 'commodityCode' | 'goodsDescription' | 'natureOfGoods' | 'grossWeight' | 'volume' | 'grossDimensions' | 'housebill' | 'lastStatusMessage' | 'state'>
);

export type CompleteAirFreightShipmentMutationVariables = Exact<{
  completeAirFreightShipmentId: Scalars['Int'];
}>;


export type CompleteAirFreightShipmentMutation = (
  { __typename?: 'Mutation' }
  & { completeAirFreightShipment?: Maybe<(
    { __typename?: 'CompleteAirFreightShipmentPayload' }
    & Pick<CompleteAirFreightShipmentPayload, 'errors'>
  )> }
);

export type UpdateAirFreightShipmentControllerMutationVariables = Exact<{
  airFreightShipmentId: Scalars['Int'];
  controllerId?: Maybe<Scalars['Int']>;
  controllerName?: Maybe<Scalars['String']>;
}>;


export type UpdateAirFreightShipmentControllerMutation = (
  { __typename?: 'Mutation' }
  & { updateAirFreightShipmentController?: Maybe<(
    { __typename?: 'UpdateAirFreightShipmentControllerPayload' }
    & Pick<UpdateAirFreightShipmentControllerPayload, 'errors'>
  )> }
);

export type AnalysisDownloadsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalysisDownloadsQuery = (
  { __typename?: 'Query' }
  & { analysisDownloads?: Maybe<Array<(
    { __typename?: 'AnalysisDownload' }
    & Pick<AnalysisDownload, 'downloadLink' | 'fileName' | 'id' | 'size'>
    & { commodities?: Maybe<Array<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'name'>
    )>> }
  )>> }
);

export type CartonCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type CartonCountsQuery = (
  { __typename?: 'Query' }
  & { cartonCounts?: Maybe<Array<(
    { __typename?: 'CartonCount' }
    & Pick<CartonCount, 'id' | 'name' | 'commodityId' | 'cartonTypeId' | 'counts' | 'priceMetric' | 'stackSizes'>
  )>> }
);

export type BaseCartonGroupingFieldsFragment = (
  { __typename?: 'CartonGrouping' }
  & Pick<CartonGrouping, 'id' | 'containerNumber' | 'state' | 'cartons' | 'palletNumber' | 'grade' | 'batchNumber' | 'sizeCount' | 'advancePrice' | 'finalPrice' | 'advanceDueDate' | 'finalDueDate'>
  & { lineItem?: Maybe<(
    { __typename?: 'LineItem' }
    & Pick<LineItem, 'currency' | 'incoterm' | 'mark' | 'orderId' | 'priceMinorUnit' | 'priceUnit' | 'referenceNumber'>
  )>, buyer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, seller?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )> }
);

export type CartonGroupingFragment = (
  { __typename?: 'CartonGrouping' }
  & Pick<CartonGrouping, 'targetRegion' | 'targetMarket' | 'targetCountry' | 'pack' | 'palletStack' | 'packhouseCode' | 'orchard' | 'containerNumber' | 'exporterCode' | 'farmCode' | 'packingWeek' | 'lineItemId' | 'consignmentNumber' | 'inventoryCode' | 'sequenceNumber' | 'coldStore' | 'productCharacteristic'>
  & { variety?: Maybe<(
    { __typename?: 'Variety' }
    & Pick<Variety, 'id' | 'name'>
    & { commodity?: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )> }
  )> }
  & BaseCartonGroupingFieldsFragment
);

export type CashFlowCartonGroupingFragment = (
  { __typename?: 'CartonGrouping' }
  & Pick<CartonGrouping, 'targetRegion' | 'targetMarket' | 'palletGrossMass'>
  & BaseCartonGroupingFieldsFragment
);

export type CartonGroupingsQueryVariables = Exact<{
  attributes: CartonGroupingSearchAttributes;
}>;


export type CartonGroupingsQuery = (
  { __typename?: 'Query' }
  & { cartonGroupingsSearch?: Maybe<(
    { __typename?: 'CartonGroupingSearch' }
    & { cartonGroupings: Array<(
      { __typename?: 'CartonGrouping' }
      & CartonGroupingFragment
    )> }
  )> }
);

export type CashFlowCartonGroupingsQueryVariables = Exact<{
  attributes: CartonGroupingSearchAttributes;
}>;


export type CashFlowCartonGroupingsQuery = (
  { __typename?: 'Query' }
  & { cartonGroupingsSearch?: Maybe<(
    { __typename?: 'CartonGroupingSearch' }
    & { cartonGroupings: Array<(
      { __typename?: 'CartonGrouping' }
      & CashFlowCartonGroupingFragment
    )> }
  )> }
);

export type CgTransactionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  docRowIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type CgTransactionsQuery = (
  { __typename?: 'Query' }
  & { cgTransactions: (
    { __typename?: 'CartonGroupingTransactionConnection' }
    & Pick<CartonGroupingTransactionConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'CartonGroupingTransaction' }
      & Pick<CartonGroupingTransaction, 'amount' | 'createdAt' | 'currency' | 'id' | 'transactionDate' | 'updatedAt' | 'exchangeRate' | 'externalReferenceNum'>
      & { cartonGrouping?: Maybe<(
        { __typename?: 'CartonGrouping' }
        & Pick<CartonGrouping, 'containerNumber' | 'farmCode' | 'grade' | 'cartons' | 'pack' | 'palletNumber' | 'state' | 'sequenceNumber'>
        & { variety?: Maybe<(
          { __typename?: 'Variety' }
          & Pick<Variety, 'name'>
        )> }
      )>, costCode: (
        { __typename?: 'CostCode' }
        & Pick<CostCode, 'name' | 'generalLedgerName'>
      ) }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type UpdateCartonGroupingTransactionsMutationVariables = Exact<{
  deleteData?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type UpdateCartonGroupingTransactionsMutation = (
  { __typename?: 'Mutation' }
  & { updateCartonGroupingTransactions?: Maybe<(
    { __typename?: 'UpdateCartonGroupingTransactionsPayload' }
    & { deleteSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )> }
  )> }
);

export type CommentFieldsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'commentText' | 'isDeleted' | 'createdAt' | 'updatedAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { avatarFile?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )> }
  )> }
);

export type CreateCommentMutationVariables = Exact<{
  attributes: CreateCommentAttributes;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment?: Maybe<(
    { __typename?: 'CreateCommentPayload' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & CommentFieldsFragment
    )> }
  )> }
);

export type DeleteCommentMutationVariables = Exact<{
  commentid: Scalars['Int'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment?: Maybe<(
    { __typename?: 'DeleteCommentPayload' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & CommentFieldsFragment
    )> }
  )> }
);

export type CommercialTermChangeRequestFieldsFragment = (
  { __typename?: 'CommercialTermChangeRequest' }
  & Pick<CommercialTermChangeRequest, 'id' | 'message' | 'flag'>
  & { commercialTerm?: Maybe<(
    { __typename?: 'CommercialTerm' }
    & Pick<CommercialTerm, 'id'>
  )> }
);

export type CreateCommercialTermChangeRequestMutationVariables = Exact<{
  attributes: CreateCommercialTermChangeRequestAttributes;
}>;


export type CreateCommercialTermChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & { createCommercialTermChangeRequest?: Maybe<(
    { __typename?: 'CreateCommercialTermChangeRequestPayload' }
    & { changeRequest?: Maybe<(
      { __typename?: 'CommercialTermChangeRequest' }
      & Pick<CommercialTermChangeRequest, 'id'>
    )> }
  )> }
);

export type CommercialTermFragment = (
  { __typename?: 'CommercialTerm' }
  & Pick<CommercialTerm, 'id' | 'startDate' | 'endDate' | 'targetRegion' | 'advanceType' | 'advanceUnit' | 'buyerId' | 'deleted' | 'pack' | 'grade' | 'paymentTerm' | 'advanceCreditTerm' | 'finalCreditTerm' | 'advanceEvent' | 'finalEvent' | 'advanceAmount' | 'rankOneAdvanceEvent' | 'rankOneFinalEvent' | 'advanceCurrency' | 'finalCurrency'>
  & { commodity?: Maybe<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
  )>, buyer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )> }
);

export type CommercialTermsQueryVariables = Exact<{
  startweek?: Maybe<Scalars['String']>;
  endweek?: Maybe<Scalars['String']>;
  companyid?: Maybe<Scalars['Int']>;
  targetregion?: Maybe<Scalars['String']>;
  advancetype?: Maybe<Scalars['String']>;
  advanceunit?: Maybe<Scalars['String']>;
  commodity?: Maybe<Scalars['String']>;
  rankoneevent?: Maybe<Scalars['String']>;
}>;


export type CommercialTermsQuery = (
  { __typename?: 'Query' }
  & { commercialTerms?: Maybe<Array<(
    { __typename?: 'CommercialTerm' }
    & CommercialTermFragment
  )>> }
);

export type CommoditiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CommoditiesQuery = (
  { __typename?: 'Query' }
  & { commodities?: Maybe<Array<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
    & { varieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
    )>> }
  )>> }
);

export type UploadCompanyCertificateMutationVariables = Exact<{
  file: Scalars['Upload'];
  expirydate: Scalars['String'];
  companyid: Scalars['Int'];
  certificatetypeid: Scalars['Int'];
}>;


export type UploadCompanyCertificateMutation = (
  { __typename?: 'Mutation' }
  & { uploadCompanyCertificate?: Maybe<(
    { __typename?: 'UploadCompanyCertificatePayload' }
    & { certificate?: Maybe<(
      { __typename?: 'CompanyCertificate' }
      & Pick<CompanyCertificate, 'id' | 'expiryDate' | 'createdAt'>
      & { certificate?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url' | 'v2Url'>
      )>, certificateType?: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'name' | 'id'>
      )> }
    )> }
  )> }
);

export type DeleteCompanyCertificateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCompanyCertificateMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanyCertificate?: Maybe<(
    { __typename?: 'DeleteCompanyCertificatePayload' }
    & Pick<DeleteCompanyCertificatePayload, 'id'>
  )> }
);

export type CertificateTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type CertificateTypeQuery = (
  { __typename?: 'Query' }
  & { certificateTypes?: Maybe<Array<(
    { __typename?: 'CertificateType' }
    & Pick<CertificateType, 'id' | 'name'>
  )>> }
);

export type CommoditySeasonFragment = (
  { __typename?: 'CommoditySeason' }
  & Pick<CommoditySeason, 'id' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
  & { commodity?: Maybe<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
    & { icon?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'v2Url'>
    )> }
  )> }
);

export type MyCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCompanyQuery = (
  { __typename?: 'Query' }
  & { myCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'about' | 'registrationNumber' | 'vatNumber' | 'createdAt' | 'isSeller' | 'isBuyer'>
    & { logoFile?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'postalCode' | 'country'>
    )>, tradeRegions?: Maybe<Array<(
      { __typename?: 'TradeRegion' }
      & Pick<TradeRegion, 'id' | 'name' | 'code'>
      & { countries?: Maybe<Array<(
        { __typename?: 'TradeCountry' }
        & Pick<TradeCountry, 'id' | 'name' | 'code'>
      )>> }
    )>>, certificates?: Maybe<Array<(
      { __typename?: 'CompanyCertificate' }
      & Pick<CompanyCertificate, 'id' | 'expiryDate' | 'createdAt' | 'updatedAt'>
      & { certificate?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url' | 'v2Url'>
      )>, certificateType?: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'name' | 'id'>
      )> }
    )>>, varieties?: Maybe<Array<(
      { __typename?: 'FarmVariety' }
      & Pick<FarmVariety, 'id' | 'varietyId' | 'name' | 'peakCount' | 'hectares' | 'packingStart' | 'packingEnd'>
      & { commodity?: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
        & { icon?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'v2Url'>
        )> }
      )>, images?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url' | 'v2Url' | 'filename' | 'thumbnailUrl' | 'v2ThumbnailUrl'>
      )>> }
    )>>, sellerVarieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
      & { commodity?: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )> }
    )>>, additionalFields?: Maybe<Array<(
      { __typename?: 'AdditionalField' }
      & Pick<AdditionalField, 'id' | 'companyId' | 'name' | 'description' | 'fieldType' | 'parameters' | 'createdAt' | 'updatedAt' | 'isPackingInstruction' | 'private'>
    )>>, activeUsers?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'state' | 'role'>
      & { roles?: Maybe<Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name' | 'resourceType' | 'resourceId' | 'createdAt' | 'updatedAt'>
      )>>, employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
      )> }
    )>>, invites?: Maybe<Array<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'id' | 'email' | 'state'>
    )>>, sellers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, buyers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, commoditySeasons?: Maybe<Array<(
      { __typename?: 'CommoditySeason' }
      & CommoditySeasonFragment
    )>> }
  )> }
);

export type UpdateCompanyDetailsMutationVariables = Exact<{
  attributes: UpdateCompanyAttributes;
}>;


export type UpdateCompanyDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyDetails?: Maybe<(
    { __typename?: 'UpdateCompanyDetailsPayload' }
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'about' | 'registrationNumber' | 'vatNumber'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'postalCode' | 'country'>
      )> }
    )> }
  )> }
);

export type UploadCompanyLogoMutationVariables = Exact<{
  file: Scalars['Upload'];
  companyid: Scalars['Int'];
}>;


export type UploadCompanyLogoMutation = (
  { __typename?: 'Mutation' }
  & { uploadCompanyLogo?: Maybe<(
    { __typename?: 'UploadCompanyLogoPayload' }
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & { logoFile?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url'>
      )> }
    )> }
  )> }
);

export type UpdateCompanyTradeRegionsMutationVariables = Exact<{
  addtraderegions: Array<TradeRegionAttributes> | TradeRegionAttributes;
  removetraderegions: Array<TradeRegionAttributes> | TradeRegionAttributes;
}>;


export type UpdateCompanyTradeRegionsMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyTradeRegions?: Maybe<(
    { __typename?: 'UpdateCompanyTradeRegionsPayload' }
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & { tradeRegions?: Maybe<Array<(
        { __typename?: 'TradeRegion' }
        & Pick<TradeRegion, 'id' | 'name' | 'code'>
      )>> }
    )> }
  )> }
);

export type DashboardQueryQueryVariables = Exact<{
  locationId?: Maybe<Scalars['Int']>;
  containerId?: Maybe<Scalars['Int']>;
  commoditygroups?: Maybe<Array<CommodityGroupEnum> | CommodityGroupEnum>;
  forExport?: Maybe<Scalars['Boolean']>;
}>;


export type DashboardQueryQuery = (
  { __typename?: 'Query' }
  & { dashboardTrackingData?: Maybe<(
    { __typename?: 'DashboardTrackingData' }
    & { cartonGroupings?: Maybe<Array<(
      { __typename?: 'DashboardCartonGrouping' }
      & Pick<DashboardCartonGrouping, 'batchNumber' | 'cartons' | 'commodityName' | 'consignmentNumber' | 'containerId' | 'exporterCode' | 'farmCode' | 'grade' | 'id' | 'inspectionDate' | 'inventoryCode' | 'locationId' | 'mark' | 'netMass' | 'orchard' | 'packhouseCode' | 'packingWeek' | 'palletGrossMass' | 'palletNumber' | 'productCharacteristic' | 'sequenceNumber' | 'state' | 'targetMarket' | 'targetCountry' | 'targetRegion' | 'varietyName'>
    )>>, containers?: Maybe<Array<(
      { __typename?: 'DashboardContainer' }
      & Pick<DashboardContainer, 'arrivalDate' | 'arrivalEventClassifier' | 'cartons' | 'cartonsInTransit' | 'containerId' | 'containerNumber' | 'containerType' | 'departureDate' | 'departureEventClassifier' | 'finalDestinationId' | 'finalDeliveryDatetime' | 'finalDestinationName' | 'finalDestinationType' | 'locationId' | 'id' | 'nextDeliveryDatetime' | 'nextDestinationId' | 'nextDestinationName' | 'nextDestinationType' | 'pallets' | 'palletsInTransit' | 'state' | 'stateInTransit'>
    )>>, locations?: Maybe<Array<(
      { __typename?: 'DashboardLocation' }
      & Pick<DashboardLocation, 'id' | 'cartons' | 'cartonsInTransit' | 'containers' | 'containersInTransit' | 'locationId' | 'locationType' | 'name' | 'pallets' | 'palletsInTransit' | 'unallocated' | 'unallocatedInTransit'>
      & { geoLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<GeoLocation, 'lat' | 'lon'>
      )> }
    )>> }
  )> }
);

export type DashboardSearchQueryVariables = Exact<{
  fields?: Maybe<Array<DashboardSearchFieldsEnum> | DashboardSearchFieldsEnum>;
  searchTerm?: Maybe<Scalars['String']>;
  commodityGroups?: Maybe<Array<CommodityGroupEnum> | CommodityGroupEnum>;
}>;


export type DashboardSearchQuery = (
  { __typename?: 'Query' }
  & { dashboardSearch?: Maybe<(
    { __typename?: 'ElasticSearchResult' }
    & Pick<ElasticSearchResult, 'raw'>
    & { hits?: Maybe<Array<(
      { __typename?: 'ElasticHit' }
      & Pick<ElasticHit, 'score' | 'highlight'>
      & { source?: Maybe<(
        { __typename?: 'DashboardCartonGrouping' }
        & Pick<DashboardCartonGrouping, 'batchNumber' | 'cartons' | 'commodityName' | 'consignmentNumber' | 'containerId' | 'containerNumber' | 'exporterCode' | 'farmCode' | 'grade' | 'id' | 'inspectionDate' | 'inventoryCode' | 'locationId' | 'mark' | 'netMass' | 'orchard' | 'packhouseCode' | 'packingWeek' | 'palletGrossMass' | 'palletNumber' | 'productCharacteristic' | 'sequenceNumber' | 'state' | 'targetCountry' | 'targetMarket' | 'targetRegion' | 'varietyName'>
      ) | (
        { __typename?: 'DashboardContainer' }
        & Pick<DashboardContainer, 'arrivalDate' | 'cartons' | 'cartonsInTransit' | 'collectionDatetime' | 'containerId' | 'containerNumber' | 'containerType' | 'departureDate' | 'finalDeliveryDatetime' | 'finalDestinationId' | 'finalDestinationLatitude' | 'finalDestinationLongitude' | 'finalDestinationName' | 'finalDestinationType' | 'id' | 'locationId' | 'nextDeliveryDatetime' | 'nextDestinationId' | 'nextDestinationLatitude' | 'nextDestinationLongitude' | 'nextDestinationName' | 'nextDestinationType' | 'pallets' | 'palletsInTransit' | 'state' | 'stateInTransit'>
      ) | (
        { __typename?: 'DashboardLocation' }
        & Pick<DashboardLocation, 'cartons' | 'cartonsInTransit' | 'containers' | 'containersInTransit' | 'id' | 'locationId' | 'locationType' | 'name' | 'pallets' | 'palletsInTransit' | 'unallocated' | 'unallocatedInTransit'>
      )> }
    )>>, total?: Maybe<(
      { __typename?: 'ElasticTotal' }
      & Pick<ElasticTotal, 'relation' | 'value'>
    )> }
  )> }
);

export type DashboardFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardFiltersQuery = (
  { __typename?: 'Query' }
  & { dashboardFilters?: Maybe<(
    { __typename?: 'DashboardFilters' }
    & { commodities?: Maybe<Array<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name' | 'commodityGroup'>
    )>> }
  )> }
);

export type DataPreparationsQueryVariables = Exact<{ [key: string]: never; }>;


export type DataPreparationsQuery = (
  { __typename?: 'Query' }
  & { dataPreparations?: Maybe<Array<(
    { __typename?: 'DataPreparation' }
    & Pick<DataPreparation, 'id' | 'name'>
  )>> }
);

export type DataPreparationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DataPreparationQuery = (
  { __typename?: 'Query' }
  & { dataPreparation?: Maybe<(
    { __typename?: 'DataPreparation' }
    & Pick<DataPreparation, 'error' | 'id' | 'description' | 'name' | 'status' | 'updatedAt'>
    & { dataOutputs?: Maybe<Array<(
      { __typename?: 'DataOutput' }
      & Pick<DataOutput, 'createdAt' | 'downloadedAt' | 'fileType' | 'id'>
      & { outputFile?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'contentType' | 'filename' | 'id' | 'url' | 'v2Url'>
      )> }
    )>> }
  )> }
);

export type GenerateDataOutputFilesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GenerateDataOutputFilesMutation = (
  { __typename?: 'Mutation' }
  & { generateDataOutputFiles?: Maybe<(
    { __typename?: 'GenerateDataOutputFilesPayload' }
    & { dataPreparation?: Maybe<(
      { __typename?: 'DataPreparation' }
      & Pick<DataPreparation, 'status'>
    )> }
  )> }
);

export type UpdateDataOutputMutationVariables = Exact<{
  attributes: DataOutputAttributes;
}>;


export type UpdateDataOutputMutation = (
  { __typename?: 'Mutation' }
  & { updateDataOutput?: Maybe<(
    { __typename?: 'UpdateDataOutputPayload' }
    & Pick<UpdateDataOutputPayload, 'errors'>
    & { dataOutput?: Maybe<(
      { __typename?: 'DataOutput' }
      & Pick<DataOutput, 'id' | 'downloadedAt'>
    )> }
  )> }
);

export type SendEmailMutationVariables = Exact<{
  attributes: SendEmailAttributes;
}>;


export type SendEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendEmail?: Maybe<(
    { __typename?: 'SendEmailPayload' }
    & Pick<SendEmailPayload, 'errors' | 'message'>
  )> }
);

export type DuplicateEphytoMutationVariables = Exact<{
  id: Scalars['Int'];
  applicationType: Scalars['Int'];
}>;


export type DuplicateEphytoMutation = (
  { __typename?: 'Mutation' }
  & { duplicateEphyto?: Maybe<(
    { __typename?: 'DuplicateEphytoPayload' }
    & Pick<DuplicateEphytoPayload, 'id'>
  )> }
);

export type DeleteEphytoMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEphytoMutation = (
  { __typename?: 'Mutation' }
  & { deleteEphyto?: Maybe<(
    { __typename?: 'DeleteEphytoPayload' }
    & Pick<DeleteEphytoPayload, 'message'>
  )> }
);

export type UpdateEphytoDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
  type: Scalars['String'];
}>;


export type UpdateEphytoDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateEphytoDocument?: Maybe<(
    { __typename?: 'UpdateEphytoDocumentPayload' }
    & Pick<UpdateEphytoDocumentPayload, 'errors'>
  )> }
);

export type UploadEphytoDocumentMutationVariables = Exact<{
  ephytoId: Scalars['Int'];
  file: Scalars['Upload'];
}>;


export type UploadEphytoDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadEphytoDocument?: Maybe<(
    { __typename?: 'UploadEphytoDocumentPayload' }
    & Pick<UploadEphytoDocumentPayload, 'errors'>
    & { ephytoDocuments?: Maybe<Array<(
      { __typename?: 'EphytoDocument' }
      & EphytoDocumentFieldsFragment
    )>> }
  )> }
);

export type DeleteEphytoDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEphytoDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteEphytoDocument?: Maybe<(
    { __typename?: 'DeleteEphytoDocumentPayload' }
    & Pick<DeleteEphytoDocumentPayload, 'id'>
  )> }
);

export type UpdateDraftEphytoMutationVariables = Exact<{
  ephytoId: Scalars['Int'];
}>;


export type UpdateDraftEphytoMutation = (
  { __typename?: 'Mutation' }
  & { updateDraftEphyto?: Maybe<(
    { __typename?: 'UpdateDraftEphytoPayload' }
    & Pick<UpdateDraftEphytoPayload, 'errors'>
    & { ephyto?: Maybe<(
      { __typename?: 'Ephyto' }
      & { ephytoTradeUnits?: Maybe<Array<(
        { __typename?: 'EphytoTradeUnit' }
        & EphytoTradeUnitCommonFieldsFragment
      )>>, ephytoConsignmentItems?: Maybe<Array<(
        { __typename?: 'EphytoConsignmentItem' }
        & EphytoConsignmentItemCommonFieldsFragment
      )>>, ephytoDocuments?: Maybe<Array<(
        { __typename?: 'EphytoDocument' }
        & EphytoDocumentFieldsFragment
      )>> }
      & EphytoCommonFieldsFragment
    )> }
  )> }
);

export type SubmitEphytoMutationVariables = Exact<{
  ephytoId: Scalars['Int'];
}>;


export type SubmitEphytoMutation = (
  { __typename?: 'Mutation' }
  & { submitEphyto?: Maybe<(
    { __typename?: 'SubmitEphytoPayload' }
    & Pick<SubmitEphytoPayload, 'errors'>
    & { ephyto?: Maybe<(
      { __typename?: 'Ephyto' }
      & { ephytoTradeUnits?: Maybe<Array<(
        { __typename?: 'EphytoTradeUnit' }
        & EphytoTradeUnitCommonFieldsFragment
      )>>, ephytoConsignmentItems?: Maybe<Array<(
        { __typename?: 'EphytoConsignmentItem' }
        & EphytoConsignmentItemCommonFieldsFragment
      )>>, ephytoDocuments?: Maybe<Array<(
        { __typename?: 'EphytoDocument' }
        & EphytoDocumentFieldsFragment
      )>> }
      & EphytoCommonFieldsFragment
    )> }
  )> }
);

export type DraftEphytoMutationVariables = Exact<{
  ephytoId: Scalars['Int'];
}>;


export type DraftEphytoMutation = (
  { __typename?: 'Mutation' }
  & { draftEphyto?: Maybe<(
    { __typename?: 'DraftEphytoPayload' }
    & Pick<DraftEphytoPayload, 'errors'>
    & { ephyto?: Maybe<(
      { __typename?: 'Ephyto' }
      & { ephytoTradeUnits?: Maybe<Array<(
        { __typename?: 'EphytoTradeUnit' }
        & EphytoTradeUnitCommonFieldsFragment
      )>>, ephytoConsignmentItems?: Maybe<Array<(
        { __typename?: 'EphytoConsignmentItem' }
        & EphytoConsignmentItemCommonFieldsFragment
      )>>, ephytoDocuments?: Maybe<Array<(
        { __typename?: 'EphytoDocument' }
        & EphytoDocumentFieldsFragment
      )>> }
      & EphytoCommonFieldsFragment
    )> }
  )> }
);

export type FetchEphytoStatusMutationVariables = Exact<{
  ephytoId: Scalars['Int'];
}>;


export type FetchEphytoStatusMutation = (
  { __typename?: 'Mutation' }
  & { fetchEphytoStatus?: Maybe<(
    { __typename?: 'FetchEphytoStatusPayload' }
    & Pick<FetchEphytoStatusPayload, 'errors'>
    & { ephyto?: Maybe<(
      { __typename?: 'Ephyto' }
      & { ephytoTradeUnits?: Maybe<Array<(
        { __typename?: 'EphytoTradeUnit' }
        & EphytoTradeUnitCommonFieldsFragment
      )>>, ephytoConsignmentItems?: Maybe<Array<(
        { __typename?: 'EphytoConsignmentItem' }
        & EphytoConsignmentItemCommonFieldsFragment
      )>>, ephytoDocuments?: Maybe<Array<(
        { __typename?: 'EphytoDocument' }
        & EphytoDocumentFieldsFragment
      )>> }
      & EphytoCommonFieldsFragment
    )> }
  )> }
);

export type GenerateEphytoMutationVariables = Exact<{
  isEntireBooking: Scalars['Boolean'];
  shipmentType: Scalars['String'];
  bookingReference?: Maybe<Scalars['String']>;
  ephytoId?: Maybe<Scalars['Int']>;
  sliIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GenerateEphytoMutation = (
  { __typename?: 'Mutation' }
  & { generateEphyto?: Maybe<(
    { __typename?: 'GenerateEphytoPayload' }
    & Pick<GenerateEphytoPayload, 'errors'>
    & { ephyto?: Maybe<(
      { __typename?: 'Ephyto' }
      & { ephytoTradeUnits?: Maybe<Array<(
        { __typename?: 'EphytoTradeUnit' }
        & EphytoTradeUnitCommonFieldsFragment
      )>>, ephytoConsignmentItems?: Maybe<Array<(
        { __typename?: 'EphytoConsignmentItem' }
        & EphytoConsignmentItemCommonFieldsFragment
      )>>, ephytoDocuments?: Maybe<Array<(
        { __typename?: 'EphytoDocument' }
        & EphytoDocumentFieldsFragment
      )>> }
      & EphytoCommonFieldsFragment
    )> }
  )> }
);

export type GenerateEphytoTradeUnitMutationVariables = Exact<{
  shipmentType: Scalars['String'];
  ephytoId?: Maybe<Scalars['Int']>;
}>;


export type GenerateEphytoTradeUnitMutation = (
  { __typename?: 'Mutation' }
  & { generateEphytoTradeUnit?: Maybe<(
    { __typename?: 'GenerateEphytoTradeUnitPayload' }
    & Pick<GenerateEphytoTradeUnitPayload, 'errors'>
    & { ephytoTradeUnits?: Maybe<Array<(
      { __typename?: 'EphytoTradeUnit' }
      & EphytoTradeUnitCommonFieldsFragment
    )>> }
  )> }
);

export type UpdateEphytoMutationVariables = Exact<{
  ephyto: EphytoAttributes;
  ephytoConsignmentItems: Array<EphytoConsignmentItemAttributes> | EphytoConsignmentItemAttributes;
}>;


export type UpdateEphytoMutation = (
  { __typename?: 'Mutation' }
  & { updateEphyto?: Maybe<(
    { __typename?: 'UpdateEphytoPayload' }
    & Pick<UpdateEphytoPayload, 'errors'>
  )> }
);

export type EphytoPackageTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoPackageTypesQuery = (
  { __typename?: 'Query' }
  & { ephytoPackageTypes?: Maybe<Array<(
    { __typename?: 'EphytoPackage' }
    & Pick<EphytoPackage, 'name' | 'code'>
  )>> }
);

export type EphytoAppliedChemicalsQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoAppliedChemicalsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ephytoAppliedChemicals'>
);

export type EphytoTransportMeansQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoTransportMeansQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ephytoTransportMeans'>
);

export type EphytoUnitOfMeasureCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoUnitOfMeasureCodesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ephytoUnitOfMeasureCodes'>
);

export type EphytoTreatmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoTreatmentsQuery = (
  { __typename?: 'Query' }
  & { ephytoTreatments?: Maybe<Array<(
    { __typename?: 'EphytoTreatment' }
    & Pick<EphytoTreatment, 'firstLevel' | 'firstLevelCode' | 'secondLevel' | 'secondLevelCode'>
  )>> }
);

export type EphytoCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoCountriesQuery = (
  { __typename?: 'Query' }
  & { ephytoCountries?: Maybe<Array<(
    { __typename?: 'EphytoCountry' }
    & Pick<EphytoCountry, 'isoCode' | 'name'>
  )>> }
);

export type EphytoAgreementCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoAgreementCodesQuery = (
  { __typename?: 'Query' }
  & { ephytoAgreementCodes?: Maybe<Array<(
    { __typename?: 'EphytoAgreementCode' }
    & Pick<EphytoAgreementCode, 'agreementCode' | 'agreementName'>
  )>> }
);

export type EphytoLocationsQueryVariables = Exact<{
  countryCodes: Array<Scalars['String']> | Scalars['String'];
}>;


export type EphytoLocationsQuery = (
  { __typename?: 'Query' }
  & { ephytoLocations?: Maybe<Array<(
    { __typename?: 'EphytoLocation' }
    & Pick<EphytoLocation, 'name' | 'code'>
  )>> }
);

export type EphytoContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoContactsQuery = (
  { __typename?: 'Query' }
  & { contacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'info' | 'contactType'>
  )>> }
);

export type NonEphytoShipmentLineItemsQueryVariables = Exact<{
  shipmentId: Scalars['Int'];
  type: Scalars['String'];
}>;


export type NonEphytoShipmentLineItemsQuery = (
  { __typename?: 'Query' }
  & { nonEphytoShipmentLineItems?: Maybe<Array<(
    { __typename?: 'NonEphytoShipmentLineItem' }
    & Pick<NonEphytoShipmentLineItem, 'id' | 'labelDisplay' | 'type'>
  )>> }
);

export type EphytosQueryVariables = Exact<{
  filterAttributes: EphytoFilterAttributes;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes> | ColumnOrderAttributes>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type EphytosQuery = (
  { __typename?: 'Query' }
  & { ephytos?: Maybe<(
    { __typename?: 'EphytoConnection' }
    & Pick<EphytoConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Ephyto' }
      & Pick<Ephyto, 'id' | 'agreementCode' | 'applicationStatus' | 'state' | 'targetCountry' | 'unloadingBaseportLocation' | 'customReferenceNo' | 'applicationReferenceNo' | 'desiredIssueLocation' | 'departureDate' | 'applicationType' | 'consignorName' | 'consigneeName' | 'updatedAt'>
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type EphytoFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { ephytoFilterAttributes?: Maybe<(
    { __typename?: 'EphytoFilterAttribute' }
    & Pick<EphytoFilterAttribute, 'statuses' | 'customerReferences' | 'entryPorts' | 'consignees' | 'consignors'>
  )> }
);

export type EphytoCertificateUrlQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EphytoCertificateUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ephytoCertificateUrl'>
);

export type ExportDocumentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportDocumentTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportDocumentTypes'>
);

export type EphytoDocumentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type EphytoDocumentTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ephytoDocumentTypes'>
);

export type EphytoByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EphytoByIdQuery = (
  { __typename?: 'Query' }
  & { ephytoById?: Maybe<(
    { __typename?: 'Ephyto' }
    & { ephytoTradeUnits?: Maybe<Array<(
      { __typename?: 'EphytoTradeUnit' }
      & EphytoTradeUnitCommonFieldsFragment
    )>>, ephytoConsignmentItems?: Maybe<Array<(
      { __typename?: 'EphytoConsignmentItem' }
      & EphytoConsignmentItemCommonFieldsFragment
    )>>, ephytoDocuments?: Maybe<Array<(
      { __typename?: 'EphytoDocument' }
      & EphytoDocumentFieldsFragment
    )>> }
    & EphytoCommonFieldsFragment
  )> }
);

export type EphytoCommonFieldsFragment = (
  { __typename?: 'Ephyto' }
  & Pick<Ephyto, 'id' | 'applicationReferenceNo' | 'applicationType' | 'applicationStatus' | 'cbrId' | 'cbrBillingId' | 'customReferenceNo' | 'agreementCode' | 'desiredIssueLocation' | 'notificationEmail' | 'consignorId' | 'consignorName' | 'consignorAddressLine1' | 'consignorAddressLine2' | 'consignorAddressLine3' | 'consigneeId' | 'consigneeName' | 'consigneeAddressLine1' | 'consigneeAddressLine2' | 'consigneeAddressLine3' | 'consigneeCountryId' | 'importCountryId' | 'targetCountryId' | 'unloadingBaseportLocation' | 'transportModeCode' | 'transportMeans' | 'paymentType' | 'dalrrdAccNum' | 'loadSeaPoint' | 'targetCountry' | 'targetRegion' | 'portOfEntry' | 'bookingRef' | 'vessel' | 'voyageNumber' | 'departureDate' | 'numberAndDescriptionOfPackage' | 'botanicalName' | 'distinguishingMarks' | 'placeOfOrigin' | 'declaredMeansOfConveyance' | 'declaredPointOfEntry' | 'nameOfProductAndDeclaredQuantity' | 'treatmentDate' | 'treatment' | 'chemical' | 'durationAndTemperature' | 'concentration' | 'additionalInformation' | 'state' | 'errorReason' | 'stuffDate' | 'temperatureRegime' | 'originalPhytoDate' | 'originalPhytoNo' | 'reexportPlaceOfOrigin' | 'originalPhyto' | 'rePacked' | 'originalContainer' | 'basedOnOriginalPhyto' | 'additionalInspection' | 'cbsFreeArea' | 'vatNo' | 'regimeCode' | 'importPermitNo' | 'importPermitDate'>
);

export type EphytoTradeUnitCommonFieldsFragment = (
  { __typename?: 'EphytoTradeUnit' }
  & Pick<EphytoTradeUnit, 'id' | 'tradeUnitId' | 'clientRef' | 'orchard' | 'phc' | 'productionArea' | 'commodity' | 'marketingIndication' | 'fleshColour' | 'tuClass' | 'containerNumber' | 'numberOfPackagedItems' | 'netTradeUnitWeight' | 'nettPalletWeight' | 'ppecbInspectionDate' | 'stuffDate' | 'inspectionManifestNo' | 'inspectionPointLocationCode'>
);

export type EphytoConsignmentItemCommonFieldsFragment = (
  { __typename?: 'EphytoConsignmentItem' }
  & Pick<EphytoConsignmentItem, 'id' | 'description' | 'commonName' | 'scientificName' | 'netWeightMeasureCode' | 'netWeightMeasure' | 'grossWeightMeasureCode' | 'grossWeightMeasure' | 'customsHarmonizedSystemClass' | 'commodityVegetablePart' | 'commodityConditionClass' | 'commodityIntentOfUseClass' | 'appliedProcessStartDate' | 'appliedProcessEndDate' | 'durationMeasureCode' | 'durationMeasure' | 'appliedProcessTreatmentTypeLevel1' | 'appliedProcessTreatmentTypeLevel2' | 'appliedProcessChemicalCode' | 'appliedProcessTemperatureUnitCode' | 'appliedProcessTemperature' | 'appliedProcessConcentrationUnitCode' | 'appliedProcessConcentration' | 'appliedProcessAdditionalNote' | 'fullTreatmentInformation' | 'packageLevelCode' | 'packageTypeCode' | 'packageItemUnitCode' | 'packageItemQuantity' | 'packageShippingMarks' | 'additionalConsignmentNotes' | 'sequenceNumeric'>
);

export type EphytoDocumentFieldsFragment = (
  { __typename?: 'EphytoDocument' }
  & Pick<EphytoDocument, 'id' | 'documentType'>
  & { file?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'filename' | 'v2Url'>
  )> }
);

export type EstimateConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type EstimateConfigQuery = (
  { __typename?: 'Query' }
  & { uiConfigs?: Maybe<Array<(
    { __typename?: 'UiConfig' }
    & Pick<UiConfig, 'id' | 'data'>
  )>> }
);

export type EstimateOptionsQueryVariables = Exact<{
  season: Scalars['Int'];
}>;


export type EstimateOptionsQuery = (
  { __typename?: 'Query' }
  & { myCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { sellers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>> }
  )>, commodities?: Maybe<Array<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
  )>>, varieties?: Maybe<Array<(
    { __typename?: 'Variety' }
    & Pick<Variety, 'id' | 'name'>
    & { commodity?: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id'>
    )> }
  )>>, productionUnits?: Maybe<Array<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'typeOfBusiness' | 'fboCode'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )> }
  )>>, packhouses?: Maybe<Array<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'typeOfBusiness' | 'fboCode'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    )> }
  )>>, fboOrchards?: Maybe<Array<(
    { __typename?: 'FboOrchard' }
    & Pick<FboOrchard, 'id' | 'hectares' | 'orchard' | 'block'>
    & { variety: (
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
      & { commodity?: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )> }
    ), foodBusinessOperator: (
      { __typename?: 'FoodBusinessOperator' }
      & Pick<FoodBusinessOperator, 'id' | 'fboCode' | 'name'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      )> }
    ) }
  )>>, cartonCounts?: Maybe<Array<(
    { __typename?: 'CartonCount' }
    & Pick<CartonCount, 'id' | 'name' | 'commodityId' | 'cartonTypeId' | 'counts' | 'priceMetric' | 'stackSizes'>
  )>>, commodityDefaultSeasons?: Maybe<Array<(
    { __typename?: 'CommodityDefaultSeason' }
    & Pick<CommodityDefaultSeason, 'commodityId' | 'minWeek' | 'maxWeek'>
  )>> }
);

export type EstimatesQueryVariables = Exact<{
  endWeek?: Maybe<Scalars['String']>;
  startWeek?: Maybe<Scalars['String']>;
  weeks?: Maybe<Scalars['Boolean']>;
  season?: Maybe<Scalars['Int']>;
  matchCols?: Maybe<Array<EstimateMatchModeEnum> | EstimateMatchModeEnum>;
  commodityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  subCommodityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  varietyIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type EstimatesQuery = (
  { __typename?: 'Query' }
  & { estimates?: Maybe<Array<(
    { __typename?: 'Estimate' }
    & Pick<Estimate, 'id' | 'week' | 'season'>
    & { provider?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, fboOrchard?: Maybe<(
      { __typename?: 'FboOrchard' }
      & Pick<FboOrchard, 'id' | 'orchard' | 'hectares'>
      & { foodBusinessOperator: (
        { __typename?: 'FoodBusinessOperator' }
        & { company?: Maybe<(
          { __typename?: 'Company' }
          & Pick<Company, 'id'>
        )> }
      ) }
    )>, cartonType: (
      { __typename?: 'CartonType' }
      & Pick<CartonType, 'id' | 'name'>
    ), commodity: (
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    ), variety?: Maybe<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
      & { subCommodity?: Maybe<(
        { __typename?: 'SubCommodity' }
        & Pick<SubCommodity, 'name'>
      )> }
    )>, productionUnit?: Maybe<(
      { __typename?: 'FoodBusinessOperator' }
      & Pick<FoodBusinessOperator, 'id' | 'name' | 'fboCode' | 'typeOfBusiness'>
    )>, packHouse?: Maybe<(
      { __typename?: 'FoodBusinessOperator' }
      & Pick<FoodBusinessOperator, 'id' | 'name' | 'fboCode' | 'typeOfBusiness'>
    )>, estimateValues?: Maybe<Array<(
      { __typename?: 'EstimateValue' }
      & Pick<EstimateValue, 'id' | 'estType' | 'value' | 'conversionWeight' | 'grade' | 'sizeCounts' | 'acceptedValue' | 'inSeasonValue'>
    )>> }
  )>> }
);

export type OrchardSeasonsQueryVariables = Exact<{
  season: Scalars['Int'];
}>;


export type OrchardSeasonsQuery = (
  { __typename?: 'Query' }
  & { orchardSeasons?: Maybe<Array<(
    { __typename?: 'OrchardSeason' }
    & Pick<OrchardSeason, 'id' | 'startDate' | 'endDate' | 'hectares' | 'plantedDate'>
    & { fboOrchard: (
      { __typename?: 'FboOrchard' }
      & Pick<FboOrchard, 'orchard'>
      & { foodBusinessOperator: (
        { __typename?: 'FoodBusinessOperator' }
        & Pick<FoodBusinessOperator, 'fboCode'>
      ), variety: (
        { __typename?: 'Variety' }
        & Pick<Variety, 'name'>
        & { commodity?: Maybe<(
          { __typename?: 'Commodity' }
          & Pick<Commodity, 'id'>
        )> }
      ) }
    ) }
  )>> }
);

export type OrchardYieldsQueryVariables = Exact<{
  orchardSeasonId?: Maybe<Scalars['ID']>;
  capturedBefore: Scalars['ISO8601DateTime'];
  capturedAfter: Scalars['ISO8601DateTime'];
}>;


export type OrchardYieldsQuery = (
  { __typename?: 'Query' }
  & { orchardYields?: Maybe<Array<(
    { __typename?: 'OrchardYield' }
    & Pick<OrchardYield, 'id' | 'weight' | 'weightPerBin' | 'orchardSeasonId' | 'captureDate'>
  )>> }
);

export type UpdateOrchardSeasonsMutationVariables = Exact<{
  updateData?: Maybe<Array<OrchardSeasonAttributes> | OrchardSeasonAttributes>;
  deleteData?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type UpdateOrchardSeasonsMutation = (
  { __typename?: 'Mutation' }
  & { updateOrchardSeasons?: Maybe<(
    { __typename?: 'UpdateOrchardSeasonsPayload' }
    & { createSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )>, updateSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )>, deleteSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )> }
  )> }
);

export type GenerateCommoditySeasonsMutationVariables = Exact<{
  season: Scalars['Int'];
}>;


export type GenerateCommoditySeasonsMutation = (
  { __typename?: 'Mutation' }
  & { generateCommoditySeasons?: Maybe<(
    { __typename?: 'GenerateCommoditySeasonsPayload' }
    & Pick<GenerateCommoditySeasonsPayload, 'error' | 'failures' | 'successes'>
  )> }
);

export type UpdateOrchardYieldsMutationVariables = Exact<{
  createData?: Maybe<Array<OrchardYieldAttributes> | OrchardYieldAttributes>;
  updateData?: Maybe<Array<OrchardYieldAttributes> | OrchardYieldAttributes>;
  deleteData?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type UpdateOrchardYieldsMutation = (
  { __typename?: 'Mutation' }
  & { updateOrchardYields?: Maybe<(
    { __typename?: 'UpdateOrchardYieldsPayload' }
    & { createSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'errors' | 'failed' | 'succeeded'>
    )>, updateSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )>, deleteSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )> }
  )> }
);

export type UpdateEstimatesMutationVariables = Exact<{
  createData?: Maybe<Array<EstimateAttributes> | EstimateAttributes>;
  deleteData?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  updateData?: Maybe<Array<EstimateAttributes> | EstimateAttributes>;
}>;


export type UpdateEstimatesMutation = (
  { __typename?: 'Mutation' }
  & { updateEstimates?: Maybe<(
    { __typename?: 'UpdateEstimatesPayload' }
    & { createSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )>, updateSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )>, deleteSummary?: Maybe<(
      { __typename?: 'CudSummary' }
      & Pick<CudSummary, 'succeeded' | 'failed' | 'errors'>
    )> }
  )> }
);

export type DocumentsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  dateAfter?: Maybe<Scalars['ISO8601DateTime']>;
  dateBefore?: Maybe<Scalars['ISO8601DateTime']>;
  states?: Maybe<Array<EtlDocumentStateEnum> | EtlDocumentStateEnum>;
}>;


export type DocumentsQuery = (
  { __typename?: 'Query' }
  & { documents: (
    { __typename?: 'EtlDocumentConnection' }
    & Pick<EtlDocumentConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'EtlDocument' }
      & DocumentFieldsFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type DocumentQueryVariables = Exact<{
  documentId: Scalars['Int'];
}>;


export type DocumentQuery = (
  { __typename?: 'Query' }
  & { document: (
    { __typename?: 'EtlDocument' }
    & DocumentFieldsFragment
  ) }
);

export type DocumentRowsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  documentId: Scalars['Int'];
  states?: Maybe<Array<EtlDocumentRowStateEnum> | EtlDocumentRowStateEnum>;
}>;


export type DocumentRowsQuery = (
  { __typename?: 'Query' }
  & { documentRows: (
    { __typename?: 'EtlDocumentRowConnection' }
    & Pick<EtlDocumentRowConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'EtlDocumentRow' }
      & Pick<EtlDocumentRow, 'createdAt' | 'errorReason' | 'id' | 'processOutput' | 'state' | 'value'>
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type UploadEtlDocumentMutationVariables = Exact<{
  documentId: Scalars['Int'];
  file: Scalars['Upload'];
}>;


export type UploadEtlDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadEtlDocument?: Maybe<(
    { __typename?: 'UploadEtlDocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'EtlDocument' }
      & DocumentFieldsFragment
    )> }
  )> }
);

export type ProcessEtlDocumentMutationVariables = Exact<{
  documentId: Scalars['Int'];
}>;


export type ProcessEtlDocumentMutation = (
  { __typename?: 'Mutation' }
  & { processEtlDocument?: Maybe<(
    { __typename?: 'ProcessEtlDocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'EtlDocument' }
      & DocumentFieldsFragment
    )> }
  )> }
);

export type DocumentFieldsFragment = (
  { __typename?: 'EtlDocument' }
  & Pick<EtlDocument, 'createdAt' | 'updatedAt' | 'errorReason' | 'erroredRows' | 'fileUrl' | 'filename' | 'id' | 'processedRows' | 'rows' | 'state'>
);

export type FarmVarietyFragment = (
  { __typename?: 'FarmVariety' }
  & Pick<FarmVariety, 'id' | 'varietyId' | 'name' | 'peakCount' | 'hectares' | 'packingStart' | 'packingEnd'>
  & { commodity?: Maybe<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
  )>, images?: Maybe<Array<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'url' | 'v2Url' | 'filename' | 'thumbnailUrl' | 'v2ThumbnailUrl'>
  )>> }
);

export type CreateFarmVarietyMutationVariables = Exact<{
  attributes: CreateFarmVarietyAttributes;
}>;


export type CreateFarmVarietyMutation = (
  { __typename?: 'Mutation' }
  & { createFarmVariety?: Maybe<(
    { __typename?: 'CreateFarmVarietyPayload' }
    & { farmVariety?: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type UpdateFarmVarietyMutationVariables = Exact<{
  attributes: UpdateFarmVarietyAttributes;
}>;


export type UpdateFarmVarietyMutation = (
  { __typename?: 'Mutation' }
  & { updateFarmVariety?: Maybe<(
    { __typename?: 'UpdateFarmVarietyPayload' }
    & { farmVariety?: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type DeleteFarmVarietyMutationVariables = Exact<{
  varietyid: Scalars['Int'];
  companyId: Scalars['Int'];
}>;


export type DeleteFarmVarietyMutation = (
  { __typename?: 'Mutation' }
  & { deleteFarmVariety?: Maybe<(
    { __typename?: 'DeleteFarmVarietyPayload' }
    & { farmVariety?: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type UploadFarmVarietyImageMutationVariables = Exact<{
  id: Scalars['Int'];
  image: Scalars['Upload'];
}>;


export type UploadFarmVarietyImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadFarmVarietyImage?: Maybe<(
    { __typename?: 'UploadFarmVarietyImagePayload' }
    & { farmVariety?: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type DeleteFarmVarietyImageMutationVariables = Exact<{
  varietyid: Scalars['Int'];
  imageid: Scalars['Int'];
}>;


export type DeleteFarmVarietyImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteFarmVarietyImage?: Maybe<(
    { __typename?: 'DeleteFarmVarietyImagePayload' }
    & { farmVariety?: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type FinanceReportQueryVariables = Exact<{
  attributes: FinanceReportFilterAttributes;
}>;


export type FinanceReportQuery = (
  { __typename?: 'Query' }
  & { financeReport?: Maybe<(
    { __typename?: 'FinanceReport' }
    & { items?: Maybe<Array<(
      { __typename?: 'FinanceReportItem' }
      & Pick<FinanceReportItem, 'commodity' | 'variety' | 'amount' | 'amountPerCarton' | 'batchNumber' | 'buyer' | 'packType' | 'cartons' | 'stdCartons' | 'containerNumber' | 'currency' | 'exchangeRate' | 'exporterCode' | 'farmCode' | 'grade' | 'incoterm' | 'isActual' | 'palletNumber' | 'liReferenceNumber' | 'txReferenceNumber' | 'seller' | 'sizeCount' | 'state' | 'targetMarket' | 'targetRegion' | 'transactionDate' | 'transactionType' | 'transactionWeek' | 'shippingWeek' | 'packingWeek' | 'generalLedgerName' | 'secondaryCategory' | 'primaryCategory' | 'zarAmount' | 'zarPerCarton'>
    )>> }
  )> }
);

export type CurrencyConversionQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrencyConversionQuery = (
  { __typename?: 'Query' }
  & { currencyConversion?: Maybe<(
    { __typename?: 'CurrencyConversion' }
    & Pick<CurrencyConversion, 'rates' | 'timestamp'>
  )> }
);

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { inviteUser?: Maybe<(
    { __typename?: 'InviteUserPayload' }
    & { invite?: Maybe<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'id' | 'email' | 'state' | 'message' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);

export type RevokeInviteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RevokeInviteMutation = (
  { __typename?: 'Mutation' }
  & { revokeInvite?: Maybe<(
    { __typename?: 'RevokeInvitePayload' }
    & Pick<RevokeInvitePayload, 'id'>
  )> }
);

export type ResendInviteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResendInviteMutation = (
  { __typename?: 'Mutation' }
  & { resendInvite?: Maybe<(
    { __typename?: 'ResendInvitePayload' }
    & Pick<ResendInvitePayload, 'id'>
  )> }
);

export type InvoicesSummaryQueryVariables = Exact<{
  filterAttributes: InvoicesFilters;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes> | ColumnOrderAttributes>;
  page: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type InvoicesSummaryQuery = (
  { __typename?: 'Query' }
  & { invoices?: Maybe<(
    { __typename?: 'InvoiceConnection' }
    & Pick<InvoiceConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & InvoicesSummaryFieldsFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type InvoicesSummaryFieldsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'invoiceNumber' | 'invoiceDate' | 'incoterm' | 'id' | 'currency' | 'totalIncoterm' | 'advancePayment' | 'finalPayment' | 'amountRemitted' | 'palletCount' | 'transportType' | 'documentType' | 'state' | 'updatedAt'>
  & { portOfLoading?: Maybe<(
    { __typename?: 'SeaPort' }
    & InvPortFieldsFragment
  )>, portOfDestination?: Maybe<(
    { __typename?: 'SeaPort' }
    & InvPortFieldsFragment
  )>, vessel?: Maybe<(
    { __typename?: 'Vessel' }
    & Pick<Vessel, 'id' | 'vesselName'>
  )>, containers?: Maybe<Array<(
    { __typename?: 'Container' }
    & Pick<Container, 'containerNumber'>
  )>>, contacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'name' | 'contactType'>
  )>>, sentEmailList?: Maybe<Array<(
    { __typename?: 'SentEmail' }
    & Pick<SentEmail, 'id' | 'createdAt' | 'recipient' | 'attachments'>
  )>> }
);

export type InvoiceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type InvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & { invoiceLineItems?: Maybe<Array<(
      { __typename?: 'InvoiceLineItem' }
      & InvliCommonFieldsFragment
    )>>, cartonGroupings?: Maybe<Array<(
      { __typename?: 'CartonGrouping' }
      & InvCommonCgFieldsFragment
    )>> }
    & InvCommonFieldsFragment
  )> }
);

export type InvoiceFilterAttributesQueryVariables = Exact<{
  page: Scalars['String'];
}>;


export type InvoiceFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { invoicesFilterAttributes?: Maybe<(
    { __typename?: 'InvoiceFilterAttribute' }
    & Pick<InvoiceFilterAttribute, 'incoterms' | 'states' | 'targetCountries' | 'invoiceNumbers' | 'transportTypes'>
    & { vessels?: Maybe<Array<(
      { __typename?: 'Vessel' }
      & Pick<Vessel, 'id' | 'vesselName'>
    )>>, contacts?: Maybe<Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'contactType'>
    )>>, varieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
    )>>, commodities?: Maybe<Array<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )>>, documentTypes: Array<(
      { __typename?: 'InvoiceDocument' }
      & Pick<InvoiceDocument, 'type' | 'name'>
    )> }
  )> }
);

export type InvoiceNumberQueryVariables = Exact<{
  documenttype?: Maybe<Scalars['String']>;
}>;


export type InvoiceNumberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'invoiceNumber'>
);

export type InvoiceSeaPortsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  seaportname?: Maybe<Scalars['String']>;
  isportofloading?: Maybe<Scalars['Boolean']>;
  orderbyinv?: Maybe<Scalars['Boolean']>;
}>;


export type InvoiceSeaPortsQuery = (
  { __typename?: 'Query' }
  & { seaPorts?: Maybe<Array<(
    { __typename?: 'SeaPort' }
    & InvPortFieldsFragment
  )>> }
);

export type InvoiceTradeCountriesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  orderByInv?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type InvoiceTradeCountriesQuery = (
  { __typename?: 'Query' }
  & { tradeCountries?: Maybe<Array<(
    { __typename?: 'TradeCountry' }
    & Pick<TradeCountry, 'id' | 'name'>
  )>> }
);

export type InvoiceVesselsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  seaportid?: Maybe<Scalars['Int']>;
  vesselname?: Maybe<Scalars['String']>;
}>;


export type InvoiceVesselsQuery = (
  { __typename?: 'Query' }
  & { vessels?: Maybe<Array<(
    { __typename?: 'Vessel' }
    & Pick<Vessel, 'id' | 'vesselName'>
  )>> }
);

export type InvoiceVoyagesQueryVariables = Exact<{
  vesselid: Scalars['Int'];
  seaportid?: Maybe<Scalars['Int']>;
}>;


export type InvoiceVoyagesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'voyages'>
);

export type InvoiceContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoiceContactsQuery = (
  { __typename?: 'Query' }
  & { contacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'info' | 'contactType'>
  )>> }
);

export type InvoiceContainersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  port?: Maybe<Scalars['Int']>;
  containernumber?: Maybe<Scalars['String']>;
  excludesassignedinvlis?: Maybe<Scalars['Boolean']>;
  onlytrackingenabled?: Maybe<Scalars['Boolean']>;
}>;


export type InvoiceContainersQuery = (
  { __typename?: 'Query' }
  & { containers?: Maybe<Array<(
    { __typename?: 'Container' }
    & Pick<Container, 'containerNumber' | 'id' | 'stuffDate'>
  )>> }
);

export type InvoiceUnassignedCGsQueryVariables = Exact<{
  attributes: CartonGroupingSearchAttributes;
}>;


export type InvoiceUnassignedCGsQuery = (
  { __typename?: 'Query' }
  & { cartonGroupingsSearch?: Maybe<(
    { __typename?: 'CartonGroupingSearch' }
    & { cartonGroupings: Array<(
      { __typename?: 'CartonGrouping' }
      & InvCommonCgFieldsFragment
    )> }
  )> }
);

export type InvoiceCustomFieldsQueryVariables = Exact<{
  excludedCustomFieldIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type InvoiceCustomFieldsQuery = (
  { __typename?: 'Query' }
  & { customFields?: Maybe<Array<(
    { __typename?: 'CustomField' }
    & Pick<CustomField, 'id' | 'name' | 'required' | 'isDefault' | 'defaultValue'>
  )>> }
);

export type CreateContactMutationVariables = Exact<{
  attributes: ContactAttributes;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact?: Maybe<(
    { __typename?: 'CreateContactPayload' }
    & Pick<CreateContactPayload, 'errors'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'info' | 'contactType'>
    )> }
  )> }
);

export type UpdateContactMutationVariables = Exact<{
  attributes: ContactAttributes;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact?: Maybe<(
    { __typename?: 'UpdateContactPayload' }
    & Pick<UpdateContactPayload, 'errors'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'info' | 'contactType'>
    )> }
  )> }
);

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact?: Maybe<(
    { __typename?: 'DeleteContactPayload' }
    & Pick<DeleteContactPayload, 'success' | 'errors'>
  )> }
);

export type CreateCustomFieldMutationVariables = Exact<{
  attributes: CustomFieldAttributes;
}>;


export type CreateCustomFieldMutation = (
  { __typename?: 'Mutation' }
  & { createCustomField?: Maybe<(
    { __typename?: 'CreateCustomFieldPayload' }
    & Pick<CreateCustomFieldPayload, 'errors'>
    & { customField?: Maybe<(
      { __typename?: 'CustomField' }
      & Pick<CustomField, 'id' | 'name' | 'required' | 'isDefault' | 'defaultValue'>
    )> }
  )> }
);

export type UpdateCustomFieldMutationVariables = Exact<{
  attributes: CustomFieldAttributes;
}>;


export type UpdateCustomFieldMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomField?: Maybe<(
    { __typename?: 'UpdateCustomFieldPayload' }
    & Pick<UpdateCustomFieldPayload, 'errors'>
    & { customField?: Maybe<(
      { __typename?: 'CustomField' }
      & Pick<CustomField, 'id' | 'name' | 'required' | 'isDefault' | 'defaultValue'>
    )> }
  )> }
);

export type DeleteCustomFieldMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCustomFieldMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomField?: Maybe<(
    { __typename?: 'DeleteCustomFieldPayload' }
    & Pick<DeleteCustomFieldPayload, 'success' | 'errors'>
  )> }
);

export type CreateInvoiceMutationVariables = Exact<{
  attributes: InvoiceAttributes;
}>;


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createInvoice?: Maybe<(
    { __typename?: 'CreateInvoicePayload' }
    & Pick<CreateInvoicePayload, 'errors'>
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & { cartonGroupings?: Maybe<Array<(
        { __typename?: 'CartonGrouping' }
        & InvCommonCgFieldsFragment
      )>>, invoiceLineItems?: Maybe<Array<(
        { __typename?: 'InvoiceLineItem' }
        & InvliCommonFieldsFragment
      )>> }
      & InvCommonFieldsFragment
    )> }
  )> }
);

export type DuplicateInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
  documentType: Scalars['String'];
}>;


export type DuplicateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { duplicateInvoice?: Maybe<(
    { __typename?: 'DuplicateInvoicePayload' }
    & Pick<DuplicateInvoicePayload, 'errors' | 'id'>
  )> }
);

export type UpdateInvoiceMutationVariables = Exact<{
  attributes: InvoiceAttributes;
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice?: Maybe<(
    { __typename?: 'UpdateInvoicePayload' }
    & Pick<UpdateInvoicePayload, 'errors'>
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & { cartonGroupings?: Maybe<Array<(
        { __typename?: 'CartonGrouping' }
        & InvCommonCgFieldsFragment
      )>>, invoiceLineItems?: Maybe<Array<(
        { __typename?: 'InvoiceLineItem' }
        & InvliCommonFieldsFragment
      )>> }
      & InvCommonFieldsFragment
    )> }
  )> }
);

export type HandleInvoiceRemittanceMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  amountRemitted?: Maybe<Scalars['Float']>;
  batchUpdate?: Maybe<Scalars['Boolean']>;
}>;


export type HandleInvoiceRemittanceMutation = (
  { __typename?: 'Mutation' }
  & { handleInvoiceRemittance?: Maybe<(
    { __typename?: 'HandleInvoiceRemittancePayload' }
    & Pick<HandleInvoiceRemittancePayload, 'errors'>
    & { invoices?: Maybe<Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'state' | 'amountRemitted' | 'finalPayment'>
    )>> }
  )> }
);

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoice?: Maybe<(
    { __typename?: 'DeleteInvoicePayload' }
    & Pick<DeleteInvoicePayload, 'success'>
  )> }
);

export type CalculateInvoiceTotalsMutationVariables = Exact<{
  attributes: InvoiceIncotermAttributes;
}>;


export type CalculateInvoiceTotalsMutation = (
  { __typename?: 'Mutation' }
  & { calculateInvoiceIncoterm?: Maybe<(
    { __typename?: 'CalculateInvoiceIncotermPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'cartonCount' | 'currency' | 'freightCost' | 'insuranceCost' | 'localCosts' | 'palletCount' | 'totalFob' | 'totalIncoterm' | 'incoterm' | 'invoiceDate' | 'advancePayment' | 'advanceCreditTerm' | 'finalPayment' | 'finalCreditTerm' | 'state'>
    )> }
  )> }
);

export type GenerateInvoiceOutputFilesMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;


export type GenerateInvoiceOutputFilesMutation = (
  { __typename?: 'Mutation' }
  & { generateInvoiceOutputFiles?: Maybe<(
    { __typename?: 'GenerateInvoiceOutputFilesPayload' }
    & { templateOutput?: Maybe<Array<(
      { __typename?: 'TemplateOutput' }
      & Pick<TemplateOutput, 'errorReason' | 'id' | 'state' | 'templateVariant'>
    )>> }
  )> }
);

export type InvliCommonFieldsFragment = (
  { __typename?: 'InvoiceLineItem' }
  & Pick<InvoiceLineItem, 'cartons' | 'currency' | 'netMass' | 'palletGrossMass' | 'price' | 'priceUnit' | 'targetMarket'>
  & { cartonGrouping?: Maybe<(
    { __typename?: 'CartonGrouping' }
    & Pick<CartonGrouping, 'id'>
  )> }
);

export type InvCommonFieldsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'cartonCount' | 'convertTonToKg' | 'currency' | 'localCosts' | 'insuranceCost' | 'freightCost' | 'invoiceNumber' | 'invoiceDate' | 'incoterm' | 'totalFob' | 'advancePayment' | 'advanceCreditTerm' | 'finalPayment' | 'amountRemitted' | 'finalCreditTerm' | 'id' | 'palletCount' | 'poNumber' | 'totalIncoterm' | 'ucrNumber' | 'voyage' | 'transportType' | 'documentType' | 'state' | 'airWaybillNo' | 'shipper' | 'destination' | 'allocatedWeight' | 'routing' | 'flightDate' | 'connectingFlightDate' | 'vehicleNumber' | 'saleType'>
  & { invoiceCustomFields?: Maybe<Array<(
    { __typename?: 'InvoiceCustomField' }
    & Pick<InvoiceCustomField, 'customFieldId' | 'name' | 'required' | 'value' | 'defaultValue' | 'isDefault'>
  )>>, countryOfOrigin?: Maybe<(
    { __typename?: 'TradeCountry' }
    & Pick<TradeCountry, 'id' | 'name'>
  )>, contacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'info' | 'name' | 'contactType'>
  )>>, containers?: Maybe<Array<(
    { __typename?: 'Container' }
    & Pick<Container, 'containerNumber' | 'id'>
  )>>, portOfLoading?: Maybe<(
    { __typename?: 'SeaPort' }
    & InvPortFieldsFragment
  )>, portOfDestination?: Maybe<(
    { __typename?: 'SeaPort' }
    & InvPortFieldsFragment
  )>, vessel?: Maybe<(
    { __typename?: 'Vessel' }
    & Pick<Vessel, 'id' | 'vesselName'>
  )>, templateOutput?: Maybe<Array<(
    { __typename?: 'TemplateOutput' }
    & TemplateOutputFieldsFragment
  )>>, stuffLocation?: Maybe<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name'>
  )>, loadingPoint?: Maybe<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name'>
  )>, offloadingPoint?: Maybe<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name'>
  )> }
);

export type InvCommonCgFieldsFragment = (
  { __typename?: 'CartonGrouping' }
  & Pick<CartonGrouping, 'batchNumber' | 'containerNumber' | 'cartons' | 'consignmentNumber' | 'exporterCode' | 'farmCode' | 'grade' | 'id' | 'inventoryCode' | 'mark' | 'netMass' | 'orchard' | 'pack' | 'packhouseCode' | 'packingWeek' | 'palletGrossMass' | 'palletNumber' | 'sequenceNumber' | 'sizeCount' | 'state' | 'targetCountry' | 'targetMarket' | 'targetRegion'>
  & { buyer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )>, invoiceLineItems?: Maybe<Array<(
    { __typename?: 'InvoiceLineItem' }
    & Pick<InvoiceLineItem, 'invoiceId' | 'price' | 'priceUnit' | 'currency' | 'documentType'>
  )>>, lineItem?: Maybe<(
    { __typename?: 'LineItem' }
    & Pick<LineItem, 'priceUnit' | 'priceMinorUnit' | 'currency' | 'orderId'>
  )>, seller?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )>, variety?: Maybe<(
    { __typename?: 'Variety' }
    & Pick<Variety, 'name'>
    & { commodity?: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'name'>
    )>, subCommodity?: Maybe<(
      { __typename?: 'SubCommodity' }
      & Pick<SubCommodity, 'name'>
    )> }
  )> }
);

export type InvPortFieldsFragment = (
  { __typename?: 'SeaPort' }
  & Pick<SeaPort, 'id' | 'name' | 'country' | 'createdAt' | 'updatedAt'>
);

export type LoisSummaryQueryVariables = Exact<{
  filterAttributes?: Maybe<LoadOutInstructionsFilters>;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes> | ColumnOrderAttributes>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type LoisSummaryQuery = (
  { __typename?: 'Query' }
  & { loadOutInstructions?: Maybe<(
    { __typename?: 'LoadOutInstructionConnection' }
    & Pick<LoadOutInstructionConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'LoadOutInstruction' }
      & Pick<LoadOutInstruction, 'loadOutReference' | 'bookingReference' | 'containerNumber' | 'transportType' | 'stuffDate' | 'truckNumber' | 'id' | 'fileNumber' | 'airWaybillNo' | 'shipper' | 'destination' | 'flightDate' | 'updatedAt'>
      & { exporter?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )>, contacts?: Maybe<Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'name' | 'contactType'>
      )>>, vessel?: Maybe<(
        { __typename?: 'Vessel' }
        & Pick<Vessel, 'vesselName'>
      )>, loadingPoint?: Maybe<(
        { __typename?: 'FoodBusinessOperator' }
        & Pick<FoodBusinessOperator, 'name'>
      )>, offloadingPoint?: Maybe<(
        { __typename?: 'FoodBusinessOperator' }
        & Pick<FoodBusinessOperator, 'name'>
      )>, sentEmailList?: Maybe<Array<(
        { __typename?: 'SentEmail' }
        & Pick<SentEmail, 'id' | 'createdAt' | 'recipient' | 'attachments'>
      )>> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type LoiFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type LoiFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { loadOutInstructionsFilterAttributes?: Maybe<(
    { __typename?: 'LoadOutInstructionFilterAttribute' }
    & { commodities?: Maybe<Array<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )>>, vessels?: Maybe<Array<(
      { __typename?: 'Vessel' }
      & Pick<Vessel, 'id' | 'vesselName'>
    )>>, varieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
    )>>, exporters?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, contacts?: Maybe<Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'contactType'>
    )>>, loadingPoints?: Maybe<Array<(
      { __typename?: 'FoodBusinessOperator' }
      & Pick<FoodBusinessOperator, 'id' | 'name'>
    )>>, offloadingPoints?: Maybe<Array<(
      { __typename?: 'FoodBusinessOperator' }
      & Pick<FoodBusinessOperator, 'id' | 'name'>
    )>> }
  )> }
);

export type LoiReferenceQueryVariables = Exact<{ [key: string]: never; }>;


export type LoiReferenceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'loadOutInstructionReference'>
);

export type AirFreightShipmentLineItemsWithNoLoiQueryVariables = Exact<{ [key: string]: never; }>;


export type AirFreightShipmentLineItemsWithNoLoiQuery = (
  { __typename?: 'Query' }
  & { airFreightShipmentLineItemsWithNoLoi?: Maybe<Array<(
    { __typename?: 'AirFreightShipmentLineItem' }
    & Pick<AirFreightShipmentLineItem, 'id' | 'orderReference' | 'bookingReference' | 'finalDestinationLocation' | 'externalShipmentId' | 'eta' | 'finalDestinationCode' | 'goodsDescription'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, airFreightShipment?: Maybe<(
      { __typename?: 'AirFreightShipment' }
      & Pick<AirFreightShipment, 'masterWaybillNumber' | 'shipper' | 'estimatedDepartureTime' | 'controller' | 'portOfLoadingCode'>
    )> }
  )>> }
);

export type LoiExportersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  orderbyloi?: Maybe<Scalars['Boolean']>;
  companyname?: Maybe<Scalars['String']>;
}>;


export type LoiExportersQuery = (
  { __typename?: 'Query' }
  & { exporters?: Maybe<Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>> }
);

export type LoiStuffLocationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  orderbyloi?: Maybe<Scalars['Boolean']>;
  fboname?: Maybe<Scalars['String']>;
}>;


export type LoiStuffLocationsQuery = (
  { __typename?: 'Query' }
  & { stuffLocations?: Maybe<Array<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type LoiTruckingLoadingPointsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  orderByLoi?: Maybe<Scalars['Boolean']>;
  fboName?: Maybe<Scalars['String']>;
}>;


export type LoiTruckingLoadingPointsQuery = (
  { __typename?: 'Query' }
  & { truckingLoadingPoints?: Maybe<Array<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type LoiTruckingOffloadingPointsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  orderByLoi?: Maybe<Scalars['Boolean']>;
  fboName?: Maybe<Scalars['String']>;
}>;


export type LoiTruckingOffloadingPointsQuery = (
  { __typename?: 'Query' }
  & { truckingOffloadingPoints?: Maybe<Array<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type LoiSeaPortsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  orderbyloi?: Maybe<Scalars['Boolean']>;
  seaportname?: Maybe<Scalars['String']>;
  isportofloading?: Maybe<Scalars['Boolean']>;
  stufflocationid?: Maybe<Scalars['Int']>;
}>;


export type LoiSeaPortsQuery = (
  { __typename?: 'Query' }
  & { seaPorts?: Maybe<Array<(
    { __typename?: 'SeaPort' }
    & Pick<SeaPort, 'id' | 'name' | 'country'>
  )>> }
);

export type LoiVesselsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  vesselname?: Maybe<Scalars['String']>;
  seaportid?: Maybe<Scalars['Int']>;
}>;


export type LoiVesselsQuery = (
  { __typename?: 'Query' }
  & { vessels?: Maybe<Array<(
    { __typename?: 'Vessel' }
    & Pick<Vessel, 'id' | 'vesselName'>
  )>> }
);

export type LoiVoyagesQueryVariables = Exact<{
  vesselid: Scalars['Int'];
  seaportid?: Maybe<Scalars['Int']>;
}>;


export type LoiVoyagesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'voyages'>
);

export type LoiContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoiContactsQuery = (
  { __typename?: 'Query' }
  & { contacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'info' | 'contactType'>
  )>> }
);

export type LoiUnassignedCgFilterAttributesQueryVariables = Exact<{
  attributes: CartonGroupingSearchAttributes;
}>;


export type LoiUnassignedCgFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { cartonGroupingsSearch?: Maybe<(
    { __typename?: 'CartonGroupingSearch' }
    & { filterValues?: Maybe<(
      { __typename?: 'CartonGroupingFilters' }
      & Pick<CartonGroupingFilters, 'batchNumber' | 'consignmentNumber' | 'packingWeek' | 'targetMarket'>
      & { variety?: Maybe<Array<(
        { __typename?: 'Variety' }
        & Pick<Variety, 'id' | 'name'>
      )>>, seller?: Maybe<Array<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      )>> }
    )> }
  )> }
);

export type LoiUnassignedCartonGroupingsQueryVariables = Exact<{
  attributes: CartonGroupingSearchAttributes;
  loadingPointId?: Maybe<Scalars['Int']>;
  portOfLoadingId?: Maybe<Scalars['Int']>;
  transportType?: Maybe<Scalars['String']>;
  hideReassignable?: Maybe<Scalars['Boolean']>;
}>;


export type LoiUnassignedCartonGroupingsQuery = (
  { __typename?: 'Query' }
  & { loadOutInstructionCgs: Array<(
    { __typename?: 'LoiCartonGrouping' }
    & Pick<LoiCartonGrouping, 'id' | 'batchNumber' | 'buyerName' | 'cartons' | 'consignmentNumber' | 'commodityName' | 'exporterCode' | 'farmCode' | 'inventoryCode' | 'mark' | 'orchard' | 'grade' | 'pack' | 'packingWeek' | 'palletNumber' | 'sellerName' | 'sequenceNumber' | 'sizeCount' | 'state' | 'subCommodityName' | 'targetMarket' | 'targetRegion' | 'varietyName' | 'palletAge' | 'existingLoadOut' | 'isReassignable' | 'netMass' | 'palletGrossMass'>
  )> }
);

export type LoiUnassignedCGsQueryVariables = Exact<{
  attributes: CartonGroupingSearchAttributes;
}>;


export type LoiUnassignedCGsQuery = (
  { __typename?: 'Query' }
  & { cartonGroupingsSearch?: Maybe<(
    { __typename?: 'CartonGroupingSearch' }
    & Pick<CartonGroupingSearch, 'totalCount' | 'totalCountPallets'>
    & { cartonGroupings: Array<(
      { __typename?: 'CartonGrouping' }
      & Pick<CartonGrouping, 'batchNumber' | 'cartons' | 'consignmentNumber' | 'exporterCode' | 'farmCode' | 'grade' | 'id' | 'inventoryCode' | 'mark' | 'netMass' | 'orchard' | 'pack' | 'packhouseCode' | 'packingWeek' | 'palletGrossMass' | 'palletNumber' | 'sequenceNumber' | 'sizeCount' | 'state' | 'targetCountry' | 'targetMarket' | 'targetRegion' | 'palletAge'>
      & { buyer?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )>, seller?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )>, variety?: Maybe<(
        { __typename?: 'Variety' }
        & Pick<Variety, 'name'>
        & { commodity?: Maybe<(
          { __typename?: 'Commodity' }
          & Pick<Commodity, 'name'>
        )>, subCommodity?: Maybe<(
          { __typename?: 'SubCommodity' }
          & Pick<SubCommodity, 'name'>
        )> }
      )>, loadOutInstructions?: Maybe<Array<(
        { __typename?: 'LoadOutInstruction' }
        & Pick<LoadOutInstruction, 'loadOutReference' | 'transportType'>
        & { loadingPoint?: Maybe<(
          { __typename?: 'FoodBusinessOperator' }
          & Pick<FoodBusinessOperator, 'id' | 'name' | 'createdAt' | 'updatedAt'>
        )>, portOfLoading?: Maybe<(
          { __typename?: 'SeaPort' }
          & LoiPortFieldsFragment
        )> }
      )>> }
    )>, filterValues?: Maybe<(
      { __typename?: 'CartonGroupingFilters' }
      & Pick<CartonGroupingFilters, 'batchNumber' | 'consignmentNumber' | 'packingWeek' | 'targetMarket'>
      & { variety?: Maybe<Array<(
        { __typename?: 'Variety' }
        & Pick<Variety, 'id' | 'name'>
      )>>, seller?: Maybe<Array<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      )>> }
    )> }
  )> }
);

export type LoiQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type LoiQuery = (
  { __typename?: 'Query' }
  & { loadOutInstruction?: Maybe<(
    { __typename?: 'LoadOutInstruction' }
    & { airFreightShipmentLineItem?: Maybe<(
      { __typename?: 'AirFreightShipmentLineItem' }
      & Pick<AirFreightShipmentLineItem, 'id' | 'orderReference' | 'bookingReference' | 'externalShipmentId' | 'updatedAt' | 'createdAt'>
    )>, cartonGroupings?: Maybe<Array<(
      { __typename?: 'CartonGrouping' }
      & CgCommonFieldsFragment
    )>>, loiCartonGroupings?: Maybe<Array<(
      { __typename?: 'LoiCartonGrouping' }
      & Pick<LoiCartonGrouping, 'id' | 'batchNumber' | 'buyerName' | 'cartons' | 'consignmentNumber' | 'commodityName' | 'exporterCode' | 'farmCode' | 'inventoryCode' | 'mark' | 'orchard' | 'grade' | 'pack' | 'packingWeek' | 'palletNumber' | 'sellerName' | 'sequenceNumber' | 'sizeCount' | 'state' | 'subCommodityName' | 'targetMarket' | 'targetRegion' | 'varietyName' | 'palletAge' | 'existingLoadOut' | 'isReassignable' | 'netMass' | 'palletGrossMass'>
    )>> }
    & LoiCommonFieldsFragment
  )> }
);

export type CreateLoiMutationVariables = Exact<{
  attributes: LoadOutAttributes;
}>;


export type CreateLoiMutation = (
  { __typename?: 'Mutation' }
  & { createLoadOutInstruction?: Maybe<(
    { __typename?: 'CreateLoadOutInstructionPayload' }
    & Pick<CreateLoadOutInstructionPayload, 'errors'>
    & { loadOutInstruction?: Maybe<(
      { __typename?: 'LoadOutInstruction' }
      & { cartonGroupings?: Maybe<Array<(
        { __typename?: 'CartonGrouping' }
        & Pick<CartonGrouping, 'id'>
      )>> }
      & LoiCommonFieldsFragment
    )> }
  )> }
);

export type UpdateLoiMutationVariables = Exact<{
  attributes: LoadOutAttributes;
}>;


export type UpdateLoiMutation = (
  { __typename?: 'Mutation' }
  & { updateLoadOutInstruction?: Maybe<(
    { __typename?: 'UpdateLoadOutInstructionPayload' }
    & Pick<UpdateLoadOutInstructionPayload, 'errors'>
    & { loadOutInstruction?: Maybe<(
      { __typename?: 'LoadOutInstruction' }
      & { cartonGroupings?: Maybe<Array<(
        { __typename?: 'CartonGrouping' }
        & Pick<CartonGrouping, 'id'>
      )>> }
      & LoiCommonFieldsFragment
    )> }
  )> }
);

export type DeleteLoiMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLoiMutation = (
  { __typename?: 'Mutation' }
  & { deleteLoadOutInstruction?: Maybe<(
    { __typename?: 'DeleteLoadOutInstructionPayload' }
    & Pick<DeleteLoadOutInstructionPayload, 'success'>
  )> }
);

export type GenerateLoadOutInstructionOutputFilesMutationVariables = Exact<{
  loadoutinstructionid: Scalars['ID'];
}>;


export type GenerateLoadOutInstructionOutputFilesMutation = (
  { __typename?: 'Mutation' }
  & { generateLoadOutInstructionOutputFiles?: Maybe<(
    { __typename?: 'GenerateLoadOutInstructionOutputFilesPayload' }
    & { templateOutput?: Maybe<Array<(
      { __typename?: 'TemplateOutput' }
      & Pick<TemplateOutput, 'errorReason' | 'id' | 'state'>
    )>> }
  )> }
);

export type GeneratePalletInOutputFilesMutationVariables = Exact<{
  loadOutInstructionId: Scalars['ID'];
}>;


export type GeneratePalletInOutputFilesMutation = (
  { __typename?: 'Mutation' }
  & { generatePalletInOutputFiles?: Maybe<(
    { __typename?: 'GeneratePalletInOutputFilesPayload' }
    & Pick<GeneratePalletInOutputFilesPayload, 'palletInId'>
  )> }
);

export type OnPalletInOutputStateChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnPalletInOutputStateChangeSubscription = (
  { __typename?: 'Subscription' }
  & { onPalletInOutputStateChange: (
    { __typename?: 'PalletIn' }
    & PalletInFieldsFragment
  ) }
);

export type PalletInFieldsFragment = (
  { __typename?: 'PalletIn' }
  & Pick<PalletIn, 'id' | 'loadOutInstructionId' | 'state' | 'updatedAt'>
  & { outputFile?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'contentType' | 'filename' | 'v2Url' | 'id' | 'url'>
  )> }
);

export type LoiCommonFieldsFragment = (
  { __typename?: 'LoadOutInstruction' }
  & Pick<LoadOutInstruction, 'bookingReference' | 'transportType' | 'containerNumber' | 'estimatedTimeOfDelivery' | 'exportersReference' | 'id' | 'loadOutReference' | 'notes' | 'printedDate' | 'q67Reference' | 'shippingLine' | 'stuffDate' | 'temperatureSetting' | 'voyage' | 'truckNumber' | 'fileNumber' | 'airWaybillNo' | 'shipper' | 'destination' | 'allocatedWeight' | 'routing' | 'flightDate' | 'connectingFlightDate' | 'equipment' | 'phytoCertificate' | 'orderPreparedBy' | 'orderPickedBy' | 'checked'>
  & { contacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'info' | 'contactType'>
  )>>, exporter?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, portOfDestination?: Maybe<(
    { __typename?: 'SeaPort' }
    & LoiPortFieldsFragment
  )>, portOfLoading?: Maybe<(
    { __typename?: 'SeaPort' }
    & LoiPortFieldsFragment
  )>, stuffLocation?: Maybe<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )>, vessel?: Maybe<(
    { __typename?: 'Vessel' }
    & Pick<Vessel, 'id' | 'vesselName'>
  )>, templateOutput?: Maybe<(
    { __typename?: 'TemplateOutput' }
    & TemplateOutputFieldsFragment
  )>, palletIn?: Maybe<(
    { __typename?: 'PalletIn' }
    & PalletInFieldsFragment
  )>, loadingPoint?: Maybe<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )>, offloadingPoint?: Maybe<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CgCommonFieldsFragment = (
  { __typename?: 'CartonGrouping' }
  & Pick<CartonGrouping, 'batchNumber' | 'cartons' | 'consignmentNumber' | 'exporterCode' | 'farmCode' | 'grade' | 'id' | 'inventoryCode' | 'mark' | 'netMass' | 'orchard' | 'pack' | 'packhouseCode' | 'packingWeek' | 'palletGrossMass' | 'palletNumber' | 'sequenceNumber' | 'sizeCount' | 'state' | 'targetCountry' | 'targetMarket' | 'targetRegion' | 'palletAge'>
  & { buyer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )>, seller?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )>, variety?: Maybe<(
    { __typename?: 'Variety' }
    & Pick<Variety, 'name'>
    & { commodity?: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'name'>
    )>, subCommodity?: Maybe<(
      { __typename?: 'SubCommodity' }
      & Pick<SubCommodity, 'name'>
    )> }
  )> }
);

export type LoiPortFieldsFragment = (
  { __typename?: 'SeaPort' }
  & Pick<SeaPort, 'id' | 'name' | 'country' | 'createdAt' | 'updatedAt'>
);

export type VesselMapFragment = (
  { __typename?: 'Vessel' }
  & Pick<Vessel, 'vesselName' | 'voyageNumber' | 'lat' | 'long'>
);

export type SeaPortMapFragment = (
  { __typename?: 'SeaPort' }
  & Pick<SeaPort, 'id' | 'name' | 'country' | 'unLocode' | 'latitude' | 'longitude' | 'createdAt' | 'updatedAt'>
);

export type FboMapFragment = (
  { __typename?: 'FoodBusinessOperator' }
  & Pick<FoodBusinessOperator, 'id' | 'name' | 'fboCode' | 'typeOfBusiness' | 'latitude' | 'longitude' | 'createdAt' | 'updatedAt'>
);

export type CurrentMapPointsForOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CurrentMapPointsForOrderQuery = (
  { __typename?: 'Query' }
  & { currentMapPointsForOrder?: Maybe<Array<(
    { __typename?: 'MapPoint' }
    & Pick<MapPoint, 'totalCartons'>
    & { containers?: Maybe<Array<(
      { __typename?: 'MapPointContainer' }
      & Pick<MapPointContainer, 'id' | 'containerNumber' | 'orderId' | 'otherPartyName' | 'vesselName' | 'portOfDestination' | 'eta'>
    )>>, orders?: Maybe<Array<(
      { __typename?: 'MapPointOrder' }
      & Pick<MapPointOrder, 'orderId' | 'otherPartyName' | 'totalCartons'>
    )>>, location?: Maybe<(
      { __typename: 'FoodBusinessOperator' }
      & FboMapFragment
    ) | (
      { __typename: 'SeaPort' }
      & SeaPortMapFragment
    ) | (
      { __typename: 'Vessel' }
      & VesselMapFragment
    )> }
  )>> }
);

export type FoodBusinessOperatorsQueryVariables = Exact<{ [key: string]: never; }>;


export type FoodBusinessOperatorsQuery = (
  { __typename?: 'Query' }
  & { foodBusinessOperators?: Maybe<Array<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'fboCode' | 'typeOfBusiness' | 'producerName' | 'latitude' | 'longitude' | 'globalGapNumber' | 'updatedAt'>
  )>> }
);

export type FarmVarietiesQueryVariables = Exact<{ [key: string]: never; }>;


export type FarmVarietiesQuery = (
  { __typename?: 'Query' }
  & { farmVarieties: Array<(
    { __typename?: 'FarmVariety' }
    & Pick<FarmVariety, 'id' | 'name' | 'varietyCodes' | 'commodityName' | 'hectares' | 'peakCount' | 'packingStart' | 'packingEnd' | 'updatedAt'>
  )> }
);

export type ListVarietiesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListVarietiesQuery = (
  { __typename?: 'Query' }
  & { listVarieties?: Maybe<Array<(
    { __typename?: 'Variety' }
    & Pick<Variety, 'id' | 'varietyName' | 'name' | 'varietyCode'>
    & { commodity?: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )>, subCommodity?: Maybe<(
      { __typename?: 'SubCommodity' }
      & Pick<SubCommodity, 'id' | 'name'>
    )> }
  )>> }
);

export type CgErrorsQueryVariables = Exact<{ [key: string]: never; }>;


export type CgErrorsQuery = (
  { __typename?: 'Query' }
  & { cgErrors: Array<(
    { __typename?: 'CgError' }
    & Pick<CgError, 'id' | 'errorType' | 'errorMessage' | 'state' | 'errorContext'>
  )> }
);

export type FarmVarietyClientChangeRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type FarmVarietyClientChangeRequestsQuery = (
  { __typename?: 'Query' }
  & { farmVarietyClientChangeRequests: Array<(
    { __typename?: 'FarmVarietyRequest' }
    & Pick<FarmVarietyRequest, 'name' | 'status' | 'varietyCodes' | 'commodityName'>
  )> }
);

export type CreateFarmVarietyClienChangeRequestMutationVariables = Exact<{
  modelId: Scalars['Int'];
  name: Scalars['String'];
  varietyId: Scalars['Int'];
  varietyCodes: Scalars['String'];
  commodityName: Scalars['String'];
  commodityId: Scalars['Int'];
  action: Scalars['String'];
}>;


export type CreateFarmVarietyClienChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & { createFarmVarietyClientChangeRequest?: Maybe<(
    { __typename?: 'CreateFarmVarietyRequestPayload' }
    & { clientChangeRequest?: Maybe<(
      { __typename?: 'FarmVarietyRequest' }
      & Pick<FarmVarietyRequest, 'status' | 'name' | 'varietyCodes' | 'commodityName'>
    )> }
  )> }
);

export type ResolveCgErrorMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResolveCgErrorMutation = (
  { __typename?: 'Mutation' }
  & { resolveCgError?: Maybe<(
    { __typename?: 'ResolveCgErrorPayload' }
    & { cgError: (
      { __typename?: 'CgError' }
      & Pick<CgError, 'state'>
    ) }
  )> }
);

export type MetabaseReportFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type MetabaseReportFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { metabaseReportFilterAttributes?: Maybe<(
    { __typename?: 'MetabaseReportsFilterAttribute' }
    & { badges?: Maybe<Array<(
      { __typename?: 'Badge' }
      & Pick<Badge, 'id' | 'text'>
    )>> }
  )> }
);

export type MetabaseReportsQueryVariables = Exact<{
  filters: MetabaseReportFilters;
  searchTerm: Scalars['String'];
  searchField: Scalars['String'];
}>;


export type MetabaseReportsQuery = (
  { __typename?: 'Query' }
  & { metabaseReports?: Maybe<Array<(
    { __typename?: 'MetabaseReport' }
    & MetabaseReportFieldsFragment
  )>> }
);

export type MetabaseReportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MetabaseReportQuery = (
  { __typename?: 'Query' }
  & { metabaseReport?: Maybe<(
    { __typename?: 'MetabaseReport' }
    & MetabaseReportFieldsFragment
  )> }
);

export type MetabaseReportFieldsFragment = (
  { __typename?: 'MetabaseReport' }
  & Pick<MetabaseReport, 'chartSource' | 'description' | 'domain' | 'id' | 'name' | 'displayName' | 'scope' | 'url'>
  & { badges?: Maybe<Array<(
    { __typename?: 'Badge' }
    & Pick<Badge, 'color' | 'text'>
  )>>, powerbiEmbedAttributes?: Maybe<(
    { __typename?: 'EmbeddedChart' }
    & Pick<EmbeddedChart, 'embedToken' | 'embedUrl' | 'reportId'>
  )> }
);

export type OrdersV3QueryVariables = Exact<{
  filterAttributes: OrderFilterAttributes;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes> | ColumnOrderAttributes>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type OrdersV3Query = (
  { __typename?: 'Query' }
  & { ordersV3?: Maybe<(
    { __typename?: 'OrderConnection' }
    & Pick<OrderConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'state' | 'updatedAt' | 'createdAt'>
      & { seller?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      )>, buyer?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      )>, lineItemGroupings?: Maybe<Array<(
        { __typename?: 'LineItemGrouping' }
        & Pick<LineItemGrouping, 'commonFields' | 'cumulativeQuantity' | 'cumulativeTooltip' | 'deleted' | 'id' | 'numberCumulativeSiblings' | 'quantityType' | 'rank'>
        & { lineItems?: Maybe<Array<(
          { __typename?: 'LineItem' }
          & SingleLineItemFieldsFragment
          & StatesDistributionFieldsFragment
        )>> }
      )>> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type OrdersFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { ordersFilterAttributes?: Maybe<(
    { __typename?: 'OrderFilterAttribute' }
    & { buyers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, commodities?: Maybe<Array<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )>>, sellers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, varieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name' | 'varietyCode'>
    )>> }
  )> }
);

export type ShipmentDocumentFieldsFragment = (
  { __typename?: 'ShipmentDocument' }
  & Pick<ShipmentDocument, 'id' | 'documentType'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    )> }
  )>, file?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'filename' | 'v2Url'>
  )> }
);

export type SingleLineItemFieldsFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'id' | 'additionalFields' | 'packingWeek' | 'startWeek' | 'endWeek' | 'targetRegion' | 'targetCountry' | 'targetMarket' | 'quantity' | 'plannedQuantity' | 'plannedQuantityUnit' | 'incoterm' | 'pack' | 'palletStack' | 'priority' | 'quantityUnit' | 'priceMinorUnit' | 'sizeCounts' | 'priceTerm' | 'currency' | 'state' | 'priceUnit' | 'grade' | 'mark' | 'commodityId' | 'varietyId' | 'packedCartons' | 'packedPallets' | 'referenceNumber' | 'rank' | 'batchNumber' | 'inventoryCode' | 'unlimited' | 'orderId' | 'lineItemGroupingId'>
  & { variety?: Maybe<(
    { __typename?: 'Variety' }
    & Pick<Variety, 'id' | 'name' | 'varietyCode'>
    & { commodity?: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )>, subCommodity?: Maybe<(
      { __typename?: 'SubCommodity' }
      & Pick<SubCommodity, 'id' | 'name'>
    )> }
  )> }
);

export type StatesDistributionFieldsFragment = (
  { __typename?: 'LineItem' }
  & { statesDistribution?: Maybe<Array<(
    { __typename?: 'LineItemState' }
    & Pick<LineItemState, 'quantity' | 'quantityTotal' | 'palletQuantity' | 'palletQuantityTotal' | 'state' | 'supplyChainOrder'>
  )>> }
);

export type ExpectedNetReturnFieldsFragment = (
  { __typename?: 'LineItem' }
  & { expectedReturnSummary?: Maybe<(
    { __typename?: 'ExpectedReturnSummary' }
    & Pick<ExpectedReturnSummary, 'unit' | 'error'>
    & { equivalentPrice?: Maybe<(
      { __typename?: 'IncotermPrice' }
      & Pick<IncotermPrice, 'incoterm'>
      & { moneyAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'displayString'>
      ) }
    )>, pricePoints?: Maybe<Array<(
      { __typename?: 'IncotermPrice' }
      & Pick<IncotermPrice, 'incoterm'>
      & { moneyAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        ) }
      ), chargesApplied?: Maybe<Array<(
        { __typename?: 'ChargeApplied' }
        & Pick<ChargeApplied, 'name'>
        & { moneyAmount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount'>
        ) }
      )>> }
    )>> }
  )> }
);

export type QuantityTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type QuantityTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'quantityType'>
);

export type SingleOrderFieldsWithNetReturnFragment = (
  { __typename?: 'Order' }
  & { lineItemGroupings?: Maybe<Array<(
    { __typename?: 'LineItemGrouping' }
    & { lineItems?: Maybe<Array<(
      { __typename?: 'LineItem' }
      & ExpectedNetReturnFieldsFragment
    )>> }
  )>> }
  & SingleOrderFieldsFragment
);

export type SingleOrderFieldsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'state' | 'isPackhouseRun' | 'createdAt' | 'updatedAt'>
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & CommentFieldsFragment
  )>>, buyer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'v2Url'>
    )>, additionalFields?: Maybe<Array<(
      { __typename?: 'AdditionalField' }
      & Pick<AdditionalField, 'id' | 'name'>
    )>>, sellerVarieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
      & { commodity?: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )> }
    )>> }
  )>, seller?: Maybe<(
    { __typename?: 'Company' }
    & SellerCompanyFieldsFragment
  )>, lineItems?: Maybe<Array<(
    { __typename?: 'LineItem' }
    & SingleLineItemFieldsFragment
  )>>, lineItemGroupings?: Maybe<Array<(
    { __typename?: 'LineItemGrouping' }
    & Pick<LineItemGrouping, 'id' | 'cumulativeQuantity' | 'quantityType' | 'commonFields' | 'deleted' | 'rank'>
    & { lineItems?: Maybe<Array<(
      { __typename?: 'LineItem' }
      & SingleLineItemFieldsFragment
      & StatesDistributionFieldsFragment
    )>> }
  )>>, audits?: Maybe<Array<(
    { __typename?: 'Audit' }
    & Pick<Audit, 'id' | 'action' | 'auditableType' | 'auditableId' | 'associatedType' | 'associatedId' | 'createdAt' | 'auditedChanges' | 'version'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      )> }
    )> }
  )>>, shipmentDocuments?: Maybe<Array<(
    { __typename?: 'ShipmentDocument' }
    & ShipmentDocumentFieldsFragment
  )>> }
);

export type MultipleOrderFieldsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'state' | 'isPackhouseRun' | 'updatedAt' | 'createdAt'>
  & { lineItemGroupings?: Maybe<Array<(
    { __typename?: 'LineItemGrouping' }
    & Pick<LineItemGrouping, 'commonFields' | 'cumulativeQuantity' | 'cumulativeTooltip' | 'deleted' | 'id' | 'numberCumulativeSiblings' | 'quantityType' | 'rank'>
    & { lineItems?: Maybe<Array<(
      { __typename?: 'LineItem' }
      & SingleLineItemFieldsFragment
      & StatesDistributionFieldsFragment
      & ExpectedNetReturnFieldsFragment
    )>> }
  )>>, buyer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'v2Url'>
    )>, sellerVarieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
      & { commodity?: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )> }
    )>> }
  )>, seller?: Maybe<(
    { __typename?: 'Company' }
    & SellerCompanyFieldsFragment
  )> }
);

export type SellerCompanyFieldsFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { logoFile?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'filename' | 'v2Url'>
  )>, varieties?: Maybe<Array<(
    { __typename?: 'FarmVariety' }
    & Pick<FarmVariety, 'id' | 'varietyId' | 'name'>
    & { commodity?: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )> }
  )>>, additionalFields?: Maybe<Array<(
    { __typename?: 'AdditionalField' }
    & Pick<AdditionalField, 'id' | 'name'>
  )>> }
);

export type DuplicateOrderFieldsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'isPackhouseRun'>
  & { lineItemGroupings?: Maybe<Array<(
    { __typename?: 'LineItemGrouping' }
    & Pick<LineItemGrouping, 'id' | 'cumulativeQuantity' | 'quantityType' | 'commonFields' | 'deleted' | 'rank'>
    & { lineItems?: Maybe<Array<(
      { __typename?: 'LineItem' }
      & SingleLineItemFieldsFragment
    )>> }
  )>>, buyer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'v2Url'>
    )>, sellerVarieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
      & { commodity?: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )> }
    )>> }
  )>, seller?: Maybe<(
    { __typename?: 'Company' }
    & SellerCompanyFieldsFragment
  )> }
);

export type CreateOrderMutationVariables = Exact<{
  attributes: CreateOrderAttributes;
  lineItemStates?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  packingWeekStart?: Maybe<Scalars['String']>;
  packingWeekEnd?: Maybe<Scalars['String']>;
  varietyIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  commodityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  subCommodityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  states?: Maybe<Array<StateFilter> | StateFilter>;
  currency?: Maybe<CurrencyEnum>;
  unit?: Maybe<Scalars['String']>;
  incoterm?: Maybe<IncotermWithNscEnum>;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder?: Maybe<(
    { __typename?: 'CreateOrderPayload' }
    & Pick<CreateOrderPayload, 'errors'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & MultipleOrderFieldsFragment
    )> }
  )> }
);

export type OrdersSearchQueryVariables = Exact<{
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  filter: OrderFilter;
  lineItemStates?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  packingWeekStart?: Maybe<Scalars['String']>;
  packingWeekEnd?: Maybe<Scalars['String']>;
  varietyIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  commodityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  subCommodityIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  states?: Maybe<Array<StateFilter> | StateFilter>;
  currency?: Maybe<CurrencyEnum>;
  unit?: Maybe<Scalars['String']>;
  incoterm?: Maybe<IncotermWithNscEnum>;
}>;


export type OrdersSearchQuery = (
  { __typename?: 'Query' }
  & { ordersSearch?: Maybe<(
    { __typename?: 'OrderSearch' }
    & Pick<OrderSearch, 'totalOrders' | 'totalFiltered'>
    & { orders?: Maybe<Array<(
      { __typename?: 'Order' }
      & MultipleOrderFieldsFragment
    )>> }
  )> }
);

export type OrdersSearchFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersSearchFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { ordersSearchFilterAttributes?: Maybe<(
    { __typename?: 'OrdersSearchFilterAttributes' }
    & { commodities?: Maybe<Array<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )>>, varieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
    )>>, subCommodities?: Maybe<Array<(
      { __typename?: 'SubCommodity' }
      & Pick<SubCommodity, 'id' | 'name'>
    )>>, companies?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>> }
  )> }
);

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
  currency?: Maybe<CurrencyEnum>;
  unit?: Maybe<Scalars['String']>;
  incoterm?: Maybe<IncotermWithNscEnum>;
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & SingleOrderFieldsWithNetReturnFragment
  )> }
);

export type DuplicateOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateOrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & DuplicateOrderFieldsFragment
  )> }
);

export type UpdateOrderMutationVariables = Exact<{
  attributes: UpdateOrderAttributes;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder?: Maybe<(
    { __typename?: 'UpdateOrderPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )> }
  )> }
);

export type UpdateLineItemsMutationVariables = Exact<{
  attributes: Array<UpdateLineItemAttributes> | UpdateLineItemAttributes;
}>;


export type UpdateLineItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateLineItems?: Maybe<(
    { __typename?: 'UpdateLineItemsPayload' }
    & Pick<UpdateLineItemsPayload, 'errors'>
    & { lineItems?: Maybe<Array<(
      { __typename?: 'LineItem' }
      & SingleLineItemFieldsFragment
    )>> }
  )> }
);

export type AcceptLineItemsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AcceptLineItemsMutation = (
  { __typename?: 'Mutation' }
  & { acceptLineItems?: Maybe<(
    { __typename?: 'AcceptLineItemsPayload' }
    & { orders?: Maybe<Array<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )>> }
  )> }
);

export type WontPackLineItemsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type WontPackLineItemsMutation = (
  { __typename?: 'Mutation' }
  & { wontPackLineItems?: Maybe<(
    { __typename?: 'WontPackLineItemsPayload' }
    & { orders?: Maybe<Array<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )>> }
  )> }
);

export type RevertLineItemsToCreatedMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RevertLineItemsToCreatedMutation = (
  { __typename?: 'Mutation' }
  & { revertLineItemsToCreated?: Maybe<(
    { __typename?: 'RevertLineItemsToCreatedPayload' }
    & { orders?: Maybe<Array<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )>> }
  )> }
);

export type RevertOrderChangeMutationVariables = Exact<{
  startid: Scalars['Int'];
  endid: Scalars['Int'];
  orderid: Scalars['Int'];
}>;


export type RevertOrderChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertOrderChange?: Maybe<(
    { __typename?: 'RevertOrderChangePayload' }
    & Pick<RevertOrderChangePayload, 'errors'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )> }
  )> }
);

export type UploadOrderShipmentDocumentMutationVariables = Exact<{
  orderid: Scalars['Int'];
  type: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type UploadOrderShipmentDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadOrderShipmentDocument?: Maybe<(
    { __typename?: 'UploadOrderShipmentDocumentPayload' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & { shipmentDocuments?: Maybe<Array<(
        { __typename?: 'ShipmentDocument' }
        & ShipmentDocumentFieldsFragment
      )>> }
    )> }
  )> }
);

export type NewOrderSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewOrderSubscription = (
  { __typename?: 'Subscription' }
  & { newOrder: (
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  ) }
);

export type OnAcceptLineItemsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAcceptLineItemsSubscription = (
  { __typename?: 'Subscription' }
  & { onAcceptLineItems: (
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  ) }
);

export type OnWontPackLineItemsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnWontPackLineItemsSubscription = (
  { __typename?: 'Subscription' }
  & { onWontPackLineItems: (
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  ) }
);

export type OnRevertLineItemsToCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnRevertLineItemsToCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { onRevertLineItemsToCreated: (
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  ) }
);

export type PackInstructionsLineItemsQueryVariables = Exact<{
  attributes: PackInstructionsAttributes;
}>;


export type PackInstructionsLineItemsQuery = (
  { __typename?: 'Query' }
  & { packInstructions?: Maybe<Array<(
    { __typename?: 'PackInstructionLineItem' }
    & Pick<PackInstructionLineItem, 'groupId' | 'priority' | 'batchNumber' | 'buyer' | 'commodity' | 'grade' | 'id' | 'inventoryCode' | 'mark' | 'orderId' | 'pack' | 'packingWeek' | 'palletStack' | 'quantitesPacked' | 'quantityRequested' | 'quantityUnit' | 'referenceNumber' | 'seller' | 'sizeCounts' | 'state' | 'subCommodity' | 'targetMarket' | 'targetRegion' | 'targetCountry' | 'variety' | 'varietyCode' | 'additionalFields'>
    & { comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'commentText' | 'id' | 'userId' | 'commentableType' | 'updatedAt' | 'isDeleted' | 'createdAt' | 'commentableId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'id'>
        & { avatarFile?: Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'filename' | 'url'>
        )> }
      )> }
    )>> }
  )>> }
);

export type PackInstructionsFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type PackInstructionsFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { packInstructionsFilterAttributes?: Maybe<(
    { __typename?: 'PackInstructionsFilterAttribute' }
    & { buyers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, commodities?: Maybe<Array<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )>>, sellers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, subCommodities?: Maybe<Array<(
      { __typename?: 'SubCommodity' }
      & Pick<SubCommodity, 'id' | 'name'>
    )>>, varieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
    )>> }
  )> }
);

export type RunReportQueryVariables = Exact<{
  reportId: Scalars['Int'];
  gridRequest?: Maybe<GridRequest>;
  reportFilters?: Maybe<Array<Filter> | Filter>;
  reportTab?: Maybe<Tab>;
  outputType?: Maybe<RunReportOutputTypeEnum>;
}>;


export type RunReportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'runReport'>
);

export type RunReportAsyncQueryVariables = Exact<{
  reportId: Scalars['Int'];
  gridRequest?: Maybe<GridRequest>;
  reportFilters?: Maybe<Array<Filter> | Filter>;
  requestUuid: Scalars['String'];
}>;


export type RunReportAsyncQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'runReportAsync'>
);

export type ReportQueryVariables = Exact<{
  reportId: Scalars['Int'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'createdAt' | 'dataPreparationId' | 'description' | 'id' | 'name' | 'updatedAt'>
    & { configs?: Maybe<Array<(
      { __typename?: 'Config' }
      & Pick<Config, 'name' | 'value'>
    )>> }
  )> }
);

export type ReportGroupsQueryVariables = Exact<{
  filters: ReportGroupFilters;
  searchTerm: Scalars['String'];
  searchField: Scalars['String'];
}>;


export type ReportGroupsQuery = (
  { __typename?: 'Query' }
  & { reportGroups?: Maybe<Array<(
    { __typename?: 'ReportGroup' }
    & Pick<ReportGroup, 'name' | 'description' | 'id' | 'domain' | 'scope'>
    & { badges?: Maybe<Array<(
      { __typename?: 'Badge' }
      & Pick<Badge, 'id' | 'text' | 'color'>
    )>> }
  )>> }
);

export type ReportGroupQueryVariables = Exact<{
  reportGroupId: Scalars['Int'];
}>;


export type ReportGroupQuery = (
  { __typename?: 'Query' }
  & { reportGroup?: Maybe<(
    { __typename?: 'ReportGroup' }
    & Pick<ReportGroup, 'id' | 'layout' | 'name' | 'description'>
    & { configs?: Maybe<Array<(
      { __typename?: 'Config' }
      & Pick<Config, 'name' | 'value'>
    )>>, reports?: Maybe<Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'reportType' | 'name'>
    )>> }
  )> }
);

export type FetchReportResultQueryVariables = Exact<{
  requestUuid: Scalars['String'];
}>;


export type FetchReportResultQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fetchReportResult'>
);

export type CreateReportGroupMutationVariables = Exact<{
  attributes: ReportGroupAttributes;
}>;


export type CreateReportGroupMutation = (
  { __typename?: 'Mutation' }
  & { createReportGroup?: Maybe<(
    { __typename?: 'CreateReportGroupPayload' }
    & Pick<CreateReportGroupPayload, 'errors'>
    & { reportGroup?: Maybe<(
      { __typename?: 'ReportGroup' }
      & Pick<ReportGroup, 'description' | 'id' | 'name'>
    )> }
  )> }
);

export type UpdateReportGroupMutationVariables = Exact<{
  attributes: ReportGroupAttributes;
}>;


export type UpdateReportGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateReportGroup?: Maybe<(
    { __typename?: 'UpdateReportGroupPayload' }
    & Pick<UpdateReportGroupPayload, 'errors'>
    & { reportGroup?: Maybe<(
      { __typename?: 'ReportGroup' }
      & Pick<ReportGroup, 'description' | 'id' | 'name'>
    )> }
  )> }
);

export type DeleteReportGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteReportGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteReportGroup?: Maybe<(
    { __typename?: 'DeleteReportGroupPayload' }
    & Pick<DeleteReportGroupPayload, 'success' | 'errors'>
  )> }
);

export type OnReportCompleteSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnReportCompleteSubscription = (
  { __typename?: 'Subscription' }
  & { onReportComplete: (
    { __typename?: 'Status' }
    & Pick<Status, 'requestUuid' | 'status'>
  ) }
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { myUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'state' | 'notifyEmail' | 'notifyBrowserPush' | 'createdAt' | 'updatedAt' | 'useV2' | 'avatar' | 'role'>
    & { avatarFile?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'name'>
    )>> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phonenumber: Scalars['String'];
  notifyemail: Scalars['Boolean'];
  notifybrowserpush: Scalars['Boolean'];
  usev2: Scalars['Boolean'];
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'state' | 'notifyEmail' | 'notifyBrowserPush' | 'useV2'>
    )> }
  )> }
);

export type UploadUserAvatarMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadUserAvatarMutation = (
  { __typename?: 'Mutation' }
  & { uploadUserAvatar?: Maybe<(
    { __typename?: 'UploadUserAvatarPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & { avatarFile?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url'>
      )> }
    )> }
  )> }
);

export type UpdateShipmentDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
  type: Scalars['String'];
}>;


export type UpdateShipmentDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateShipmentDocument?: Maybe<(
    { __typename?: 'UpdateShipmentDocumentPayload' }
    & { shipmentDocument?: Maybe<(
      { __typename?: 'ShipmentDocument' }
      & Pick<ShipmentDocument, 'id' | 'documentType' | 'state'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
        & { company?: Maybe<(
          { __typename?: 'Company' }
          & Pick<Company, 'id'>
        )> }
      )>, file?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'v2Url'>
      )> }
    )> }
  )> }
);

export type DeleteShipmentDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteShipmentDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteShipmentDocument?: Maybe<(
    { __typename?: 'DeleteShipmentDocumentPayload' }
    & Pick<DeleteShipmentDocumentPayload, 'id'>
  )> }
);

export type CreateShipmentMutationVariables = Exact<{
  attributes: ShipmentAttributes;
}>;


export type CreateShipmentMutation = (
  { __typename?: 'Mutation' }
  & { createShipment?: Maybe<(
    { __typename?: 'CreateShipmentPayload' }
    & Pick<CreateShipmentPayload, 'errors'>
    & { shipment?: Maybe<(
      { __typename?: 'Shipment' }
      & ShipmentCommonFieldsFragment
    )> }
  )> }
);

export type UpdateShipmentMutationVariables = Exact<{
  attributes: ShipmentAttributes;
}>;


export type UpdateShipmentMutation = (
  { __typename?: 'Mutation' }
  & { updateShipment?: Maybe<(
    { __typename?: 'UpdateShipmentPayload' }
    & Pick<UpdateShipmentPayload, 'errors'>
    & { shipment?: Maybe<(
      { __typename?: 'Shipment' }
      & ShipmentCommonFieldsFragment
    )> }
  )> }
);

export type CreateShipmentLineItemMutationVariables = Exact<{
  attributes: ShipmentLineItemAttributes;
}>;


export type CreateShipmentLineItemMutation = (
  { __typename?: 'Mutation' }
  & { createShipmentLineItem?: Maybe<(
    { __typename?: 'CreateShipmentLineItemPayload' }
    & Pick<CreateShipmentLineItemPayload, 'errors'>
    & { shipmentLineItem?: Maybe<(
      { __typename?: 'ShipmentLineItem' }
      & SliCommonFieldsFragment
    )> }
  )> }
);

export type DuplicateShipmentLineItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateShipmentLineItemMutation = (
  { __typename?: 'Mutation' }
  & { duplicateShipmentLineItem?: Maybe<(
    { __typename?: 'DuplicateShipmentLineItemPayload' }
    & { shipmentLineItems?: Maybe<Array<(
      { __typename?: 'ShipmentLineItem' }
      & SliCommonFieldsFragment
    )>> }
  )> }
);

export type UpdateShipmentLineItemMutationVariables = Exact<{
  attributes: ShipmentLineItemAttributes;
}>;


export type UpdateShipmentLineItemMutation = (
  { __typename?: 'Mutation' }
  & { updateShipmentLineItem?: Maybe<(
    { __typename?: 'UpdateShipmentLineItemPayload' }
    & Pick<UpdateShipmentLineItemPayload, 'errors'>
    & { shipmentLineItem?: Maybe<(
      { __typename?: 'ShipmentLineItem' }
      & SliCommonFieldsFragment
    )> }
  )> }
);

export type UpdateShipmentLineItemTasksMutationVariables = Exact<{
  attributes: ShipmentLineItemTaskAttributes;
}>;


export type UpdateShipmentLineItemTasksMutation = (
  { __typename?: 'Mutation' }
  & { updateShipmentLineItemTasks?: Maybe<(
    { __typename?: 'UpdateShipmentLineItemTasksPayload' }
    & Pick<UpdateShipmentLineItemTasksPayload, 'errors'>
    & { shipmentLineItems?: Maybe<Array<(
      { __typename?: 'ShipmentLineItem' }
      & IndexShipmentLineItemFieldssFragment
    )>> }
  )> }
);

export type CreateExportNotificationBookingMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateExportNotificationBookingMutation = (
  { __typename?: 'Mutation' }
  & { createExportNotificationBooking?: Maybe<(
    { __typename?: 'CreateExportNotificationBookingPayload' }
    & Pick<CreateExportNotificationBookingPayload, 'errors'>
  )> }
);

export type CreateExportNotificationLineItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateExportNotificationLineItemMutation = (
  { __typename?: 'Mutation' }
  & { createExportNotificationLineItem?: Maybe<(
    { __typename?: 'CreateExportNotificationLineItemPayload' }
    & Pick<CreateExportNotificationLineItemPayload, 'errors'>
  )> }
);

export type UpdateAddendumMutationVariables = Exact<{
  addendumLineItems: Array<AddendumLineItemAttributes> | AddendumLineItemAttributes;
  addendumHeader: AddendumHeaderAttributes;
}>;


export type UpdateAddendumMutation = (
  { __typename?: 'Mutation' }
  & { updateAddendum?: Maybe<(
    { __typename?: 'UpdateAddendumPayload' }
    & Pick<UpdateAddendumPayload, 'errors'>
  )> }
);

export type GenerateAddendumMutationVariables = Exact<{
  sliIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  bookingReference?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
}>;


export type GenerateAddendumMutation = (
  { __typename?: 'Mutation' }
  & { generateAddendum?: Maybe<(
    { __typename?: 'GenerateAddendumPayload' }
    & Pick<GenerateAddendumPayload, 'errors'>
    & { addendumHeader?: Maybe<(
      { __typename?: 'AddendumHeader' }
      & { addendumLineItems?: Maybe<Array<(
        { __typename?: 'AddendumLineItem' }
        & AddendumLineItemFieldsFragment
      )>> }
      & AddendumHeaderFieldsFragment
    )> }
  )> }
);

export type CancelAddendumMutationVariables = Exact<{
  id: Scalars['Int'];
  deleteAddendum: Scalars['Boolean'];
}>;


export type CancelAddendumMutation = (
  { __typename?: 'Mutation' }
  & { cancelAddendum?: Maybe<(
    { __typename?: 'CancelAddendumPayload' }
    & Pick<CancelAddendumPayload, 'id' | 'message'>
  )> }
);

export type ConfirmExportNotificationLineItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ConfirmExportNotificationLineItemMutation = (
  { __typename?: 'Mutation' }
  & { confirmExportNotificationLineItem?: Maybe<(
    { __typename?: 'ConfirmExportNotificationLineItemPayload' }
    & Pick<ConfirmExportNotificationLineItemPayload, 'errors'>
    & { shipment?: Maybe<(
      { __typename?: 'Shipment' }
      & { portOfLoading?: Maybe<(
        { __typename?: 'PpecbPort' }
        & Pick<PpecbPort, 'id' | 'portCode' | 'portName'>
      )>, shipmentLineItems?: Maybe<Array<(
        { __typename?: 'ShipmentLineItem' }
        & SliCommonFieldsFragment
      )>> }
      & ShipmentCommonFieldsFragment
    )> }
  )> }
);

export type CompleteShipmentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteShipmentMutation = (
  { __typename?: 'Mutation' }
  & { completeShipment?: Maybe<(
    { __typename?: 'CompleteShipmentPayload' }
    & Pick<CompleteShipmentPayload, 'errors'>
  )> }
);

export type SubmitAddendumMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SubmitAddendumMutation = (
  { __typename?: 'Mutation' }
  & { submitAddendum?: Maybe<(
    { __typename?: 'SubmitAddendumPayload' }
    & Pick<SubmitAddendumPayload, 'errors' | 'validation'>
    & { addendumHeader?: Maybe<(
      { __typename?: 'AddendumHeader' }
      & { addendumLineItems?: Maybe<Array<(
        { __typename?: 'AddendumLineItem' }
        & AddendumLineItemFieldsFragment
      )>> }
      & AddendumHeaderFieldsFragment
    )> }
  )> }
);

export type QueryAddendumMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type QueryAddendumMutation = (
  { __typename?: 'Mutation' }
  & { queryAddendum?: Maybe<(
    { __typename?: 'QueryAddendumPayload' }
    & Pick<QueryAddendumPayload, 'errors'>
  )> }
);

export type AllocateSliContainerMutationVariables = Exact<{
  sliId: Scalars['Int'];
  sliContainerId?: Maybe<Scalars['Int']>;
  isBreakBulk: Scalars['Boolean'];
}>;


export type AllocateSliContainerMutation = (
  { __typename?: 'Mutation' }
  & { allocateSliContainer?: Maybe<(
    { __typename?: 'AllocateSliContainerPayload' }
    & Pick<AllocateSliContainerPayload, 'errors'>
  )> }
);

export type DeleteShipmentLineItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteShipmentLineItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteShipmentLineItem?: Maybe<(
    { __typename?: 'DeleteShipmentLineItemPayload' }
    & Pick<DeleteShipmentLineItemPayload, 'id'>
  )> }
);

export type DeleteAddendumLineItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAddendumLineItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteAddendumLineItem?: Maybe<(
    { __typename?: 'DeleteAddendumLineItemPayload' }
    & Pick<DeleteAddendumLineItemPayload, 'errors'>
    & { addendumLineItems?: Maybe<Array<(
      { __typename?: 'AddendumLineItem' }
      & AddendumLineItemFieldsFragment
    )>> }
  )> }
);

export type DuplicateAddendumLineItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DuplicateAddendumLineItemMutation = (
  { __typename?: 'Mutation' }
  & { duplicateAddendumLineItem?: Maybe<(
    { __typename?: 'DuplicateAddendumLineItemPayload' }
    & { addendumLineItems?: Maybe<Array<(
      { __typename?: 'AddendumLineItem' }
      & AddendumLineItemFieldsFragment
    )>> }
  )> }
);

export type DeleteExportDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteExportDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteExportDocument?: Maybe<(
    { __typename?: 'DeleteExportDocumentPayload' }
    & Pick<DeleteExportDocumentPayload, 'id'>
  )> }
);

export type UploadExportDocumentMutationVariables = Exact<{
  attributes: ShipmentLineItemAttributes;
  file: Scalars['Upload'];
}>;


export type UploadExportDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadExportDocument?: Maybe<(
    { __typename?: 'UploadExportDocumentPayload' }
    & Pick<UploadExportDocumentPayload, 'errors'>
    & { shipmentLineItem?: Maybe<(
      { __typename?: 'ShipmentLineItem' }
      & { exportDocuments?: Maybe<Array<(
        { __typename?: 'ExportDocument' }
        & ExportDocumentFieldsFragment
      )>> }
    )> }
  )> }
);

export type UpdateExportDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
  type: Scalars['String'];
}>;


export type UpdateExportDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateExportDocument?: Maybe<(
    { __typename?: 'UpdateExportDocumentPayload' }
    & { exportDocument?: Maybe<(
      { __typename?: 'ExportDocument' }
      & Pick<ExportDocument, 'id' | 'documentType'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
        & { company?: Maybe<(
          { __typename?: 'Company' }
          & Pick<Company, 'id'>
        )> }
      )>, file?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'v2Url'>
      )> }
    )> }
  )> }
);

export type ExportDocumentFieldsFragment = (
  { __typename?: 'ExportDocument' }
  & Pick<ExportDocument, 'id' | 'documentType'>
  & { file?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'filename' | 'v2Url'>
  )> }
);

export type ShipmentCommonFieldsFragment = (
  { __typename?: 'Shipment' }
  & Pick<Shipment, 'id' | 'ppecbBookingReference' | 'shippingLine' | 'voyageNumber' | 'shippingLineVoyageNumber' | 'loadPort' | 'billToParty' | 'exporterCode' | 'cfCode' | 'ppecbRegisteredExporterCode' | 'ppecbRegisteredAgentCode' | 'phoneNumber' | 'userName' | 'dateCreated' | 'vesselOriginalEstimatedDateOfDeparture' | 'stackDates' | 'stackDatesClose' | 'shipper' | 'status' | 'vesselName' | 'vesselCode' | 'ppecbVoyageNumber' | 'controller' | 'client' | 'comments' | 'estimatedTimeOfDeparture' | 'actualTimeOfDeparture' | 'exportNotificationError'>
);

export type SliCommonFieldsFragment = (
  { __typename?: 'ShipmentLineItem' }
  & Pick<ShipmentLineItem, 'id' | 'ppecbBookingReference' | 'ppecbBookingItemReference' | 'shippingLineReference' | 'customerReference' | 'regimeCode' | 'finalCountryOfDestination' | 'importPermitNumber' | 'exporterOnCertificate' | 'isGensetRequired' | 'isTranshipment' | 'isColdTreatment' | 'transhipmentVesselName' | 'transhipmentVoyageNumber' | 'transhipmentPort' | 'finalPortOfDischarge' | 'tempSensor' | 'userName' | 'dateCreated' | 'isConventionalShipping' | 'depot' | 'collectionDate' | 'loadingInstructionNumber' | 'haulageType' | 'transporter' | 'receiver' | 'cargoVgm' | 'solas' | 'tempDevice' | 'eta' | 'containerAndSealNumber' | 'paymentTerms' | 'shippingLineContactNumber' | 'humidity' | 'voidPlugs' | 'ventSetting' | 'protocolTemperature' | 'protocolDays' | 'specialInstruction' | 'status' | 'productCodes' | 'containerNumber' | 'sealNumber' | 'nettWeight' | 'grossWeight' | 'state' | 'comments' | 'consignee' | 'serviceContractNumber' | 'totalCartons' | 'exportNotificationError' | 'tareWeight' | 'clientReference' | 'shipmentLineItemGroupingId'>
  & { loadingPoints?: Maybe<Array<(
    { __typename?: 'LoadingPoint' }
    & Pick<LoadingPoint, 'activityPointCode' | 'loadingDate' | 'quantity' | 'containerType' | 'unitOfMeasure'>
  )>>, portOfDestination?: Maybe<(
    { __typename?: 'PpecbWorldPort' }
    & Pick<PpecbWorldPort, 'id' | 'portCode' | 'portName'>
  )>, emptyDepot?: Maybe<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'producerName' | 'name' | 'fboCode'>
  )>, exportDocuments?: Maybe<Array<(
    { __typename?: 'ExportDocument' }
    & ExportDocumentFieldsFragment
  )>>, certificateTransportInstruction?: Maybe<(
    { __typename?: 'CertificateTransportInstruction' }
    & Pick<CertificateTransportInstruction, 'id'>
    & { templateOutput?: Maybe<(
      { __typename?: 'TemplateOutput' }
      & Pick<TemplateOutput, 'state'>
      & TemplateOutputFieldsFragment
    )> }
  )>, tasks?: Maybe<Array<(
    { __typename?: 'TaskableTask' }
    & Pick<TaskableTask, 'id' | 'index' | 'uploadedAt' | 'customDate' | 'hasDocument' | 'title'>
    & { file?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'filename' | 'v2Url'>
    )> }
  )>> }
);

export type IndexShipmentLineItemFieldssFragment = (
  { __typename?: 'ShipmentLineItem' }
  & Pick<ShipmentLineItem, 'id' | 'containerNumber' | 'sealNumber' | 'grossWeight' | 'nettWeight' | 'comments' | 'shippingLineReference' | 'customerReference' | 'consignee' | 'serviceContractNumber' | 'clientReference' | 'state' | 'status' | 'totalCartons' | 'productCodes' | 'createdAt'>
  & { portOfDestination?: Maybe<(
    { __typename?: 'PpecbWorldPort' }
    & Pick<PpecbWorldPort, 'portName'>
  )>, shipment?: Maybe<(
    { __typename?: 'Shipment' }
    & Pick<Shipment, 'id' | 'controller' | 'client' | 'vesselName'>
    & { portOfLoading?: Maybe<(
      { __typename?: 'PpecbPort' }
      & Pick<PpecbPort, 'portName'>
    )> }
  )>, sliContainer?: Maybe<(
    { __typename?: 'SliContainer' }
    & Pick<SliContainer, 'id'>
    & { container?: Maybe<(
      { __typename?: 'Container' }
      & Pick<Container, 'containerNumber'>
    )> }
  )>, sliBreakBulk?: Maybe<(
    { __typename?: 'SliBreakBulk' }
    & Pick<SliBreakBulk, 'id'>
    & { breakBulkContainer?: Maybe<(
      { __typename?: 'BreakBulkContainer' }
      & Pick<BreakBulkContainer, 'name'>
    )> }
  )>, tasks?: Maybe<Array<(
    { __typename?: 'TaskableTask' }
    & Pick<TaskableTask, 'id' | 'index' | 'uploadedAt' | 'customDate' | 'hasDocument' | 'title'>
    & { file?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'filename' | 'v2Url'>
    )> }
  )>> }
);

export type AddendumHeaderFieldsFragment = (
  { __typename?: 'AddendumHeader' }
  & Pick<AddendumHeader, 'id' | 'requestId' | 'exporterCode' | 'cfCode' | 'lspCode' | 'transportType' | 'vesselName' | 'vesselType' | 'voyageNumber' | 'regimeCode' | 'shippingBookingReference' | 'loadPort' | 'dischargePort' | 'shippedTargetCountry' | 'shippedTargetRegion' | 'locationOfIssue' | 'estimatedDepartureDate' | 'eCertAgreementCode' | 'verificationStatus' | 'addendumStatus' | 'ecertStatus' | 'transactionId' | 'errorReason' | 'state'>
);

export type AddendumLineItemFieldsFragment = (
  { __typename?: 'AddendumLineItem' }
  & Pick<AddendumLineItem, 'id' | 'stuffLoadDate' | 'loadPointFboCode' | 'consignmentNumber' | 'phc' | 'inspectedSscc' | 'clientRef' | 'upn' | 'inspectedTargetRegion' | 'commodityCode' | 'varietyCode' | 'productClass' | 'nettWeight' | 'grossWeight' | 'ssccNettWeight' | 'ssccGrossWeight' | 'cartonQuantity' | 'inspectionPoint' | 'inspectorCode' | 'inspectionDate' | 'containerNumber' | 'sequenceNumberOfInspectedSscc' | 'puc' | 'orchard' | 'productionArea' | 'phytoData' | 'sizeCountBerrySize' | 'packCode' | 'palletQuantity'>
);

export type ShipmentLineItemFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentLineItemFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { shipmentLineItemFilterAttributes?: Maybe<(
    { __typename?: 'ShipmentLineItemFilterAttribute' }
    & Pick<ShipmentLineItemFilterAttribute, 'controllers' | 'shippingLineReferences' | 'clients' | 'vessels' | 'states'>
  )> }
);

export type ShipmentPpecbVoyageNumbersQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbVoyageNumbersQuery = (
  { __typename?: 'Query' }
  & { ppecbVoyageNumbers?: Maybe<Array<(
    { __typename?: 'PpecbVoyageNumber' }
    & Pick<PpecbVoyageNumber, 'id' | 'voyageNumber' | 'vesselName' | 'ppecbVoyageNumber'>
  )>> }
);

export type ShipmentPpecbCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbCountriesQuery = (
  { __typename?: 'Query' }
  & { ppecbCountries?: Maybe<Array<(
    { __typename?: 'PpecbCountry' }
    & Pick<PpecbCountry, 'code' | 'name'>
  )>> }
);

export type ShipmentPpecbActivityPointsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbActivityPointsQuery = (
  { __typename?: 'Query' }
  & { ppecbActivityPoints?: Maybe<Array<(
    { __typename?: 'PpecbActivityPoint' }
    & Pick<PpecbActivityPoint, 'code' | 'name'>
  )>> }
);

export type ShipmentPpecbWorldPortsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbWorldPortsQuery = (
  { __typename?: 'Query' }
  & { ppecbWorldPorts?: Maybe<Array<(
    { __typename?: 'PpecbWorldPort' }
    & Pick<PpecbWorldPort, 'id' | 'portCode' | 'portName'>
  )>> }
);

export type ShipmentPpecbContainerTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbContainerTypesQuery = (
  { __typename?: 'Query' }
  & { ppecbContainerTypes?: Maybe<Array<(
    { __typename?: 'PpecbContainerType' }
    & Pick<PpecbContainerType, 'description'>
  )>> }
);

export type ShipmentPpecbProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbProductsQuery = (
  { __typename?: 'Query' }
  & { ppecbProducts?: Maybe<Array<(
    { __typename?: 'PpecbProduct' }
    & Pick<PpecbProduct, 'code' | 'name'>
  )>> }
);

export type ShipmentPpecbVentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbVentsQuery = (
  { __typename?: 'Query' }
  & { ppecbVents?: Maybe<Array<(
    { __typename?: 'PpecbVent' }
    & Pick<PpecbVent, 'code' | 'description'>
  )>> }
);

export type ShipmentPpecbRegimeCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbRegimeCodesQuery = (
  { __typename?: 'Query' }
  & { ppecbRegimeCodes?: Maybe<Array<(
    { __typename?: 'PpecbRegimeCode' }
    & Pick<PpecbRegimeCode, 'regimeCode'>
  )>> }
);

export type ShipmentPpecbPortsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentPpecbPortsQuery = (
  { __typename?: 'Query' }
  & { ppecbPorts?: Maybe<Array<(
    { __typename?: 'PpecbPort' }
    & Pick<PpecbPort, 'id' | 'portCode' | 'portName'>
  )>> }
);

export type ShipmentTerminalsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentTerminalsQuery = (
  { __typename?: 'Query' }
  & { terminals?: Maybe<Array<(
    { __typename?: 'Terminal' }
    & Pick<Terminal, 'id' | 'name' | 'code'>
  )>> }
);

export type SliContainersQueryVariables = Exact<{ [key: string]: never; }>;


export type SliContainersQuery = (
  { __typename?: 'Query' }
  & { sliContainers?: Maybe<Array<(
    { __typename?: 'SliContainer' }
    & Pick<SliContainer, 'id'>
    & { container?: Maybe<(
      { __typename?: 'Container' }
      & Pick<Container, 'id' | 'containerNumber'>
    )> }
  )>> }
);

export type SliBreakBulksQueryVariables = Exact<{ [key: string]: never; }>;


export type SliBreakBulksQuery = (
  { __typename?: 'Query' }
  & { sliBreakBulks?: Maybe<Array<(
    { __typename?: 'SliBreakBulk' }
    & Pick<SliBreakBulk, 'id'>
    & { breakBulkContainer?: Maybe<(
      { __typename?: 'BreakBulkContainer' }
      & Pick<BreakBulkContainer, 'id' | 'name'>
    )> }
  )>> }
);

export type SeaShipmentControllersQueryVariables = Exact<{ [key: string]: never; }>;


export type SeaShipmentControllersQuery = (
  { __typename?: 'Query' }
  & { seaShipmentControllers?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )>> }
);

export type AllocateShipmentControllerMutationVariables = Exact<{
  shipmentId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
}>;


export type AllocateShipmentControllerMutation = (
  { __typename?: 'Mutation' }
  & { allocateShipmentController?: Maybe<(
    { __typename?: 'AllocateShipmentControllerPayload' }
    & Pick<AllocateShipmentControllerPayload, 'errors'>
  )> }
);

export type ShipmentContainerDepotsQueryVariables = Exact<{
  typeOfBusiness: Scalars['Int'];
}>;


export type ShipmentContainerDepotsQuery = (
  { __typename?: 'Query' }
  & { foodBusinessOperatorsByType?: Maybe<Array<(
    { __typename?: 'FoodBusinessOperator' }
    & Pick<FoodBusinessOperator, 'id' | 'name' | 'producerName' | 'fboCode'>
  )>> }
);

export type AddendumHeaderByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AddendumHeaderByIdQuery = (
  { __typename?: 'Query' }
  & { addendumHeaderById?: Maybe<(
    { __typename?: 'AddendumHeader' }
    & { addendumLineItems?: Maybe<Array<(
      { __typename?: 'AddendumLineItem' }
      & AddendumLineItemFieldsFragment
    )>> }
    & AddendumHeaderFieldsFragment
  )> }
);

export type AddendumLineItemsByHeaderIdQueryVariables = Exact<{
  addendumHeaderId: Scalars['Int'];
}>;


export type AddendumLineItemsByHeaderIdQuery = (
  { __typename?: 'Query' }
  & { addendumLineItemsByHeaderId?: Maybe<Array<(
    { __typename?: 'AddendumLineItem' }
    & AddendumLineItemFieldsFragment
  )>> }
);

export type AddendumsOnShipmentQueryVariables = Exact<{
  shipmentId: Scalars['Int'];
}>;


export type AddendumsOnShipmentQuery = (
  { __typename?: 'Query' }
  & { addendumsOnShipment?: Maybe<Array<(
    { __typename?: 'AddendumHeader' }
    & Pick<AddendumHeader, 'id' | 'shippingBookingReference' | 'verificationStatus' | 'state' | 'addendumStatus'>
  )>> }
);

export type NonAddendumShipmentLineItemsQueryVariables = Exact<{
  shipmentId: Scalars['Int'];
  bookingRef?: Maybe<Scalars['String']>;
}>;


export type NonAddendumShipmentLineItemsQuery = (
  { __typename?: 'Query' }
  & { nonAddendumShipmentLineItems?: Maybe<Array<(
    { __typename?: 'ShipmentLineItem' }
    & Pick<ShipmentLineItem, 'id' | 'containerNumber' | 'customerReference' | 'shippingLineReference'>
  )>> }
);

export type SliContainersByBookingReferenceQueryVariables = Exact<{
  bookingReference: Scalars['String'];
}>;


export type SliContainersByBookingReferenceQuery = (
  { __typename?: 'Query' }
  & { sliContainersByBookingReference?: Maybe<Array<(
    { __typename?: 'SliContainer' }
    & Pick<SliContainer, 'id'>
    & { container?: Maybe<(
      { __typename?: 'Container' }
      & Pick<Container, 'containerNumber'>
    )> }
  )>> }
);

export type ShipmentLineItemsQueryVariables = Exact<{
  filterAttributes: ShipmentLineItemFilterAttributes;
  searchAttributes: SearchAttributes;
  orderByFields?: Maybe<Array<ColumnOrderAttributes> | ColumnOrderAttributes>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type ShipmentLineItemsQuery = (
  { __typename?: 'Query' }
  & { shipmentLineItems?: Maybe<(
    { __typename?: 'ShipmentLineItemConnection' }
    & Pick<ShipmentLineItemConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentLineItem' }
      & IndexShipmentLineItemFieldssFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  )> }
);

export type ShipmentLineItemsByShipmentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ShipmentLineItemsByShipmentQuery = (
  { __typename?: 'Query' }
  & { shipmentLineItemsByShipment?: Maybe<Array<(
    { __typename?: 'ShipmentLineItem' }
    & SliCommonFieldsFragment
  )>> }
);

export type ShipmentLineItemGroupingsByShipmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShipmentLineItemGroupingsByShipmentQuery = (
  { __typename?: 'Query' }
  & { shipmentLineItemGroupingsByShipment?: Maybe<Array<(
    { __typename?: 'ShipmentLineItemGrouping' }
    & Pick<ShipmentLineItemGrouping, 'companyId' | 'id' | 'shipmentId' | 'sligFields' | 'totalCartons' | 'totalGrossWeight' | 'totalNettWeight' | 'updatedAt' | 'createdAt'>
  )>> }
);

export type ShipmentLineItemsBySligQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShipmentLineItemsBySligQuery = (
  { __typename?: 'Query' }
  & { shipmentLineItemsBySlig?: Maybe<Array<(
    { __typename?: 'ShipmentLineItem' }
    & SliCommonFieldsFragment
  )>> }
);

export type ReassignShipmentLineItemGroupingMutationVariables = Exact<{
  id: Scalars['ID'];
  sligId: Scalars['ID'];
}>;


export type ReassignShipmentLineItemGroupingMutation = (
  { __typename?: 'Mutation' }
  & { reassignShipmentLineItemGrouping?: Maybe<(
    { __typename?: 'ReassignShipmentLineItemGroupingPayload' }
    & Pick<ReassignShipmentLineItemGroupingPayload, 'errors'>
  )> }
);

export type ShipmentBookingReferencesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ShipmentBookingReferencesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'shipmentBookingReferences'>
);

export type PpecbUserByUserIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PpecbUserByUserIdQuery = (
  { __typename?: 'Query' }
  & { ppecbUserByUserId?: Maybe<(
    { __typename?: 'PpecbUser' }
    & Pick<PpecbUser, 'agentFboCode' | 'ppecbRegisteredAgentCode'>
    & { ppecbExporters?: Maybe<Array<(
      { __typename?: 'PpecbExporter' }
      & Pick<PpecbExporter, 'exporterFboCode' | 'ppecbRegisteredExporterCode'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )> }
    )>> }
  )> }
);

export type ShipmentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ShipmentQuery = (
  { __typename?: 'Query' }
  & { shipment?: Maybe<(
    { __typename?: 'Shipment' }
    & { portOfLoading?: Maybe<(
      { __typename?: 'PpecbPort' }
      & Pick<PpecbPort, 'id' | 'portCode' | 'portName'>
    )>, terminal?: Maybe<(
      { __typename?: 'Terminal' }
      & Pick<Terminal, 'id' | 'name' | 'code'>
    )>, shipmentLineItems?: Maybe<Array<(
      { __typename?: 'ShipmentLineItem' }
      & SliCommonFieldsFragment
    )>>, templateOutput?: Maybe<(
      { __typename?: 'TemplateOutput' }
      & Pick<TemplateOutput, 'state'>
      & TemplateOutputFieldsFragment
    )> }
    & ShipmentCommonFieldsFragment
  )> }
);

export type ShipmentLineItemQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ShipmentLineItemQuery = (
  { __typename?: 'Query' }
  & { shipmentLineItemById?: Maybe<(
    { __typename?: 'ShipmentLineItem' }
    & Pick<ShipmentLineItem, 'shipmentId'>
    & { shipment?: Maybe<(
      { __typename?: 'Shipment' }
      & Pick<Shipment, 'id' | 'ppecbBookingReference'>
    )> }
    & SliCommonFieldsFragment
  )> }
);

export type ShipmentSeaPortsQueryVariables = Exact<{ [key: string]: never; }>;


export type ShipmentSeaPortsQuery = (
  { __typename?: 'Query' }
  & { seaPorts?: Maybe<Array<(
    { __typename?: 'SeaPort' }
    & Pick<SeaPort, 'id' | 'name' | 'country'>
  )>> }
);

export type OnAddendumHeaderStateChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnAddendumHeaderStateChangeSubscription = (
  { __typename?: 'Subscription' }
  & { onAddendumHeaderStateChange: (
    { __typename?: 'AddendumHeader' }
    & { addendumLineItems?: Maybe<Array<(
      { __typename?: 'AddendumLineItem' }
      & AddendumLineItemFieldsFragment
    )>> }
    & AddendumHeaderFieldsFragment
  ) }
);

export type OnShipmentStateChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnShipmentStateChangeSubscription = (
  { __typename?: 'Subscription' }
  & { onShipmentStateChange: (
    { __typename?: 'Shipment' }
    & { shipmentLineItems?: Maybe<Array<(
      { __typename?: 'ShipmentLineItem' }
      & SliCommonFieldsFragment
    )>> }
    & ShipmentCommonFieldsFragment
  ) }
);

export type SubmitShipmentExportNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubmitShipmentExportNotificationMutation = (
  { __typename?: 'Mutation' }
  & { submitShipmentExportNotification?: Maybe<(
    { __typename?: 'SubmitShipmentExportNotificationPayload' }
    & Pick<SubmitShipmentExportNotificationPayload, 'errors'>
    & { shipment?: Maybe<(
      { __typename?: 'Shipment' }
      & { shipmentLineItems?: Maybe<Array<(
        { __typename?: 'ShipmentLineItem' }
        & SliCommonFieldsFragment
      )>> }
      & ShipmentCommonFieldsFragment
    )> }
  )> }
);

export type GenerateShipmentExportNotificationOutputFilesMutationVariables = Exact<{
  shipmentId: Scalars['ID'];
}>;


export type GenerateShipmentExportNotificationOutputFilesMutation = (
  { __typename?: 'Mutation' }
  & { generateShipmentExportNotificationOutputFiles?: Maybe<(
    { __typename?: 'GenerateShipmentExportNotificationOutputFilesPayload' }
    & { shipment?: Maybe<(
      { __typename?: 'Shipment' }
      & Pick<Shipment, 'id'>
      & { templateOutput?: Maybe<(
        { __typename?: 'TemplateOutput' }
        & Pick<TemplateOutput, 'state'>
        & TemplateOutputFieldsFragment
      )> }
    )> }
  )> }
);

export type CertificateTransportInstructionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CertificateTransportInstructionQuery = (
  { __typename?: 'Query' }
  & { certificateTransportInstruction?: Maybe<(
    { __typename?: 'CertificateTransportInstruction' }
    & Pick<CertificateTransportInstruction, 'id'>
    & { templateOutput?: Maybe<(
      { __typename?: 'TemplateOutput' }
      & Pick<TemplateOutput, 'state'>
      & TemplateOutputFieldsFragment
    )> }
  )> }
);

export type UpsertCertificateTransportInstructionMutationVariables = Exact<{
  shipmentLineItemId: Scalars['ID'];
}>;


export type UpsertCertificateTransportInstructionMutation = (
  { __typename?: 'Mutation' }
  & { upsertCertificateTransportInstruction?: Maybe<(
    { __typename?: 'UpsertCertificateTransportInstructionPayload' }
    & { certificateTransportInstruction?: Maybe<(
      { __typename?: 'CertificateTransportInstruction' }
      & Pick<CertificateTransportInstruction, 'id'>
      & { templateOutput?: Maybe<(
        { __typename?: 'TemplateOutput' }
        & Pick<TemplateOutput, 'state'>
        & TemplateOutputFieldsFragment
      )> }
    )> }
  )> }
);

export type StockAtLocationQueryVariables = Exact<{
  filterAttributes?: Maybe<StockFilterInputAttributes>;
  searchAttributes?: Maybe<SearchAttributes>;
  orderAttributes?: Maybe<Array<ColumnOrderAttributes> | ColumnOrderAttributes>;
  millerAttributes?: Maybe<MillerCollapsibleInLocationInput>;
}>;


export type StockAtLocationQuery = (
  { __typename?: 'Query' }
  & { stockAtLocation?: Maybe<(
    { __typename?: 'StockModuleData' }
    & Pick<StockModuleData, 'groupKeys' | 'groupFields'>
    & { allLocations?: Maybe<Array<(
      { __typename?: 'LocationMillerFilter' }
      & Pick<LocationMillerFilter, 'locationType' | 'totalPallets' | 'name' | 'id'>
    )>>, millerCollapsibleForLocation?: Maybe<Array<(
      { __typename?: 'MillerCollapsibleInLocation' }
      & Pick<MillerCollapsibleInLocation, 'field' | 'value' | 'totalPallets'>
    )>>, results?: Maybe<Array<(
      { __typename?: 'CartonGroupingWithLocation' }
      & Pick<CartonGroupingWithLocation, 'currentLocationName' | 'nextLocationName' | 'commodityId' | 'batchNumber' | 'buyerId' | 'buyerName' | 'consignmentNumber' | 'subCommodityName' | 'varietyVarietyCode' | 'cartons' | 'commodityName' | 'exporterCode' | 'farmCode' | 'grade' | 'id' | 'inspectionDate' | 'inventoryCode' | 'mark' | 'netMass' | 'orchard' | 'pack' | 'packhouseCode' | 'packingWeek' | 'palletNumber' | 'palletAge' | 'palletGrossMass' | 'productCharacteristic' | 'sequenceNumber' | 'state' | 'status' | 'sizeCount' | 'sellerName' | 'targetMarket' | 'targetRegion' | 'targetCountry' | 'varietyName' | 'varietyId'>
    )>> }
  )> }
);

export type StockFilterAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type StockFilterAttributesQuery = (
  { __typename?: 'Query' }
  & { stockFilterAttributes?: Maybe<(
    { __typename?: 'StockFilterAttributes' }
    & Pick<StockFilterAttributes, 'batchNumbers' | 'exporterCodes' | 'grades' | 'inventoryCodes' | 'marks' | 'packTypes' | 'sizeCounts' | 'states' | 'targetMarkets'>
    & { buyers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, commodities?: Maybe<Array<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )>>, sellers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, subCommodities?: Maybe<Array<(
      { __typename?: 'SubCommodity' }
      & Pick<SubCommodity, 'id' | 'name'>
    )>>, varieties?: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name' | 'varietyCode'>
    )>> }
  )> }
);

export type UploadTaskDocumentMutationVariables = Exact<{
  taskId: Scalars['Int'];
  file: Scalars['Upload'];
  uploadDate?: Maybe<Scalars['ISO8601DateTime']>;
  customDate?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type UploadTaskDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadTaskDocument?: Maybe<(
    { __typename?: 'UploadTaskDocumentPayload' }
    & Pick<UploadTaskDocumentPayload, 'errors'>
    & { task?: Maybe<(
      { __typename?: 'TaskableTask' }
      & Pick<TaskableTask, 'id' | 'customDate' | 'uploadedAt'>
      & { file?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'filename'>
      )> }
    )> }
  )> }
);

export type DeleteTaskDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTaskDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaskDocument?: Maybe<(
    { __typename?: 'DeleteTaskDocumentPayload' }
    & Pick<DeleteTaskDocumentPayload, 'id'>
  )> }
);

export type UpdateTasksDatesMutationVariables = Exact<{
  updateValues: Scalars['Json'];
}>;


export type UpdateTasksDatesMutation = (
  { __typename?: 'Mutation' }
  & { updateTasksDates?: Maybe<(
    { __typename?: 'UpdateTasksDatesPayload' }
    & Pick<UpdateTasksDatesPayload, 'errors'>
  )> }
);

export type OnTemplateOutputStateChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnTemplateOutputStateChangeSubscription = (
  { __typename?: 'Subscription' }
  & { onTemplateOutputStateChange: (
    { __typename?: 'TemplateOutput' }
    & TemplateOutputFieldsFragment
  ) }
);

export type TemplateOutputFieldsFragment = (
  { __typename?: 'TemplateOutput' }
  & Pick<TemplateOutput, 'id' | 'updatedAt' | 'templateDataSourceType' | 'templateDataSourceId' | 'templateVariant'>
  & { outputFiles?: Maybe<Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'v2Url' | 'filename' | 'contentType'>
  )>> }
);

export type TemplateOutputFilesQueryVariables = Exact<{
  templateOutputFilesId: Scalars['Int'];
}>;


export type TemplateOutputFilesQuery = (
  { __typename?: 'Query' }
  & { templateOutputFiles?: Maybe<(
    { __typename?: 'TemplateOutput' }
    & Pick<TemplateOutput, 'errorReason' | 'id' | 'state' | 'templateDataSourceId' | 'templateDataSourceType' | 'updatedAt'>
    & { outputFiles?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'contentType' | 'filename' | 'id' | 'url' | 'v2Url'>
    )>> }
  )> }
);

export type TemplateOutputFilesBySourceQueryVariables = Exact<{
  dataSourceId: Scalars['Int'];
  dataSourceType: Scalars['String'];
}>;


export type TemplateOutputFilesBySourceQuery = (
  { __typename?: 'Query' }
  & { templateOutputFilesBySource?: Maybe<Array<(
    { __typename?: 'TemplateOutput' }
    & Pick<TemplateOutput, 'errorReason' | 'id' | 'state' | 'templateDataSourceId' | 'templateDataSourceType' | 'updatedAt'>
    & { outputFiles?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'contentType' | 'filename' | 'id' | 'url' | 'v2Url'>
    )>> }
  )>> }
);

export type TradePartnerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TradePartnerQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'about' | 'isBuyer' | 'isSeller' | 'registrationNumber' | 'vatNumber' | 'createdAt'>
    & { logoFile?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'postalCode' | 'country'>
    )>, varieties?: Maybe<Array<(
      { __typename?: 'FarmVariety' }
      & Pick<FarmVariety, 'id' | 'varietyId' | 'name' | 'packingStart' | 'packingEnd' | 'peakCount' | 'hectares'>
      & { images?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url' | 'thumbnailUrl'>
      )>>, commodity?: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
        & { icon?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'v2Url'>
        )> }
      )> }
    )>>, certificates?: Maybe<Array<(
      { __typename?: 'CompanyCertificate' }
      & Pick<CompanyCertificate, 'id' | 'companyId' | 'expiryDate'>
      & { certificate?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'url' | 'filename'>
      )>, certificateType?: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'name' | 'id'>
      )> }
    )>>, tradeRegions?: Maybe<Array<(
      { __typename?: 'TradeRegion' }
      & Pick<TradeRegion, 'name' | 'code'>
    )>> }
  )> }
);

export type CompanyInformationFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
  & { logoFile?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url'>
  )>, varieties?: Maybe<Array<(
    { __typename?: 'FarmVariety' }
    & Pick<FarmVariety, 'id' | 'varietyId' | 'name'>
    & { commodity?: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
      & { icon?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'v2Url'>
      )> }
    )> }
  )>>, certificates?: Maybe<Array<(
    { __typename?: 'CompanyCertificate' }
    & { certificateType?: Maybe<(
      { __typename?: 'CertificateType' }
      & Pick<CertificateType, 'name'>
    )> }
  )>>, tradeRegions?: Maybe<Array<(
    { __typename?: 'TradeRegion' }
    & Pick<TradeRegion, 'name' | 'code'>
  )>> }
);

export type TradePartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type TradePartnersQuery = (
  { __typename?: 'Query' }
  & { myCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'isBuyer' | 'isSeller'>
    & { buyers?: Maybe<Array<(
      { __typename?: 'Company' }
      & CompanyInformationFragment
    )>>, sellers?: Maybe<Array<(
      { __typename?: 'Company' }
      & CompanyInformationFragment
    )>> }
  )> }
);

export type AllTradeRegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTradeRegionsQuery = (
  { __typename?: 'Query' }
  & { tradeRegions?: Maybe<Array<(
    { __typename?: 'TradeRegion' }
    & Pick<TradeRegion, 'id' | 'name' | 'code'>
    & { countries?: Maybe<Array<(
      { __typename?: 'TradeCountry' }
      & Pick<TradeCountry, 'id' | 'code' | 'name'>
    )>> }
  )>> }
);

export type UiConfigsQueryVariables = Exact<{
  visibility?: Maybe<UiConfigVisibilityEnum>;
  configType?: Maybe<Scalars['String']>;
}>;


export type UiConfigsQuery = (
  { __typename?: 'Query' }
  & { uiConfigs?: Maybe<Array<(
    { __typename?: 'UiConfig' }
    & Pick<UiConfig, 'id' | 'configType' | 'visibility' | 'data' | 'readonly'>
  )>>, myUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email'>
  )> }
);

export type UiConfigQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UiConfigQuery = (
  { __typename?: 'Query' }
  & { uiConfig?: Maybe<(
    { __typename?: 'UiConfig' }
    & Pick<UiConfig, 'id' | 'configType' | 'visibility' | 'data' | 'readonly'>
  )>, myUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email'>
  )> }
);

export type CreateUiConfigMutationVariables = Exact<{
  attributes: CreateUpdateUiConfigAttributes;
}>;


export type CreateUiConfigMutation = (
  { __typename?: 'Mutation' }
  & { createUiConfig?: Maybe<(
    { __typename?: 'CreateUiConfigPayload' }
    & { config?: Maybe<(
      { __typename?: 'UiConfig' }
      & Pick<UiConfig, 'id' | 'configType' | 'visibility' | 'data' | 'readonly'>
    )> }
  )> }
);

export type DeleteUiConfigMutationVariables = Exact<{
  deleteUiConfigId: Scalars['Int'];
}>;


export type DeleteUiConfigMutation = (
  { __typename?: 'Mutation' }
  & { deleteUiConfig?: Maybe<(
    { __typename?: 'DeleteUiConfigPayload' }
    & Pick<DeleteUiConfigPayload, 'id'>
  )> }
);

export type UpdateUiConfigMutationVariables = Exact<{
  attributes: CreateUpdateUiConfigAttributes;
}>;


export type UpdateUiConfigMutation = (
  { __typename?: 'Mutation' }
  & { updateUiConfig?: Maybe<(
    { __typename?: 'UpdateUiConfigPayload' }
    & { config?: Maybe<(
      { __typename?: 'UiConfig' }
      & Pick<UiConfig, 'id' | 'configType' | 'visibility' | 'data' | 'readonly'>
    )> }
  )> }
);

export type UserFeedItemFragment = (
  { __typename?: 'UserFeedItem' }
  & Pick<UserFeedItem, 'id' | 'message' | 'read' | 'seen' | 'createdAt'>
  & { notifiable?: Maybe<(
    { __typename: 'Comment' }
    & Pick<Comment, 'id'>
    & { commentable?: Maybe<{ __typename?: 'LineItem' } | (
      { __typename: 'Order' }
      & Pick<Order, 'id'>
    )> }
  )> }
);

export type UserFeedItemsConnectionQueryVariables = Exact<{
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type UserFeedItemsConnectionQuery = (
  { __typename?: 'Query' }
  & { userFeedConnection: (
    { __typename?: 'UserFeedItemConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'UserFeedItemEdge' }
      & Pick<UserFeedItemEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'UserFeedItem' }
        & UserFeedItemFragment
      )> }
    )>>> }
  ) }
);

export type UserFeedUnseenCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UserFeedUnseenCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userFeedUnseenCount'>
);

export type UpdateUserFeedItemMutationVariables = Exact<{
  attributes: UpdateUserFeedItemAttributes;
}>;


export type UpdateUserFeedItemMutation = (
  { __typename?: 'Mutation' }
  & { updateUserFeedItem?: Maybe<(
    { __typename?: 'UpdateUserFeedItemPayload' }
    & { userFeedItem?: Maybe<(
      { __typename?: 'UserFeedItem' }
      & Pick<UserFeedItem, 'id' | 'seen' | 'read'>
    )> }
  )> }
);

export type UpdateUserFeedItemsMutationVariables = Exact<{
  attributes: Array<UpdateUserFeedItemAttributes> | UpdateUserFeedItemAttributes;
}>;


export type UpdateUserFeedItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserFeedItems?: Maybe<(
    { __typename?: 'UpdateUserFeedItemsPayload' }
    & { userFeedItems?: Maybe<Array<(
      { __typename?: 'UserFeedItem' }
      & Pick<UserFeedItem, 'id' | 'seen' | 'read'>
    )>> }
  )> }
);

export type NewUserFeedItemSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewUserFeedItemSubscription = (
  { __typename?: 'Subscription' }
  & { newUserFeedItem: (
    { __typename?: 'UserFeedItem' }
    & Pick<UserFeedItem, 'id'>
  ) }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { myUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'state' | 'notifyEmail' | 'notifyBrowserPush' | 'createdAt' | 'updatedAt' | 'useV2' | 'externalIdentifier' | 'role'>
    & { avatarFile?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )>, company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'name'>
    )>>, permissions?: Maybe<(
      { __typename?: 'Permissions' }
      & Pick<Permissions, 'landingPage'>
      & { permissions?: Maybe<Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'key' | 'value'>
      )>> }
    )> }
  )>, myCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'isBuyer' | 'isSeller'>
    & { certificates?: Maybe<Array<(
      { __typename?: 'CompanyCertificate' }
      & Pick<CompanyCertificate, 'id' | 'expiryDate' | 'createdAt' | 'updatedAt'>
      & { certificate?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url'>
      )>, certificateType?: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'id' | 'name'>
      )> }
    )>>, sellers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>, buyers?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>> }
  )> }
);

export type DeleteEmployeeMutationVariables = Exact<{
  employeeid: Scalars['Int'];
}>;


export type DeleteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { deleteEmployee?: Maybe<(
    { __typename?: 'DeleteEmployeePayload' }
    & Pick<DeleteEmployeePayload, 'employeeId'>
  )> }
);

export type FindOrCreateVesselMutationVariables = Exact<{
  vesselName: Scalars['String'];
}>;


export type FindOrCreateVesselMutation = (
  { __typename?: 'Mutation' }
  & { findOrCreateVessel?: Maybe<(
    { __typename?: 'FindOrCreateVesselPayload' }
    & Pick<FindOrCreateVesselPayload, 'errors'>
    & { vessel?: Maybe<(
      { __typename?: 'Vessel' }
      & Pick<Vessel, 'id' | 'vesselName'>
    )> }
  )> }
);

export const TemplateOutputFieldsFragmentDoc = gql`
    fragment templateOutputFields on TemplateOutput {
  id
  updatedAt
  templateDataSourceType
  templateDataSourceId
  templateVariant
  outputFiles {
    v2Url
    filename
    contentType
  }
}
    `;
export const PalletOutFieldsFragmentDoc = gql`
    fragment palletOutFields on PalletOut {
  id
  addendumHeaderId
  state
  updatedAt
  outputFile {
    contentType
    filename
    v2Url
    id
    url
  }
}
    `;
export const AirAddendumHeaderFieldsFragmentDoc = gql`
    fragment airAddendumHeaderFields on AddendumHeader {
  templateOutput {
    ...templateOutputFields
  }
  palletOut {
    ...palletOutFields
  }
  id
  requestId
  exporterCode
  cfCode
  lspCode
  transportType
  vesselName
  vesselType
  voyageNumber
  exportCertificateNumber
  exportFromCapeTown
  exportFromDurban
  exportFromMaputo
  exportFromOther
  exportFromPortElizabeth
  exporterAgentDate
  exporterAgentName
  forwardingAgentDate
  forwardingAgentName
  disAgentDate
  disAgentName
  inlandInspectorDate
  inlandInspectorName
  portInspectorDate
  portInspectorName
  shippingBookingReference
  loadPort
  dischargePort
  shippedTargetCountry
  shippedTargetRegion
  locationOfIssue
  estimatedDepartureDate
  eCertAgreementCode
  verificationStatus
  addendumStatus
  ecertStatus
  transactionId
  errorReason
  state
  printedName
  ppecbCertificateNumber
}
    ${TemplateOutputFieldsFragmentDoc}
${PalletOutFieldsFragmentDoc}`;
export const AirAddendumLineItemFieldsFragmentDoc = gql`
    fragment airAddendumLineItemFields on AddendumLineItem {
  id
  stuffLoadDate
  loadPointFboCode
  consignmentNumber
  phc
  inspectedSscc
  clientRef
  upn
  inspectedTargetRegion
  commodityCode
  varietyCode
  productClass
  nettWeight
  grossWeight
  ssccNettWeight
  ssccGrossWeight
  cartonQuantity
  inspectionPoint
  inspectorCode
  inspectionDate
  containerNumber
  sequenceNumberOfInspectedSscc
  puc
  orchard
  productionArea
  phytoData
  sizeCountBerrySize
  packCode
  palletQuantity
  fleshColor
  inspectionManifestNumber
}
    `;
export const AirFreightShipmentFieldsFragmentDoc = gql`
    fragment airFreightShipmentFields on AirFreightShipment {
  id
  externalTransportMasterId
  createdAt
  updatedAt
  shipmentType
  carrierName
  flightNumber
  masterWaybillNumber
  shipper
  consignor
  consignee
  portOfLoadingCode
  portOfLoadingLocation
  forwarderName
  departureAirport
  estimatedDepartureTime
  actualDepartureTime
  portOfDestination
  estimatedArrivalTime
  actualArrivalTime
  controller
  userName
  phoneNumber
  state
  billToParty
  exporterCode
  fboCode
}
    `;
export const AirFreightShipmentLineItemFieldsFragmentDoc = gql`
    fragment airFreightShipmentLineItemFields on AirFreightShipmentLineItem {
  id
  externalShipmentId
  orderReference
  bookingReference
  consignee
  consignor
  finalPortOfDischargeCode
  finalPortOfDischargeLocation
  finalDestinationCode
  finalDestinationLocation
  eta
  collectionDate
  isTransshipment
  transshipmentCarrierName
  transshipmentFlightNumber
  transshipmentPort
  specialHandling
  comments
  packageType
  packageQuantity
  commodityCode
  goodsDescription
  natureOfGoods
  grossWeight
  volume
  grossDimensions
  housebill
  lastStatusMessage
  state
}
    `;
export const BaseCartonGroupingFieldsFragmentDoc = gql`
    fragment baseCartonGroupingFields on CartonGrouping {
  id
  containerNumber
  state
  cartons
  palletNumber
  grade
  batchNumber
  sizeCount
  advancePrice
  finalPrice
  advanceDueDate
  finalDueDate
  lineItem {
    currency
    incoterm
    mark
    orderId
    priceMinorUnit
    priceUnit
    referenceNumber
  }
  buyer {
    id
    name
  }
  seller {
    id
    name
  }
}
    `;
export const CartonGroupingFragmentDoc = gql`
    fragment cartonGrouping on CartonGrouping {
  ...baseCartonGroupingFields
  targetRegion
  targetMarket
  targetCountry
  pack
  palletStack
  packhouseCode
  orchard
  containerNumber
  exporterCode
  farmCode
  packingWeek
  lineItemId
  consignmentNumber
  inventoryCode
  sequenceNumber
  coldStore
  productCharacteristic
  variety {
    id
    name
    commodity {
      id
      name
    }
  }
}
    ${BaseCartonGroupingFieldsFragmentDoc}`;
export const CashFlowCartonGroupingFragmentDoc = gql`
    fragment cashFlowCartonGrouping on CartonGrouping {
  ...baseCartonGroupingFields
  targetRegion
  targetMarket
  palletGrossMass
}
    ${BaseCartonGroupingFieldsFragmentDoc}`;
export const CommercialTermChangeRequestFieldsFragmentDoc = gql`
    fragment CommercialTermChangeRequestFields on CommercialTermChangeRequest {
  id
  message
  flag
  commercialTerm {
    id
  }
}
    `;
export const CommercialTermFragmentDoc = gql`
    fragment commercialTerm on CommercialTerm {
  id
  startDate
  endDate
  targetRegion
  advanceType
  advanceUnit
  buyerId
  deleted
  pack
  grade
  paymentTerm
  advanceCreditTerm
  finalCreditTerm
  advanceEvent
  finalEvent
  advanceAmount
  rankOneAdvanceEvent
  rankOneFinalEvent
  advanceCurrency
  finalCurrency
  commodity {
    id
    name
  }
  buyer {
    id
    name
  }
}
    `;
export const CommoditySeasonFragmentDoc = gql`
    fragment commoditySeason on CommoditySeason {
  id
  startDate
  endDate
  commodity {
    id
    name
    icon {
      v2Url
    }
  }
  createdAt
  updatedAt
}
    `;
export const EphytoCommonFieldsFragmentDoc = gql`
    fragment ephytoCommonFields on Ephyto {
  id
  applicationReferenceNo
  applicationType
  applicationStatus
  cbrId
  cbrBillingId
  customReferenceNo
  agreementCode
  desiredIssueLocation
  notificationEmail
  consignorId
  consignorName
  consignorAddressLine1
  consignorAddressLine2
  consignorAddressLine3
  consigneeId
  consigneeName
  consigneeAddressLine1
  consigneeAddressLine2
  consigneeAddressLine3
  consigneeCountryId
  importCountryId
  targetCountryId
  unloadingBaseportLocation
  transportModeCode
  transportMeans
  paymentType
  dalrrdAccNum
  loadSeaPoint
  targetCountry
  targetRegion
  portOfEntry
  bookingRef
  vessel
  voyageNumber
  departureDate
  numberAndDescriptionOfPackage
  botanicalName
  distinguishingMarks
  placeOfOrigin
  declaredMeansOfConveyance
  declaredPointOfEntry
  nameOfProductAndDeclaredQuantity
  treatmentDate
  treatment
  chemical
  durationAndTemperature
  concentration
  additionalInformation
  state
  errorReason
  stuffDate
  temperatureRegime
  originalPhytoDate
  originalPhytoNo
  reexportPlaceOfOrigin
  originalPhyto
  rePacked
  originalContainer
  basedOnOriginalPhyto
  additionalInspection
  cbsFreeArea
  vatNo
  regimeCode
  importPermitNo
  importPermitDate
}
    `;
export const EphytoTradeUnitCommonFieldsFragmentDoc = gql`
    fragment ephytoTradeUnitCommonFields on EphytoTradeUnit {
  id
  tradeUnitId
  clientRef
  orchard
  phc
  productionArea
  commodity
  marketingIndication
  fleshColour
  tuClass
  containerNumber
  numberOfPackagedItems
  netTradeUnitWeight
  nettPalletWeight
  ppecbInspectionDate
  stuffDate
  inspectionManifestNo
  inspectionPointLocationCode
}
    `;
export const EphytoConsignmentItemCommonFieldsFragmentDoc = gql`
    fragment ephytoConsignmentItemCommonFields on EphytoConsignmentItem {
  id
  description
  commonName
  scientificName
  netWeightMeasureCode
  netWeightMeasure
  grossWeightMeasureCode
  grossWeightMeasure
  customsHarmonizedSystemClass
  commodityVegetablePart
  commodityConditionClass
  commodityIntentOfUseClass
  appliedProcessStartDate
  appliedProcessEndDate
  durationMeasureCode
  durationMeasure
  appliedProcessTreatmentTypeLevel1
  appliedProcessTreatmentTypeLevel2
  appliedProcessChemicalCode
  appliedProcessTemperatureUnitCode
  appliedProcessTemperature
  appliedProcessConcentrationUnitCode
  appliedProcessConcentration
  appliedProcessAdditionalNote
  fullTreatmentInformation
  packageLevelCode
  packageTypeCode
  packageItemUnitCode
  packageItemQuantity
  packageShippingMarks
  additionalConsignmentNotes
  sequenceNumeric
}
    `;
export const EphytoDocumentFieldsFragmentDoc = gql`
    fragment ephytoDocumentFields on EphytoDocument {
  id
  documentType
  file {
    id
    filename
    v2Url
  }
}
    `;
export const DocumentFieldsFragmentDoc = gql`
    fragment documentFields on EtlDocument {
  createdAt
  updatedAt
  errorReason
  erroredRows
  fileUrl
  filename
  id
  processedRows
  rows
  state
}
    `;
export const FarmVarietyFragmentDoc = gql`
    fragment farmVariety on FarmVariety {
  id
  varietyId
  name
  peakCount
  hectares
  packingStart
  packingEnd
  commodity {
    id
    name
  }
  images {
    id
    url
    v2Url
    filename
    thumbnailUrl
    v2ThumbnailUrl
  }
}
    `;
export const InvPortFieldsFragmentDoc = gql`
    fragment invPortFields on SeaPort {
  id
  name
  country
  createdAt
  updatedAt
}
    `;
export const InvoicesSummaryFieldsFragmentDoc = gql`
    fragment invoicesSummaryFields on Invoice {
  invoiceNumber
  invoiceDate
  portOfLoading {
    ...invPortFields
  }
  portOfDestination {
    ...invPortFields
  }
  vessel {
    id
    vesselName
  }
  incoterm
  containers {
    containerNumber
  }
  contacts {
    name
    contactType
  }
  id
  currency
  totalIncoterm
  advancePayment
  finalPayment
  amountRemitted
  palletCount
  transportType
  documentType
  state
  updatedAt
  sentEmailList {
    id
    createdAt
    recipient
    attachments
  }
}
    ${InvPortFieldsFragmentDoc}`;
export const InvliCommonFieldsFragmentDoc = gql`
    fragment invliCommonFields on InvoiceLineItem {
  cartonGrouping {
    id
  }
  cartons
  currency
  netMass
  palletGrossMass
  price
  priceUnit
  targetMarket
}
    `;
export const InvCommonFieldsFragmentDoc = gql`
    fragment invCommonFields on Invoice {
  cartonCount
  invoiceCustomFields {
    customFieldId
    name
    required
    value
    defaultValue
    isDefault
  }
  convertTonToKg
  countryOfOrigin {
    id
    name
  }
  currency
  localCosts
  insuranceCost
  freightCost
  invoiceNumber
  invoiceDate
  incoterm
  totalFob
  advancePayment
  advanceCreditTerm
  finalPayment
  amountRemitted
  finalCreditTerm
  contacts {
    id
    info
    name
    contactType
  }
  containers {
    containerNumber
    id
  }
  id
  palletCount
  poNumber
  portOfLoading {
    ...invPortFields
  }
  portOfDestination {
    ...invPortFields
  }
  totalIncoterm
  ucrNumber
  vessel {
    id
    vesselName
  }
  voyage
  templateOutput {
    ...templateOutputFields
  }
  transportType
  documentType
  state
  airWaybillNo
  shipper
  destination
  allocatedWeight
  routing
  flightDate
  connectingFlightDate
  vehicleNumber
  saleType
  stuffLocation {
    id
    name
  }
  loadingPoint {
    id
    name
  }
  offloadingPoint {
    id
    name
  }
}
    ${InvPortFieldsFragmentDoc}
${TemplateOutputFieldsFragmentDoc}`;
export const InvCommonCgFieldsFragmentDoc = gql`
    fragment invCommonCGFields on CartonGrouping {
  batchNumber
  buyer {
    name
  }
  invoiceLineItems {
    invoiceId
    price
    priceUnit
    currency
    documentType
  }
  containerNumber
  cartons
  consignmentNumber
  exporterCode
  farmCode
  grade
  id
  inventoryCode
  lineItem {
    priceUnit
    priceMinorUnit
    currency
    orderId
  }
  mark
  netMass
  orchard
  pack
  packhouseCode
  packingWeek
  palletGrossMass
  palletNumber
  seller {
    name
  }
  sequenceNumber
  sizeCount
  state
  targetCountry
  targetMarket
  targetRegion
  variety {
    commodity {
      name
    }
    name
    subCommodity {
      name
    }
  }
}
    `;
export const LoiPortFieldsFragmentDoc = gql`
    fragment loiPortFields on SeaPort {
  id
  name
  country
  createdAt
  updatedAt
}
    `;
export const PalletInFieldsFragmentDoc = gql`
    fragment palletInFields on PalletIn {
  id
  loadOutInstructionId
  state
  updatedAt
  outputFile {
    contentType
    filename
    v2Url
    id
    url
  }
}
    `;
export const LoiCommonFieldsFragmentDoc = gql`
    fragment LoiCommonFields on LoadOutInstruction {
  bookingReference
  transportType
  contacts {
    id
    name
    info
    contactType
  }
  containerNumber
  estimatedTimeOfDelivery
  exporter {
    id
    name
  }
  exportersReference
  id
  loadOutReference
  notes
  portOfDestination {
    ...loiPortFields
  }
  portOfLoading {
    ...loiPortFields
  }
  printedDate
  q67Reference
  shippingLine
  stuffDate
  stuffLocation {
    id
    name
    createdAt
    updatedAt
  }
  temperatureSetting
  vessel {
    id
    vesselName
  }
  voyage
  templateOutput {
    ...templateOutputFields
  }
  palletIn {
    ...palletInFields
  }
  truckNumber
  loadingPoint {
    id
    name
    createdAt
    updatedAt
  }
  offloadingPoint {
    id
    name
    createdAt
    updatedAt
  }
  fileNumber
  airWaybillNo
  shipper
  destination
  allocatedWeight
  routing
  flightDate
  connectingFlightDate
  equipment
  phytoCertificate
  orderPreparedBy
  orderPickedBy
  checked
}
    ${LoiPortFieldsFragmentDoc}
${TemplateOutputFieldsFragmentDoc}
${PalletInFieldsFragmentDoc}`;
export const CgCommonFieldsFragmentDoc = gql`
    fragment cgCommonFields on CartonGrouping {
  batchNumber
  buyer {
    name
  }
  cartons
  consignmentNumber
  exporterCode
  farmCode
  grade
  id
  inventoryCode
  mark
  netMass
  orchard
  pack
  packhouseCode
  packingWeek
  palletGrossMass
  palletNumber
  seller {
    name
  }
  sequenceNumber
  sizeCount
  state
  targetCountry
  targetMarket
  targetRegion
  variety {
    commodity {
      name
    }
    name
    subCommodity {
      name
    }
  }
  palletAge
}
    `;
export const VesselMapFragmentDoc = gql`
    fragment vesselMap on Vessel {
  vesselName
  voyageNumber
  lat
  long
}
    `;
export const SeaPortMapFragmentDoc = gql`
    fragment seaPortMap on SeaPort {
  id
  name
  country
  unLocode
  latitude
  longitude
  createdAt
  updatedAt
}
    `;
export const FboMapFragmentDoc = gql`
    fragment fboMap on FoodBusinessOperator {
  id
  name
  fboCode
  typeOfBusiness
  latitude
  longitude
  createdAt
  updatedAt
}
    `;
export const MetabaseReportFieldsFragmentDoc = gql`
    fragment MetabaseReportFields on MetabaseReport {
  badges {
    color
    text
  }
  chartSource
  description
  domain
  id
  name
  displayName
  scope
  url
  powerbiEmbedAttributes {
    embedToken
    embedUrl
    reportId
  }
}
    `;
export const CommentFieldsFragmentDoc = gql`
    fragment commentFields on Comment {
  id
  commentText
  isDeleted
  createdAt
  updatedAt
  user {
    id
    firstName
    lastName
    avatarFile {
      id
      filename
      url
    }
  }
}
    `;
export const SellerCompanyFieldsFragmentDoc = gql`
    fragment sellerCompanyFields on Company {
  id
  name
  logoFile {
    id
    filename
    v2Url
  }
  varieties {
    id
    varietyId
    name
    commodity {
      id
      name
    }
  }
  additionalFields {
    id
    name
  }
}
    `;
export const SingleLineItemFieldsFragmentDoc = gql`
    fragment singleLineItemFields on LineItem {
  id
  additionalFields
  packingWeek
  startWeek
  endWeek
  targetRegion
  targetCountry
  targetMarket
  quantity
  plannedQuantity
  plannedQuantityUnit
  incoterm
  pack
  palletStack
  priority
  quantityUnit
  priceMinorUnit
  sizeCounts
  priceTerm
  currency
  state
  priceUnit
  grade
  mark
  commodityId
  varietyId
  packedCartons
  packedPallets
  variety {
    id
    commodity {
      id
      name
    }
    subCommodity {
      id
      name
    }
    name
    varietyCode
  }
  referenceNumber
  rank
  batchNumber
  inventoryCode
  unlimited
  orderId
  lineItemGroupingId
}
    `;
export const StatesDistributionFieldsFragmentDoc = gql`
    fragment statesDistributionFields on LineItem {
  statesDistribution {
    quantity
    quantityTotal
    palletQuantity
    palletQuantityTotal
    state
    supplyChainOrder
  }
}
    `;
export const ShipmentDocumentFieldsFragmentDoc = gql`
    fragment shipmentDocumentFields on ShipmentDocument {
  id
  documentType
  user {
    id
    firstName
    lastName
    company {
      id
    }
  }
  file {
    id
    filename
    v2Url
  }
}
    `;
export const SingleOrderFieldsFragmentDoc = gql`
    fragment singleOrderFields on Order {
  id
  state
  isPackhouseRun
  createdAt
  updatedAt
  comments {
    ...commentFields
  }
  buyer {
    id
    name
    logoFile {
      id
      v2Url
    }
    additionalFields {
      id
      name
    }
    sellerVarieties {
      id
      name
      commodity {
        id
        name
      }
    }
  }
  seller {
    ...sellerCompanyFields
  }
  lineItems {
    ...singleLineItemFields
  }
  lineItemGroupings {
    id
    cumulativeQuantity
    quantityType
    commonFields
    deleted
    rank
    lineItems {
      ...singleLineItemFields
      ...statesDistributionFields
    }
  }
  audits {
    id
    action
    auditableType
    auditableId
    associatedType
    associatedId
    createdAt
    auditedChanges
    version
    user {
      id
      firstName
      company {
        id
        name
      }
    }
  }
  shipmentDocuments {
    ...shipmentDocumentFields
  }
}
    ${CommentFieldsFragmentDoc}
${SellerCompanyFieldsFragmentDoc}
${SingleLineItemFieldsFragmentDoc}
${StatesDistributionFieldsFragmentDoc}
${ShipmentDocumentFieldsFragmentDoc}`;
export const ExpectedNetReturnFieldsFragmentDoc = gql`
    fragment expectedNetReturnFields on LineItem {
  expectedReturnSummary(currency: $currency, unit: $unit, incoterm: $incoterm) {
    unit
    error
    equivalentPrice {
      incoterm
      moneyAmount {
        displayString
      }
    }
    pricePoints {
      incoterm
      moneyAmount {
        currency {
          symbol
        }
        amount
      }
      chargesApplied {
        name
        moneyAmount {
          amount
        }
      }
    }
  }
}
    `;
export const SingleOrderFieldsWithNetReturnFragmentDoc = gql`
    fragment singleOrderFieldsWithNetReturn on Order {
  ...singleOrderFields
  lineItemGroupings {
    lineItems {
      ...expectedNetReturnFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}
${ExpectedNetReturnFieldsFragmentDoc}`;
export const MultipleOrderFieldsFragmentDoc = gql`
    fragment multipleOrderFields on Order {
  id
  state
  isPackhouseRun
  updatedAt
  createdAt
  lineItemGroupings {
    commonFields
    cumulativeQuantity
    cumulativeTooltip
    deleted
    id
    numberCumulativeSiblings
    quantityType
    rank
    lineItems(
      lineItemStates: $lineItemStates
      packingWeekStart: $packingWeekStart
      packingWeekEnd: $packingWeekEnd
      varietyIds: $varietyIds
      commodityIds: $commodityIds
      subCommodityIds: $subCommodityIds
      states: $states
    ) {
      ...singleLineItemFields
      ...statesDistributionFields
      ...expectedNetReturnFields
    }
  }
  buyer {
    id
    name
    logoFile {
      id
      filename
      v2Url
    }
    sellerVarieties {
      id
      name
      commodity {
        id
        name
      }
    }
  }
  seller {
    ...sellerCompanyFields
  }
}
    ${SingleLineItemFieldsFragmentDoc}
${StatesDistributionFieldsFragmentDoc}
${ExpectedNetReturnFieldsFragmentDoc}
${SellerCompanyFieldsFragmentDoc}`;
export const DuplicateOrderFieldsFragmentDoc = gql`
    fragment duplicateOrderFields on Order {
  id
  isPackhouseRun
  lineItemGroupings {
    id
    cumulativeQuantity
    quantityType
    commonFields
    deleted
    rank
    lineItems {
      ...singleLineItemFields
    }
  }
  buyer {
    id
    name
    logoFile {
      id
      filename
      v2Url
    }
    sellerVarieties {
      id
      name
      commodity {
        id
        name
      }
    }
  }
  seller {
    ...sellerCompanyFields
  }
}
    ${SingleLineItemFieldsFragmentDoc}
${SellerCompanyFieldsFragmentDoc}`;
export const ShipmentCommonFieldsFragmentDoc = gql`
    fragment shipmentCommonFields on Shipment {
  id
  ppecbBookingReference
  shippingLine
  voyageNumber
  shippingLineVoyageNumber
  loadPort
  billToParty
  exporterCode
  cfCode
  ppecbRegisteredExporterCode
  ppecbRegisteredAgentCode
  phoneNumber
  userName
  dateCreated
  vesselOriginalEstimatedDateOfDeparture
  stackDates
  stackDatesClose
  shipper
  status
  vesselName
  vesselCode
  ppecbVoyageNumber
  controller
  client
  comments
  estimatedTimeOfDeparture
  actualTimeOfDeparture
  exportNotificationError
}
    `;
export const ExportDocumentFieldsFragmentDoc = gql`
    fragment exportDocumentFields on ExportDocument {
  id
  documentType
  file {
    id
    filename
    v2Url
  }
}
    `;
export const SliCommonFieldsFragmentDoc = gql`
    fragment sliCommonFields on ShipmentLineItem {
  id
  ppecbBookingReference
  ppecbBookingItemReference
  shippingLineReference
  customerReference
  regimeCode
  finalCountryOfDestination
  importPermitNumber
  exporterOnCertificate
  isGensetRequired
  isTranshipment
  isColdTreatment
  transhipmentVesselName
  transhipmentVoyageNumber
  transhipmentPort
  finalPortOfDischarge
  tempSensor
  userName
  dateCreated
  isConventionalShipping
  depot
  collectionDate
  loadingInstructionNumber
  haulageType
  transporter
  receiver
  cargoVgm
  solas
  tempDevice
  eta
  containerAndSealNumber
  paymentTerms
  shippingLineContactNumber
  humidity
  voidPlugs
  ventSetting
  protocolTemperature
  protocolDays
  specialInstruction
  status
  productCodes
  containerNumber
  sealNumber
  nettWeight
  grossWeight
  state
  comments
  consignee
  serviceContractNumber
  customerReference
  totalCartons
  exportNotificationError
  tareWeight
  loadingPoints {
    activityPointCode
    loadingDate
    quantity
    containerType
    unitOfMeasure
  }
  portOfDestination {
    id
    portCode
    portName
  }
  emptyDepot {
    id
    producerName
    name
    fboCode
  }
  clientReference
  exportDocuments {
    ...exportDocumentFields
  }
  certificateTransportInstruction {
    id
    templateOutput {
      state
      ...templateOutputFields
    }
  }
  shipmentLineItemGroupingId
  tasks {
    id
    index
    uploadedAt
    customDate
    hasDocument
    title
    file {
      filename
      v2Url
    }
  }
}
    ${ExportDocumentFieldsFragmentDoc}
${TemplateOutputFieldsFragmentDoc}`;
export const IndexShipmentLineItemFieldssFragmentDoc = gql`
    fragment indexShipmentLineItemFieldss on ShipmentLineItem {
  id
  containerNumber
  sealNumber
  grossWeight
  nettWeight
  comments
  shippingLineReference
  customerReference
  consignee
  serviceContractNumber
  clientReference
  portOfDestination {
    portName
  }
  state
  status
  totalCartons
  productCodes
  shipment {
    id
    controller
    client
    vesselName
    portOfLoading {
      portName
    }
  }
  sliContainer {
    id
    container {
      containerNumber
    }
  }
  sliBreakBulk {
    id
    breakBulkContainer {
      name
    }
  }
  tasks {
    id
    index
    uploadedAt
    customDate
    hasDocument
    title
    file {
      filename
      v2Url
    }
  }
  createdAt
}
    `;
export const AddendumHeaderFieldsFragmentDoc = gql`
    fragment addendumHeaderFields on AddendumHeader {
  id
  requestId
  exporterCode
  cfCode
  lspCode
  transportType
  vesselName
  vesselType
  voyageNumber
  regimeCode
  shippingBookingReference
  loadPort
  dischargePort
  shippedTargetCountry
  shippedTargetRegion
  locationOfIssue
  estimatedDepartureDate
  eCertAgreementCode
  verificationStatus
  addendumStatus
  ecertStatus
  transactionId
  errorReason
  state
}
    `;
export const AddendumLineItemFieldsFragmentDoc = gql`
    fragment addendumLineItemFields on AddendumLineItem {
  id
  stuffLoadDate
  loadPointFboCode
  consignmentNumber
  phc
  inspectedSscc
  clientRef
  upn
  inspectedTargetRegion
  commodityCode
  varietyCode
  productClass
  nettWeight
  grossWeight
  ssccNettWeight
  ssccGrossWeight
  cartonQuantity
  inspectionPoint
  inspectorCode
  inspectionDate
  containerNumber
  sequenceNumberOfInspectedSscc
  puc
  orchard
  productionArea
  phytoData
  sizeCountBerrySize
  packCode
  palletQuantity
}
    `;
export const CompanyInformationFragmentDoc = gql`
    fragment companyInformation on Company {
  id
  name
  logoFile {
    id
    url
  }
  varieties {
    id
    varietyId
    name
    commodity {
      id
      name
      icon {
        v2Url
      }
    }
  }
  certificates {
    certificateType {
      name
    }
  }
  tradeRegions {
    name
    code
  }
}
    `;
export const UserFeedItemFragmentDoc = gql`
    fragment userFeedItem on UserFeedItem {
  id
  message
  read
  seen
  createdAt
  notifiable {
    ... on Comment {
      id
      __typename
      commentable {
        ... on Order {
          id
          __typename
        }
      }
    }
  }
}
    `;
export const CreateAdditionalFieldDocument = gql`
    mutation CreateAdditionalField($attributes: CreateAdditionalFieldAttributes!) {
  createAdditionalField(attributes: $attributes) {
    additionalField {
      id
      companyId
      name
      description
      fieldType
      parameters
      isPackingInstruction
      private
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAdditionalFieldGQL extends Apollo.Mutation<CreateAdditionalFieldMutation, CreateAdditionalFieldMutationVariables> {
    document = CreateAdditionalFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAdditionalFieldDocument = gql`
    mutation DeleteAdditionalField($id: Int!) {
  deleteAdditionalField(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdditionalFieldGQL extends Apollo.Mutation<DeleteAdditionalFieldMutation, DeleteAdditionalFieldMutationVariables> {
    document = DeleteAdditionalFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAdditionalFieldDocument = gql`
    mutation UpdateAdditionalField($id: Int!, $attributes: UpdateAdditionalFieldAttributes!) {
  updateAdditionalField(id: $id, attributes: $attributes) {
    additionalField {
      id
      name
      description
      fieldType
      parameters
      isPackingInstruction
      private
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAdditionalFieldGQL extends Apollo.Mutation<UpdateAdditionalFieldMutation, UpdateAdditionalFieldMutationVariables> {
    document = UpdateAdditionalFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdditionalFieldsDocument = gql`
    query AdditionalFields($companyids: [Int!]!) {
  additionalFields(companyIds: $companyids) {
    id
    name
    description
    fieldType
    parameters
    isPackingInstruction
    private
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdditionalFieldsGQL extends Apollo.Query<AdditionalFieldsQuery, AdditionalFieldsQueryVariables> {
    document = AdditionalFieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AirAddendumHeaderByIdDocument = gql`
    query AirAddendumHeaderById($id: Int!) {
  addendumHeaderById(id: $id) {
    ...airAddendumHeaderFields
    addendumLineItems {
      ...airAddendumLineItemFields
    }
  }
}
    ${AirAddendumHeaderFieldsFragmentDoc}
${AirAddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AirAddendumHeaderByIdGQL extends Apollo.Query<AirAddendumHeaderByIdQuery, AirAddendumHeaderByIdQueryVariables> {
    document = AirAddendumHeaderByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddendumsOnAirFreightShipmentDocument = gql`
    query AddendumsOnAirFreightShipment($airFreightShipmentId: Int!) {
  addendumsOnAirFreightShipment(airFreightShipmentId: $airFreightShipmentId) {
    id
    loadOutInstructions {
      id
      loadOutReference
      airFreightShipmentLineItem {
        externalShipmentId
        airFreightShipment {
          masterWaybillNumber
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddendumsOnAirFreightShipmentGQL extends Apollo.Query<AddendumsOnAirFreightShipmentQuery, AddendumsOnAirFreightShipmentQueryVariables> {
    document = AddendumsOnAirFreightShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AirFreightShipmentLoisDocument = gql`
    query AirFreightShipmentLois($airFreightShipmentId: ID!) {
  airFreightShipmentLois(airFreightShipmentId: $airFreightShipmentId) {
    id
    loadOutReference
    airWaybillNo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AirFreightShipmentLoisGQL extends Apollo.Query<AirFreightShipmentLoisQuery, AirFreightShipmentLoisQueryVariables> {
    document = AirFreightShipmentLoisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateAirAddendumDocument = gql`
    mutation GenerateAirAddendum($loiIds: [ID!]!) {
  generateAirAddendum(loiIds: $loiIds) {
    errors
    addendumHeader {
      ...airAddendumHeaderFields
      addendumLineItems {
        ...airAddendumLineItemFields
      }
    }
  }
}
    ${AirAddendumHeaderFieldsFragmentDoc}
${AirAddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateAirAddendumGQL extends Apollo.Mutation<GenerateAirAddendumMutation, GenerateAirAddendumMutationVariables> {
    document = GenerateAirAddendumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAirAddendumDocument = gql`
    mutation UpdateAirAddendum($addendumLineItems: [AddendumLineItemAttributes!]!, $addendumHeader: AddendumHeaderAttributes!) {
  updateAirAddendum(
    addendumLineItems: $addendumLineItems
    addendumHeader: $addendumHeader
  ) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAirAddendumGQL extends Apollo.Mutation<UpdateAirAddendumMutation, UpdateAirAddendumMutationVariables> {
    document = UpdateAirAddendumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelAirAddendumDocument = gql`
    mutation CancelAirAddendum($id: Int!) {
  cancelAirAddendum(id: $id) {
    id
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelAirAddendumGQL extends Apollo.Mutation<CancelAirAddendumMutation, CancelAirAddendumMutationVariables> {
    document = CancelAirAddendumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateAddendumHeaderOutputFilesDocument = gql`
    mutation GenerateAddendumHeaderOutputFiles($addendumHeaderId: ID!) {
  generateAddendumHeaderOutputFiles(addendumHeaderId: $addendumHeaderId) {
    templateOutput {
      errorReason
      id
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateAddendumHeaderOutputFilesGQL extends Apollo.Mutation<GenerateAddendumHeaderOutputFilesMutation, GenerateAddendumHeaderOutputFilesMutationVariables> {
    document = GenerateAddendumHeaderOutputFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeneratePalletOutOutputFilesDocument = gql`
    mutation GeneratePalletOutOutputFiles($addendumHeaderId: ID!) {
  generatePalletOutOutputFiles(addendumHeaderId: $addendumHeaderId) {
    palletOutId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GeneratePalletOutOutputFilesGQL extends Apollo.Mutation<GeneratePalletOutOutputFilesMutation, GeneratePalletOutOutputFilesMutationVariables> {
    document = GeneratePalletOutOutputFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnPalletOutOutputStateChangeDocument = gql`
    subscription onPalletOutOutputStateChange {
  onPalletOutOutputStateChange {
    ...palletOutFields
  }
}
    ${PalletOutFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnPalletOutOutputStateChangeGQL extends Apollo.Subscription<OnPalletOutOutputStateChangeSubscription, OnPalletOutOutputStateChangeSubscriptionVariables> {
    document = OnPalletOutOutputStateChangeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AirFreightShipmentsDocument = gql`
    query AirFreightShipments($filterAttributes: AirFreightShipmentsFilter!, $searchAttributes: SearchAttributes!, $orderByFields: [ColumnOrderAttributes!], $first: Int, $last: Int, $after: String, $before: String) {
  airFreightShipments(
    filterAttributes: $filterAttributes
    searchAttributes: $searchAttributes
    orderByFields: $orderByFields
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      ...airFreightShipmentFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${AirFreightShipmentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AirFreightShipmentsGQL extends Apollo.Query<AirFreightShipmentsQuery, AirFreightShipmentsQueryVariables> {
    document = AirFreightShipmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AirFreightShipmentsFilterAttributesDocument = gql`
    query AirFreightShipmentsFilterAttributes {
  airFreightShipmentsFilterAttributes {
    carrierNames
    controllers
    flightNumbers
    states
    shippers
    masterWaybillNumbers
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AirFreightShipmentsFilterAttributesGQL extends Apollo.Query<AirFreightShipmentsFilterAttributesQuery, AirFreightShipmentsFilterAttributesQueryVariables> {
    document = AirFreightShipmentsFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AirFreightShipmentLineItemsByShipmentDocument = gql`
    query airFreightShipmentLineItemsByShipment($id: Int!) {
  airFreightShipmentLineItemsByShipment(id: $id) {
    ...airFreightShipmentLineItemFields
    airFreightShipment {
      id
    }
  }
}
    ${AirFreightShipmentLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AirFreightShipmentLineItemsByShipmentGQL extends Apollo.Query<AirFreightShipmentLineItemsByShipmentQuery, AirFreightShipmentLineItemsByShipmentQueryVariables> {
    document = AirFreightShipmentLineItemsByShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AirFreightShipmentByIdDocument = gql`
    query airFreightShipmentById($id: Int!) {
  airFreightShipmentById(id: $id) {
    ...airFreightShipmentFields
    airFreightShipmentLineItems {
      ...airFreightShipmentLineItemFields
    }
  }
}
    ${AirFreightShipmentFieldsFragmentDoc}
${AirFreightShipmentLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AirFreightShipmentByIdGQL extends Apollo.Query<AirFreightShipmentByIdQuery, AirFreightShipmentByIdQueryVariables> {
    document = AirFreightShipmentByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NestedAirFreightShipmentLineItemsDocument = gql`
    query NestedAirFreightShipmentLineItems($airFreightShipmentId: ID!) {
  nestedAirFreightShipmentLineItems(airFreightShipmentId: $airFreightShipmentId) {
    bookingReference
    collectionDate
    comments
    commodityCode
    consignee
    consignor
    eta
    externalShipmentId
    finalDestinationCode
    finalDestinationLocation
    finalPortOfDischargeCode
    finalPortOfDischargeLocation
    goodsDescription
    grossDimensions
    grossWeight
    housebill
    id
    isTransshipment
    natureOfGoods
    packageQuantity
    packageType
    orderReference
    specialHandling
    transshipmentPort
    transshipmentCarrierName
    transshipmentFlightNumber
    volume
    lastStatusMessage
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NestedAirFreightShipmentLineItemsGQL extends Apollo.Query<NestedAirFreightShipmentLineItemsQuery, NestedAirFreightShipmentLineItemsQueryVariables> {
    document = NestedAirFreightShipmentLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnallocatedAirFreightShipmentLineItemsDocument = gql`
    query UnallocatedAirFreightShipmentLineItems($orderByFields: [ColumnOrderAttributes!], $first: Int, $last: Int, $after: String, $before: String) {
  unallocatedAirFreightShipmentLineItems(
    orderByFields: $orderByFields
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      bookingReference
      collectionDate
      comments
      commodityCode
      consignee
      consignor
      createdAt
      eta
      externalShipmentId
      finalDestinationCode
      finalDestinationLocation
      finalPortOfDischargeCode
      finalPortOfDischargeLocation
      goodsDescription
      grossDimensions
      grossWeight
      housebill
      id
      isTransshipment
      natureOfGoods
      packageQuantity
      packageType
      orderReference
      specialHandling
      lastStatusMessage
      state
      transshipmentPort
      transshipmentCarrierName
      transshipmentFlightNumber
      updatedAt
      volume
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnallocatedAirFreightShipmentLineItemsGQL extends Apollo.Query<UnallocatedAirFreightShipmentLineItemsQuery, UnallocatedAirFreightShipmentLineItemsQueryVariables> {
    document = UnallocatedAirFreightShipmentLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeesDocument = gql`
    query Employees {
  employees {
    id
    userFirstName
    userLastName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeesGQL extends Apollo.Query<EmployeesQuery, EmployeesQueryVariables> {
    document = EmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompleteAirFreightShipmentDocument = gql`
    mutation CompleteAirFreightShipment($completeAirFreightShipmentId: Int!) {
  completeAirFreightShipment(id: $completeAirFreightShipmentId) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompleteAirFreightShipmentGQL extends Apollo.Mutation<CompleteAirFreightShipmentMutation, CompleteAirFreightShipmentMutationVariables> {
    document = CompleteAirFreightShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAirFreightShipmentControllerDocument = gql`
    mutation UpdateAirFreightShipmentController($airFreightShipmentId: Int!, $controllerId: Int, $controllerName: String) {
  updateAirFreightShipmentController(
    airFreightShipmentId: $airFreightShipmentId
    controllerId: $controllerId
    controllerName: $controllerName
  ) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAirFreightShipmentControllerGQL extends Apollo.Mutation<UpdateAirFreightShipmentControllerMutation, UpdateAirFreightShipmentControllerMutationVariables> {
    document = UpdateAirFreightShipmentControllerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AnalysisDownloadsDocument = gql`
    query analysisDownloads {
  analysisDownloads {
    commodities {
      name
    }
    downloadLink
    fileName
    id
    size
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AnalysisDownloadsGQL extends Apollo.Query<AnalysisDownloadsQuery, AnalysisDownloadsQueryVariables> {
    document = AnalysisDownloadsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartonCountsDocument = gql`
    query CartonCounts {
  cartonCounts {
    id
    name
    commodityId
    cartonTypeId
    counts
    priceMetric
    stackSizes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartonCountsGQL extends Apollo.Query<CartonCountsQuery, CartonCountsQueryVariables> {
    document = CartonCountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartonGroupingsDocument = gql`
    query CartonGroupings($attributes: CartonGroupingSearchAttributes!) {
  cartonGroupingsSearch(attributes: $attributes) {
    cartonGroupings {
      ...cartonGrouping
    }
  }
}
    ${CartonGroupingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartonGroupingsGQL extends Apollo.Query<CartonGroupingsQuery, CartonGroupingsQueryVariables> {
    document = CartonGroupingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CashFlowCartonGroupingsDocument = gql`
    query CashFlowCartonGroupings($attributes: CartonGroupingSearchAttributes!) {
  cartonGroupingsSearch(attributes: $attributes) {
    cartonGroupings {
      ...cashFlowCartonGrouping
    }
  }
}
    ${CashFlowCartonGroupingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CashFlowCartonGroupingsGQL extends Apollo.Query<CashFlowCartonGroupingsQuery, CashFlowCartonGroupingsQueryVariables> {
    document = CashFlowCartonGroupingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CgTransactionsDocument = gql`
    query CgTransactions($first: Int, $last: Int, $after: String, $before: String, $docRowIds: [Int!]) {
  cgTransactions(
    first: $first
    last: $last
    after: $after
    before: $before
    docRowIds: $docRowIds
  ) {
    nodes {
      amount
      cartonGrouping {
        containerNumber
        farmCode
        grade
        cartons
        pack
        palletNumber
        state
        sequenceNumber
        variety {
          name
        }
      }
      createdAt
      currency
      id
      transactionDate
      updatedAt
      exchangeRate
      externalReferenceNum
      costCode {
        name
        generalLedgerName
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CgTransactionsGQL extends Apollo.Query<CgTransactionsQuery, CgTransactionsQueryVariables> {
    document = CgTransactionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCartonGroupingTransactionsDocument = gql`
    mutation UpdateCartonGroupingTransactions($deleteData: [Int!]) {
  updateCartonGroupingTransactions(deleteData: $deleteData) {
    deleteSummary {
      succeeded
      failed
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCartonGroupingTransactionsGQL extends Apollo.Mutation<UpdateCartonGroupingTransactionsMutation, UpdateCartonGroupingTransactionsMutationVariables> {
    document = UpdateCartonGroupingTransactionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCommentDocument = gql`
    mutation CreateComment($attributes: CreateCommentAttributes!) {
  createComment(attributes: $attributes) {
    comment {
      ...commentFields
    }
  }
}
    ${CommentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCommentGQL extends Apollo.Mutation<CreateCommentMutation, CreateCommentMutationVariables> {
    document = CreateCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCommentDocument = gql`
    mutation DeleteComment($commentid: Int!) {
  deleteComment(commentId: $commentid) {
    comment {
      ...commentFields
    }
  }
}
    ${CommentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCommentGQL extends Apollo.Mutation<DeleteCommentMutation, DeleteCommentMutationVariables> {
    document = DeleteCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCommercialTermChangeRequestDocument = gql`
    mutation CreateCommercialTermChangeRequest($attributes: CreateCommercialTermChangeRequestAttributes!) {
  createCommercialTermChangeRequest(attributes: $attributes) {
    changeRequest {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCommercialTermChangeRequestGQL extends Apollo.Mutation<CreateCommercialTermChangeRequestMutation, CreateCommercialTermChangeRequestMutationVariables> {
    document = CreateCommercialTermChangeRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CommercialTermsDocument = gql`
    query CommercialTerms($startweek: String, $endweek: String, $companyid: Int, $targetregion: String, $advancetype: String, $advanceunit: String, $commodity: String, $rankoneevent: String) {
  commercialTerms(
    startweek: $startweek
    endweek: $endweek
    companyid: $companyid
    targetregion: $targetregion
    advancetype: $advancetype
    advanceunit: $advanceunit
    commodity: $commodity
    rankoneevent: $rankoneevent
  ) {
    ...commercialTerm
  }
}
    ${CommercialTermFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CommercialTermsGQL extends Apollo.Query<CommercialTermsQuery, CommercialTermsQueryVariables> {
    document = CommercialTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CommoditiesDocument = gql`
    query Commodities {
  commodities {
    id
    name
    varieties {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CommoditiesGQL extends Apollo.Query<CommoditiesQuery, CommoditiesQueryVariables> {
    document = CommoditiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadCompanyCertificateDocument = gql`
    mutation UploadCompanyCertificate($file: Upload!, $expirydate: String!, $companyid: Int!, $certificatetypeid: Int!) {
  uploadCompanyCertificate(
    file: $file
    expiryDate: $expirydate
    companyId: $companyid
    certificateTypeId: $certificatetypeid
  ) {
    certificate {
      id
      expiryDate
      createdAt
      certificate {
        id
        filename
        url
        v2Url
      }
      certificateType {
        name
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadCompanyCertificateGQL extends Apollo.Mutation<UploadCompanyCertificateMutation, UploadCompanyCertificateMutationVariables> {
    document = UploadCompanyCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCompanyCertificateDocument = gql`
    mutation DeleteCompanyCertificate($id: Int!) {
  deleteCompanyCertificate(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCompanyCertificateGQL extends Apollo.Mutation<DeleteCompanyCertificateMutation, DeleteCompanyCertificateMutationVariables> {
    document = DeleteCompanyCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CertificateTypeDocument = gql`
    query CertificateType {
  certificateTypes {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CertificateTypeGQL extends Apollo.Query<CertificateTypeQuery, CertificateTypeQueryVariables> {
    document = CertificateTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyCompanyDocument = gql`
    query MyCompany {
  myCompany {
    id
    name
    about
    registrationNumber
    vatNumber
    createdAt
    logoFile {
      id
      filename
      url
    }
    address {
      addressLine1
      addressLine2
      city
      postalCode
      country
    }
    tradeRegions {
      id
      name
      code
      countries {
        id
        name
        code
      }
    }
    certificates {
      id
      expiryDate
      createdAt
      updatedAt
      certificate {
        id
        filename
        url
        v2Url
      }
      certificateType {
        name
        id
      }
    }
    varieties {
      id
      varietyId
      name
      peakCount
      hectares
      packingStart
      packingEnd
      commodity {
        id
        name
        icon {
          v2Url
        }
      }
      images {
        id
        url
        v2Url
        filename
        thumbnailUrl
        v2ThumbnailUrl
      }
    }
    sellerVarieties {
      id
      name
      commodity {
        id
        name
      }
    }
    additionalFields {
      id
      companyId
      name
      description
      fieldType
      parameters
      createdAt
      updatedAt
      isPackingInstruction
      private
    }
    activeUsers {
      id
      firstName
      lastName
      phoneNumber
      email
      state
      role
      roles {
        id
        name
        resourceType
        resourceId
        createdAt
        updatedAt
      }
      employee {
        id
      }
    }
    invites {
      id
      email
      state
    }
    sellers {
      id
      name
    }
    isSeller
    buyers {
      id
      name
    }
    isBuyer
    commoditySeasons {
      ...commoditySeason
    }
  }
}
    ${CommoditySeasonFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MyCompanyGQL extends Apollo.Query<MyCompanyQuery, MyCompanyQueryVariables> {
    document = MyCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCompanyDetailsDocument = gql`
    mutation UpdateCompanyDetails($attributes: UpdateCompanyAttributes!) {
  updateCompanyDetails(attributes: $attributes) {
    company {
      id
      name
      about
      registrationNumber
      vatNumber
      address {
        addressLine1
        addressLine2
        city
        postalCode
        country
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyDetailsGQL extends Apollo.Mutation<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables> {
    document = UpdateCompanyDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadCompanyLogoDocument = gql`
    mutation UploadCompanyLogo($file: Upload!, $companyid: Int!) {
  uploadCompanyLogo(file: $file, companyId: $companyid) {
    company {
      logoFile {
        id
        filename
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadCompanyLogoGQL extends Apollo.Mutation<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables> {
    document = UploadCompanyLogoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCompanyTradeRegionsDocument = gql`
    mutation UpdateCompanyTradeRegions($addtraderegions: [TradeRegionAttributes!]!, $removetraderegions: [TradeRegionAttributes!]!) {
  updateCompanyTradeRegions(
    addTradeRegions: $addtraderegions
    removeTradeRegions: $removetraderegions
  ) {
    company {
      tradeRegions {
        id
        name
        code
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyTradeRegionsGQL extends Apollo.Mutation<UpdateCompanyTradeRegionsMutation, UpdateCompanyTradeRegionsMutationVariables> {
    document = UpdateCompanyTradeRegionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DashboardQueryDocument = gql`
    query DashboardQuery($locationId: Int, $containerId: Int, $commoditygroups: [CommodityGroupEnum!], $forExport: Boolean) {
  dashboardTrackingData(
    locationId: $locationId
    containerId: $containerId
    commoditygroups: $commoditygroups
    forExport: $forExport
  ) {
    cartonGroupings {
      batchNumber
      cartons
      commodityName
      consignmentNumber
      containerId
      exporterCode
      farmCode
      grade
      id
      inspectionDate
      inventoryCode
      locationId
      mark
      netMass
      orchard
      packhouseCode
      packingWeek
      palletGrossMass
      palletNumber
      productCharacteristic
      sequenceNumber
      state
      targetMarket
      targetCountry
      targetRegion
      varietyName
    }
    containers {
      arrivalDate
      arrivalEventClassifier
      cartons
      cartonsInTransit
      containerId
      containerNumber
      containerType
      departureDate
      departureEventClassifier
      finalDestinationId
      finalDeliveryDatetime
      finalDestinationName
      finalDestinationType
      locationId
      id
      nextDeliveryDatetime
      nextDestinationId
      nextDestinationName
      nextDestinationType
      pallets
      palletsInTransit
      state
      stateInTransit
    }
    locations {
      id
      cartons
      cartonsInTransit
      containers
      containersInTransit
      geoLocation {
        lat
        lon
      }
      locationId
      locationType
      name
      pallets
      palletsInTransit
      unallocated
      unallocatedInTransit
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DashboardQueryGQL extends Apollo.Query<DashboardQueryQuery, DashboardQueryQueryVariables> {
    document = DashboardQueryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DashboardSearchDocument = gql`
    query DashboardSearch($fields: [DashboardSearchFieldsEnum!], $searchTerm: String, $commodityGroups: [CommodityGroupEnum!]) {
  dashboardSearch(
    fields: $fields
    searchTerm: $searchTerm
    commodityGroups: $commodityGroups
  ) {
    raw
    hits {
      score
      highlight
      source {
        ... on DashboardCartonGrouping {
          batchNumber
          cartons
          commodityName
          consignmentNumber
          containerId
          containerNumber
          exporterCode
          farmCode
          grade
          id
          inspectionDate
          inventoryCode
          locationId
          mark
          netMass
          orchard
          packhouseCode
          packingWeek
          palletGrossMass
          palletNumber
          productCharacteristic
          sequenceNumber
          state
          targetCountry
          targetMarket
          targetRegion
          varietyName
        }
        ... on DashboardContainer {
          arrivalDate
          cartons
          cartonsInTransit
          collectionDatetime
          containerId
          containerNumber
          containerType
          departureDate
          finalDeliveryDatetime
          finalDestinationId
          finalDestinationLatitude
          finalDestinationLongitude
          finalDestinationName
          finalDestinationType
          id
          locationId
          nextDeliveryDatetime
          nextDestinationId
          nextDestinationLatitude
          nextDestinationLongitude
          nextDestinationName
          nextDestinationType
          pallets
          palletsInTransit
          state
          stateInTransit
        }
        ... on DashboardLocation {
          cartons
          cartonsInTransit
          containers
          containersInTransit
          id
          locationId
          locationType
          name
          pallets
          palletsInTransit
          unallocated
          unallocatedInTransit
        }
      }
    }
    total {
      relation
      value
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DashboardSearchGQL extends Apollo.Query<DashboardSearchQuery, DashboardSearchQueryVariables> {
    document = DashboardSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DashboardFiltersDocument = gql`
    query DashboardFilters {
  dashboardFilters {
    commodities {
      id
      name
      commodityGroup
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DashboardFiltersGQL extends Apollo.Query<DashboardFiltersQuery, DashboardFiltersQueryVariables> {
    document = DashboardFiltersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataPreparationsDocument = gql`
    query DataPreparations {
  dataPreparations {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DataPreparationsGQL extends Apollo.Query<DataPreparationsQuery, DataPreparationsQueryVariables> {
    document = DataPreparationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DataPreparationDocument = gql`
    query DataPreparation($id: ID!) {
  dataPreparation(id: $id) {
    dataOutputs {
      createdAt
      downloadedAt
      fileType
      id
      outputFile {
        contentType
        filename
        id
        url
        v2Url
      }
    }
    error
    id
    description
    name
    status
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DataPreparationGQL extends Apollo.Query<DataPreparationQuery, DataPreparationQueryVariables> {
    document = DataPreparationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateDataOutputFilesDocument = gql`
    mutation GenerateDataOutputFiles($id: ID!) {
  generateDataOutputFiles(id: $id) {
    dataPreparation {
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateDataOutputFilesGQL extends Apollo.Mutation<GenerateDataOutputFilesMutation, GenerateDataOutputFilesMutationVariables> {
    document = GenerateDataOutputFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDataOutputDocument = gql`
    mutation UpdateDataOutput($attributes: DataOutputAttributes!) {
  updateDataOutput(attributes: $attributes) {
    dataOutput {
      id
      downloadedAt
    }
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDataOutputGQL extends Apollo.Mutation<UpdateDataOutputMutation, UpdateDataOutputMutationVariables> {
    document = UpdateDataOutputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendEmailDocument = gql`
    mutation SendEmail($attributes: SendEmailAttributes!) {
  sendEmail(attributes: $attributes) {
    errors
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendEmailGQL extends Apollo.Mutation<SendEmailMutation, SendEmailMutationVariables> {
    document = SendEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DuplicateEphytoDocument = gql`
    mutation DuplicateEphyto($id: Int!, $applicationType: Int!) {
  duplicateEphyto(id: $id, applicationType: $applicationType) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicateEphytoGQL extends Apollo.Mutation<DuplicateEphytoMutation, DuplicateEphytoMutationVariables> {
    document = DuplicateEphytoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEphytoDocument = gql`
    mutation DeleteEphyto($id: Int!) {
  deleteEphyto(id: $id) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEphytoGQL extends Apollo.Mutation<DeleteEphytoMutation, DeleteEphytoMutationVariables> {
    document = DeleteEphytoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEphytoDocumentDocument = gql`
    mutation UpdateEphytoDocument($id: Int!, $type: String!) {
  updateEphytoDocument(id: $id, type: $type) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEphytoDocumentGQL extends Apollo.Mutation<UpdateEphytoDocumentMutation, UpdateEphytoDocumentMutationVariables> {
    document = UpdateEphytoDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadEphytoDocumentDocument = gql`
    mutation UploadEphytoDocument($ephytoId: Int!, $file: Upload!) {
  uploadEphytoDocument(ephytoId: $ephytoId, file: $file) {
    errors
    ephytoDocuments {
      ...ephytoDocumentFields
    }
  }
}
    ${EphytoDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadEphytoDocumentGQL extends Apollo.Mutation<UploadEphytoDocumentMutation, UploadEphytoDocumentMutationVariables> {
    document = UploadEphytoDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEphytoDocumentDocument = gql`
    mutation DeleteEphytoDocument($id: Int!) {
  deleteEphytoDocument(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEphytoDocumentGQL extends Apollo.Mutation<DeleteEphytoDocumentMutation, DeleteEphytoDocumentMutationVariables> {
    document = DeleteEphytoDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDraftEphytoDocument = gql`
    mutation UpdateDraftEphyto($ephytoId: Int!) {
  updateDraftEphyto(ephytoId: $ephytoId) {
    errors
    ephyto {
      ...ephytoCommonFields
      ephytoTradeUnits {
        ...ephytoTradeUnitCommonFields
      }
      ephytoConsignmentItems {
        ...ephytoConsignmentItemCommonFields
      }
      ephytoDocuments {
        ...ephytoDocumentFields
      }
    }
  }
}
    ${EphytoCommonFieldsFragmentDoc}
${EphytoTradeUnitCommonFieldsFragmentDoc}
${EphytoConsignmentItemCommonFieldsFragmentDoc}
${EphytoDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDraftEphytoGQL extends Apollo.Mutation<UpdateDraftEphytoMutation, UpdateDraftEphytoMutationVariables> {
    document = UpdateDraftEphytoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitEphytoDocument = gql`
    mutation SubmitEphyto($ephytoId: Int!) {
  submitEphyto(ephytoId: $ephytoId) {
    errors
    ephyto {
      ...ephytoCommonFields
      ephytoTradeUnits {
        ...ephytoTradeUnitCommonFields
      }
      ephytoConsignmentItems {
        ...ephytoConsignmentItemCommonFields
      }
      ephytoDocuments {
        ...ephytoDocumentFields
      }
    }
  }
}
    ${EphytoCommonFieldsFragmentDoc}
${EphytoTradeUnitCommonFieldsFragmentDoc}
${EphytoConsignmentItemCommonFieldsFragmentDoc}
${EphytoDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitEphytoGQL extends Apollo.Mutation<SubmitEphytoMutation, SubmitEphytoMutationVariables> {
    document = SubmitEphytoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DraftEphytoDocument = gql`
    mutation DraftEphyto($ephytoId: Int!) {
  draftEphyto(ephytoId: $ephytoId) {
    errors
    ephyto {
      ...ephytoCommonFields
      ephytoTradeUnits {
        ...ephytoTradeUnitCommonFields
      }
      ephytoConsignmentItems {
        ...ephytoConsignmentItemCommonFields
      }
      ephytoDocuments {
        ...ephytoDocumentFields
      }
    }
  }
}
    ${EphytoCommonFieldsFragmentDoc}
${EphytoTradeUnitCommonFieldsFragmentDoc}
${EphytoConsignmentItemCommonFieldsFragmentDoc}
${EphytoDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DraftEphytoGQL extends Apollo.Mutation<DraftEphytoMutation, DraftEphytoMutationVariables> {
    document = DraftEphytoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchEphytoStatusDocument = gql`
    mutation FetchEphytoStatus($ephytoId: Int!) {
  fetchEphytoStatus(ephytoId: $ephytoId) {
    errors
    ephyto {
      ...ephytoCommonFields
      ephytoTradeUnits {
        ...ephytoTradeUnitCommonFields
      }
      ephytoConsignmentItems {
        ...ephytoConsignmentItemCommonFields
      }
      ephytoDocuments {
        ...ephytoDocumentFields
      }
    }
  }
}
    ${EphytoCommonFieldsFragmentDoc}
${EphytoTradeUnitCommonFieldsFragmentDoc}
${EphytoConsignmentItemCommonFieldsFragmentDoc}
${EphytoDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchEphytoStatusGQL extends Apollo.Mutation<FetchEphytoStatusMutation, FetchEphytoStatusMutationVariables> {
    document = FetchEphytoStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateEphytoDocument = gql`
    mutation GenerateEphyto($isEntireBooking: Boolean!, $shipmentType: String!, $bookingReference: String, $ephytoId: Int, $sliIds: [Int!]) {
  generateEphyto(
    isEntireBooking: $isEntireBooking
    shipmentType: $shipmentType
    bookingReference: $bookingReference
    ephytoId: $ephytoId
    sliIds: $sliIds
  ) {
    ephyto {
      ...ephytoCommonFields
      ephytoTradeUnits {
        ...ephytoTradeUnitCommonFields
      }
      ephytoConsignmentItems {
        ...ephytoConsignmentItemCommonFields
      }
      ephytoDocuments {
        ...ephytoDocumentFields
      }
    }
    errors
  }
}
    ${EphytoCommonFieldsFragmentDoc}
${EphytoTradeUnitCommonFieldsFragmentDoc}
${EphytoConsignmentItemCommonFieldsFragmentDoc}
${EphytoDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateEphytoGQL extends Apollo.Mutation<GenerateEphytoMutation, GenerateEphytoMutationVariables> {
    document = GenerateEphytoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateEphytoTradeUnitDocument = gql`
    mutation GenerateEphytoTradeUnit($shipmentType: String!, $ephytoId: Int) {
  generateEphytoTradeUnit(shipmentType: $shipmentType, ephytoId: $ephytoId) {
    errors
    ephytoTradeUnits {
      ...ephytoTradeUnitCommonFields
    }
  }
}
    ${EphytoTradeUnitCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateEphytoTradeUnitGQL extends Apollo.Mutation<GenerateEphytoTradeUnitMutation, GenerateEphytoTradeUnitMutationVariables> {
    document = GenerateEphytoTradeUnitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEphytoDocument = gql`
    mutation UpdateEphyto($ephyto: EphytoAttributes!, $ephytoConsignmentItems: [EphytoConsignmentItemAttributes!]!) {
  updateEphyto(ephyto: $ephyto, ephytoConsignmentItems: $ephytoConsignmentItems) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEphytoGQL extends Apollo.Mutation<UpdateEphytoMutation, UpdateEphytoMutationVariables> {
    document = UpdateEphytoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoPackageTypesDocument = gql`
    query EphytoPackageTypes {
  ephytoPackageTypes {
    name
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoPackageTypesGQL extends Apollo.Query<EphytoPackageTypesQuery, EphytoPackageTypesQueryVariables> {
    document = EphytoPackageTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoAppliedChemicalsDocument = gql`
    query EphytoAppliedChemicals {
  ephytoAppliedChemicals
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoAppliedChemicalsGQL extends Apollo.Query<EphytoAppliedChemicalsQuery, EphytoAppliedChemicalsQueryVariables> {
    document = EphytoAppliedChemicalsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoTransportMeansDocument = gql`
    query EphytoTransportMeans {
  ephytoTransportMeans
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoTransportMeansGQL extends Apollo.Query<EphytoTransportMeansQuery, EphytoTransportMeansQueryVariables> {
    document = EphytoTransportMeansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoUnitOfMeasureCodesDocument = gql`
    query EphytoUnitOfMeasureCodes {
  ephytoUnitOfMeasureCodes
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoUnitOfMeasureCodesGQL extends Apollo.Query<EphytoUnitOfMeasureCodesQuery, EphytoUnitOfMeasureCodesQueryVariables> {
    document = EphytoUnitOfMeasureCodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoTreatmentsDocument = gql`
    query EphytoTreatments {
  ephytoTreatments {
    firstLevel
    firstLevelCode
    secondLevel
    secondLevelCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoTreatmentsGQL extends Apollo.Query<EphytoTreatmentsQuery, EphytoTreatmentsQueryVariables> {
    document = EphytoTreatmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoCountriesDocument = gql`
    query EphytoCountries {
  ephytoCountries {
    isoCode
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoCountriesGQL extends Apollo.Query<EphytoCountriesQuery, EphytoCountriesQueryVariables> {
    document = EphytoCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoAgreementCodesDocument = gql`
    query EphytoAgreementCodes {
  ephytoAgreementCodes {
    agreementCode
    agreementName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoAgreementCodesGQL extends Apollo.Query<EphytoAgreementCodesQuery, EphytoAgreementCodesQueryVariables> {
    document = EphytoAgreementCodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoLocationsDocument = gql`
    query EphytoLocations($countryCodes: [String!]!) {
  ephytoLocations(countryCodes: $countryCodes) {
    name
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoLocationsGQL extends Apollo.Query<EphytoLocationsQuery, EphytoLocationsQueryVariables> {
    document = EphytoLocationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoContactsDocument = gql`
    query EphytoContacts {
  contacts {
    id
    name
    info
    contactType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoContactsGQL extends Apollo.Query<EphytoContactsQuery, EphytoContactsQueryVariables> {
    document = EphytoContactsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NonEphytoShipmentLineItemsDocument = gql`
    query nonEphytoShipmentLineItems($shipmentId: Int!, $type: String!) {
  nonEphytoShipmentLineItems(shipmentId: $shipmentId, type: $type) {
    id
    labelDisplay
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NonEphytoShipmentLineItemsGQL extends Apollo.Query<NonEphytoShipmentLineItemsQuery, NonEphytoShipmentLineItemsQueryVariables> {
    document = NonEphytoShipmentLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytosDocument = gql`
    query ephytos($filterAttributes: EphytoFilterAttributes!, $searchAttributes: SearchAttributes!, $orderByFields: [ColumnOrderAttributes!], $first: Int, $last: Int, $after: String, $before: String) {
  ephytos(
    filterAttributes: $filterAttributes
    searchAttributes: $searchAttributes
    orderByFields: $orderByFields
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      id
      agreementCode
      applicationStatus
      state
      targetCountry
      unloadingBaseportLocation
      customReferenceNo
      applicationReferenceNo
      desiredIssueLocation
      departureDate
      applicationType
      consignorName
      consigneeName
      updatedAt
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytosGQL extends Apollo.Query<EphytosQuery, EphytosQueryVariables> {
    document = EphytosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoFilterAttributesDocument = gql`
    query EphytoFilterAttributes {
  ephytoFilterAttributes {
    statuses
    customerReferences
    entryPorts
    consignees
    consignors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoFilterAttributesGQL extends Apollo.Query<EphytoFilterAttributesQuery, EphytoFilterAttributesQueryVariables> {
    document = EphytoFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoCertificateUrlDocument = gql`
    query EphytoCertificateUrl($id: Int!) {
  ephytoCertificateUrl(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoCertificateUrlGQL extends Apollo.Query<EphytoCertificateUrlQuery, EphytoCertificateUrlQueryVariables> {
    document = EphytoCertificateUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExportDocumentTypesDocument = gql`
    query ExportDocumentTypes {
  exportDocumentTypes
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportDocumentTypesGQL extends Apollo.Query<ExportDocumentTypesQuery, ExportDocumentTypesQueryVariables> {
    document = ExportDocumentTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoDocumentTypesDocument = gql`
    query EphytoDocumentTypes {
  ephytoDocumentTypes
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoDocumentTypesGQL extends Apollo.Query<EphytoDocumentTypesQuery, EphytoDocumentTypesQueryVariables> {
    document = EphytoDocumentTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EphytoByIdDocument = gql`
    query EphytoById($id: Int!) {
  ephytoById(id: $id) {
    ...ephytoCommonFields
    ephytoTradeUnits {
      ...ephytoTradeUnitCommonFields
    }
    ephytoConsignmentItems {
      ...ephytoConsignmentItemCommonFields
    }
    ephytoDocuments {
      ...ephytoDocumentFields
    }
  }
}
    ${EphytoCommonFieldsFragmentDoc}
${EphytoTradeUnitCommonFieldsFragmentDoc}
${EphytoConsignmentItemCommonFieldsFragmentDoc}
${EphytoDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EphytoByIdGQL extends Apollo.Query<EphytoByIdQuery, EphytoByIdQueryVariables> {
    document = EphytoByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EstimateConfigDocument = gql`
    query estimateConfig {
  uiConfigs(configType: "estimate-config", visibility: Company) {
    id
    data
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EstimateConfigGQL extends Apollo.Query<EstimateConfigQuery, EstimateConfigQueryVariables> {
    document = EstimateConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EstimateOptionsDocument = gql`
    query estimateOptions($season: Int!) {
  myCompany {
    id
    name
    sellers {
      id
      name
    }
  }
  commodities(onlyOperational: true) {
    id
    name
  }
  varieties(onlyOperational: true, skipMixedVarieties: true) {
    id
    name
    commodity {
      id
    }
  }
  productionUnits: foodBusinessOperators(businessTypes: [0], includeSellers: true) {
    id
    name
    typeOfBusiness
    fboCode
    company {
      id
      name
    }
  }
  packhouses: foodBusinessOperators(businessTypes: [1, 5], includeSellers: true) {
    id
    name
    typeOfBusiness
    fboCode
    company {
      id
    }
  }
  fboOrchards(includeSellers: true) {
    id
    hectares(season: $season)
    orchard
    block
    variety {
      id
      name
      commodity {
        id
        name
      }
    }
    foodBusinessOperator {
      id
      fboCode
      name
      company {
        id
        name
      }
    }
  }
  cartonCounts {
    id
    name
    commodityId
    cartonTypeId
    counts
    priceMetric
    stackSizes
  }
  commodityDefaultSeasons {
    commodityId
    minWeek(year: $season)
    maxWeek(year: $season)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EstimateOptionsGQL extends Apollo.Query<EstimateOptionsQuery, EstimateOptionsQueryVariables> {
    document = EstimateOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EstimatesDocument = gql`
    query Estimates($endWeek: String, $startWeek: String, $weeks: Boolean, $season: Int, $matchCols: [EstimateMatchModeEnum!], $commodityIds: [Int!], $subCommodityIds: [Int!], $varietyIds: [Int!]) {
  estimates(
    endWeek: $endWeek
    startWeek: $startWeek
    weeks: $weeks
    season: $season
    commodityIds: $commodityIds
    subCommodityIds: $subCommodityIds
    varietyIds: $varietyIds
  ) {
    id
    week
    season
    provider {
      id
      name
    }
    fboOrchard {
      id
      orchard
      hectares(season: $season)
      foodBusinessOperator {
        company {
          id
        }
      }
    }
    cartonType {
      id
      name
    }
    commodity {
      id
      name
    }
    variety {
      id
      name
      subCommodity {
        name
      }
    }
    productionUnit {
      id
      name
      fboCode
      typeOfBusiness
    }
    packHouse {
      id
      name
      fboCode
      typeOfBusiness
    }
    estimateValues {
      id
      estType
      value
      conversionWeight
      grade
      sizeCounts
      acceptedValue(matchCols: $matchCols)
      inSeasonValue
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EstimatesGQL extends Apollo.Query<EstimatesQuery, EstimatesQueryVariables> {
    document = EstimatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrchardSeasonsDocument = gql`
    query OrchardSeasons($season: Int!) {
  orchardSeasons(season: $season) {
    id
    startDate
    endDate
    hectares
    plantedDate
    fboOrchard {
      orchard
      foodBusinessOperator {
        fboCode
      }
      variety {
        name
        commodity {
          id
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrchardSeasonsGQL extends Apollo.Query<OrchardSeasonsQuery, OrchardSeasonsQueryVariables> {
    document = OrchardSeasonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrchardYieldsDocument = gql`
    query OrchardYields($orchardSeasonId: ID, $capturedBefore: ISO8601DateTime!, $capturedAfter: ISO8601DateTime!) {
  orchardYields(
    orchardSeasonId: $orchardSeasonId
    capturedBefore: $capturedBefore
    capturedAfter: $capturedAfter
  ) {
    id
    weight
    weightPerBin
    orchardSeasonId
    captureDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrchardYieldsGQL extends Apollo.Query<OrchardYieldsQuery, OrchardYieldsQueryVariables> {
    document = OrchardYieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrchardSeasonsDocument = gql`
    mutation UpdateOrchardSeasons($updateData: [OrchardSeasonAttributes!], $deleteData: [Int!]) {
  updateOrchardSeasons(updateData: $updateData, deleteData: $deleteData) {
    createSummary {
      succeeded
      failed
      errors
    }
    updateSummary {
      succeeded
      failed
      errors
    }
    deleteSummary {
      succeeded
      failed
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrchardSeasonsGQL extends Apollo.Mutation<UpdateOrchardSeasonsMutation, UpdateOrchardSeasonsMutationVariables> {
    document = UpdateOrchardSeasonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateCommoditySeasonsDocument = gql`
    mutation GenerateCommoditySeasons($season: Int!) {
  generateCommoditySeasons(seasonYear: $season) {
    error
    failures
    successes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateCommoditySeasonsGQL extends Apollo.Mutation<GenerateCommoditySeasonsMutation, GenerateCommoditySeasonsMutationVariables> {
    document = GenerateCommoditySeasonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrchardYieldsDocument = gql`
    mutation UpdateOrchardYields($createData: [OrchardYieldAttributes!], $updateData: [OrchardYieldAttributes!], $deleteData: [Int!]) {
  updateOrchardYields(
    createData: $createData
    updateData: $updateData
    deleteData: $deleteData
  ) {
    createSummary {
      errors
      failed
      succeeded
    }
    updateSummary {
      succeeded
      failed
      errors
    }
    deleteSummary {
      succeeded
      failed
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrchardYieldsGQL extends Apollo.Mutation<UpdateOrchardYieldsMutation, UpdateOrchardYieldsMutationVariables> {
    document = UpdateOrchardYieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEstimatesDocument = gql`
    mutation UpdateEstimates($createData: [EstimateAttributes!], $deleteData: [Int!], $updateData: [EstimateAttributes!]) {
  updateEstimates(
    createData: $createData
    deleteData: $deleteData
    updateData: $updateData
  ) {
    createSummary {
      succeeded
      failed
      errors
    }
    updateSummary {
      succeeded
      failed
      errors
    }
    deleteSummary {
      succeeded
      failed
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEstimatesGQL extends Apollo.Mutation<UpdateEstimatesMutation, UpdateEstimatesMutationVariables> {
    document = UpdateEstimatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DocumentsDocument = gql`
    query Documents($first: Int, $last: Int, $after: String, $before: String, $dateAfter: ISO8601DateTime, $dateBefore: ISO8601DateTime, $states: [EtlDocumentStateEnum!]) {
  documents(
    first: $first
    last: $last
    after: $after
    before: $before
    dateAfter: $dateAfter
    dateBefore: $dateBefore
    states: $states
  ) {
    nodes {
      ...documentFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${DocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DocumentsGQL extends Apollo.Query<DocumentsQuery, DocumentsQueryVariables> {
    document = DocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DocumentDocument = gql`
    query Document($documentId: Int!) {
  document(id: $documentId) {
    ...documentFields
  }
}
    ${DocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DocumentGQL extends Apollo.Query<DocumentQuery, DocumentQueryVariables> {
    document = DocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DocumentRowsDocument = gql`
    query DocumentRows($after: String, $before: String, $first: Int, $last: Int, $documentId: Int!, $states: [EtlDocumentRowStateEnum!]) {
  documentRows(
    after: $after
    before: $before
    first: $first
    last: $last
    documentId: $documentId
    states: $states
  ) {
    nodes {
      createdAt
      errorReason
      id
      processOutput
      state
      value
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DocumentRowsGQL extends Apollo.Query<DocumentRowsQuery, DocumentRowsQueryVariables> {
    document = DocumentRowsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadEtlDocumentDocument = gql`
    mutation UploadEtlDocument($documentId: Int!, $file: Upload!) {
  uploadEtlDocument(documentId: $documentId, file: $file) {
    document {
      ...documentFields
    }
  }
}
    ${DocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadEtlDocumentGQL extends Apollo.Mutation<UploadEtlDocumentMutation, UploadEtlDocumentMutationVariables> {
    document = UploadEtlDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProcessEtlDocumentDocument = gql`
    mutation ProcessEtlDocument($documentId: Int!) {
  processEtlDocument(documentId: $documentId) {
    document {
      ...documentFields
    }
  }
}
    ${DocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProcessEtlDocumentGQL extends Apollo.Mutation<ProcessEtlDocumentMutation, ProcessEtlDocumentMutationVariables> {
    document = ProcessEtlDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFarmVarietyDocument = gql`
    mutation CreateFarmVariety($attributes: CreateFarmVarietyAttributes!) {
  createFarmVariety(attributes: $attributes) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFarmVarietyGQL extends Apollo.Mutation<CreateFarmVarietyMutation, CreateFarmVarietyMutationVariables> {
    document = CreateFarmVarietyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFarmVarietyDocument = gql`
    mutation UpdateFarmVariety($attributes: UpdateFarmVarietyAttributes!) {
  updateFarmVariety(attributes: $attributes) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFarmVarietyGQL extends Apollo.Mutation<UpdateFarmVarietyMutation, UpdateFarmVarietyMutationVariables> {
    document = UpdateFarmVarietyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFarmVarietyDocument = gql`
    mutation DeleteFarmVariety($varietyid: Int!, $companyId: Int!) {
  deleteFarmVariety(varietyId: $varietyid, companyId: $companyId) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFarmVarietyGQL extends Apollo.Mutation<DeleteFarmVarietyMutation, DeleteFarmVarietyMutationVariables> {
    document = DeleteFarmVarietyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadFarmVarietyImageDocument = gql`
    mutation UploadFarmVarietyImage($id: Int!, $image: Upload!) {
  uploadFarmVarietyImage(id: $id, image: $image) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadFarmVarietyImageGQL extends Apollo.Mutation<UploadFarmVarietyImageMutation, UploadFarmVarietyImageMutationVariables> {
    document = UploadFarmVarietyImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFarmVarietyImageDocument = gql`
    mutation DeleteFarmVarietyImage($varietyid: Int!, $imageid: Int!) {
  deleteFarmVarietyImage(varietyId: $varietyid, imageId: $imageid) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFarmVarietyImageGQL extends Apollo.Mutation<DeleteFarmVarietyImageMutation, DeleteFarmVarietyImageMutationVariables> {
    document = DeleteFarmVarietyImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinanceReportDocument = gql`
    query FinanceReport($attributes: FinanceReportFilterAttributes!) {
  financeReport(attributes: $attributes) {
    items {
      commodity
      variety
      amount
      amountPerCarton
      batchNumber
      buyer
      packType
      cartons
      stdCartons
      containerNumber
      currency
      exchangeRate
      exporterCode
      farmCode
      grade
      incoterm
      isActual
      palletNumber
      liReferenceNumber
      txReferenceNumber
      seller
      sizeCount
      state
      targetMarket
      targetRegion
      transactionDate
      transactionType
      transactionWeek
      shippingWeek
      packingWeek
      generalLedgerName
      secondaryCategory
      primaryCategory
      zarAmount
      zarPerCarton
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinanceReportGQL extends Apollo.Query<FinanceReportQuery, FinanceReportQueryVariables> {
    document = FinanceReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrencyConversionDocument = gql`
    query CurrencyConversion {
  currencyConversion {
    rates
    timestamp
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrencyConversionGQL extends Apollo.Query<CurrencyConversionQuery, CurrencyConversionQueryVariables> {
    document = CurrencyConversionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteUserDocument = gql`
    mutation InviteUser($email: String!, $firstName: String, $lastName: String) {
  inviteUser(email: $email, firstName: $firstName, lastName: $lastName) {
    invite {
      id
      email
      state
      message
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteUserGQL extends Apollo.Mutation<InviteUserMutation, InviteUserMutationVariables> {
    document = InviteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RevokeInviteDocument = gql`
    mutation RevokeInvite($id: Int!) {
  revokeInvite(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RevokeInviteGQL extends Apollo.Mutation<RevokeInviteMutation, RevokeInviteMutationVariables> {
    document = RevokeInviteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendInviteDocument = gql`
    mutation ResendInvite($id: Int!) {
  resendInvite(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendInviteGQL extends Apollo.Mutation<ResendInviteMutation, ResendInviteMutationVariables> {
    document = ResendInviteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoicesSummaryDocument = gql`
    query invoicesSummary($filterAttributes: InvoicesFilters!, $searchAttributes: SearchAttributes!, $orderByFields: [ColumnOrderAttributes!], $page: String!, $first: Int, $last: Int, $after: String, $before: String) {
  invoices(
    filterAttributes: $filterAttributes
    searchAttributes: $searchAttributes
    orderByFields: $orderByFields
    page: $page
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      ...invoicesSummaryFields
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${InvoicesSummaryFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoicesSummaryGQL extends Apollo.Query<InvoicesSummaryQuery, InvoicesSummaryQueryVariables> {
    document = InvoicesSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceDocument = gql`
    query invoice($id: Int!) {
  invoice(id: $id) {
    invoiceLineItems {
      ...invliCommonFields
    }
    ...invCommonFields
    cartonGroupings {
      ...invCommonCGFields
    }
  }
}
    ${InvliCommonFieldsFragmentDoc}
${InvCommonFieldsFragmentDoc}
${InvCommonCgFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceGQL extends Apollo.Query<InvoiceQuery, InvoiceQueryVariables> {
    document = InvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceFilterAttributesDocument = gql`
    query invoiceFilterAttributes($page: String!) {
  invoicesFilterAttributes(page: $page) {
    vessels {
      id
      vesselName
    }
    contacts {
      id
      name
      contactType
    }
    incoterms
    varieties {
      id
      name
    }
    commodities {
      id
      name
    }
    documentTypes {
      type
      name
    }
    states
    targetCountries
    invoiceNumbers
    transportTypes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceFilterAttributesGQL extends Apollo.Query<InvoiceFilterAttributesQuery, InvoiceFilterAttributesQueryVariables> {
    document = InvoiceFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceNumberDocument = gql`
    query invoiceNumber($documenttype: String) {
  invoiceNumber(documenttype: $documenttype)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceNumberGQL extends Apollo.Query<InvoiceNumberQuery, InvoiceNumberQueryVariables> {
    document = InvoiceNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceSeaPortsDocument = gql`
    query invoiceSeaPorts($limit: Int, $seaportname: String, $isportofloading: Boolean, $orderbyinv: Boolean) {
  seaPorts(
    limit: $limit
    seaportname: $seaportname
    isportofloading: $isportofloading
    orderbyinv: $orderbyinv
  ) {
    ...invPortFields
  }
}
    ${InvPortFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceSeaPortsGQL extends Apollo.Query<InvoiceSeaPortsQuery, InvoiceSeaPortsQueryVariables> {
    document = InvoiceSeaPortsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceTradeCountriesDocument = gql`
    query invoiceTradeCountries($limit: Int, $orderByInv: Boolean, $searchTerm: String) {
  tradeCountries(limit: $limit, orderByInv: $orderByInv, searchTerm: $searchTerm) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceTradeCountriesGQL extends Apollo.Query<InvoiceTradeCountriesQuery, InvoiceTradeCountriesQueryVariables> {
    document = InvoiceTradeCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceVesselsDocument = gql`
    query invoiceVessels($limit: Int, $seaportid: Int, $vesselname: String) {
  vessels(limit: $limit, seaportid: $seaportid, vesselname: $vesselname) {
    id
    vesselName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceVesselsGQL extends Apollo.Query<InvoiceVesselsQuery, InvoiceVesselsQueryVariables> {
    document = InvoiceVesselsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceVoyagesDocument = gql`
    query invoiceVoyages($vesselid: Int!, $seaportid: Int) {
  voyages(vesselid: $vesselid, seaportid: $seaportid)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceVoyagesGQL extends Apollo.Query<InvoiceVoyagesQuery, InvoiceVoyagesQueryVariables> {
    document = InvoiceVoyagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceContactsDocument = gql`
    query invoiceContacts {
  contacts {
    id
    name
    info
    contactType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceContactsGQL extends Apollo.Query<InvoiceContactsQuery, InvoiceContactsQueryVariables> {
    document = InvoiceContactsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceContainersDocument = gql`
    query invoiceContainers($limit: Int, $port: Int, $containernumber: String, $excludesassignedinvlis: Boolean, $onlytrackingenabled: Boolean) {
  containers(
    limit: $limit
    originportid: $port
    containernumber: $containernumber
    excludesassignedinvlis: $excludesassignedinvlis
    onlytrackingenabled: $onlytrackingenabled
  ) {
    containerNumber
    id
    stuffDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceContainersGQL extends Apollo.Query<InvoiceContainersQuery, InvoiceContainersQueryVariables> {
    document = InvoiceContainersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceUnassignedCGsDocument = gql`
    query invoiceUnassignedCGs($attributes: CartonGroupingSearchAttributes!) {
  cartonGroupingsSearch(attributes: $attributes) {
    cartonGroupings {
      ...invCommonCGFields
    }
  }
}
    ${InvCommonCgFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceUnassignedCGsGQL extends Apollo.Query<InvoiceUnassignedCGsQuery, InvoiceUnassignedCGsQueryVariables> {
    document = InvoiceUnassignedCGsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceCustomFieldsDocument = gql`
    query invoiceCustomFields($excludedCustomFieldIds: [Int!]) {
  customFields(excludedCustomFieldIds: $excludedCustomFieldIds) {
    id
    name
    required
    isDefault
    defaultValue
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceCustomFieldsGQL extends Apollo.Query<InvoiceCustomFieldsQuery, InvoiceCustomFieldsQueryVariables> {
    document = InvoiceCustomFieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContactDocument = gql`
    mutation CreateContact($attributes: ContactAttributes!) {
  createContact(attributes: $attributes) {
    contact {
      id
      name
      info
      contactType
    }
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContactGQL extends Apollo.Mutation<CreateContactMutation, CreateContactMutationVariables> {
    document = CreateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateContactDocument = gql`
    mutation UpdateContact($attributes: ContactAttributes!) {
  updateContact(attributes: $attributes) {
    contact {
      id
      name
      info
      contactType
    }
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateContactGQL extends Apollo.Mutation<UpdateContactMutation, UpdateContactMutationVariables> {
    document = UpdateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: Int!) {
  deleteContact(id: $id) {
    success
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteContactGQL extends Apollo.Mutation<DeleteContactMutation, DeleteContactMutationVariables> {
    document = DeleteContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCustomFieldDocument = gql`
    mutation CreateCustomField($attributes: CustomFieldAttributes!) {
  createCustomField(attributes: $attributes) {
    customField {
      id
      name
      required
      isDefault
      defaultValue
    }
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCustomFieldGQL extends Apollo.Mutation<CreateCustomFieldMutation, CreateCustomFieldMutationVariables> {
    document = CreateCustomFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCustomFieldDocument = gql`
    mutation UpdateCustomField($attributes: CustomFieldAttributes!) {
  updateCustomField(attributes: $attributes) {
    customField {
      id
      name
      required
      isDefault
      defaultValue
    }
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCustomFieldGQL extends Apollo.Mutation<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables> {
    document = UpdateCustomFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCustomFieldDocument = gql`
    mutation DeleteCustomField($id: Int!) {
  deleteCustomField(id: $id) {
    success
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCustomFieldGQL extends Apollo.Mutation<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables> {
    document = DeleteCustomFieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($attributes: InvoiceAttributes!) {
  createInvoice(attributes: $attributes) {
    errors
    invoice {
      cartonGroupings {
        ...invCommonCGFields
      }
      invoiceLineItems {
        ...invliCommonFields
      }
      ...invCommonFields
    }
  }
}
    ${InvCommonCgFieldsFragmentDoc}
${InvliCommonFieldsFragmentDoc}
${InvCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInvoiceGQL extends Apollo.Mutation<CreateInvoiceMutation, CreateInvoiceMutationVariables> {
    document = CreateInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DuplicateInvoiceDocument = gql`
    mutation DuplicateInvoice($id: ID!, $documentType: String!) {
  duplicateInvoice(id: $id, documentType: $documentType) {
    errors
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicateInvoiceGQL extends Apollo.Mutation<DuplicateInvoiceMutation, DuplicateInvoiceMutationVariables> {
    document = DuplicateInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($attributes: InvoiceAttributes!) {
  updateInvoice(attributes: $attributes) {
    errors
    invoice {
      cartonGroupings {
        ...invCommonCGFields
      }
      invoiceLineItems {
        ...invliCommonFields
      }
      ...invCommonFields
    }
  }
}
    ${InvCommonCgFieldsFragmentDoc}
${InvliCommonFieldsFragmentDoc}
${InvCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInvoiceGQL extends Apollo.Mutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables> {
    document = UpdateInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HandleInvoiceRemittanceDocument = gql`
    mutation HandleInvoiceRemittance($ids: [ID!]!, $state: String, $amountRemitted: Float, $batchUpdate: Boolean) {
  handleInvoiceRemittance(
    ids: $ids
    state: $state
    amountRemitted: $amountRemitted
    batchUpdate: $batchUpdate
  ) {
    invoices {
      id
      state
      amountRemitted
      finalPayment
    }
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HandleInvoiceRemittanceGQL extends Apollo.Mutation<HandleInvoiceRemittanceMutation, HandleInvoiceRemittanceMutationVariables> {
    document = HandleInvoiceRemittanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteInvoiceDocument = gql`
    mutation DeleteInvoice($id: Int!) {
  deleteInvoice(id: $id) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteInvoiceGQL extends Apollo.Mutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables> {
    document = DeleteInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalculateInvoiceTotalsDocument = gql`
    mutation CalculateInvoiceTotals($attributes: InvoiceIncotermAttributes!) {
  calculateInvoiceIncoterm(attributes: $attributes) {
    invoice {
      id
      cartonCount
      currency
      freightCost
      insuranceCost
      localCosts
      palletCount
      totalFob
      totalIncoterm
      incoterm
      invoiceDate
      advancePayment
      advanceCreditTerm
      finalPayment
      finalCreditTerm
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalculateInvoiceTotalsGQL extends Apollo.Mutation<CalculateInvoiceTotalsMutation, CalculateInvoiceTotalsMutationVariables> {
    document = CalculateInvoiceTotalsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateInvoiceOutputFilesDocument = gql`
    mutation GenerateInvoiceOutputFiles($invoiceId: ID!) {
  generateInvoiceOutputFiles(invoiceId: $invoiceId) {
    templateOutput {
      errorReason
      id
      state
      templateVariant
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateInvoiceOutputFilesGQL extends Apollo.Mutation<GenerateInvoiceOutputFilesMutation, GenerateInvoiceOutputFilesMutationVariables> {
    document = GenerateInvoiceOutputFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoisSummaryDocument = gql`
    query LoisSummary($filterAttributes: LoadOutInstructionsFilters, $searchAttributes: SearchAttributes!, $orderByFields: [ColumnOrderAttributes!], $first: Int, $last: Int, $after: String, $before: String) {
  loadOutInstructions(
    filterAttributes: $filterAttributes
    searchAttributes: $searchAttributes
    orderByFields: $orderByFields
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      loadOutReference
      bookingReference
      containerNumber
      transportType
      exporter {
        name
      }
      stuffDate
      contacts {
        name
        contactType
      }
      vessel {
        vesselName
      }
      truckNumber
      loadingPoint {
        name
      }
      offloadingPoint {
        name
      }
      id
      fileNumber
      airWaybillNo
      shipper
      destination
      flightDate
      updatedAt
      sentEmailList {
        id
        createdAt
        recipient
        attachments
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoisSummaryGQL extends Apollo.Query<LoisSummaryQuery, LoisSummaryQueryVariables> {
    document = LoisSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiFilterAttributesDocument = gql`
    query LoiFilterAttributes {
  loadOutInstructionsFilterAttributes {
    commodities {
      id
      name
    }
    vessels {
      id
      vesselName
    }
    varieties {
      id
      name
    }
    exporters {
      id
      name
    }
    contacts {
      id
      name
      contactType
    }
    loadingPoints {
      id
      name
    }
    offloadingPoints {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiFilterAttributesGQL extends Apollo.Query<LoiFilterAttributesQuery, LoiFilterAttributesQueryVariables> {
    document = LoiFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiReferenceDocument = gql`
    query loiReference {
  loadOutInstructionReference
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiReferenceGQL extends Apollo.Query<LoiReferenceQuery, LoiReferenceQueryVariables> {
    document = LoiReferenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AirFreightShipmentLineItemsWithNoLoiDocument = gql`
    query airFreightShipmentLineItemsWithNoLoi {
  airFreightShipmentLineItemsWithNoLoi {
    id
    orderReference
    bookingReference
    finalDestinationLocation
    externalShipmentId
    eta
    finalDestinationCode
    goodsDescription
    company {
      id
      name
    }
    airFreightShipment {
      masterWaybillNumber
      shipper
      estimatedDepartureTime
      controller
      portOfLoadingCode
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AirFreightShipmentLineItemsWithNoLoiGQL extends Apollo.Query<AirFreightShipmentLineItemsWithNoLoiQuery, AirFreightShipmentLineItemsWithNoLoiQueryVariables> {
    document = AirFreightShipmentLineItemsWithNoLoiDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiExportersDocument = gql`
    query loiExporters($limit: Int, $orderbyloi: Boolean, $companyname: String) {
  exporters(limit: $limit, orderbyloi: $orderbyloi, companyname: $companyname) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiExportersGQL extends Apollo.Query<LoiExportersQuery, LoiExportersQueryVariables> {
    document = LoiExportersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiStuffLocationsDocument = gql`
    query loiStuffLocations($limit: Int, $orderbyloi: Boolean, $fboname: String) {
  stuffLocations(limit: $limit, orderbyloi: $orderbyloi, fboname: $fboname) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiStuffLocationsGQL extends Apollo.Query<LoiStuffLocationsQuery, LoiStuffLocationsQueryVariables> {
    document = LoiStuffLocationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiTruckingLoadingPointsDocument = gql`
    query loiTruckingLoadingPoints($limit: Int, $orderByLoi: Boolean, $fboName: String) {
  truckingLoadingPoints(limit: $limit, orderByLoi: $orderByLoi, fboName: $fboName) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiTruckingLoadingPointsGQL extends Apollo.Query<LoiTruckingLoadingPointsQuery, LoiTruckingLoadingPointsQueryVariables> {
    document = LoiTruckingLoadingPointsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiTruckingOffloadingPointsDocument = gql`
    query loiTruckingOffloadingPoints($limit: Int, $orderByLoi: Boolean, $fboName: String) {
  truckingOffloadingPoints(
    limit: $limit
    orderByLoi: $orderByLoi
    fboName: $fboName
  ) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiTruckingOffloadingPointsGQL extends Apollo.Query<LoiTruckingOffloadingPointsQuery, LoiTruckingOffloadingPointsQueryVariables> {
    document = LoiTruckingOffloadingPointsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiSeaPortsDocument = gql`
    query loiSeaPorts($limit: Int, $orderbyloi: Boolean, $seaportname: String, $isportofloading: Boolean, $stufflocationid: Int) {
  seaPorts(
    limit: $limit
    orderbyloi: $orderbyloi
    seaportname: $seaportname
    isportofloading: $isportofloading
    stufflocationid: $stufflocationid
  ) {
    id
    name
    country
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiSeaPortsGQL extends Apollo.Query<LoiSeaPortsQuery, LoiSeaPortsQueryVariables> {
    document = LoiSeaPortsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiVesselsDocument = gql`
    query loiVessels($limit: Int, $vesselname: String, $seaportid: Int) {
  vessels(limit: $limit, vesselname: $vesselname, seaportid: $seaportid) {
    id
    vesselName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiVesselsGQL extends Apollo.Query<LoiVesselsQuery, LoiVesselsQueryVariables> {
    document = LoiVesselsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiVoyagesDocument = gql`
    query loiVoyages($vesselid: Int!, $seaportid: Int) {
  voyages(vesselid: $vesselid, seaportid: $seaportid)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiVoyagesGQL extends Apollo.Query<LoiVoyagesQuery, LoiVoyagesQueryVariables> {
    document = LoiVoyagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiContactsDocument = gql`
    query loiContacts {
  contacts {
    id
    name
    info
    contactType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiContactsGQL extends Apollo.Query<LoiContactsQuery, LoiContactsQueryVariables> {
    document = LoiContactsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiUnassignedCgFilterAttributesDocument = gql`
    query loiUnassignedCGFilterAttributes($attributes: CartonGroupingSearchAttributes!) {
  cartonGroupingsSearch(attributes: $attributes) {
    filterValues {
      batchNumber
      consignmentNumber
      packingWeek
      targetMarket
      variety {
        id
        name
      }
      seller {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiUnassignedCgFilterAttributesGQL extends Apollo.Query<LoiUnassignedCgFilterAttributesQuery, LoiUnassignedCgFilterAttributesQueryVariables> {
    document = LoiUnassignedCgFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiUnassignedCartonGroupingsDocument = gql`
    query loiUnassignedCartonGroupings($attributes: CartonGroupingSearchAttributes!, $loadingPointId: Int, $portOfLoadingId: Int, $transportType: String, $hideReassignable: Boolean) {
  loadOutInstructionCgs(
    attributes: $attributes
    loadingPointId: $loadingPointId
    portOfLoadingId: $portOfLoadingId
    transportType: $transportType
    hideReassignable: $hideReassignable
  ) {
    id
    batchNumber
    buyerName
    cartons
    consignmentNumber
    commodityName
    exporterCode
    farmCode
    inventoryCode
    mark
    orchard
    grade
    pack
    packingWeek
    palletNumber
    sellerName
    sequenceNumber
    sizeCount
    state
    subCommodityName
    targetMarket
    targetRegion
    varietyName
    palletAge
    existingLoadOut
    isReassignable
    netMass
    palletGrossMass
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiUnassignedCartonGroupingsGQL extends Apollo.Query<LoiUnassignedCartonGroupingsQuery, LoiUnassignedCartonGroupingsQueryVariables> {
    document = LoiUnassignedCartonGroupingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiUnassignedCGsDocument = gql`
    query loiUnassignedCGs($attributes: CartonGroupingSearchAttributes!) {
  cartonGroupingsSearch(attributes: $attributes) {
    cartonGroupings {
      batchNumber
      buyer {
        name
      }
      cartons
      consignmentNumber
      exporterCode
      farmCode
      grade
      id
      inventoryCode
      mark
      netMass
      orchard
      pack
      packhouseCode
      packingWeek
      palletGrossMass
      palletNumber
      seller {
        name
      }
      sequenceNumber
      sizeCount
      state
      targetCountry
      targetMarket
      targetRegion
      variety {
        commodity {
          name
        }
        name
        subCommodity {
          name
        }
      }
      loadOutInstructions {
        loadOutReference
        transportType
        loadingPoint {
          id
          name
          createdAt
          updatedAt
        }
        portOfLoading {
          ...loiPortFields
        }
      }
      palletAge
    }
    filterValues {
      batchNumber
      consignmentNumber
      packingWeek
      targetMarket
      variety {
        id
        name
      }
      seller {
        id
        name
      }
    }
    totalCount
    totalCountPallets
  }
}
    ${LoiPortFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiUnassignedCGsGQL extends Apollo.Query<LoiUnassignedCGsQuery, LoiUnassignedCGsQueryVariables> {
    document = LoiUnassignedCGsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoiDocument = gql`
    query Loi($id: Int!) {
  loadOutInstruction(id: $id) {
    ...LoiCommonFields
    airFreightShipmentLineItem {
      id
      orderReference
      bookingReference
      externalShipmentId
      updatedAt
      createdAt
    }
    cartonGroupings {
      ...cgCommonFields
    }
    loiCartonGroupings {
      id
      batchNumber
      buyerName
      cartons
      consignmentNumber
      commodityName
      exporterCode
      farmCode
      inventoryCode
      mark
      orchard
      grade
      pack
      packingWeek
      palletNumber
      sellerName
      sequenceNumber
      sizeCount
      state
      subCommodityName
      targetMarket
      targetRegion
      varietyName
      palletAge
      existingLoadOut
      isReassignable
      netMass
      palletGrossMass
    }
  }
}
    ${LoiCommonFieldsFragmentDoc}
${CgCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoiGQL extends Apollo.Query<LoiQuery, LoiQueryVariables> {
    document = LoiDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateLoiDocument = gql`
    mutation CreateLoi($attributes: LoadOutAttributes!) {
  createLoadOutInstruction(attributes: $attributes) {
    errors
    loadOutInstruction {
      cartonGroupings {
        id
      }
      ...LoiCommonFields
    }
  }
}
    ${LoiCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateLoiGQL extends Apollo.Mutation<CreateLoiMutation, CreateLoiMutationVariables> {
    document = CreateLoiDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLoiDocument = gql`
    mutation UpdateLoi($attributes: LoadOutAttributes!) {
  updateLoadOutInstruction(attributes: $attributes) {
    errors
    loadOutInstruction {
      cartonGroupings {
        id
      }
      ...LoiCommonFields
    }
  }
}
    ${LoiCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLoiGQL extends Apollo.Mutation<UpdateLoiMutation, UpdateLoiMutationVariables> {
    document = UpdateLoiDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteLoiDocument = gql`
    mutation DeleteLoi($id: Int!) {
  deleteLoadOutInstruction(id: $id) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteLoiGQL extends Apollo.Mutation<DeleteLoiMutation, DeleteLoiMutationVariables> {
    document = DeleteLoiDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateLoadOutInstructionOutputFilesDocument = gql`
    mutation GenerateLoadOutInstructionOutputFiles($loadoutinstructionid: ID!) {
  generateLoadOutInstructionOutputFiles(
    loadoutinstructionid: $loadoutinstructionid
  ) {
    templateOutput {
      errorReason
      id
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateLoadOutInstructionOutputFilesGQL extends Apollo.Mutation<GenerateLoadOutInstructionOutputFilesMutation, GenerateLoadOutInstructionOutputFilesMutationVariables> {
    document = GenerateLoadOutInstructionOutputFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeneratePalletInOutputFilesDocument = gql`
    mutation GeneratePalletInOutputFiles($loadOutInstructionId: ID!) {
  generatePalletInOutputFiles(loadOutInstructionId: $loadOutInstructionId) {
    palletInId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GeneratePalletInOutputFilesGQL extends Apollo.Mutation<GeneratePalletInOutputFilesMutation, GeneratePalletInOutputFilesMutationVariables> {
    document = GeneratePalletInOutputFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnPalletInOutputStateChangeDocument = gql`
    subscription onPalletInOutputStateChange {
  onPalletInOutputStateChange {
    ...palletInFields
  }
}
    ${PalletInFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnPalletInOutputStateChangeGQL extends Apollo.Subscription<OnPalletInOutputStateChangeSubscription, OnPalletInOutputStateChangeSubscriptionVariables> {
    document = OnPalletInOutputStateChangeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentMapPointsForOrderDocument = gql`
    query CurrentMapPointsForOrder($id: Int!) {
  currentMapPointsForOrder(id: $id) {
    totalCartons
    containers {
      id
      containerNumber
      orderId
      otherPartyName
      vesselName
      portOfDestination
      eta
    }
    orders {
      orderId
      otherPartyName
      totalCartons
    }
    location {
      ...vesselMap
      ...seaPortMap
      ...fboMap
      __typename
    }
  }
}
    ${VesselMapFragmentDoc}
${SeaPortMapFragmentDoc}
${FboMapFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentMapPointsForOrderGQL extends Apollo.Query<CurrentMapPointsForOrderQuery, CurrentMapPointsForOrderQueryVariables> {
    document = CurrentMapPointsForOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FoodBusinessOperatorsDocument = gql`
    query foodBusinessOperators {
  foodBusinessOperators {
    id
    name
    fboCode
    typeOfBusiness
    producerName
    latitude
    longitude
    globalGapNumber
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FoodBusinessOperatorsGQL extends Apollo.Query<FoodBusinessOperatorsQuery, FoodBusinessOperatorsQueryVariables> {
    document = FoodBusinessOperatorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FarmVarietiesDocument = gql`
    query farmVarieties {
  farmVarieties {
    id
    name
    varietyCodes
    commodityName
    hectares
    peakCount
    packingStart
    packingEnd
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FarmVarietiesGQL extends Apollo.Query<FarmVarietiesQuery, FarmVarietiesQueryVariables> {
    document = FarmVarietiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListVarietiesDocument = gql`
    query listVarieties {
  listVarieties {
    id
    varietyName
    name
    commodity {
      id
      name
    }
    subCommodity {
      id
      name
    }
    varietyCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListVarietiesGQL extends Apollo.Query<ListVarietiesQuery, ListVarietiesQueryVariables> {
    document = ListVarietiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CgErrorsDocument = gql`
    query cgErrors {
  cgErrors {
    id
    errorType
    errorMessage
    state
    errorContext
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CgErrorsGQL extends Apollo.Query<CgErrorsQuery, CgErrorsQueryVariables> {
    document = CgErrorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FarmVarietyClientChangeRequestsDocument = gql`
    query farmVarietyClientChangeRequests {
  farmVarietyClientChangeRequests {
    name
    status
    varietyCodes
    commodityName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FarmVarietyClientChangeRequestsGQL extends Apollo.Query<FarmVarietyClientChangeRequestsQuery, FarmVarietyClientChangeRequestsQueryVariables> {
    document = FarmVarietyClientChangeRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFarmVarietyClienChangeRequestDocument = gql`
    mutation CreateFarmVarietyClienChangeRequest($modelId: Int!, $name: String!, $varietyId: Int!, $varietyCodes: String!, $commodityName: String!, $commodityId: Int!, $action: String!) {
  createFarmVarietyClientChangeRequest(
    modelId: $modelId
    name: $name
    varietyId: $varietyId
    varietyCodes: $varietyCodes
    commodityName: $commodityName
    commodityId: $commodityId
    action: $action
  ) {
    clientChangeRequest {
      status
      name
      varietyCodes
      commodityName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFarmVarietyClienChangeRequestGQL extends Apollo.Mutation<CreateFarmVarietyClienChangeRequestMutation, CreateFarmVarietyClienChangeRequestMutationVariables> {
    document = CreateFarmVarietyClienChangeRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResolveCgErrorDocument = gql`
    mutation ResolveCgError($id: Int!) {
  resolveCgError(id: $id) {
    cgError {
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResolveCgErrorGQL extends Apollo.Mutation<ResolveCgErrorMutation, ResolveCgErrorMutationVariables> {
    document = ResolveCgErrorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MetabaseReportFilterAttributesDocument = gql`
    query MetabaseReportFilterAttributes {
  metabaseReportFilterAttributes {
    badges {
      id
      text
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MetabaseReportFilterAttributesGQL extends Apollo.Query<MetabaseReportFilterAttributesQuery, MetabaseReportFilterAttributesQueryVariables> {
    document = MetabaseReportFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MetabaseReportsDocument = gql`
    query MetabaseReports($filters: MetabaseReportFilters!, $searchTerm: String!, $searchField: String!) {
  metabaseReports(
    filters: $filters
    searchTerm: $searchTerm
    searchField: $searchField
  ) {
    ...MetabaseReportFields
  }
}
    ${MetabaseReportFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MetabaseReportsGQL extends Apollo.Query<MetabaseReportsQuery, MetabaseReportsQueryVariables> {
    document = MetabaseReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MetabaseReportDocument = gql`
    query MetabaseReport($id: Int!) {
  metabaseReport(id: $id) {
    ...MetabaseReportFields
  }
}
    ${MetabaseReportFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MetabaseReportGQL extends Apollo.Query<MetabaseReportQuery, MetabaseReportQueryVariables> {
    document = MetabaseReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrdersV3Document = gql`
    query OrdersV3($filterAttributes: OrderFilterAttributes!, $searchAttributes: SearchAttributes!, $orderByFields: [ColumnOrderAttributes!], $first: Int, $last: Int, $after: String, $before: String) {
  ordersV3(
    filterAttributes: $filterAttributes
    searchAttributes: $searchAttributes
    orderByFields: $orderByFields
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      id
      seller {
        id
        name
      }
      state
      updatedAt
      buyer {
        id
        name
      }
      createdAt
      lineItemGroupings {
        commonFields
        cumulativeQuantity
        cumulativeTooltip
        deleted
        id
        lineItems {
          ...singleLineItemFields
          ...statesDistributionFields
        }
        numberCumulativeSiblings
        quantityType
        rank
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${SingleLineItemFieldsFragmentDoc}
${StatesDistributionFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrdersV3GQL extends Apollo.Query<OrdersV3Query, OrdersV3QueryVariables> {
    document = OrdersV3Document;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrdersFilterAttributesDocument = gql`
    query OrdersFilterAttributes {
  ordersFilterAttributes {
    buyers {
      id
      name
    }
    commodities {
      id
      name
    }
    sellers {
      id
      name
    }
    varieties {
      id
      name
      varietyCode
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrdersFilterAttributesGQL extends Apollo.Query<OrdersFilterAttributesQuery, OrdersFilterAttributesQueryVariables> {
    document = OrdersFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QuantityTypeDocument = gql`
    query quantityType {
  quantityType
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QuantityTypeGQL extends Apollo.Query<QuantityTypeQuery, QuantityTypeQueryVariables> {
    document = QuantityTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrderDocument = gql`
    mutation CreateOrder($attributes: CreateOrderAttributes!, $lineItemStates: [String!], $packingWeekStart: String, $packingWeekEnd: String, $varietyIds: [Int!], $commodityIds: [Int!], $subCommodityIds: [Int!], $states: [StateFilter!], $currency: CurrencyEnum, $unit: String, $incoterm: IncotermWithNscEnum) {
  createOrder(attributes: $attributes) {
    order {
      ...multipleOrderFields
    }
    errors
  }
}
    ${MultipleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrderGQL extends Apollo.Mutation<CreateOrderMutation, CreateOrderMutationVariables> {
    document = CreateOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrdersSearchDocument = gql`
    query OrdersSearch($limit: Int!, $skip: Int!, $filter: OrderFilter!, $lineItemStates: [String!], $packingWeekStart: String, $packingWeekEnd: String, $varietyIds: [Int!], $commodityIds: [Int!], $subCommodityIds: [Int!], $states: [StateFilter!], $currency: CurrencyEnum, $unit: String, $incoterm: IncotermWithNscEnum) {
  ordersSearch(filter: $filter) {
    orders(limit: $limit, skip: $skip) {
      ...multipleOrderFields
    }
    totalOrders
    totalFiltered
  }
}
    ${MultipleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrdersSearchGQL extends Apollo.Query<OrdersSearchQuery, OrdersSearchQueryVariables> {
    document = OrdersSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrdersSearchFilterAttributesDocument = gql`
    query ordersSearchFilterAttributes {
  ordersSearchFilterAttributes {
    commodities {
      id
      name
    }
    varieties {
      id
      name
    }
    subCommodities {
      id
      name
    }
    companies {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrdersSearchFilterAttributesGQL extends Apollo.Query<OrdersSearchFilterAttributesQuery, OrdersSearchFilterAttributesQueryVariables> {
    document = OrdersSearchFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderDocument = gql`
    query Order($id: ID!, $currency: CurrencyEnum, $unit: String, $incoterm: IncotermWithNscEnum) {
  order(id: $id) {
    ...singleOrderFieldsWithNetReturn
  }
}
    ${SingleOrderFieldsWithNetReturnFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderGQL extends Apollo.Query<OrderQuery, OrderQueryVariables> {
    document = OrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DuplicateOrderDocument = gql`
    query DuplicateOrder($id: ID!) {
  order(id: $id) {
    ...duplicateOrderFields
  }
}
    ${DuplicateOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicateOrderGQL extends Apollo.Query<DuplicateOrderQuery, DuplicateOrderQueryVariables> {
    document = DuplicateOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($attributes: UpdateOrderAttributes!) {
  updateOrder(attributes: $attributes) {
    order {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrderGQL extends Apollo.Mutation<UpdateOrderMutation, UpdateOrderMutationVariables> {
    document = UpdateOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLineItemsDocument = gql`
    mutation UpdateLineItems($attributes: [UpdateLineItemAttributes!]!) {
  updateLineItems(attributes: $attributes) {
    lineItems {
      ...singleLineItemFields
    }
    errors
  }
}
    ${SingleLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLineItemsGQL extends Apollo.Mutation<UpdateLineItemsMutation, UpdateLineItemsMutationVariables> {
    document = UpdateLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptLineItemsDocument = gql`
    mutation AcceptLineItems($ids: [Int!]!) {
  acceptLineItems(ids: $ids) {
    orders {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptLineItemsGQL extends Apollo.Mutation<AcceptLineItemsMutation, AcceptLineItemsMutationVariables> {
    document = AcceptLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WontPackLineItemsDocument = gql`
    mutation WontPackLineItems($ids: [Int!]!) {
  wontPackLineItems(ids: $ids) {
    orders {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WontPackLineItemsGQL extends Apollo.Mutation<WontPackLineItemsMutation, WontPackLineItemsMutationVariables> {
    document = WontPackLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RevertLineItemsToCreatedDocument = gql`
    mutation RevertLineItemsToCreated($ids: [Int!]!) {
  revertLineItemsToCreated(ids: $ids) {
    orders {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RevertLineItemsToCreatedGQL extends Apollo.Mutation<RevertLineItemsToCreatedMutation, RevertLineItemsToCreatedMutationVariables> {
    document = RevertLineItemsToCreatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RevertOrderChangeDocument = gql`
    mutation RevertOrderChange($startid: Int!, $endid: Int!, $orderid: Int!) {
  revertOrderChange(startId: $startid, endId: $endid, orderId: $orderid) {
    order {
      ...singleOrderFields
    }
    errors
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RevertOrderChangeGQL extends Apollo.Mutation<RevertOrderChangeMutation, RevertOrderChangeMutationVariables> {
    document = RevertOrderChangeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadOrderShipmentDocumentDocument = gql`
    mutation UploadOrderShipmentDocument($orderid: Int!, $type: String!, $file: Upload!) {
  uploadOrderShipmentDocument(orderid: $orderid, type: $type, file: $file) {
    order {
      shipmentDocuments {
        ...shipmentDocumentFields
      }
    }
  }
}
    ${ShipmentDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadOrderShipmentDocumentGQL extends Apollo.Mutation<UploadOrderShipmentDocumentMutation, UploadOrderShipmentDocumentMutationVariables> {
    document = UploadOrderShipmentDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewOrderDocument = gql`
    subscription NewOrder {
  newOrder {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewOrderGQL extends Apollo.Subscription<NewOrderSubscription, NewOrderSubscriptionVariables> {
    document = NewOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnAcceptLineItemsDocument = gql`
    subscription OnAcceptLineItems {
  onAcceptLineItems {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnAcceptLineItemsGQL extends Apollo.Subscription<OnAcceptLineItemsSubscription, OnAcceptLineItemsSubscriptionVariables> {
    document = OnAcceptLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnWontPackLineItemsDocument = gql`
    subscription OnWontPackLineItems {
  onWontPackLineItems {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnWontPackLineItemsGQL extends Apollo.Subscription<OnWontPackLineItemsSubscription, OnWontPackLineItemsSubscriptionVariables> {
    document = OnWontPackLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnRevertLineItemsToCreatedDocument = gql`
    subscription OnRevertLineItemsToCreated {
  onRevertLineItemsToCreated {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnRevertLineItemsToCreatedGQL extends Apollo.Subscription<OnRevertLineItemsToCreatedSubscription, OnRevertLineItemsToCreatedSubscriptionVariables> {
    document = OnRevertLineItemsToCreatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PackInstructionsLineItemsDocument = gql`
    query PackInstructionsLineItems($attributes: PackInstructionsAttributes!) {
  packInstructions(attributes: $attributes) {
    groupId
    priority
    batchNumber
    buyer
    comments {
      commentText
      id
      userId
      commentableType
      user {
        avatarFile {
          id
          filename
          url
        }
        firstName
        lastName
        id
      }
      updatedAt
      isDeleted
      createdAt
      commentableId
    }
    commodity
    grade
    id
    inventoryCode
    mark
    orderId
    pack
    packingWeek
    palletStack
    quantitesPacked
    quantityRequested
    quantityUnit
    referenceNumber
    seller
    sizeCounts
    state
    subCommodity
    targetMarket
    targetRegion
    targetCountry
    variety
    varietyCode
    additionalFields
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackInstructionsLineItemsGQL extends Apollo.Query<PackInstructionsLineItemsQuery, PackInstructionsLineItemsQueryVariables> {
    document = PackInstructionsLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PackInstructionsFilterAttributesDocument = gql`
    query PackInstructionsFilterAttributes {
  packInstructionsFilterAttributes {
    buyers {
      id
      name
    }
    commodities {
      id
      name
    }
    sellers {
      id
      name
    }
    subCommodities {
      id
      name
    }
    varieties {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PackInstructionsFilterAttributesGQL extends Apollo.Query<PackInstructionsFilterAttributesQuery, PackInstructionsFilterAttributesQueryVariables> {
    document = PackInstructionsFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RunReportDocument = gql`
    query runReport($reportId: Int!, $gridRequest: GridRequest, $reportFilters: [Filter!], $reportTab: Tab, $outputType: RunReportOutputTypeEnum) {
  runReport(
    id: $reportId
    gridRequest: $gridRequest
    reportFilters: $reportFilters
    reportTab: $reportTab
    outputType: $outputType
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RunReportGQL extends Apollo.Query<RunReportQuery, RunReportQueryVariables> {
    document = RunReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RunReportAsyncDocument = gql`
    query runReportAsync($reportId: Int!, $gridRequest: GridRequest, $reportFilters: [Filter!], $requestUuid: String!) {
  runReportAsync(
    id: $reportId
    gridRequest: $gridRequest
    reportFilters: $reportFilters
    requestUuid: $requestUuid
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RunReportAsyncGQL extends Apollo.Query<RunReportAsyncQuery, RunReportAsyncQueryVariables> {
    document = RunReportAsyncDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportDocument = gql`
    query report($reportId: Int!) {
  report(id: $reportId) {
    createdAt
    dataPreparationId
    description
    id
    name
    configs {
      name
      value
    }
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportGQL extends Apollo.Query<ReportQuery, ReportQueryVariables> {
    document = ReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportGroupsDocument = gql`
    query reportGroups($filters: ReportGroupFilters!, $searchTerm: String!, $searchField: String!) {
  reportGroups(
    filters: $filters
    searchTerm: $searchTerm
    searchField: $searchField
  ) {
    name
    description
    id
    domain
    scope
    badges {
      id
      text
      color
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportGroupsGQL extends Apollo.Query<ReportGroupsQuery, ReportGroupsQueryVariables> {
    document = ReportGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReportGroupDocument = gql`
    query reportGroup($reportGroupId: Int!) {
  reportGroup(id: $reportGroupId) {
    id
    layout
    name
    description
    configs {
      name
      value
    }
    reports {
      id
      reportType
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReportGroupGQL extends Apollo.Query<ReportGroupQuery, ReportGroupQueryVariables> {
    document = ReportGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchReportResultDocument = gql`
    query fetchReportResult($requestUuid: String!) {
  fetchReportResult(requestUuid: $requestUuid)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchReportResultGQL extends Apollo.Query<FetchReportResultQuery, FetchReportResultQueryVariables> {
    document = FetchReportResultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateReportGroupDocument = gql`
    mutation createReportGroup($attributes: ReportGroupAttributes!) {
  createReportGroup(attributes: $attributes) {
    errors
    reportGroup {
      description
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateReportGroupGQL extends Apollo.Mutation<CreateReportGroupMutation, CreateReportGroupMutationVariables> {
    document = CreateReportGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReportGroupDocument = gql`
    mutation updateReportGroup($attributes: ReportGroupAttributes!) {
  updateReportGroup(attributes: $attributes) {
    errors
    reportGroup {
      description
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReportGroupGQL extends Apollo.Mutation<UpdateReportGroupMutation, UpdateReportGroupMutationVariables> {
    document = UpdateReportGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReportGroupDocument = gql`
    mutation deleteReportGroup($id: Int!) {
  deleteReportGroup(id: $id) {
    success
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReportGroupGQL extends Apollo.Mutation<DeleteReportGroupMutation, DeleteReportGroupMutationVariables> {
    document = DeleteReportGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnReportCompleteDocument = gql`
    subscription onReportComplete {
  onReportComplete {
    requestUuid
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OnReportCompleteGQL extends Apollo.Subscription<OnReportCompleteSubscription, OnReportCompleteSubscriptionVariables> {
    document = OnReportCompleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SettingsDocument = gql`
    query Settings {
  myUser {
    id
    firstName
    lastName
    phoneNumber
    email
    state
    notifyEmail
    notifyBrowserPush
    createdAt
    updatedAt
    useV2
    avatar
    avatarFile {
      id
      filename
      url
    }
    company {
      id
      name
    }
    role
    roles {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SettingsGQL extends Apollo.Query<SettingsQuery, SettingsQueryVariables> {
    document = SettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $firstname: String!, $lastname: String!, $phonenumber: String!, $notifyemail: Boolean!, $notifybrowserpush: Boolean!, $usev2: Boolean!) {
  updateUser(
    id: $id
    firstName: $firstname
    lastName: $lastname
    phoneNumber: $phonenumber
    notifyEmail: $notifyemail
    notifyBrowserPush: $notifybrowserpush
    useV2: $usev2
  ) {
    user {
      id
      firstName
      lastName
      phoneNumber
      state
      notifyEmail
      notifyBrowserPush
      useV2
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadUserAvatarDocument = gql`
    mutation UploadUserAvatar($file: Upload!) {
  uploadUserAvatar(file: $file) {
    user {
      avatarFile {
        id
        filename
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadUserAvatarGQL extends Apollo.Mutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables> {
    document = UploadUserAvatarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShipmentDocumentDocument = gql`
    mutation UpdateShipmentDocument($id: Int!, $type: String!) {
  updateShipmentDocument(id: $id, type: $type) {
    shipmentDocument {
      id
      documentType
      state
      user {
        id
        firstName
        lastName
        company {
          id
        }
      }
      file {
        id
        filename
        v2Url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShipmentDocumentGQL extends Apollo.Mutation<UpdateShipmentDocumentMutation, UpdateShipmentDocumentMutationVariables> {
    document = UpdateShipmentDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteShipmentDocumentDocument = gql`
    mutation DeleteShipmentDocument($id: Int!) {
  deleteShipmentDocument(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteShipmentDocumentGQL extends Apollo.Mutation<DeleteShipmentDocumentMutation, DeleteShipmentDocumentMutationVariables> {
    document = DeleteShipmentDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateShipmentDocument = gql`
    mutation CreateShipment($attributes: ShipmentAttributes!) {
  createShipment(attributes: $attributes) {
    errors
    shipment {
      ...shipmentCommonFields
    }
  }
}
    ${ShipmentCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShipmentGQL extends Apollo.Mutation<CreateShipmentMutation, CreateShipmentMutationVariables> {
    document = CreateShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShipmentDocument = gql`
    mutation UpdateShipment($attributes: ShipmentAttributes!) {
  updateShipment(attributes: $attributes) {
    errors
    shipment {
      ...shipmentCommonFields
    }
  }
}
    ${ShipmentCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShipmentGQL extends Apollo.Mutation<UpdateShipmentMutation, UpdateShipmentMutationVariables> {
    document = UpdateShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateShipmentLineItemDocument = gql`
    mutation CreateShipmentLineItem($attributes: ShipmentLineItemAttributes!) {
  createShipmentLineItem(attributes: $attributes) {
    errors
    shipmentLineItem {
      ...sliCommonFields
    }
  }
}
    ${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShipmentLineItemGQL extends Apollo.Mutation<CreateShipmentLineItemMutation, CreateShipmentLineItemMutationVariables> {
    document = CreateShipmentLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DuplicateShipmentLineItemDocument = gql`
    mutation duplicateShipmentLineItem($id: ID!) {
  duplicateShipmentLineItem(id: $id) {
    shipmentLineItems {
      ...sliCommonFields
    }
  }
}
    ${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicateShipmentLineItemGQL extends Apollo.Mutation<DuplicateShipmentLineItemMutation, DuplicateShipmentLineItemMutationVariables> {
    document = DuplicateShipmentLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShipmentLineItemDocument = gql`
    mutation UpdateShipmentLineItem($attributes: ShipmentLineItemAttributes!) {
  updateShipmentLineItem(attributes: $attributes) {
    errors
    shipmentLineItem {
      ...sliCommonFields
    }
  }
}
    ${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShipmentLineItemGQL extends Apollo.Mutation<UpdateShipmentLineItemMutation, UpdateShipmentLineItemMutationVariables> {
    document = UpdateShipmentLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShipmentLineItemTasksDocument = gql`
    mutation UpdateShipmentLineItemTasks($attributes: ShipmentLineItemTaskAttributes!) {
  updateShipmentLineItemTasks(attributes: $attributes) {
    errors
    shipmentLineItems {
      ...indexShipmentLineItemFieldss
    }
  }
}
    ${IndexShipmentLineItemFieldssFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShipmentLineItemTasksGQL extends Apollo.Mutation<UpdateShipmentLineItemTasksMutation, UpdateShipmentLineItemTasksMutationVariables> {
    document = UpdateShipmentLineItemTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateExportNotificationBookingDocument = gql`
    mutation CreateExportNotificationBooking($id: Int!) {
  createExportNotificationBooking(id: $id) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateExportNotificationBookingGQL extends Apollo.Mutation<CreateExportNotificationBookingMutation, CreateExportNotificationBookingMutationVariables> {
    document = CreateExportNotificationBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateExportNotificationLineItemDocument = gql`
    mutation CreateExportNotificationLineItem($id: Int!) {
  createExportNotificationLineItem(id: $id) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateExportNotificationLineItemGQL extends Apollo.Mutation<CreateExportNotificationLineItemMutation, CreateExportNotificationLineItemMutationVariables> {
    document = CreateExportNotificationLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAddendumDocument = gql`
    mutation UpdateAddendum($addendumLineItems: [AddendumLineItemAttributes!]!, $addendumHeader: AddendumHeaderAttributes!) {
  updateAddendum(
    addendumLineItems: $addendumLineItems
    addendumHeader: $addendumHeader
  ) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAddendumGQL extends Apollo.Mutation<UpdateAddendumMutation, UpdateAddendumMutationVariables> {
    document = UpdateAddendumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateAddendumDocument = gql`
    mutation GenerateAddendum($sliIds: [Int!], $bookingReference: String, $shipmentId: ID) {
  generateAddendum(
    sliIds: $sliIds
    bookingReference: $bookingReference
    shipmentId: $shipmentId
  ) {
    errors
    addendumHeader {
      ...addendumHeaderFields
      addendumLineItems {
        ...addendumLineItemFields
      }
    }
  }
}
    ${AddendumHeaderFieldsFragmentDoc}
${AddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateAddendumGQL extends Apollo.Mutation<GenerateAddendumMutation, GenerateAddendumMutationVariables> {
    document = GenerateAddendumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelAddendumDocument = gql`
    mutation CancelAddendum($id: Int!, $deleteAddendum: Boolean!) {
  cancelAddendum(id: $id, deleteAddendum: $deleteAddendum) {
    id
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelAddendumGQL extends Apollo.Mutation<CancelAddendumMutation, CancelAddendumMutationVariables> {
    document = CancelAddendumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmExportNotificationLineItemDocument = gql`
    mutation ConfirmExportNotificationLineItem($id: Int!) {
  confirmExportNotificationLineItem(id: $id) {
    errors
    shipment {
      ...shipmentCommonFields
      portOfLoading {
        id
        portCode
        portName
      }
      shipmentLineItems {
        ...sliCommonFields
      }
    }
  }
}
    ${ShipmentCommonFieldsFragmentDoc}
${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmExportNotificationLineItemGQL extends Apollo.Mutation<ConfirmExportNotificationLineItemMutation, ConfirmExportNotificationLineItemMutationVariables> {
    document = ConfirmExportNotificationLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompleteShipmentDocument = gql`
    mutation CompleteShipment($id: Int!) {
  completeShipment(id: $id) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompleteShipmentGQL extends Apollo.Mutation<CompleteShipmentMutation, CompleteShipmentMutationVariables> {
    document = CompleteShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitAddendumDocument = gql`
    mutation SubmitAddendum($id: Int!) {
  submitAddendum(id: $id) {
    errors
    validation
    addendumHeader {
      ...addendumHeaderFields
      addendumLineItems {
        ...addendumLineItemFields
      }
    }
  }
}
    ${AddendumHeaderFieldsFragmentDoc}
${AddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitAddendumGQL extends Apollo.Mutation<SubmitAddendumMutation, SubmitAddendumMutationVariables> {
    document = SubmitAddendumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QueryAddendumDocument = gql`
    mutation QueryAddendum($id: Int!) {
  queryAddendum(id: $id) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryAddendumGQL extends Apollo.Mutation<QueryAddendumMutation, QueryAddendumMutationVariables> {
    document = QueryAddendumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllocateSliContainerDocument = gql`
    mutation AllocateSliContainer($sliId: Int!, $sliContainerId: Int, $isBreakBulk: Boolean!) {
  allocateSliContainer(
    sliId: $sliId
    sliContainerId: $sliContainerId
    isBreakBulk: $isBreakBulk
  ) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllocateSliContainerGQL extends Apollo.Mutation<AllocateSliContainerMutation, AllocateSliContainerMutationVariables> {
    document = AllocateSliContainerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteShipmentLineItemDocument = gql`
    mutation DeleteShipmentLineItem($id: Int!) {
  deleteShipmentLineItem(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteShipmentLineItemGQL extends Apollo.Mutation<DeleteShipmentLineItemMutation, DeleteShipmentLineItemMutationVariables> {
    document = DeleteShipmentLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAddendumLineItemDocument = gql`
    mutation DeleteAddendumLineItem($id: Int!) {
  deleteAddendumLineItem(id: $id) {
    addendumLineItems {
      ...addendumLineItemFields
    }
    errors
  }
}
    ${AddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAddendumLineItemGQL extends Apollo.Mutation<DeleteAddendumLineItemMutation, DeleteAddendumLineItemMutationVariables> {
    document = DeleteAddendumLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DuplicateAddendumLineItemDocument = gql`
    mutation DuplicateAddendumLineItem($id: Int!) {
  duplicateAddendumLineItem(id: $id) {
    addendumLineItems {
      ...addendumLineItemFields
    }
  }
}
    ${AddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicateAddendumLineItemGQL extends Apollo.Mutation<DuplicateAddendumLineItemMutation, DuplicateAddendumLineItemMutationVariables> {
    document = DuplicateAddendumLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteExportDocumentDocument = gql`
    mutation DeleteExportDocument($id: Int!) {
  deleteExportDocument(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteExportDocumentGQL extends Apollo.Mutation<DeleteExportDocumentMutation, DeleteExportDocumentMutationVariables> {
    document = DeleteExportDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadExportDocumentDocument = gql`
    mutation UploadExportDocument($attributes: ShipmentLineItemAttributes!, $file: Upload!) {
  uploadExportDocument(attributes: $attributes, file: $file) {
    errors
    shipmentLineItem {
      exportDocuments {
        ...exportDocumentFields
      }
    }
  }
}
    ${ExportDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadExportDocumentGQL extends Apollo.Mutation<UploadExportDocumentMutation, UploadExportDocumentMutationVariables> {
    document = UploadExportDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateExportDocumentDocument = gql`
    mutation UpdateExportDocument($id: Int!, $type: String!) {
  updateExportDocument(id: $id, type: $type) {
    exportDocument {
      id
      documentType
      user {
        id
        firstName
        lastName
        company {
          id
        }
      }
      file {
        id
        filename
        v2Url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateExportDocumentGQL extends Apollo.Mutation<UpdateExportDocumentMutation, UpdateExportDocumentMutationVariables> {
    document = UpdateExportDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentLineItemFilterAttributesDocument = gql`
    query ShipmentLineItemFilterAttributes {
  shipmentLineItemFilterAttributes {
    controllers
    shippingLineReferences
    clients
    vessels
    states
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLineItemFilterAttributesGQL extends Apollo.Query<ShipmentLineItemFilterAttributesQuery, ShipmentLineItemFilterAttributesQueryVariables> {
    document = ShipmentLineItemFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbVoyageNumbersDocument = gql`
    query shipmentPpecbVoyageNumbers {
  ppecbVoyageNumbers {
    id
    voyageNumber
    vesselName
    ppecbVoyageNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbVoyageNumbersGQL extends Apollo.Query<ShipmentPpecbVoyageNumbersQuery, ShipmentPpecbVoyageNumbersQueryVariables> {
    document = ShipmentPpecbVoyageNumbersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbCountriesDocument = gql`
    query shipmentPpecbCountries {
  ppecbCountries {
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbCountriesGQL extends Apollo.Query<ShipmentPpecbCountriesQuery, ShipmentPpecbCountriesQueryVariables> {
    document = ShipmentPpecbCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbActivityPointsDocument = gql`
    query shipmentPpecbActivityPoints {
  ppecbActivityPoints {
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbActivityPointsGQL extends Apollo.Query<ShipmentPpecbActivityPointsQuery, ShipmentPpecbActivityPointsQueryVariables> {
    document = ShipmentPpecbActivityPointsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbWorldPortsDocument = gql`
    query shipmentPpecbWorldPorts {
  ppecbWorldPorts {
    id
    portCode
    portName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbWorldPortsGQL extends Apollo.Query<ShipmentPpecbWorldPortsQuery, ShipmentPpecbWorldPortsQueryVariables> {
    document = ShipmentPpecbWorldPortsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbContainerTypesDocument = gql`
    query shipmentPpecbContainerTypes {
  ppecbContainerTypes {
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbContainerTypesGQL extends Apollo.Query<ShipmentPpecbContainerTypesQuery, ShipmentPpecbContainerTypesQueryVariables> {
    document = ShipmentPpecbContainerTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbProductsDocument = gql`
    query shipmentPpecbProducts {
  ppecbProducts {
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbProductsGQL extends Apollo.Query<ShipmentPpecbProductsQuery, ShipmentPpecbProductsQueryVariables> {
    document = ShipmentPpecbProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbVentsDocument = gql`
    query shipmentPpecbVents {
  ppecbVents {
    code
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbVentsGQL extends Apollo.Query<ShipmentPpecbVentsQuery, ShipmentPpecbVentsQueryVariables> {
    document = ShipmentPpecbVentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbRegimeCodesDocument = gql`
    query shipmentPpecbRegimeCodes {
  ppecbRegimeCodes {
    regimeCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbRegimeCodesGQL extends Apollo.Query<ShipmentPpecbRegimeCodesQuery, ShipmentPpecbRegimeCodesQueryVariables> {
    document = ShipmentPpecbRegimeCodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentPpecbPortsDocument = gql`
    query shipmentPpecbPorts {
  ppecbPorts {
    id
    portCode
    portName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentPpecbPortsGQL extends Apollo.Query<ShipmentPpecbPortsQuery, ShipmentPpecbPortsQueryVariables> {
    document = ShipmentPpecbPortsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentTerminalsDocument = gql`
    query shipmentTerminals {
  terminals {
    id
    name
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentTerminalsGQL extends Apollo.Query<ShipmentTerminalsQuery, ShipmentTerminalsQueryVariables> {
    document = ShipmentTerminalsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SliContainersDocument = gql`
    query sliContainers {
  sliContainers {
    id
    container {
      id
      containerNumber
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SliContainersGQL extends Apollo.Query<SliContainersQuery, SliContainersQueryVariables> {
    document = SliContainersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SliBreakBulksDocument = gql`
    query sliBreakBulks {
  sliBreakBulks {
    id
    breakBulkContainer {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SliBreakBulksGQL extends Apollo.Query<SliBreakBulksQuery, SliBreakBulksQueryVariables> {
    document = SliBreakBulksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SeaShipmentControllersDocument = gql`
    query SeaShipmentControllers {
  seaShipmentControllers {
    id
    firstName
    lastName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SeaShipmentControllersGQL extends Apollo.Query<SeaShipmentControllersQuery, SeaShipmentControllersQueryVariables> {
    document = SeaShipmentControllersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllocateShipmentControllerDocument = gql`
    mutation AllocateShipmentController($shipmentId: Int!, $userId: Int) {
  allocateShipmentController(shipmentId: $shipmentId, userId: $userId) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllocateShipmentControllerGQL extends Apollo.Mutation<AllocateShipmentControllerMutation, AllocateShipmentControllerMutationVariables> {
    document = AllocateShipmentControllerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentContainerDepotsDocument = gql`
    query shipmentContainerDepots($typeOfBusiness: Int!) {
  foodBusinessOperatorsByType(typeOfBusiness: $typeOfBusiness) {
    id
    name
    producerName
    fboCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentContainerDepotsGQL extends Apollo.Query<ShipmentContainerDepotsQuery, ShipmentContainerDepotsQueryVariables> {
    document = ShipmentContainerDepotsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddendumHeaderByIdDocument = gql`
    query addendumHeaderById($id: Int!) {
  addendumHeaderById(id: $id) {
    ...addendumHeaderFields
    addendumLineItems {
      ...addendumLineItemFields
    }
  }
}
    ${AddendumHeaderFieldsFragmentDoc}
${AddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddendumHeaderByIdGQL extends Apollo.Query<AddendumHeaderByIdQuery, AddendumHeaderByIdQueryVariables> {
    document = AddendumHeaderByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddendumLineItemsByHeaderIdDocument = gql`
    query addendumLineItemsByHeaderId($addendumHeaderId: Int!) {
  addendumLineItemsByHeaderId(addendumHeaderId: $addendumHeaderId) {
    ...addendumLineItemFields
  }
}
    ${AddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddendumLineItemsByHeaderIdGQL extends Apollo.Query<AddendumLineItemsByHeaderIdQuery, AddendumLineItemsByHeaderIdQueryVariables> {
    document = AddendumLineItemsByHeaderIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddendumsOnShipmentDocument = gql`
    query addendumsOnShipment($shipmentId: Int!) {
  addendumsOnShipment(shipmentId: $shipmentId) {
    id
    shippingBookingReference
    verificationStatus
    state
    addendumStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddendumsOnShipmentGQL extends Apollo.Query<AddendumsOnShipmentQuery, AddendumsOnShipmentQueryVariables> {
    document = AddendumsOnShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NonAddendumShipmentLineItemsDocument = gql`
    query nonAddendumShipmentLineItems($shipmentId: Int!, $bookingRef: String) {
  nonAddendumShipmentLineItems(shipmentId: $shipmentId, bookingRef: $bookingRef) {
    id
    containerNumber
    customerReference
    shippingLineReference
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NonAddendumShipmentLineItemsGQL extends Apollo.Query<NonAddendumShipmentLineItemsQuery, NonAddendumShipmentLineItemsQueryVariables> {
    document = NonAddendumShipmentLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SliContainersByBookingReferenceDocument = gql`
    query sliContainersByBookingReference($bookingReference: String!) {
  sliContainersByBookingReference(bookingReference: $bookingReference) {
    id
    container {
      containerNumber
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SliContainersByBookingReferenceGQL extends Apollo.Query<SliContainersByBookingReferenceQuery, SliContainersByBookingReferenceQueryVariables> {
    document = SliContainersByBookingReferenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentLineItemsDocument = gql`
    query shipmentLineItems($filterAttributes: ShipmentLineItemFilterAttributes!, $searchAttributes: SearchAttributes!, $orderByFields: [ColumnOrderAttributes!], $first: Int, $last: Int, $after: String, $before: String) {
  shipmentLineItems(
    filterAttributes: $filterAttributes
    searchAttributes: $searchAttributes
    orderByFields: $orderByFields
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      ...indexShipmentLineItemFieldss
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${IndexShipmentLineItemFieldssFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLineItemsGQL extends Apollo.Query<ShipmentLineItemsQuery, ShipmentLineItemsQueryVariables> {
    document = ShipmentLineItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentLineItemsByShipmentDocument = gql`
    query shipmentLineItemsByShipment($id: Int!) {
  shipmentLineItemsByShipment(id: $id) {
    ...sliCommonFields
  }
}
    ${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLineItemsByShipmentGQL extends Apollo.Query<ShipmentLineItemsByShipmentQuery, ShipmentLineItemsByShipmentQueryVariables> {
    document = ShipmentLineItemsByShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentLineItemGroupingsByShipmentDocument = gql`
    query ShipmentLineItemGroupingsByShipment($id: ID!) {
  shipmentLineItemGroupingsByShipment(id: $id) {
    companyId
    id
    shipmentId
    sligFields
    totalCartons
    totalGrossWeight
    totalNettWeight
    updatedAt
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLineItemGroupingsByShipmentGQL extends Apollo.Query<ShipmentLineItemGroupingsByShipmentQuery, ShipmentLineItemGroupingsByShipmentQueryVariables> {
    document = ShipmentLineItemGroupingsByShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentLineItemsBySligDocument = gql`
    query ShipmentLineItemsBySlig($id: ID!) {
  shipmentLineItemsBySlig(id: $id) {
    ...sliCommonFields
  }
}
    ${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLineItemsBySligGQL extends Apollo.Query<ShipmentLineItemsBySligQuery, ShipmentLineItemsBySligQueryVariables> {
    document = ShipmentLineItemsBySligDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReassignShipmentLineItemGroupingDocument = gql`
    mutation ReassignShipmentLineItemGrouping($id: ID!, $sligId: ID!) {
  reassignShipmentLineItemGrouping(id: $id, sligId: $sligId) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReassignShipmentLineItemGroupingGQL extends Apollo.Mutation<ReassignShipmentLineItemGroupingMutation, ReassignShipmentLineItemGroupingMutationVariables> {
    document = ReassignShipmentLineItemGroupingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentBookingReferencesDocument = gql`
    query shipmentBookingReferences($id: Int!) {
  shipmentBookingReferences(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentBookingReferencesGQL extends Apollo.Query<ShipmentBookingReferencesQuery, ShipmentBookingReferencesQueryVariables> {
    document = ShipmentBookingReferencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PpecbUserByUserIdDocument = gql`
    query ppecbUserByUserId($id: Int!) {
  ppecbUserByUserId(id: $id) {
    agentFboCode
    ppecbRegisteredAgentCode
    ppecbExporters {
      exporterFboCode
      ppecbRegisteredExporterCode
      company {
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PpecbUserByUserIdGQL extends Apollo.Query<PpecbUserByUserIdQuery, PpecbUserByUserIdQueryVariables> {
    document = PpecbUserByUserIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentDocument = gql`
    query shipment($id: Int!) {
  shipment(id: $id) {
    ...shipmentCommonFields
    portOfLoading {
      id
      portCode
      portName
    }
    terminal {
      id
      name
      code
    }
    shipmentLineItems {
      ...sliCommonFields
    }
    templateOutput {
      state
      ...templateOutputFields
    }
  }
}
    ${ShipmentCommonFieldsFragmentDoc}
${SliCommonFieldsFragmentDoc}
${TemplateOutputFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentGQL extends Apollo.Query<ShipmentQuery, ShipmentQueryVariables> {
    document = ShipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentLineItemDocument = gql`
    query shipmentLineItem($id: Int!) {
  shipmentLineItemById(id: $id) {
    ...sliCommonFields
    shipmentId
    shipment {
      id
      ppecbBookingReference
    }
  }
}
    ${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLineItemGQL extends Apollo.Query<ShipmentLineItemQuery, ShipmentLineItemQueryVariables> {
    document = ShipmentLineItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentSeaPortsDocument = gql`
    query shipmentSeaPorts {
  seaPorts {
    id
    name
    country
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentSeaPortsGQL extends Apollo.Query<ShipmentSeaPortsQuery, ShipmentSeaPortsQueryVariables> {
    document = ShipmentSeaPortsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnAddendumHeaderStateChangeDocument = gql`
    subscription onAddendumHeaderStateChange {
  onAddendumHeaderStateChange {
    ...addendumHeaderFields
    addendumLineItems {
      ...addendumLineItemFields
    }
  }
}
    ${AddendumHeaderFieldsFragmentDoc}
${AddendumLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnAddendumHeaderStateChangeGQL extends Apollo.Subscription<OnAddendumHeaderStateChangeSubscription, OnAddendumHeaderStateChangeSubscriptionVariables> {
    document = OnAddendumHeaderStateChangeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnShipmentStateChangeDocument = gql`
    subscription onShipmentStateChange {
  onShipmentStateChange {
    ...shipmentCommonFields
    shipmentLineItems {
      ...sliCommonFields
    }
  }
}
    ${ShipmentCommonFieldsFragmentDoc}
${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnShipmentStateChangeGQL extends Apollo.Subscription<OnShipmentStateChangeSubscription, OnShipmentStateChangeSubscriptionVariables> {
    document = OnShipmentStateChangeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitShipmentExportNotificationDocument = gql`
    mutation submitShipmentExportNotification($id: ID!) {
  submitShipmentExportNotification(id: $id) {
    errors
    shipment {
      ...shipmentCommonFields
      shipmentLineItems {
        ...sliCommonFields
      }
    }
  }
}
    ${ShipmentCommonFieldsFragmentDoc}
${SliCommonFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitShipmentExportNotificationGQL extends Apollo.Mutation<SubmitShipmentExportNotificationMutation, SubmitShipmentExportNotificationMutationVariables> {
    document = SubmitShipmentExportNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateShipmentExportNotificationOutputFilesDocument = gql`
    mutation generateShipmentExportNotificationOutputFiles($shipmentId: ID!) {
  generateShipmentExportNotificationOutputFiles(shipmentId: $shipmentId) {
    shipment {
      id
      templateOutput {
        state
        ...templateOutputFields
      }
    }
  }
}
    ${TemplateOutputFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateShipmentExportNotificationOutputFilesGQL extends Apollo.Mutation<GenerateShipmentExportNotificationOutputFilesMutation, GenerateShipmentExportNotificationOutputFilesMutationVariables> {
    document = GenerateShipmentExportNotificationOutputFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CertificateTransportInstructionDocument = gql`
    query certificateTransportInstruction($id: Int!) {
  certificateTransportInstruction(id: $id) {
    id
    templateOutput {
      state
      ...templateOutputFields
    }
  }
}
    ${TemplateOutputFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CertificateTransportInstructionGQL extends Apollo.Query<CertificateTransportInstructionQuery, CertificateTransportInstructionQueryVariables> {
    document = CertificateTransportInstructionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpsertCertificateTransportInstructionDocument = gql`
    mutation upsertCertificateTransportInstruction($shipmentLineItemId: ID!) {
  upsertCertificateTransportInstruction(shipmentLineItemId: $shipmentLineItemId) {
    certificateTransportInstruction {
      id
      templateOutput {
        state
        ...templateOutputFields
      }
    }
  }
}
    ${TemplateOutputFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpsertCertificateTransportInstructionGQL extends Apollo.Mutation<UpsertCertificateTransportInstructionMutation, UpsertCertificateTransportInstructionMutationVariables> {
    document = UpsertCertificateTransportInstructionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockAtLocationDocument = gql`
    query stockAtLocation($filterAttributes: StockFilterInputAttributes, $searchAttributes: SearchAttributes, $orderAttributes: [ColumnOrderAttributes!], $millerAttributes: MillerCollapsibleInLocationInput) {
  stockAtLocation(
    filterAttributes: $filterAttributes
    searchAttributes: $searchAttributes
    orderAttributes: $orderAttributes
    millerAttributes: $millerAttributes
  ) {
    groupKeys
    groupFields
    allLocations {
      locationType
      totalPallets
      name
      id
    }
    millerCollapsibleForLocation(millerColumnAttributes: $millerAttributes) {
      field
      value
      totalPallets
    }
    results {
      currentLocationName
      nextLocationName
      commodityId
      batchNumber
      buyerId
      buyerName
      consignmentNumber
      subCommodityName
      varietyVarietyCode
      cartons
      commodityName
      exporterCode
      farmCode
      grade
      id
      inspectionDate
      consignmentNumber
      inventoryCode
      mark
      netMass
      orchard
      pack
      packhouseCode
      packingWeek
      palletNumber
      palletAge
      palletGrossMass
      productCharacteristic
      sequenceNumber
      state
      status
      sizeCount
      sellerName
      targetMarket
      targetRegion
      targetCountry
      varietyName
      varietyId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StockAtLocationGQL extends Apollo.Query<StockAtLocationQuery, StockAtLocationQueryVariables> {
    document = StockAtLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StockFilterAttributesDocument = gql`
    query stockFilterAttributes {
  stockFilterAttributes {
    batchNumbers
    buyers {
      id
      name
    }
    commodities {
      id
      name
    }
    exporterCodes
    grades
    inventoryCodes
    marks
    packTypes
    sellers {
      id
      name
    }
    sizeCounts
    states
    subCommodities {
      id
      name
    }
    targetMarkets
    varieties {
      id
      name
      varietyCode
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StockFilterAttributesGQL extends Apollo.Query<StockFilterAttributesQuery, StockFilterAttributesQueryVariables> {
    document = StockFilterAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadTaskDocumentDocument = gql`
    mutation UploadTaskDocument($taskId: Int!, $file: Upload!, $uploadDate: ISO8601DateTime, $customDate: ISO8601DateTime) {
  uploadTaskDocument(
    taskId: $taskId
    file: $file
    uploadDate: $uploadDate
    customDate: $customDate
  ) {
    errors
    task {
      id
      customDate
      uploadedAt
      file {
        filename
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadTaskDocumentGQL extends Apollo.Mutation<UploadTaskDocumentMutation, UploadTaskDocumentMutationVariables> {
    document = UploadTaskDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTaskDocumentDocument = gql`
    mutation DeleteTaskDocument($id: Int!) {
  deleteTaskDocument(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTaskDocumentGQL extends Apollo.Mutation<DeleteTaskDocumentMutation, DeleteTaskDocumentMutationVariables> {
    document = DeleteTaskDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTasksDatesDocument = gql`
    mutation UpdateTasksDates($updateValues: Json!) {
  updateTasksDates(updateValues: $updateValues) {
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTasksDatesGQL extends Apollo.Mutation<UpdateTasksDatesMutation, UpdateTasksDatesMutationVariables> {
    document = UpdateTasksDatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnTemplateOutputStateChangeDocument = gql`
    subscription onTemplateOutputStateChange {
  onTemplateOutputStateChange {
    ...templateOutputFields
  }
}
    ${TemplateOutputFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnTemplateOutputStateChangeGQL extends Apollo.Subscription<OnTemplateOutputStateChangeSubscription, OnTemplateOutputStateChangeSubscriptionVariables> {
    document = OnTemplateOutputStateChangeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateOutputFilesDocument = gql`
    query templateOutputFiles($templateOutputFilesId: Int!) {
  templateOutputFiles(id: $templateOutputFilesId) {
    outputFiles {
      contentType
      filename
      id
      url
      v2Url
    }
    errorReason
    id
    state
    templateDataSourceId
    templateDataSourceType
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateOutputFilesGQL extends Apollo.Query<TemplateOutputFilesQuery, TemplateOutputFilesQueryVariables> {
    document = TemplateOutputFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateOutputFilesBySourceDocument = gql`
    query templateOutputFilesBySource($dataSourceId: Int!, $dataSourceType: String!) {
  templateOutputFilesBySource(
    dataSourceId: $dataSourceId
    dataSourceType: $dataSourceType
  ) {
    outputFiles {
      contentType
      filename
      id
      url
      v2Url
    }
    errorReason
    id
    state
    templateDataSourceId
    templateDataSourceType
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateOutputFilesBySourceGQL extends Apollo.Query<TemplateOutputFilesBySourceQuery, TemplateOutputFilesBySourceQueryVariables> {
    document = TemplateOutputFilesBySourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TradePartnerDocument = gql`
    query TradePartner($id: ID!) {
  company(id: $id) {
    id
    name
    about
    isBuyer
    isSeller
    logoFile {
      id
      filename
      url
    }
    registrationNumber
    vatNumber
    createdAt
    address {
      addressLine1
      addressLine2
      city
      postalCode
      country
    }
    varieties {
      id
      varietyId
      name
      packingStart
      packingEnd
      peakCount
      hectares
      images {
        id
        url
        thumbnailUrl
      }
      commodity {
        id
        name
        icon {
          v2Url
        }
      }
    }
    certificates {
      id
      companyId
      expiryDate
      certificate {
        url
        filename
      }
      certificateType {
        name
        id
      }
    }
    tradeRegions {
      name
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradePartnerGQL extends Apollo.Query<TradePartnerQuery, TradePartnerQueryVariables> {
    document = TradePartnerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TradePartnersDocument = gql`
    query TradePartners {
  myCompany {
    id
    isBuyer
    buyers {
      ...companyInformation
    }
    isSeller
    sellers {
      ...companyInformation
    }
  }
}
    ${CompanyInformationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TradePartnersGQL extends Apollo.Query<TradePartnersQuery, TradePartnersQueryVariables> {
    document = TradePartnersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllTradeRegionsDocument = gql`
    query AllTradeRegions {
  tradeRegions {
    id
    name
    code
    countries {
      id
      code
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllTradeRegionsGQL extends Apollo.Query<AllTradeRegionsQuery, AllTradeRegionsQueryVariables> {
    document = AllTradeRegionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UiConfigsDocument = gql`
    query UiConfigs($visibility: UiConfigVisibilityEnum, $configType: String) {
  uiConfigs(visibility: $visibility, configType: $configType) {
    id
    configType
    visibility
    data
    readonly
  }
  myUser {
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UiConfigsGQL extends Apollo.Query<UiConfigsQuery, UiConfigsQueryVariables> {
    document = UiConfigsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UiConfigDocument = gql`
    query UiConfig($id: ID!) {
  uiConfig(id: $id) {
    id
    configType
    visibility
    data
    readonly
  }
  myUser {
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UiConfigGQL extends Apollo.Query<UiConfigQuery, UiConfigQueryVariables> {
    document = UiConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUiConfigDocument = gql`
    mutation CreateUiConfig($attributes: CreateUpdateUiConfigAttributes!) {
  createUiConfig(attributes: $attributes) {
    config {
      id
      configType
      visibility
      data
      readonly
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUiConfigGQL extends Apollo.Mutation<CreateUiConfigMutation, CreateUiConfigMutationVariables> {
    document = CreateUiConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUiConfigDocument = gql`
    mutation DeleteUiConfig($deleteUiConfigId: Int!) {
  deleteUiConfig(id: $deleteUiConfigId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUiConfigGQL extends Apollo.Mutation<DeleteUiConfigMutation, DeleteUiConfigMutationVariables> {
    document = DeleteUiConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUiConfigDocument = gql`
    mutation UpdateUiConfig($attributes: CreateUpdateUiConfigAttributes!) {
  updateUiConfig(attributes: $attributes) {
    config {
      id
      configType
      visibility
      data
      readonly
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUiConfigGQL extends Apollo.Mutation<UpdateUiConfigMutation, UpdateUiConfigMutationVariables> {
    document = UpdateUiConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserFeedItemsConnectionDocument = gql`
    query UserFeedItemsConnection($last: Int, $after: String, $first: Int, $before: String) {
  userFeedConnection(last: $last, after: $after, first: $first, before: $before) {
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...userFeedItem
      }
    }
  }
}
    ${UserFeedItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFeedItemsConnectionGQL extends Apollo.Query<UserFeedItemsConnectionQuery, UserFeedItemsConnectionQueryVariables> {
    document = UserFeedItemsConnectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserFeedUnseenCountDocument = gql`
    query UserFeedUnseenCount {
  userFeedUnseenCount
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFeedUnseenCountGQL extends Apollo.Query<UserFeedUnseenCountQuery, UserFeedUnseenCountQueryVariables> {
    document = UserFeedUnseenCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserFeedItemDocument = gql`
    mutation UpdateUserFeedItem($attributes: UpdateUserFeedItemAttributes!) {
  updateUserFeedItem(attributes: $attributes) {
    userFeedItem {
      id
      seen
      read
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserFeedItemGQL extends Apollo.Mutation<UpdateUserFeedItemMutation, UpdateUserFeedItemMutationVariables> {
    document = UpdateUserFeedItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserFeedItemsDocument = gql`
    mutation UpdateUserFeedItems($attributes: [UpdateUserFeedItemAttributes!]!) {
  updateUserFeedItems(attributes: $attributes) {
    userFeedItems {
      id
      seen
      read
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserFeedItemsGQL extends Apollo.Mutation<UpdateUserFeedItemsMutation, UpdateUserFeedItemsMutationVariables> {
    document = UpdateUserFeedItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewUserFeedItemDocument = gql`
    subscription newUserFeedItem {
  newUserFeedItem {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewUserFeedItemGQL extends Apollo.Subscription<NewUserFeedItemSubscription, NewUserFeedItemSubscriptionVariables> {
    document = NewUserFeedItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDocument = gql`
    query User {
  myUser {
    id
    firstName
    lastName
    phoneNumber
    email
    state
    notifyEmail
    notifyBrowserPush
    createdAt
    updatedAt
    useV2
    externalIdentifier
    avatarFile {
      id
      filename
      url
    }
    company {
      id
      name
    }
    role
    roles {
      name
    }
    permissions {
      landingPage
      permissions {
        key
        value
      }
    }
  }
  myCompany {
    id
    name
    certificates {
      id
      expiryDate
      createdAt
      updatedAt
      certificate {
        id
        filename
        url
      }
      certificateType {
        id
        name
      }
    }
    sellers {
      id
      name
    }
    isBuyer
    buyers {
      id
      name
    }
    isSeller
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    document = UserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($employeeid: Int!) {
  deleteEmployee(employeeId: $employeeid) {
    employeeId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEmployeeGQL extends Apollo.Mutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables> {
    document = DeleteEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindOrCreateVesselDocument = gql`
    mutation FindOrCreateVessel($vesselName: String!) {
  findOrCreateVessel(vesselName: $vesselName) {
    vessel {
      id
      vesselName
    }
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindOrCreateVesselGQL extends Apollo.Mutation<FindOrCreateVesselMutation, FindOrCreateVesselMutationVariables> {
    document = FindOrCreateVesselDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }